<template>
    <app-expansion-panel
        :title="$t('admin.products.exchange.parametersOverview.title')"
        :additional-data="parametersShort"
        :loading="!tradingParametersFetched"
    >
        <app-row>
            <app-registration-col>
                <p
                    class="text-center text-sm-start"
                    v-if="lastParametersUpdate"
                    v-html="
                        $t('admin.products.exchange.parametersOverview.lastUpdate', {
                            date: formatTimestamp(lastParametersUpdate)
                        })
                    "
                />
            </app-registration-col>
        </app-row>

        <app-divider />

        <app-row>
            <app-registration-col whole-screen>
                <validation-observer ref="formTradingParameters">
                    <div class="params-container">
                        <app-text-stack class="params-container__item">
                            <template #key>
                                {{ $t('admin.products.exchange.common.purchaseRateMaxAvg') }}
                            </template>

                            <app-text-field
                                v-model="diffPercentage.purchaseRateMaxAvg"
                                :rules="{ required: true, regex: numberRegex, decimals: 4 }"
                            >
                                <template #append>
                                    %
                                </template>
                            </app-text-field>
                        </app-text-stack>

                        <app-text-stack class="params-container__item">
                            <template #key>
                                {{ $t('admin.products.exchange.common.purchaseRateAvgMaxLB') }}
                            </template>

                            <app-text-field
                                v-model="diffPercentage.purchaseRateAvgMaxLB"
                                :rules="{ required: true, regex: numberRegex, decimals: 4 }"
                            >
                                <template #append>
                                    %
                                </template>
                            </app-text-field>
                        </app-text-stack>

                        <app-text-stack class="params-container__item">
                            <template #key>
                                {{ $t('admin.products.exchange.common.saleRateMinAvg') }}
                            </template>

                            <app-text-field
                                v-model="diffPercentage.saleRateMinAvg"
                                :rules="{ required: true, regex: numberRegex, decimals: 4 }"
                            >
                                <template #append>
                                    %
                                </template>
                            </app-text-field>
                        </app-text-stack>

                        <div class="params-container__item">
                            <button-primary @click="updateParameters" full-width :loading="parametersButtonLoading">
                                {{ $t('admin.products.exchange.parametersOverview.submit') }}
                            </button-primary>
                        </div>
                    </div>
                </validation-observer>
            </app-registration-col>
        </app-row>
    </app-expansion-panel>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { mapGetters } from 'vuex';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'ParametersExpansionPanel',

        components: { AppExpansionPanel, AppTextField, ValidationObserver },

        data: () => ({
            parametersButtonLoading: false,

            parametersShort: '',

            diffPercentage: {
                purchaseRateMaxAvg: '',
                purchaseRateAvgMaxLB: '',
                saleRateMinAvg: ''
            }
        }),

        computed: {
            ...mapGetters('admin_productTrading', [
                'tradingParameters',
                'tradingParametersError',
                'tradingParametersFetched'
            ]),

            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            lastParametersUpdate() {
                return this.tradingParameters?.createdAt;
            }
        },

        methods: {
            parametersShortFormat() {
                const tradingParams = JSON.parse(JSON.stringify(this.tradingParameters?.diffPercentage));

                if (
                    tradingParams &&
                    tradingParams.purchaseRateMaxAvg &&
                    tradingParams.purchaseRateAvgMaxLB &&
                    tradingParams.saleRateMinAvg
                ) {
                    return `${tradingParams.purchaseRateMaxAvg}% - ${tradingParams.purchaseRateAvgMaxLB}% - ${tradingParams.saleRateMinAvg}%`;
                }
            },

            initParameters() {
                if (this.tradingParameters?.diffPercentage) {
                    this.parametersShort = this.parametersShortFormat();

                    const diffPercentage = this.deepCopy(this.tradingParameters?.diffPercentage);

                    for (const key in diffPercentage) {
                        diffPercentage[key] = this.convertNumberToLocale(diffPercentage[key], this.$i18n?.locale);
                    }

                    this.$set(this, 'diffPercentage', diffPercentage);
                }
            },

            updateParameters() {
                this.parametersButtonLoading = true;

                this.$refs.formTradingParameters
                    .validate()
                    .then(result => {
                        if (result) {
                            const diffPercentage = this.deepCopy(this.diffPercentage);

                            for (const key in diffPercentage) {
                                diffPercentage[key] = this.convertLocaleToNumber(diffPercentage[key]);
                            }

                            this.$store
                                .dispatch('admin_productTrading/parameters:update', diffPercentage)
                                .then(() => {
                                    this.initParameters();

                                    this.$emit('update');

                                    EventBus.$emit(
                                        'snackbar:show:success',
                                        this.$t('success.tradingParametersUpdated')
                                    );
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.parametersButtonLoading = false;
                                });
                        } else {
                            this.parametersButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.parametersButtonLoading = false;
                    });
            }
        },

        watch: {
            tradingParametersFetched() {
                this.initParameters();
            }
        },

        mounted() {
            this.initParameters();
        }
    };
</script>

<style scoped lang="scss">
    .params-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media.resolve('md-and-down') {
            justify-content: space-between;
        }

        .params-container__item {
            @include media.resolve('lg-and-up') {
                margin-right: 36px;
            }

            @include media.resolve('md-and-down') {
                width: 24%;
            }

            @include media.resolve('sm-and-down') {
                width: 48%;
            }

            @include media.resolve('xs-only') {
                width: 100%;
            }
        }
    }

    .expansion-header {
        display: flex;
        align-items: center;

        @include media.resolve('xs-only') {
            flex-direction: column;
            align-items: start;
        }

        .expansion-header__additional-info {
            margin-left: spacing.$spacing-6;
            margin-top: 0;

            @include media.resolve('xs-only') {
                margin-left: 0;
                margin-top: spacing.$spacing-3;
            }
        }
    }
</style>
