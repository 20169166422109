<template>
    <div>
        <user-digitization-dialog admin-verification :dialog="identifyUserDialog" :user="user" />

        <v-skeleton-loader
            v-if="!documentsOverviewFetched"
            :loading="true"
            type="text"
            width="500"
            class="d-flex align-center"
            :min-height="24"
            transition="fade-transition"
        >
        </v-skeleton-loader>

        <p v-else-if="documentsOverviewError" class="text-start">
            <font-awesome-icon v-color:danger :icon="['fas', 'times-circle']"></font-awesome-icon>

            <span class="ml-2">{{ $t('common.fetchingErrorOccurred') }}</span>
        </p>

        <p v-else-if="userDigitizationVerified" class="text-start">
            {{ $t('admin.users.user.documents.digitization.state.completed') }}
        </p>

        <div v-else-if="userDigitized" class="text-start">
            <p class="text-start">
                {{ $t('admin.users.user.documents.digitization.state.madeByUser') }}
            </p>

            <button-wrapper justify="start" class="mt-6">
                <button-primary @click="identifyUserDialog.open = true" full-width>
                    {{ $t('admin.users.user.documents.digitization.approveDigitization') }}
                </button-primary>
            </button-wrapper>
        </div>

        <p v-else-if="!documentsOverviewApproved && !userDigitized" class="text-start">
            {{ $t('admin.users.user.documents.digitization.state.notAvailable') }}
        </p>

        <div v-else>
            <p class="text-start">
                {{ $t('admin.users.user.documents.digitization.state.available') }}
            </p>

            <button-wrapper justify="start" class="mt-6">
                <button-primary @click="identifyUserDialog.open = true" full-width>
                    {{ $t('admin.users.user.documents.digitization.digitize') }}
                </button-primary>
            </button-wrapper>
        </div>
    </div>
</template>

<script>
    import UserDigitizationDialog from '@/components/ui/dialogs/UserDigitizationDialog';

    import { mapGetters } from 'vuex';

    export default {
        name: 'UserDigitizationProcess',

        components: { UserDigitizationDialog },

        data: () => ({
            identifyUserDialog: {
                open: false
            }
        }),

        computed: {
            ...mapGetters('admin_userDocuments', [
                'documentsOverviewApproved',
                'documentsOverviewFetched',
                'documentsOverviewError'
            ]),
            ...mapGetters('admin_userDetail', {
                userDigitized: 'user:digitized',
                userDigitizationVerified: 'user:digitizationVerified',
                user: 'user'
            })
        }
    };
</script>

<style scoped></style>
