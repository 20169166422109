<template>
    <div class="d-flex flex-column">
        <app-section-tabs
            :tabs="productsTabs"
            :i18n-prefix="productsTabs.i18nPrefix"
            v-if="$vuetify.breakpoint.mdAndUp"
        />

        <app-container unlimited-width fill-height section-padding>
            <router-view />
        </app-container>
    </div>
</template>

<script>
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';

    export default {
        name: 'Products',

        components: { AppSectionTabs },

        computed: {
            productsTabs() {
                return this.$store.getters['tabs/admin:products'];
            }
        }
    };
</script>

<style scoped></style>
