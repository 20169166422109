import axios from 'axios';

import Vue from 'vue';

import { API_INTERNAL_ERROR, API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import { deepCopy } from '@lb-world/core/public/utils/general';

import { getTablePageCurrent, getTablePageCount } from '@lb-world/core/public/models/store/TableDataset';
import { createFilters, createPageOffset } from '@lb-world/core/public/api/utils';

const BASE_CRYPTO_CURRENCY = 'USDT';

const state = {
    stateTxsTypes: [
        {
            name: 'stateTxsTypes.ALL',
            value: ''
        },
        {
            name: 'stateTxsTypes.PROCESSED',
            value: 1
        },
        {
            name: 'stateTxsTypes.NOT_PROCESSED',
            value: 0
        }
    ],
    account: {
        fetched: false,
        data: {}
    },
    users: {
        fetched: false,
        fetchError: false,
        filter: null,
        data: []
    },
    usersTree: {
        fetched: false,
        fetchError: false,
        data: []
    },
    transactions: {
        fetched: false,
        fetchError: false,
        filter: null,
        data: {}
    },
    withdrawalTransactions: {
        fetched: false,
        filter: null,
        fetchError: false,
        data: {}
    },
    usersApproval: {
        fetched: false,
        fetchError: false,
        data: []
    },
    userDetail: {
        data: {
            wallets: {
                fetched: false,
                fetchError: false,
                data: []
            },
            transactions: {
                fetched: false,
                fetchError: false,
                data: []
            },
            withdrawalTransactions: {
                fetched: false,
                fetchError: false,
                data: {}
            }
        }
    }
};

const getReferralsForDirectNodes = (acc, node) => {
    acc.push(node.user);

    for (const child of node.children) {
        getReferralsForDirectNodes(acc, child);
    }
};

const getters = {
    productTypes: state => state.productTypes,
    stateTxsTypes: state => state.stateTxsTypes,

    'account:fetched': state => state.account.fetched,
    'account:passwordChangeRequired': state => state.account.data?.passwordChangeRequired,
    'account:language': state => state.account.data?.language,

    'users:approval': state => state.usersApproval.data.items ?? [],
    'users:approval:fetched': state => state.usersApproval.fetched,
    'users:approval:error': state => state.usersApproval.fetchError,
    'users:approval:pages': state => {
        return {
            current: getTablePageCurrent(state.usersApproval.data?.start),
            length: getTablePageCount(state.usersApproval.data?.totalCount)
        };
    },

    'users:tree:fetched': state => state.usersTree.fetched,
    'users:tree:error': state => state.usersTree.fetchError,
    'users:tree:user': state => state.usersTree.data.user ?? {},
    'users:tree:children': state => state.usersTree.data?.children,
    'users:tree:referrals': state => {
        const acc = [];

        if (state.usersTree.fetched && !state.usersTree.fetchError) {
            for (const direct of state.usersTree.data?.children) {
                getReferralsForDirectNodes(acc, direct);
            }
        }

        return acc;
    },

    users: state => state.users.data.items ?? [],
    'users:fetched': state => state.users.fetched,
    'users:error': state => state.users.fetchError,
    'users:filter': state => state.users.filter,
    'users:pages': state => {
        return {
            current: getTablePageCurrent(state.users.data?.start),
            length: getTablePageCount(state.users.data?.totalCount)
        };
    },

    transactions: state => state.transactions.data.items ?? [],
    transactionsFilter: state => state.transactions.filter,
    transactionsFetched: state => state.transactions.fetched,
    transactionsError: state => state.transactions.fetchError,
    transactionsPages: state => {
        return {
            current: getTablePageCurrent(state.transactions.data?.start),
            length: getTablePageCount(state.transactions.data?.totalCount)
        };
    },

    withdrawalTransactions: state => state.withdrawalTransactions.data.items ?? [],
    withdrawalTransactionsFilter: state => state.withdrawalTransactions.filter,
    withdrawalTransactionsFetched: state => state.withdrawalTransactions.fetched,
    withdrawalTransactionsError: state => state.withdrawalTransactions.fetchError,
    withdrawalTransactionsPages: state => {
        return {
            current: getTablePageCurrent(state.withdrawalTransactions.data?.start),
            length: getTablePageCount(state.withdrawalTransactions.data?.totalCount)
        };
    },

    user: (state, getters) => id => {
        return getters['users:data'].find(u => {
            return u.id === id;
        });
    },

    'userDetail:wallets': state => state.userDetail.data.wallets.data.items ?? [],
    'userDetail:wallets:fetched': state => state.userDetail.data.wallets.fetched,
    'userDetail:wallets:error': state => state.userDetail.data.wallets.fetchError,

    'userDetail:transactions': state => state.userDetail.data.transactions.data.items ?? [],
    'userDetail:transactions:fetched': state => state.userDetail.data.transactions.fetched,
    'userDetail:transactions:error': state => state.userDetail.data.transactions.fetchError,
    'userDetail:transactions:pages': state => {
        return {
            current: getTablePageCurrent(state.userDetail.data.transactions.data?.start),
            length: getTablePageCount(state.userDetail.data.transactions.data?.totalCount)
        };
    },

    'userDetail:withdrawalTransactions': state => state.userDetail.data.withdrawalTransactions.data.items ?? [],
    'userDetail:withdrawalTransactions:fetched': state => state.userDetail.data.withdrawalTransactions.fetched,
    'userDetail:withdrawalTransactions:error': state => state.userDetail.data.withdrawalTransactions.fetchError,
    'userDetail:withdrawalTransactions:pages': state => {
        return {
            current: getTablePageCurrent(state.userDetail.data.withdrawalTransactions.data?.start),
            length: getTablePageCount(state.userDetail.data.withdrawalTransactions.data?.totalCount)
        };
    }
};

const actions = {
    'account:password:change': ({ rootGetters }, { password, newPassword }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const adminId = rootGetters['auth/token:id'];

            if (adminId) {
                axios
                    .post(`lbw/administrators/${adminId}/change-password`, {
                        password,
                        newPassword
                    })
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] AdminId is not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    update: ({ commit, rootGetters }, { user }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const adminId = rootGetters['auth/token:id'];

            if (adminId) {
                axios
                    .patch(`lbw/administrators/${adminId}`, user)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            commit('account:update', user);

                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] AdminId is not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'account:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const adminId = rootGetters['auth/token:id'];

            if (adminId) {
                axios
                    .get(`lbw/administrators/${adminId}`)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const account = response.data;

                        if (account) {
                            commit('account:save', account);

                            resolve(account);
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] AdminId is not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'users:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('users:clear');

            resolve();
        });
    },
    'users:fetch': ({ commit }, { filter, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            const pageOffset = createPageOffset(page);
            const filtersOutput = createFilters(filter);

            axios
                .get('lbw/users?' + pageOffset + '&' + filtersOutput)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const users = response.data;

                    if (users) {
                        commit('users:save', { users, filter });

                        resolve();
                    } else {
                        commit('users:error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('users:error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'users:approval:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('users:approval:clear');

            resolve();
        });
    },
    'users:approval:fetch': ({ commit }, { page = 1, filter }) => {
        Vue.$log.debug('[ACTION] Running action with API call', filter);

        const pageOffset = createPageOffset(page);
        const filterQuery = createFilters(filter);

        return new Promise((resolve, reject) => {
            axios
                .get('lbw/users?' + pageOffset + '&' + filterQuery)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const users = response.data;

                    if (users) {
                        commit('users:approval:save', users);

                        resolve();
                    } else {
                        commit('users:approval:error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('users:approval:error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'users:tree:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            axios
                .get(`lbw/user-tree/U0000001/node`)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('users:tree:save', response.data);

                        resolve();
                    } else {
                        commit('users:tree:error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('users:tree:error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'transactions:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('transactions:clear');

            resolve();
        });
    },
    'transactions:fetch': ({ commit }, { filter, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', filter, page);

        return new Promise((resolve, reject) => {
            const pageOffset = createPageOffset(page);
            const filtersOutput = createFilters(filter);

            axios
                .get('lbb/wallet-transactions?' + pageOffset + '&' + filtersOutput)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const transactions = response.data;

                    if (transactions) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', transactions);

                        commit('transactions:save', { transactions, filter });

                        resolve();
                    } else {
                        commit('transactions:error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('transactions:error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'withdrawalTransactions:accept': (_, { transactionId, data }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            axios
                .post(`lbb/wallet-withdrawals/${transactionId}/process-withdrawal`, data)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'withdrawalTransactions:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('withdrawalTransactions:clear');

            resolve();
        });
    },
    'withdrawalTransactions:cancel': ({ commit }, transaction) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            axios
                .post(`lbb/wallet-withdrawals/${transaction.id}/cancel-withdrawal`)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('withdrawalTransactions:remove', transaction);

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'withdrawalTransactions:fetch': ({ commit }, { filter, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', filter, page);

        return new Promise((resolve, reject) => {
            const pageOffset = createPageOffset(page);
            const filtersOutput = createFilters(filter);

            axios
                .get('lbb/wallet-withdrawals?' + pageOffset + '&' + filtersOutput)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const transactions = response.data;

                    if (transactions) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', transactions);

                        commit('withdrawalTransactions:save', {
                            transactions,
                            filter
                        });

                        resolve();
                    } else {
                        commit('withdrawalTransactions:error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('withdrawalTransactions:error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'userDetail:transactions:fetch': ({ commit }, { userId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] User id defined', userId);

                const pageOffset = createPageOffset(page);
                const filterQuery = createFilters({
                    user: userId
                });

                axios
                    .get('lbb/wallet-transactions?' + pageOffset + '&' + filterQuery)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const userTransactions = response.data;

                        if (userTransactions) {
                            Vue.$log.debug('[ACTION] Returning user wallet transactions', userTransactions);

                            commit('userDetail:store:data', {
                                key: 'transactions',
                                data: userTransactions
                            });

                            resolve();
                        } else {
                            commit('userDetail:store:error', 'transactions');

                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        commit('userDetail:store:error', 'transactions');

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] User id not defined');

                commit('userDetail:store:error', 'transactions');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'userDetail:transactions:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('userDetail:data:clear', 'transactions');

            resolve();
        });
    },
    'userDetail:withdrawalTransactions:fetch': ({ commit }, { userId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] User id defined', userId);

                const pageOffset = createPageOffset(page);
                const filterQuery = createFilters({
                    processed: 0,
                    user: userId
                });

                axios
                    .get('lbb/wallet-withdrawals?' + pageOffset + '&' + filterQuery)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const userTransactions = response.data;

                        if (userTransactions) {
                            Vue.$log.debug('[ACTION] Returning user wallet transactions', userTransactions);

                            commit('userDetail:store:data', {
                                key: 'withdrawalTransactions',
                                data: userTransactions
                            });

                            resolve();
                        } else {
                            commit('userDetail:store:error', 'withdrawalTransactions');

                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        commit('userDetail:store:error', 'withdrawalTransactions');

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] User id not defined');

                commit('userDetail:store:error', 'withdrawalTransactions');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'userDetail:withdrawalTransactions:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('userDetail:data:clear', 'withdrawalTransactions');

            resolve();
        });
    },
    'userDetail:wallets:fetch': ({ commit }, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] User id defined', userId);

                const filterQuery = createFilters({
                    user: userId
                });

                axios
                    .get('lbb/wallets?' + filterQuery)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const userWallets = response.data;

                        if (userWallets.items) {
                            userWallets.items = sortWallets(userWallets.items);
                        }

                        if (userWallets) {
                            Vue.$log.debug('[ACTION] Returning user wallets', userWallets);

                            commit('userDetail:store:data', {
                                key: 'wallets',
                                data: userWallets
                            });

                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] User id not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'userDetail:wallets:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('userDetail:data:clear', 'wallets');

            resolve();
        });
    },
    'userDetail:wallets:newTransaction': (_, { userId, manualTransaction }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId, manualTransaction);

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] User id defined', userId);

                axios
                    .post('lbb/wallet-transactions', {
                        userId,
                        ...manualTransaction
                    })
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] User id not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    }
};

const mutations = {
    'account:update': (state, language) => {
        Vue.$log.debug('[MUTATION] Running mutation', language);

        if (state.account.data) {
            Vue.set(state.account.data, language, language);
        }
    },
    'account:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.account.fetched = false;
        state.account.data = {};
    },
    'account:save': (state, account) => {
        Vue.$log.debug('[MUTATION] Running mutation', account);

        state.account.fetched = true;
        state.account.data = account;
    },
    'users:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.users.fetched = false;
        state.users.fetchError = false;
        state.users.data = {};
    },
    'users:error': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.users.fetched = true;
        state.users.fetchError = true;
        state.users.filter = null;
        state.users.data = {};
    },
    'users:save': (state, { users, filter }) => {
        Vue.$log.debug('[MUTATION] Running mutation', users);

        state.users.filter = deepCopy(filter);
        state.users.fetched = true;
        state.users.data = users;
    },
    'users:tree:save': (state, usersTree) => {
        Vue.$log.debug('[MUTATION] Running mutation', usersTree);

        state.usersTree.fetched = true;
        state.usersTree.fetchError = false;
        state.usersTree.data = usersTree;
    },
    'users:tree:error': (state, usersTree) => {
        Vue.$log.debug('[MUTATION] Running mutation', usersTree);

        state.usersTree.fetched = true;
        state.usersTree.fetchError = true;
        state.usersTree.data = {};
    },
    'transactions:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.transactions.fetched = false;
        state.transactions.fetchError = false;
        state.transactions.data = {};
    },
    'transactions:error': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.transactions.filter = null;
        state.transactions.fetched = true;
        state.transactions.fetchError = true;
        state.transactions.data = {};
    },
    'transactions:save': (state, { transactions, filter }) => {
        Vue.$log.debug('[MUTATION] Running mutation', transactions, filter);

        state.transactions.filter = deepCopy(filter);
        state.transactions.fetched = true;
        state.transactions.data = transactions;
    },
    'withdrawalTransactions:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.withdrawalTransactions.fetched = false;
        state.withdrawalTransactions.fetchError = false;
        state.withdrawalTransactions.data = {};
    },
    'withdrawalTransactions:error': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.withdrawalTransactions.fetched = true;
        state.withdrawalTransactions.fetchError = true;
        state.withdrawalTransactions.filter = null;
        state.withdrawalTransactions.data = {};
    },
    'withdrawalTransactions:remove': (state, transaction) => {
        Vue.$log.debug('[MUTATION] Running mutation');

        const index = state.withdrawalTransactions.data?.items?.findIndex(txs => txs.id === transaction.id);
        const indexDetail = state.userDetail.data.withdrawalTransactions.data?.items?.findIndex(
            txs => txs.id === transaction.id
        );

        if (index !== undefined && index !== -1) {
            Vue.delete(state.withdrawalTransactions.data?.items, index);
        }

        if (indexDetail !== undefined && indexDetail !== -1) {
            Vue.delete(state.userDetail.data.withdrawalTransactions.data?.items, indexDetail);
        }
    },
    'withdrawalTransactions:save': (state, { filter, transactions }) => {
        Vue.$log.debug('[MUTATION] Running mutation', transactions);

        state.withdrawalTransactions.fetched = true;
        state.withdrawalTransactions.fetchError = false;
        state.withdrawalTransactions.filter = deepCopy(filter);
        state.withdrawalTransactions.data = transactions;
    },
    'users:approval:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.usersApproval.fetched = false;
        state.usersApproval.fetchError = false;
        state.usersApproval.data = {};
    },
    'users:approval:error': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.usersApproval.fetched = true;
        state.usersApproval.fetchError = true;
        state.usersApproval.data = {};
    },
    'users:approval:save': (state, users) => {
        Vue.$log.debug('[MUTATION] Running mutation', users);

        state.usersApproval.fetched = true;
        state.usersApproval.data = users;
    },
    'userDetail:store:data': (state, { key, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', { key, data });

        state.userDetail.data[key].fetched = true;
        state.userDetail.data[key].data = data;
    },
    'userDetail:store:error': (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state.userDetail.data[key].fetched = true;
        state.userDetail.data[key].fetchError = true;
        state.userDetail.data[key].data = {};
    },
    'userDetail:data:clear': (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.userDetail.data[key].data = [];
        state.userDetail.data[key].fetched = false;

        if (state.userDetail.data[key].fetchError) {
            state.userDetail.data[key].fetchError = false;
        }
    },
    'userDetail:account:update': (state, user) => {
        Vue.$log.debug('[MUTATION] Running mutation', user);

        for (let [key, value] of Object.entries(user)) {
            Vue.set(state.userDetail.data.info.data, key, value);
        }
    },
    'userDetail:data:update': (state, { section, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', section, data);

        for (let [key, value] of Object.entries(data)) {
            Vue.set(state.userDetail.data[section].data, key, value);
        }
    },
    'userDetail:clear': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(state.userDetail.data)) {
            state.userDetail.data[key].data = [];
            state.userDetail.data[key].fetched = false;

            if (state.userDetail.data[key].fetchError) {
                state.userDetail.data[key].fetchError = false;
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export const sortWallets = wallets => {
    return wallets.sort((a, b) => {
        if (a.currency === BASE_CRYPTO_CURRENCY) {
            return 1;
        } else if (b.currency === BASE_CRYPTO_CURRENCY) {
            return -1;
        } else if (a.currency.length < b.currency.length) {
            return -1;
        } else if (a.currency.length > b.currency.length) {
            return 1;
        }

        return 0;
    });
};
