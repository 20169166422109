var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-expansion-panel',{attrs:{"title":_vm.$t('admin.products.exchange.rateOverview.title'),"additional-data":_vm.getRateFormat,"loading":!_vm.tradeItemsFetched,"disabled":!_vm.tradeItemsFetched || _vm.tradeItemsError}},[_c('app-text',[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('admin.products.exchange.rateOverview.currentRate', {
                    rate: _vm.getRateFormat
                })
            )}})]),_c('app-text',[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('admin.products.exchange.rateOverview.lastUpdate', {
                    date: _vm.lastRateUpdate
                })
            )}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }