<template>
    <validation-observer ref="formAdminUserProfileBankAccountEdit" v-slot="{ invalid }">
        <app-row class="mt-6">
            <app-registration-col field>
                <app-text-field
                    :label="$t('bankAccount.bankName')"
                    v-model="userEdit.bankAccount.bankName"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="$t('bankAccount.bankCode')"
                    v-model="userEdit.bankAccount.code"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row class="mt-2">
            <app-registration-col field>
                <app-text-field
                    :label="$t('bankAccount.accountPrefix')"
                    v-model="userEdit.bankAccount.accountNumberPrefix"
                    optional
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="$t('bankAccount.accountNumber')"
                    v-model="userEdit.bankAccount.accountNumber"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row class="mt-2">
            <app-registration-col field>
                <app-text-field label="IBAN" v-model="userEdit.bankAccount.iban" rules="required"></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field label="SWIFT" v-model="userEdit.bankAccount.swift" rules="required"></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col>
                <button-primary full-width :disabled="invalid" :loading="submitButtonLoading" @click="submit">
                    {{ $t('bankAccount.submit') }}
                </button-primary>

                <button-secondary
                    full-width
                    @click="reset"
                    :class="{
                        'ml-2': $vuetify.breakpoint.smAndUp,
                        'mt-2': $vuetify.breakpoint.xsOnly
                    }"
                >
                    {{ $t('admin.users.user.profile.reset') }}
                </button-secondary>
            </app-registration-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'InfoBankAccount',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: ['user'],

        data: () => ({
            userEdit: {
                bankAccount: {
                    bankName: '',
                    swift: '',
                    iban: '',
                    accountNumberPrefix: '',
                    accountNumber: '',
                    code: ''
                }
            },

            submitButtonLoading: false
        }),

        methods: {
            fillData() {
                this.$set(this.userEdit, 'bankAccount', JSON.parse(JSON.stringify(this.user.bankAccount)));
            },
            reset() {
                this.fillData();
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formAdminUserProfileBankAccountEdit
                    .validate()
                    .then(result => {
                        if (result) {
                            if (this.userEdit.bankAccount.accountNumberPrefix === '') {
                                this.userEdit.bankAccount.accountNumberPrefix = null;
                            }

                            this.$store
                                .dispatch('admin_userDetail/update', this.userEdit)
                                .then(() => {
                                    this.$emit('user:update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped></style>
