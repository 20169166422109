<template>
    <app-card table left class="py-6">
        <app-title>Limits</app-title>

        <purchase-limits-panels whole-screen @user:fetch="fetchUserLimits" />

        <purchase-limits-table-legend with-divider />
    </app-card>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import PurchaseLimitsPanels from '@/components/module-admin/user/purchase-limits/PurchaseLimitsPanels';
    import PurchaseLimitsTableLegend from '@/components/ui/purchase-limits/PurchaseLimitsTableLegend';

    export default {
        name: 'UserLimits',

        components: { PurchaseLimitsTableLegend, PurchaseLimitsPanels },

        methods: {
            fetchUserLimits() {
                this.$store.dispatch('admin_aml/user:clear').then(() => {
                    this.$store.dispatch('admin_aml/user:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });
            }
        },

        mounted() {
            this.fetchUserLimits();
        }
    };
</script>

<style scoped></style>
