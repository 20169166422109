<template>
    <div>
        <app-title>{{ $t('productPicker.selectProduct') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <v-chip-group class="app-chips" column v-if="mode === 'routing'">
                    <v-chip v-for="product in productsTabs.data" :key="product.name" exact :to="{ name: product.path }">
                        <font-awesome-icon :icon="['fad', product.icon]" />
                        <span class="ml-2">
                            {{ $t(productsTabs.i18nPrefix + '.' + product.id) }}
                        </span>
                    </v-chip>
                </v-chip-group>

                <v-chip-group class="app-chips" column v-else-if="mode === 'selectable'">
                    <v-chip
                        link
                        v-for="product in productsTabs.data"
                        :key="product.name"
                        @click="$emit('select', product)"
                    >
                        <font-awesome-icon :icon="['fad', product.icon]" />
                        <span class="ml-2">
                            {{ $t(productsTabs.i18nPrefix + '.' + product.id) }}
                        </span>
                    </v-chip>
                </v-chip-group>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    export default {
        name: 'ProductPicker',

        props: {
            mode: {
                type: String,
                validator: v => ['routing', 'selectable'].includes(v),
                default: 'routing'
            },
            productsTabs: {
                type: Object,
                required: true
            }
        }
    };
</script>

<style scoped lang="scss">
    .v-chip.v-slide-item--active {
        color: colors.$color-primary;
    }

    .v-chip {
        color: colors.$black-50;
    }
</style>
