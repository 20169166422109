<template>
    <vt-card>
        <v-row>
            <v-col class="text-left">
                <app-logo :logo-id="service.id" size="md"> </app-logo>
            </v-col>
            <v-col v-if="service.version" class="service-version text-right align-self-center">
                {{ service.version || '1.0.0' }}
            </v-col>
        </v-row>
        <v-row>
            <v-col class="service-description">
                {{ $t(service.description) }}
            </v-col>
        </v-row>
        <v-row class="mt-6">
            <v-col class="text-center text-sm-left">
                <vt-button
                    @click="redirectToApp(service.id)"
                    :color="getButtonColors(service.id).color"
                    :bg="getButtonColors(service.id).bg"
                    >{{ $t(getButtonVisitText(service.id)) }}</vt-button
                >
            </v-col>
        </v-row>
    </vt-card>
</template>

<script>
    import { getAppUrlFormat } from '@/utils';
    export default {
        name: 'VtServiceCard',
        props: {
            service: Object
        },
        methods: {
            redirectToApp(app) {
                window.open(getAppUrlFormat(app), '_blank');
            },
            getButtonVisitText(integration) {
                switch (integration) {
                    case 'finance':
                        return 'bo.services.visitBusinessman';
                    case 'broker':
                        return 'bo.services.visitClients';
                    default:
                        return 'bo.services.visit';
                }
            },
            getButtonColors(integration) {
                switch (integration) {
                    case 'finance':
                        return { color: 'rgba(8, 130, 184, 1)', bg: 'rgba(201, 239, 255, 1)' };
                    case 'broker':
                        return { color: 'rgba(228, 86, 46, 1)', bg: 'rgba(255, 199, 184, 1)' };
                    case 'technology':
                        return { color: 'rgba(12, 142, 113, 1)', bg: 'rgba(191, 253, 239, 1)' };
                    default:
                        return { color: 'rgba(219, 122, 55, 1)', bg: 'rgba(242, 182, 140, 1)' };
                }
            }
        }
    };
</script>

<style scoped lang="scss"></style>
