<template>
    <div>
        <app-error v-if="parametersError" horizontal fetching />

        <app-row v-else>
            <app-registration-col whole-screen>
                <v-expansion-panels>
                    <app-expansion-panel :title="$t('admin.productsSettings.title')" :loading="!parametersFetched">
                        <validation-observer ref="formProductsSettingsThroughputForm">
                            <app-flex class="mt-6">
                                <app-flex-item class="three-items">
                                    <app-text-field
                                        :rules="{ required: true, regex: numberRegex, decimals: 4 }"
                                        :label="$t('admin.productsSettings.throughput.efkPurchase')"
                                        v-model="throughputEFKPurchase"
                                    >
                                        <template #append>
                                            {{ throughputEFKPurchaseUnit }}
                                        </template>
                                    </app-text-field>
                                </app-flex-item>

                                <app-flex-item class="three-items">
                                    <app-text-field
                                        :rules="{ required: true, regex: numberRegex, decimals: 4 }"
                                        :label="$t('admin.productsSettings.throughput.efkLiquidity')"
                                        v-model="throughputEFKLiquidity"
                                    >
                                        <template #append>
                                            {{ throughputEFKLiquidityUnit }}
                                        </template>
                                    </app-text-field>
                                </app-flex-item>

                                <app-flex-item class="three-items">
                                    <button-primary full-width @click="setParameters">
                                        {{ $t('admin.productsSettings.set') }}
                                    </button-primary>
                                </app-flex-item>
                            </app-flex>
                        </validation-observer>
                    </app-expansion-panel>
                </v-expansion-panels>
            </app-registration-col>
        </app-row>

        <app-divider />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { mapGetters } from 'vuex';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'ProductsSettings',

        components: { AppExpansionPanel, AppFlexItem, AppFlex, AppTextField, ValidationObserver },

        computed: {
            ...mapGetters('admin_products', ['parameters', 'parametersError', 'parametersFetched']),

            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            throughputEFKPurchaseUnit() {
                return this.parameters['throughput__efk_purchase']?.unit;
            },
            throughputEFKPurchase: {
                set(v) {
                    this.throughputs['throughput__efk_purchase'] = { value: v, name: 'throughput__efk_purchase' };
                },
                get() {
                    return (
                        this.throughputs?.['throughput__efk_purchase']?.value ??
                        this.parameters['throughput__efk_purchase']?.value
                    );
                }
            },
            throughputEFKLiquidityUnit() {
                return this.parameters['throughput__efk_liquidity']?.unit;
            },
            throughputEFKLiquidity: {
                set(v) {
                    this.throughputs['throughput__efk_liquidity'] = { value: v, name: 'throughput__efk_liquidity' };
                },
                get() {
                    return (
                        this.throughputs?.['throughput__efk_liquidity']?.value ??
                        this.parameters['throughput__efk_liquidity']?.value
                    );
                }
            }
        },

        data: () => ({
            throughputs: {}
        }),

        methods: {
            setParameters() {
                this.$refs.formProductsSettingsThroughputForm.validate().then(result => {
                    if (result) {
                        for (const product in this.throughputs) {
                            const productThroughputs = this.throughputs[product];

                            if (productThroughputs?.value !== this.parameters[product]?.value) {
                                this.$store
                                    .dispatch('admin_products/parameters:update', {
                                        name: productThroughputs.name,
                                        value: productThroughputs.value
                                    })
                                    .then(() => {
                                        EventBus.$emit(
                                            'snackbar:show:success',
                                            this.$t('success.productSettingsUpdated')
                                        );
                                    });
                            }
                        }
                    }
                });
            }
        },

        mounted() {
            if (!this.parametersFetched) {
                this.$store.dispatch('admin_products/parameters:fetch');
            }
        }
    };
</script>

<style scoped></style>
