<template>
    <app-text-stack justify="left" page-title-value :space-delimiter="4" v-if="stateLoaded">
        <template #key>
            <span class="basic-color" v-color:[documentGroupColor]>
                {{ $t('bo.documents.' + documentGroupName) }}
            </span>
        </template>

        <span class="doc-info-count">{{ documentsGroupCount }}</span>
        <span v-if="showBatch" class="mt-2 doc-info-batch">
            {{ $t('bo.documents.state.not_uploaded') }}
        </span>
    </app-text-stack>

    <app-loader v-else justify="center" class="mt-3" />
</template>

<script>
    export default {
        name: 'DocumentGroupInfo',

        props: ['stateLoaded', 'documentGroupName', 'documentsGroupCount', 'documentGroupColor', 'showBatch']
    };
</script>

<style scoped lang="scss"></style>
