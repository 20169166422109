<template>
    <validation-observer ref="formUserReferralUpdate">
        <app-title>{{ $t('admin.users.user.profile.referralChange') }}</app-title>

        <app-row v-if="!userFetched">
            <app-registration-col>
                <app-loader wrapper horizontal />
            </app-registration-col>
        </app-row>

        <app-row v-else-if="userError">
            <app-registration-col>
                <app-error fetching horizontal />
            </app-registration-col>
        </app-row>

        <app-row class="mt-6" v-else-if="user.id !== 'U0000001'">
            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.users.user.profile.referralId')"
                    v-model="userEdit.referral.id"
                    rules="required"
                />

                <app-registration-col whole-screen class="pt-0 pl-0">
                    <p class="text-note text-start">
                        <span>
                            {{ $t('admin.users.user.profile.referralEmail') }}
                            <mark>{{ userEdit.referral.email }}</mark
                            >.
                        </span>
                    </p>
                </app-registration-col>
            </app-registration-col>

            <app-registration-col field>
                <button-wrapper justify="start">
                    <button-primary full-width @click="updateReferral" :loading="referralUpdateButtonLoading">
                        {{ $t('admin.users.user.profile.referralUpdateButton') }}
                    </button-primary>
                </button-wrapper>
            </app-registration-col>
        </app-row>

        <app-row v-else>
            <app-registration-col whole-screen>
                <p class="text-start">
                    {{ $t('admin.users.user.profile.referralEmailChangeDisabled') }}
                </p>
            </app-registration-col>
        </app-row>

        <app-divider />
    </validation-observer>
</template>

<script>
    import { mapGetters } from 'vuex';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'UserReferralUpdate',

        components: { AppTextField, ValidationObserver },

        computed: mapGetters('admin_userDetail', ['user', 'userFetched', 'userError']),

        data: () => ({
            referralUpdateButtonLoading: false,

            userEdit: {
                referral: {
                    id: ''
                }
            }
        }),

        methods: {
            fillData() {
                if (this.user?.referral) {
                    this.$set(this.userEdit, 'referral', JSON.parse(JSON.stringify(this.user.referral)));
                }
            },
            updateReferral() {
                this.$refs.formUserReferralUpdate.validate().then(result => {
                    if (result) {
                        if (this.userEdit.referral.id !== this.user.referral.id) {
                            this.referralUpdateButtonLoading = true;

                            this.$store
                                .dispatch('admin_userDetail/update:referral', this.userEdit.referral.id)
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));

                                    this.$emit('update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.referralUpdateButtonLoading = false;
                                });
                        }
                    }
                });
            }
        },

        watch: {
            userFetched() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped></style>
