<template>
    <div>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    label="Titul"
                    v-model="user.personalInfo.degree"
                    rules="required"
                    readonly
                    :disabled="user.personalInfo.degree === ''"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field v-model="user.personalInfo.sex" label="Pohlaví" rules="required" readonly />
            </app-registration-col>
        </app-row>
        <app-row class="mt-2">
            <app-registration-col field>
                <app-text-field
                    label="Datum narození"
                    v-model="user.personalInfo.dateOfBirth"
                    readonly
                    rules="required"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    label="Rodné číslo"
                    v-model="user.personalInfo.personalNumber"
                    readonly
                    rules="required"
                ></app-text-field>
            </app-registration-col>
        </app-row>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    label="Místo narození"
                    v-model="user.personalInfo.placeOfBirth"
                    rules="required"
                    readonly
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    label="Stát narození"
                    :value="parseCountry(user.personalInfo.countryOfBirth)"
                    rules="required"
                    readonly
                    :disabled="parseCountry(user.personalInfo.countryOfBirth) === null"
                ></app-text-field>
            </app-registration-col>
        </app-row>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    label="Státní občanství"
                    :value="parseCountry(user.personalInfo.nationality)"
                    rules="required"
                    readonly
                    :disabled="parseCountry(user.personalInfo.nationality) === null"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field />
        </app-row>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import countries, { convertCodeToName } from '@/countries';

    export default {
        name: 'InfoAdvancedTab',

        components: {
            AppTextField
        },

        props: ['user'],

        methods: {
            parseCountry(value) {
                return convertCodeToName(value, countries);
            }
        }
    };
</script>

<style scoped></style>
