<template>
    <v-menu bottom offset-y content-class="rounded-xl elevation-2">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="btn-hov btn-lang-div"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                v-ripple="{ class: `primary--text` }"
                :class="{ xs: xs }"
            >
                <div>
                    <img height="22" class="vertical-middle" src="@/assets/logos/menu-from-dots.svg" />
                </div>
            </v-btn>
        </template>
        <div class="pa-6 rounded-xl lang-modal-card">
            <div class="text-center mb-8 lang-modal-title">
                {{ $t('bo.optionSwitcher.switchApp') }}
            </div>
            <v-row class="ma-0">
                <template v-for="app in apps">
                    <button-secondary
                        v-if="currentApp !== app.id"
                        inline
                        justify="start"
                        class="app-select-container__item col-12 ma-0 pa-0 pl-2"
                        :key="app.id"
                        @click="redirectToApp(app.id)"
                    >
                        <app-logo :logo-id="app.id" size="sm">
                            {{ app.name }}
                        </app-logo>
                    </button-secondary>
                </template>
            </v-row>
        </div>
    </v-menu>
</template>

<script>
    import { getAppUrlFormat } from '@/utils';
    import { LBServicesFactory } from '@lb-world/core/public/static/apps';
    import { CURRENT_APP } from '@/constants';

    export default {
        name: 'VtAppMenu',

        props: {
            xs: Boolean,
            justify: {
                type: String,
                default: 'center',
                validator: v => ['center', 'start', 'end'].includes(v)
            }
        },

        data: () => ({
            currentApp: CURRENT_APP
        }),

        computed: {
            apps() {
                return LBServicesFactory.appsDeployed();
            }
        },

        methods: {
            redirectToApp(app) {
                window.location = getAppUrlFormat(app);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
