<template>
    <validation-observer ref="formCompanyDigitizationInfo">
        <app-card>
            <app-row justify="center" class="mt-6">
                <app-col half>
                    <app-text-field
                        :label="$t('admin.companyDigitization.name')"
                        v-model="digitizationInfo.name"
                        rules="required"
                    ></app-text-field>
                </app-col>
                <app-col half>
                    <app-text-field
                        :label="$t('admin.companyDigitization.surname')"
                        v-model="digitizationInfo.surname"
                        rules="required"
                    ></app-text-field>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col>
                    <app-text-field
                        :label="$t('admin.companyDigitization.company')"
                        v-model="digitizationInfo.company"
                        rules="required"
                    ></app-text-field>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col half>
                    <app-text-field
                        :label="$t('admin.companyDigitization.idNumber')"
                        v-model="digitizationInfo.ico"
                        rules="required"
                    ></app-text-field>
                </app-col>
                <app-col half>
                    <app-text-field
                        :label="$t('admin.companyDigitization.taxNumber')"
                        v-model="digitizationInfo.dic"
                        rules="required"
                    ></app-text-field>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col>
                    <app-select-field
                        :items="companyRoles"
                        v-model="digitizationInfo.role"
                        item-text="name"
                        item-value="value"
                        :label="$t('admin.companyDigitization.role')"
                        rules="required"
                    />
                </app-col>
            </app-row>

            <template #button-primary>
                <button-primary @click="submit">
                    {{ $t('admin.digitizationDialog.continue') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import AppCol from '@/components/ui/layout/AppCol';
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppSelectField from '@/components/ui/form/AppSelectField';

    import { ValidationObserver } from 'vee-validate';

    import { CompanyRoles } from '@lb-world/core/public/static/roles';

    export default {
        name: 'DigitizationCompanyInfo',

        components: {
            AppSelectField,
            AppTextField,
            AppCol,
            ValidationObserver
        },

        props: {
            digitizationInfo: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            companyRoles: CompanyRoles.ROLES
        }),

        methods: {
            submit() {
                this.$refs.formCompanyDigitizationInfo.validate().then(result => {
                    if (result) {
                        this.$emit('process:next');
                    }
                });
            }
        }
    };
</script>

<style scoped></style>
