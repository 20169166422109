<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.inheritance.amount') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.inheritance.amount')"
            v-model="details['Hodnota zděděné pozůstalosti']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.inheritance.name') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.inheritance.name')"
            v-model="details['Jméno zůstavitele']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.inheritance.name') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.inheritance.name')"
            v-model="details['Váš vztah k zůstaviteli']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'Inheritance',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Hodnota zděděné pozůstalosti', '');
            this.$set(this.details, 'Jméno zůstavitele', '');
            this.$set(this.details, 'Váš vztah k zůstaviteli', '');
        }
    };
</script>

<style scoped></style>
