<template>
    <div>
        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:item.actions="{ item }">
                <button-secondary inline icon="file-alt" @click="openUserDetailProfile(item)">
                    {{ $t('admin.documents.documents') }}
                </button-secondary>
            </template>

            <template v-slot:item.name="{ item }">
                {{ getName(item) }}
            </template>

            <template v-slot:item.surname="{ item }">
                {{ getSurname(item) }}
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners"></table-pagination>
    </div>
</template>

<script>
    import { core, pagination } from '@lb-world/core/public/components/tables/mixins';

    export default {
        name: 'AdminUsersApprovalTable',

        mixins: [core, pagination],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.e-mail'),
                        value: 'email'
                    },
                    {
                        text: this.$t('table.columns.name'),
                        value: 'name'
                    },
                    {
                        text: this.$t('table.columns.surname'),
                        value: 'surname'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions'
                    }
                ];
            }
        },

        methods: {
            getName(item) {
                return item.personalInfo.name ?? item.name;
            },
            getSurname(item) {
                return item.personalInfo.surname ?? item.surname;
            },
            openUserDetailProfile(item) {
                const routeName = this.$route.name;

                this.$store
                    .dispatch('admin_userDetail/meta', {
                        key: 'returnTo',
                        data: routeName
                    })
                    .then(() => {
                        this.$router.push({
                            name: 'admin:user:documents',
                            params: { id: item.id }
                        });
                    });
            }
        }
    };
</script>

<style scoped></style>
