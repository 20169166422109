<template>
    <admin-product-accounts-table
        :items="pa"
        :loading="!paFetched"
        :fetch-error="paError"
        :pages="paPages"
        :filters.sync="productTable.filters"
        :pre-applied="productTable.preAppliedFilters"
        filtering-enabled
        @pagination:change="fetchProductAccounts"
        @filter:update="filterProductAccounts"
        @filter:reset="filterProductAccounts"
    />
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AdminProductAccountsTable from '@/components/ui/tables/AdminProductAccountsTable';

    export default {
        name: 'ProductAccountsTab',

        components: {
            AdminProductAccountsTable
        },

        data: () => ({
            productTable: {
                preAppliedFilters: null,
                page: 1,
                filters: {
                    id: '',
                    user: '',
                    product: '',
                    vs: '',
                    rateFrom: '',
                    rateTo: ''
                }
            }
        }),

        computed: mapGetters('admin_productAccounts', ['pa', 'paFetched', 'paError', 'paPages', 'paFilter']),

        methods: {
            fetchProductAccounts(page) {
                this.productTable.page = page;

                this.$store.dispatch('admin_productAccounts/pa:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/pa:fetch', {
                            page,
                            filters: this.productTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterProductAccounts(filters) {
                this.productTable.filters = filters;
                this.productTable.page = 1;

                this.$store.dispatch('admin_productAccounts/pa:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/pa:fetch', {
                            page: this.productTable.page,
                            filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.paFilter) {
                this.$set(this.productTable, 'preAppliedFilters', this.paFilter);
            }

            if (!this.paFetched) {
                this.fetchProductAccounts();
            }
        }
    };
</script>

<style scoped></style>
