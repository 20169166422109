<template>
    <div>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.companyDigitization.name')"
                    v-model="company.name"
                    rules="required"
                    readonly
                />
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.companyDigitization.surname')"
                    v-model="company.surname"
                    rules="required"
                    readonly
                />
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.companyDigitization.company')"
                    v-model="company.company"
                    rules="required"
                    readonly
                />
            </app-registration-col>

            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.companyDigitization.idNumber')"
                    v-model="company.ico"
                    rules="required"
                    readonly
                />
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col field>
                <app-text-field
                    :label="$t('admin.companyDigitization.taxNumber')"
                    v-model="company.dic"
                    rules="required"
                    readonly
                />
            </app-registration-col>

            <app-registration-col field>
                <app-text-field
                    :value="companyRole"
                    :label="$t('admin.companyDigitization.role')"
                    rules="required"
                    readonly
                />
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import { CompanyRoles } from '@lb-world/core/public/static/roles';

    export default {
        name: 'CompanyInfo',

        components: { AppTextField },

        props: ['company'],

        computed: {
            companyRole() {
                return CompanyRoles.findName(this.company.role);
            }
        }
    };
</script>

<style scoped></style>
