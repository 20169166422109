var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hasUserAddress)?_c('app-row',{staticClass:"mt-6"},[_c('app-registration-col',{attrs:{"whole-screen":"","field":""}},[_c('hr'),_c('p',{staticClass:"mt-6 mb-6"},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.profile.addressPhoneNotFilled'))+" ")]),_c('hr')])],1):_c('validation-observer',{ref:"formAdminUserProfileAddressEdit",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('app-row',{staticClass:"mt-6"},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"label":_vm.$t('address.streetLine', {
                        number: '1'
                    }),"rules":"required"},model:{value:(_vm.userEdit.address.line1),callback:function ($$v) {_vm.$set(_vm.userEdit.address, "line1", $$v)},expression:"userEdit.address.line1"}})],1),_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"label":_vm.$t('address.streetLine', {
                        number: '2'
                    })},model:{value:(_vm.userEdit.address.line2),callback:function ($$v) {_vm.$set(_vm.userEdit.address, "line2", $$v)},expression:"userEdit.address.line2"}})],1)],1),_c('app-row',{staticClass:"mt-2"},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"label":_vm.$t('address.city'),"rules":"required"},model:{value:(_vm.userEdit.address.city),callback:function ($$v) {_vm.$set(_vm.userEdit.address, "city", $$v)},expression:"userEdit.address.city"}})],1),_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"label":_vm.$t('address.postalCode'),"rules":"required"},model:{value:(_vm.userEdit.address.postalCode),callback:function ($$v) {_vm.$set(_vm.userEdit.address, "postalCode", $$v)},expression:"userEdit.address.postalCode"}})],1)],1),_c('app-row',{staticClass:"mt-2"},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-country-select',{attrs:{"label":_vm.$t('address.country'),"required":""},model:{value:(_vm.userEdit.address.country),callback:function ($$v) {_vm.$set(_vm.userEdit.address, "country", $$v)},expression:"userEdit.address.country"}})],1),_c('app-registration-col',{attrs:{"field":""}},[_c('app-phone-number-field',{attrs:{"disabled-fetching-country":"","default-country-dial":_vm.countryDial,"placeholder":_vm.$t('address.phone'),"rules":{ required: true, regex: _vm.numberRegexp }},on:{"country-changed":_vm.countryChanged},model:{value:(_vm.userEdit.phone.number),callback:function ($$v) {_vm.$set(_vm.userEdit.phone, "number", $$v)},expression:"userEdit.phone.number"}})],1)],1),_c('app-row',{class:{
            'mt-6': _vm.$vuetify.breakpoint.xsOnly
        }},[_c('app-registration-col',[_c('button-primary',{attrs:{"full-width":"","disabled":invalid,"loading":_vm.submitButtonLoading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('address.submit'))+" ")]),_c('button-secondary',{class:{
                    'ml-2': _vm.$vuetify.breakpoint.smAndUp,
                    'mt-2': _vm.$vuetify.breakpoint.xsOnly
                },attrs:{"full-width":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.profile.reset'))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }