<template>
    <app-row justify="center">
        <app-registration-col class="ma-0 pa-0">
            <app-card class="ala-carda">
                <div class="main-title justify-center">{{ $t('reg.userAddress.title') }}</div>

                <validation-observer ref="formUserAddress">
                    <app-row justify="center" class="mt-6 mb-0">
                        <app-registration-col addressField class="pt-0 pb-0">
                            <app-text-field
                                name="address-line1"
                                :placeholder="$t('reg.userAddress.addressPlaceholder')"
                                rules="required"
                                :label="$t('reg.userAddress.streetLine')"
                                v-model="user.address.line1"
                            ></app-text-field>
                        </app-registration-col>
                        <app-registration-col addressNumberField class="pt-0 pb-0">
                            <app-text-field
                                name="address-line2"
                                placeholder=" "
                                :label="$t('reg.userAddress.number')"
                                v-model="user.address.line2"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-6">
                        <app-registration-col addressField class="pt-0 pb-0">
                            <app-text-field
                                name="city"
                                :placeholder="$t('reg.userAddress.cityPlaceholder')"
                                rules="required"
                                :label="$t('reg.userAddress.city')"
                                v-model="user.address.city"
                            ></app-text-field>
                        </app-registration-col>
                        <app-registration-col addressNumberField class="pt-0 pb-0">
                            <app-text-field
                                name="zip|postal-code"
                                placeholder=" "
                                rules="required"
                                :label="$t('reg.userAddress.postalCode')"
                                v-model="user.address.postalCode"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-6">
                        <app-registration-col wholeScreen class="pt-0 pb-0">
                            <app-country-select
                                :label="$t('reg.userAddress.country')"
                                v-model="user.address.country"
                                required
                                :placeholder="$t('reg.userAddress.countryPlaceholder')"
                            />
                        </app-registration-col>
                        <app-registration-col wholeScreen class="pt-10 pb-0">
                            <app-phone-number-field
                                name="phonenumber"
                                v-model="user.phone.number"
                                :rules="{ required: true, regex: numberRegexp }"
                                :placeholder="$t('reg.userAddress.phonePlaceholder')"
                                :label="$t('reg.userAddress.phone')"
                                @country-changed="countryChanged"
                            ></app-phone-number-field>
                        </app-registration-col>
                    </app-row>
                    <v-row justify="center" class="mt-16 flex-nowrap">
                        <v-col class="text-left pr-0">
                            <vt-button-secondary
                                class="return-button registration-address-button-secondary"
                                small
                                @click="$router.go(-1)"
                            >
                                <span class="back-font">
                                    <img class="mr-2" src="@/assets/icons/button-arrow-left.svg" />
                                    {{ $t('reg.userDetails.return') }}
                                </span>
                            </vt-button-secondary>
                        </v-col>
                        <v-col class="text-right pl-0 pr-0">
                            <button-primary
                                class="registration-address-button-primary"
                                :loading="continueButton.loading"
                                @click="updateUser"
                                full-width
                            >
                                {{ $t('reg.userAddress.continue') }}
                                <img class="ml-2" src="@/assets/icons/button-arrow-right.svg" />
                            </button-primary>
                        </v-col>
                    </v-row>
                </validation-observer>
            </app-card>
        </app-registration-col>
    </app-row>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import { getIntegerNumberRegex } from '@lb-world/core/public/utils/regexp';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '../../../components/ui/form/VtAppTextField';
    import AppPhoneNumberField from '../../../components/ui/form/AppPhoneNumberField';
    import AppCountrySelect from '@/components/ui/form/VtAppCountrySelect';

    export default {
        name: 'UserAddress',

        components: {
            AppCountrySelect,
            AppPhoneNumberField,
            AppTextField,
            ValidationObserver
        },

        data: () => ({
            continueButton: {
                loading: false
            },
            user: {
                phone: {
                    countryCallingCode: '',
                    number: ''
                },
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            },

            numberRegexp: getIntegerNumberRegex()
        }),

        methods: {
            countryChanged(country) {
                this.user.phone.countryCallingCode = country.dialCode ? '+' + country.dialCode : '';
            },
            updateUser() {
                this.continueButton.loading = true;

                this.$refs.formUserAddress
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('user/update', {
                                    user: this.user
                                })
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.registrationCompleted'));

                                    this.$router.replace({
                                        name: 'account:services'
                                    });
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.continueButton.loading = false;
                                });
                        } else {
                            this.continueButton.loading = false;
                        }
                    })
                    .catch(() => {
                        this.continueButton.loading = false;
                    });
            }
        },

        mounted() {
            this.$emit('toolbar:update', {
                hideUserPanel: false
            });
        }
    };
</script>

<style scoped></style>
