<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.other.info') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.other.info')"
            v-model="details['Informace pro přezkoumání zdroje']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'Other',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Informace pro přezkoumání zdroje', '');
        }
    };
</script>

<style scoped></style>
