import axios from 'axios';

import Vue from 'vue';

import { parseErrorMessageFromAPI, API_INTERNAL_ERROR, API_SERVER_ERROR } from '../utils';

const state = {
    documents: {
        fetched: false,
        fetchError: false,
        data: {}
    },
    systemDocuments: {
        fetched: false,
        fetchError: false,
        data: {}
    }
};

export const DocumentSet = {
    USER: {
        fetchUrl: 'lbw/personal-documents/overviews',
        storeKey: 'documents',
        uploadUrl: 'lbw/personal-documents/upload-url',
        dispatchUpload: 'documents/upload:file'
    },
    SYSTEM: {
        fetchUrl: 'com/document-list',
        storeKey: 'systemDocuments'
    }
};

const getters = {
    documents: state => state.documents.data,
    documentsFetched: state => state.documents.fetched,
    documentsError: state => state.documents.fetchError,
    documentsState: state => state.documents.data.state,
    documentsGroupCount: state => {
        return groupName => {
            return state.documents.data[groupName];
        };
    },
    documentsGroupData: state => {
        return group => {
            return state.documents.data?.documentsInfo?.filter(d => d[group]);
        };
    },

    systemDocuments: state => state.systemDocuments.data.items ?? [],
    systemDocumentsFetched: state => state.systemDocuments.fetched,
    systemDocumentsError: state => state.systemDocuments.fetchError
};

const actions = {
    fetch: ({ commit, rootGetters }, documentSet) => {
        const userId = rootGetters['user/user:id'];

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] Running action with API call', userId);

                axios
                    .get(`${documentSet.fetchUrl}/${userId}`)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            commit('save', {
                                key: documentSet.storeKey,
                                data: response.data
                            });

                            resolve();
                        } else {
                            commit('error', documentSet.storeKey);

                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        commit('error', documentSet.storeKey);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.error('[ACTION] UserID is not defined, cant retrieve documents status');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'upload:file': (_, document) => {
        Vue.$log.debug('[ACTION] Running action with API call', document);

        return new Promise((resolve, reject) => {
            axios
                .post('lbw/personal-documents', document)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    process: (_, { documentId, process }) => {
        Vue.$log.debug('[ACTION] Running action with API call', documentId, process);

        return new Promise((resolve, reject) => {
            axios
                .post(`lbw/personal-documents/${documentId}/process`, process)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'document:request': (_, id) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            Vue.$log.debug('[ACTION] UserID is defined', id);

            axios
                .post(`lbw/documents/${id}/create-download-url`, null)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    clear: ({ commit }, documentSet) => {
        return new Promise(resolve => {
            commit('clear', documentSet.storeKey);

            resolve();
        });
    }
};

const mutations = {
    save: (state, { key, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', key, data);

        state[key].fetched = true;
        state[key].fetchError = false;
        state[key].data = data;
    },
    error: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = true;
        state[key].fetchError = true;
        state[key].data = {};
    },
    clear: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = false;
        state[key].fetchError = false;
        state[key].data = {};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
