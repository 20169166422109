<template>
    <div class="input-check-cont pointer border-radius-8" @click="change" v-ripple="{ class: `primary--text` }">
        <div class="input-check-div">
            <vt-checkbox-icon :checked="modelValue" :small="true" />
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </div>
        <div class="input-check-label-cont">
            <div class="input-check-label">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import VtCheckboxIcon from '../icons/VtCheckboxIcon';
    export default {
        name: 'VtCheckbox',
        components: {
            VtCheckboxIcon
        },
        model: {
            prop: 'modelValue'
        },
        props: {
            modelValue: Boolean,
            loading: Boolean
        },
        methods: {
            change() {
                const result = !this.modelValue;
                this.$emit('input', result);
                this.$emit('change', result);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
