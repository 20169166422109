<template>
    <div class="max-width-card-x2">
        <app-row v-if="documentsCount > 0">
            <app-sign-col full-space class="pa-0 ml-n1">
                <app-flex>
                    <app-flex-item v-for="(document, index) in documents" :key="index" class="pa-0">
                        <document-card
                            class="document-card-style pa-0 mr-3"
                            :document="document"
                            :groupName="name"
                            @document:upload="$emit('document:upload', $event)"
                        />
                    </app-flex-item>
                </app-flex>
            </app-sign-col>
        </app-row>
    </div>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/VtAppFlexItem';
    import AppFlex from '@/components/ui/layout/VtAppFlex';
    import DocumentCard from '@/components/module-user/documents/documentGroupOverview/VtDocumentCard';
    import AppSignCol from '@/components/ui/layout/VtAppSignCol';

    export default {
        name: 'DocumentsGroupOverview',

        components: {
            AppSignCol,
            DocumentCard,
            AppFlex,
            AppFlexItem
        },

        props: ['documents', 'documentsCount', 'name']
    };
</script>

<style scoped>
    .document-card-style {
        max-width: 421px;
        height: 219px;
        background: #f4f3ff;
        border: 1px dashed #b4c0cb;
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: none;
    }
</style>
