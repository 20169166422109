<template>
    <div>
        <app-title>{{ $t('admin.users.menu.usersTree') }}</app-title>

        <app-row v-if="$vuetify.breakpoint.lgAndUp">
            <user-tree-layout-col structure>
                <user-tree-structure
                    @update:active="setActiveNode"
                    :requestedNode="activeNode"
                    :tree-children="treeChildren"
                    :tree-fetched="treeFetched"
                    :tree-error="treeError"
                    :tree-user="treeUser"
                />
            </user-tree-layout-col>

            <user-tree-layout-col detail>
                <user-detail-overview
                    :user="user"
                    floating
                    :commission-account="activeNodeCA"
                    :tree-fetched="treeFetched"
                    :tree-error="treeError"
                />
            </user-tree-layout-col>
        </app-row>

        <div v-else>
            <user-detail-overview
                :user="user"
                :commission-account="activeNodeCA"
                :tree-fetched="treeFetched"
                :tree-error="treeError"
            />

            <user-tree-structure
                @update:active="setActiveNode"
                :requestedNode="activeNode"
                :tree-children="treeChildren"
                :tree-fetched="treeFetched"
                :tree-error="treeError"
                :tree-user="treeUser"
            />
        </div>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import UserDetailOverview from '@lb-world/user-tree/public/components/UserDetailOverview';
    import UserTreeStructure from '@lb-world/user-tree/public/components/UserTreeStructure';
    import UserTreeLayoutCol from '@lb-world/user-tree/public/components/UserTreeLayoutCol';

    export default {
        name: 'UsersTree',

        components: { UserTreeLayoutCol, UserTreeStructure, UserDetailOverview },

        data: () => ({
            activeNode: null,
            activeNodeCA: null
        }),

        computed: {
            ...mapGetters('admin', {
                treeFetched: 'users:tree:fetched',
                treeChildren: 'users:tree:children',
                treeReferrals: 'users:tree:referrals',
                treeError: 'users:tree:error',
                treeUser: 'users:tree:user'
            }),

            requestedNode() {
                return this.$route.params?.id ?? null;
            },

            user() {
                return this.activeNode ? this.treeReferrals?.find(node => node.id === this.activeNode) : null ?? null;
            }
        },

        methods: {
            fetchCommissionAccount() {
                this.activeNodeCA = null;

                if (this.user?.id) {
                    this.$store
                        .dispatch('admin_commissionAccount/fetch:detail', this.user?.id)
                        .then(commissionAccount => {
                            this.activeNodeCA = commissionAccount;
                        });
                }
            },

            setActiveNode(nodes) {
                if (nodes && nodes[0]) {
                    this.activeNode = nodes[0];
                }
            }
        },

        watch: {
            user() {
                this.fetchCommissionAccount();
            }
        },

        mounted() {
            this.activeNode = this.requestedNode;

            if (!this.treeFetched) {
                this.$store.dispatch('admin/users:tree:fetch').catch(error => {
                    EventBus.$emit('snackbar:show:error', error);
                });
            }
        }
    };
</script>

<style scoped></style>
