<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-radio-group :readonly="readonly" class="mt-0" v-model="innerValue" :error-messages="errors">
            <v-radio :key="p.name" v-for="p in items" :label="$t('' + p.name)" :value="p.value"></v-radio>
        </v-radio-group>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppRadioGroup',

        components: {
            ValidationProvider
        },

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },

            items: Array,

            value: {
                required: false
            },

            readonly: Boolean
        },

        data: () => ({
            innerValue: ''
        }),

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        mounted() {
            if (this.value !== undefined) {
                this.innerValue = this.value;
            }
        }
    };
</script>

<style scoped></style>
