<template>
    <v-item-group :class="['flex-box-container', { larger }]">
        <slot></slot>
    </v-item-group>
</template>

<script>
    export default {
        name: 'AppFlex',

        props: {
            larger: {
                type: Boolean
            }
        }
    };
</script>
