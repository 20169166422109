<template>
    <v-list>
        <v-list-item-group v-model="model">
            <template v-for="section in sections.data">
                <v-list-item
                    active-class="vt-active-list-item"
                    class="vt-list-item"
                    v-if="!section.subgroup"
                    :key="section.id"
                    link
                    :to="{ name: section.path }"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-icon>
                            <img height="20" :src="getImgUrl(section.name + '-' + active)" alt="" />
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $t(sections.i18nPrefix + '.' + section.name) }}
                        </v-list-item-title>
                    </template>
                </v-list-item>

                <v-list-group v-else sub-group :key="section.id">
                    <template v-slot:prependIcon>
                        <font-awesome-icon :icon="['fad', section.icon]"></font-awesome-icon>
                    </template>
                    <template v-slot:appendIcon>
                        <font-awesome-icon :icon="['fas', 'chevron-down']"></font-awesome-icon>
                    </template>
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ $t(sections.i18nPrefix + '.' + section.name) }}
                        </v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="subgroup in section.subgroup.data"
                        :key="subgroup.id"
                        link
                        exact
                        :to="{ name: subgroup.path }"
                    >
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fad', subgroup.icon]"></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ $t(section.subgroup.i18nPrefix + '.' + subgroup.name) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </template>
        </v-list-item-group>
    </v-list>
</template>

<script>
    export default {
        name: 'VtSidebarMenu',

        props: ['sections'],

        data() {
            return {
                model: null
            };
        },

        methods: {
            getImgUrl(name) {
                const images = require.context('../../../assets/icons/', false, /\.svg$/);
                return images('./' + name + '.svg');
            }
        }
    };
</script>

<style scoped lang="scss"></style>
