<template>
    <app-card table left class="py-6">
        <product-picker :products-tabs="productTypes" mode="selectable" @select="productSelected" />

        <app-divider :bottom-margin="0" />

        <app-row>
            <app-registration-col whole-screen>
                <p v-if="!selectedProduct" class="general-info-text mt-9 mb-6">
                    {{ $t('admin.users.user.products.selectProductAbove') }}
                </p>

                <div v-else>
                    <app-custom-navigation-tabs
                        :i18n-prefix="productTabs.i18nPrefix"
                        :tabs="productTabs"
                        @change="tabChange"
                    />

                    <hr />

                    <component
                        class="py-9"
                        :is="currentTab"
                        :userId="this.$route.params.id"
                        :productId="productId"
                    ></component>
                </div>
            </app-registration-col>
        </app-row>
    </app-card>
</template>

<script>
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppCol from '@/components/ui/layout/AppCol';
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';
    import ProductAccountsTab from '@/views/admin/sections/user/tabs/ProductAccountsTab';
    import ProductTransactionsTab from '@/views/admin/sections/user/tabs/ProductTransactionsTab';
    import ProductPicker from '@/components/module-admin/user/products/ProductPicker';
    import AppCustomNavigationTabs from '@/components/ui/tabs/AppCustomNavigationTabs';

    export default {
        name: 'UserProducts',

        components: {
            AppCustomNavigationTabs,
            ProductPicker,
            AppSectionTabs,
            AppCol,
            AppFlexItem,
            AppFlex,

            productAccounts: ProductAccountsTab,
            transactions: ProductTransactionsTab
        },

        data: () => ({
            selectedProduct: '',
            productId: '',

            productAccountData: [],
            tabIndex: 0
        }),

        computed: {
            productTabs() {
                return this.$store.getters['tabs/admin:products:browse'];
            },
            currentTab() {
                return this.productTabs.data[this.tabIndex].name;
            },
            productTypes() {
                return this.$store.getters['tabs/admin:products:types'];
            }
        },

        methods: {
            tabChange(id) {
                this.tabIndex = id;
            },
            productSelected(product) {
                this.selectedProduct = product.name;
                this.productId = product.id;
            }
        }
    };
</script>

<style scoped></style>
