<template>
    <div>
        <withdrawal-confirmation-dialog
            :dialog="withdrawalConfirmationDialog"
            @transaction:processed="fetchTransactions"
        />

        <export-unprocessed-withdrawals />

        <admin-withdrawal-transactions-table
            :items="withdrawalTransactions"
            :loading="!withdrawalTransactionsFetched"
            :filters.sync="transactionsTable.filters"
            :fetch-error="withdrawalTransactionsError"
            :pages="withdrawalTransactionsPages"
            :pre-applied="transactionsTable.preAppliedFilters"
            @transaction:open="openWithdrawalDialog"
            @pagination:change="fetchTransactions"
            @filter:update="filterTransactions"
            @filter:reset="filterTransactions"
        />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AdminWithdrawalTransactionsTable from '@/components/ui/tables/AdminWithdrawalTransactionsTable';
    import WithdrawalConfirmationDialog from '@/components/module-admin/user/withdrawals/WithdrawalConfirmationDialog';
    import ExportUnprocessedWithdrawals from '@/components/module-admin/user/withdrawals/ExportUnprocessedWithdrawals';

    export default {
        name: 'WithdrawalTransactionsTab',

        components: {
            ExportUnprocessedWithdrawals,
            WithdrawalConfirmationDialog,
            AdminWithdrawalTransactionsTable
        },

        data: () => ({
            withdrawalConfirmationDialog: {
                open: false
            },

            transactionsTable: {
                preAppliedFilters: null,
                page: 1,
                filters: {
                    id: '',
                    user: '',
                    processed: '',
                    withdrawalOrigin: ''
                }
            }
        }),

        computed: mapGetters('admin', [
            'withdrawalTransactions',
            'withdrawalTransactionsFetched',
            'withdrawalTransactionsFilter',
            'withdrawalTransactionsError',
            'withdrawalTransactionsPages'
        ]),

        methods: {
            openWithdrawalDialog(transaction) {
                this.withdrawalConfirmationDialog.transaction = transaction;
                this.withdrawalConfirmationDialog.open = true;
            },

            fetchTransactions(page) {
                this.transactionsTable.page = page;

                this.$store.dispatch('admin/withdrawalTransactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/withdrawalTransactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            filterTransactions(filter) {
                this.transactionsTable.page = 1;
                this.transactionsTable.filters = filter;

                this.$store.dispatch('admin/withdrawalTransactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/withdrawalTransactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.withdrawalTransactionsFilter) {
                this.$set(this.transactionsTable, 'preAppliedFilters', this.withdrawalTransactionsFilter);
            }

            if (!this.withdrawalTransactionsFetched) {
                this.fetchTransactions();
            }
        }
    };
</script>

<style scoped></style>
