<template>
    <div class="container-overview-box">
        <div class="vt-tab-title">{{ $t('bo.documents.overview') }}</div>
        <app-row class="ml-n10">
            <app-registration-col whole-screen>
                <app-card left class="vt-document-box-over">
                    <app-error v-if="error" fetching horizontal no-padding />
                    <div v-else>
                        <!--                        <document-box-state :state="documentsState" />-->
                        <!--                        <app-divider/>-->
                        <app-row>
                            <app-registration-col class="pa-0" whole-screen>
                                <app-flex>
                                    <app-flex-item v-for="group in documentGroups" :key="group.name">
                                        <document-group-info
                                            :state-loaded="documentsState"
                                            :document-group-name="group.name"
                                            :document-group-color="group.labelColor"
                                            :documents-group-count="group.documentsCount"
                                        />
                                    </app-flex-item>
                                </app-flex>
                            </app-registration-col>
                        </app-row>
                    </div>
                </app-card>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AppFlex from '@/components/ui/layout/VtAppFlex';
    import AppFlexItem from '@/components/ui/layout/VtAppFlexItem';
    import DocumentGroupInfo from '@/components/module-user/documents/documentsBoxOverview/VtDocumentGroupInfo';
    // import DocumentBoxState from '@/components/module-user/documents/documentsBoxOverview/DocumentBoxState';

    export default {
        name: 'VtDocumentsBoxOverview',

        components: {
            // DocumentBoxState,
            DocumentGroupInfo,
            AppFlexItem,
            AppFlex
        },

        props: ['documentsState', 'documentGroups', 'error']
    };
</script>

<style scoped lang="scss"></style>
