<template>
    <validation-observer ref="formPasswordChange" v-slot="{ valid }">
        <v-dialog v-model="dialog.open" max-width="1000" transition="slide-y-transition" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title :title="$t('bo.account.changePasswordDialog.title')" :dialog="dialog" />

                <app-card>
                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.account.changePasswordDialog.password')"
                                type="password"
                                v-model="password"
                                rules="required|min:8"
                            ></app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.account.changePasswordDialog.newPassword')"
                                type="password"
                                v-model="newPassword"
                                rules="required|min:8"
                            ></app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('bo.account.changePasswordDialog.newPasswordConfirmation')"
                                type="password"
                                v-model="newPasswordConfirmation"
                                :rules="{ required: true, is: newPassword }"
                            ></app-text-field>
                        </app-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <g-recaptcha :data-sitekey="siteKey" :data-validate="() => valid" :data-callback="onSubmit">
                        <button-primary @click="changePassword" :loading="changePasswordButtonLoading">
                            {{ $t('bo.account.changePasswordDialog.change') }}
                        </button-primary>
                    </g-recaptcha>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import { APP_SITE_KEY } from '@/constants';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

    import AppCol from '../layout/AppCol';
    import AppTextField from '../form/VtAppTextField';

    export default {
        name: 'UserPasswordChangeDialog',

        props: ['dialog', 'pOldPassword', 'pNewPassword'],

        components: {
            AppTextField,
            AppCol,
            ValidationObserver,
            gRecaptcha
        },

        data: () => ({
            siteKey: APP_SITE_KEY,

            password: '',
            newPassword: '',
            newPasswordConfirmation: '',

            changePasswordButtonLoading: false
        }),

        methods: {
            onSubmit(token) {
                this.$log.debug('[RECAPTCHA] Submitting..', token);

                this.changePasswordButtonLoading = true;

                this.$refs.formPasswordChange
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$log.debug('[RECAPTCHA] Submit successful', token);

                            if (token) {
                                this.changePassword(token);
                            } else {
                                EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                            }
                        } else {
                            this.$log.debug('[RECAPTCHA] Submit failed');

                            this.changePasswordButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.$log.debug('[RECAPTCHA] Submit failed');

                        this.changePasswordButtonLoading = false;
                    });
            },
            changePassword(token) {
                const { password, newPassword } = this;

                this.$store
                    .dispatch('user/change:password', {
                        password,
                        newPassword,
                        token
                    })
                    .then(() => {
                        this.dialog.open = false;

                        EventBus.$emit('snackbar:show:success', this.$t('success.passwordChanged'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.$emit('reset');
                        this.changePasswordButtonLoading = false;
                    });
            }
        },

        watch: {
            pOldPassword: function(val) {
                this.password = val;
            },
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.password = this.pOldPassword;
                        this.newPassword = this.pNewPassword;
                        this.newPasswordConfirmation = '';

                        if (this.$refs.formPasswordChange) {
                            this.$refs.formPasswordChange.reset();
                        }
                    }
                }
            }
        }
    };
</script>
