<template>
    <div>
        <documents-approval-decline-dialog :dialog="documentDeclineDialog" @process:success="fetchUserDocuments" />

        <app-card table left class="py-6">
            <app-title>{{ $t('admin.users.user.documents.digitization.title') }}</app-title>

            <app-row>
                <app-registration-col whole-screen>
                    <user-digitization-process />
                </app-registration-col>
            </app-row>

            <app-divider :bottom-margin="0" />

            <app-row>
                <app-registration-col whole-screen>
                    <company-digitization-process :key="documentUploadedKey" />
                </app-registration-col>
            </app-row>

            <app-divider />

            <system-documents-overview @document:upload="documentUploadedKey = $event"></system-documents-overview>

            <app-title>{{ $t('admin.users.user.documents.userDocuments') }}</app-title>

            <table-divider />

            <app-row>
                <app-registration-col whole-screen>
                    <admin-user-detail-documents-table
                        :loading="!userDocumentsFetched"
                        :items="userDocuments"
                        :fetch-error="userDocumentsError"
                        @document:openDeclineDialog="openDeclineDialog"
                        @document:approve="fetchUserDocuments"
                    />
                </app-registration-col>
            </app-row>
        </app-card>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { DocumentSet } from '@/store/admin/documents';
    import { mapGetters } from 'vuex';

    import DocumentsApprovalDeclineDialog from '../../../../components/ui/dialogs/DocumentsApprovalDeclineDialog';
    import AdminUserDetailDocumentsTable from '@/components/ui/tables/AdminUserDetailDocumentsTable';
    import TableDivider from '@lb-world/core/public/components/tables/TableDivider';
    import SystemDocumentsOverview from '@/components/module-admin/user/documents/SystemDocumentsOverview';
    import CompanyDigitizationProcess from '@/components/module-admin/user/companyDigitization/CompanyDigitizationProcess';
    import UserDigitizationProcess from '@/components/module-admin/user/userDigitization/UserDigitizationProcess';

    export default {
        name: 'UserDocuments',

        components: {
            UserDigitizationProcess,
            CompanyDigitizationProcess,
            SystemDocumentsOverview,
            TableDivider,
            AdminUserDetailDocumentsTable,
            DocumentsApprovalDeclineDialog
        },

        data: () => ({
            documentUploadedKey: null,
            documentDeclineDialog: {
                open: false
            }
        }),

        computed: {
            ...mapGetters('admin_userDocuments', {
                userDocuments: 'documents',
                userDocumentsFetched: 'documents:fetched',
                userDocumentsError: 'documents:error'
            }),
            userEmail() {
                return this.$store.getters['admin_userDetail/user:email'];
            }
        },

        methods: {
            openDeclineDialog(document) {
                this.documentDeclineDialog.documentKey = document.type;
                this.documentDeclineDialog.documentId = document.id;
                this.documentDeclineDialog.userEmail = this.userEmail;
                this.documentDeclineDialog.open = true;
            },
            fetchUserDocuments() {
                this.$store.dispatch('admin_userDocuments/clear', DocumentSet.USER).then(() => {
                    this.$store.dispatch('admin_userDocuments/fetch', DocumentSet.USER).catch(error => {
                        this.$log.debug('[DOC_APPROVAL] Could not load users documents', error);

                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });

                this.$store.dispatch('admin_userDocuments/clear', DocumentSet.OVERVIEW).then(() => {
                    this.$store.dispatch('admin_userDocuments/fetch', DocumentSet.OVERVIEW).catch(error => {
                        this.$log.debug('[DOC_APPROVAL] Could not load users documents overview', error);

                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });
            }
        },

        mounted() {
            this.fetchUserDocuments();
        }
    };
</script>

<style scoped></style>
