<template>
    <div>
        <document-limit-upload-dialog :dialog="documentsUploadDialog" @document:uploaded="$emit('user:fetch')" />

        <app-row v-if="!userLimitFetched">
            <app-registration-col whole-screen>
                <app-card>
                    <app-loader vertical />
                </app-card>
            </app-registration-col>
        </app-row>

        <app-row v-else-if="userLimitError">
            <app-registration-col whole-screen>
                <app-card>
                    <app-error fetching vertical />
                </app-card>
            </app-registration-col>
        </app-row>

        <div v-else>
            <app-text v-if="isLimitRequested('limit2') && !isLimitApproved('limit1')">
                <span v-html="$t('admin.aml.description.requiredLimit1Approval')" />
            </app-text>

            <app-text v-else-if="isLimitRequested('limit2') && !isLimitApproved('limit2')">
                <span v-html="$t('admin.aml.description.approvalIntersection')" />
            </app-text>

            <app-row>
                <app-registration-col whole-screen class="pa-0 pa-sm-3">
                    <v-expansion-panels v-model="panelsCollapsed">
                        <v-expansion-panel :disabled="!isLimitRequested('limit1')">
                            <purchase-limit-header :limit="userLimit['limit1']" />

                            <validation-observer ref="formAccomplish_limit1">
                                <purchase-limit-content>
                                    <p class="mr-1 mb-0 text-start">
                                        {{ $t('bo.aml.limit1.politicallyExposedPerson') }}
                                    </p>

                                    <app-radio-group
                                        :value="politicallyExposedPerson"
                                        readonly
                                        :items="[
                                            { name: $t('bo.aml.politicallyExposedPerson.yes'), value: true },
                                            { name: $t('bo.aml.politicallyExposedPerson.no'), value: false }
                                        ]"
                                    />

                                    <div class="mt-6">
                                        <p class="text-start">
                                            {{ $t('bo.aml.limit1.statement') }}
                                        </p>

                                        <app-text-field readonly :value="$t('bo.aml.sources.' + source.type)" />
                                    </div>

                                    <component :is="source.type" :details="source.details" />

                                    <div class="d-flex flex-column justify-center">
                                        <app-checkbox-group readonly :items="purposeItems" :value="purpose.types" />

                                        <div v-if="purpose.types.includes('other')">
                                            <p class="text-start">
                                                {{ $t('bo.aml.purposeOtherDetails') }}
                                            </p>

                                            <app-text-field class="mt-6" v-model="purpose.additionalInfo" readonly />
                                        </div>
                                    </div>

                                    <button-primary
                                        v-if="isLimitRequested('limit1') && !isLimitApproved('limit1')"
                                        class="mt-6"
                                        @click="approve(userLimit['limit1'])"
                                        :loading="loadingButtons.limit1"
                                    >
                                        {{ $t('admin.aml.approveLimit') }}
                                    </button-primary>

                                    <button-primary
                                        v-if="isLimitRequested('limit1') && !isLimitApproved('limit1')"
                                        class="mt-6"
                                        @click="reset(userLimit['limit1'])"
                                        :loading="loadingCancelButtons.limit1"
                                    >
                                        Zrušit
                                    </button-primary>
                                </purchase-limit-content>
                            </validation-observer>
                        </v-expansion-panel>

                        <v-expansion-panel :disabled="isLimit2Disabled">
                            <purchase-limit-header :limit-disabled="isLimit2Disabled" :limit="userLimit['limit2']" />

                            <validation-observer ref="formAccomplish_limit2">
                                <purchase-limit-content>
                                    <app-row>
                                        <app-registration-col>
                                            <p class="text-start">
                                                {{ $t('admin.aml.uploadDocumentInfo') }}
                                            </p>
                                        </app-registration-col>
                                    </app-row>

                                    <purchase-limit-file-overview
                                        @document:upload="uploadDocument"
                                        :user-limit="userLimit['limit2']"
                                    />

                                    <app-divider v-if="isLimitRequested('limit2') && !isLimitApproved('limit2')" />

                                    <app-row v-if="isLimitRequested('limit2') && !isLimitApproved('limit2')">
                                        <app-registration-col>
                                            <button-primary
                                                @click="approve(userLimit['limit2'])"
                                                :disabled="!isDocumentUploaded('limit2')"
                                                :loading="loadingButtons.limit2"
                                            >
                                                {{ $t('admin.aml.approveLimit') }}
                                            </button-primary>

                                            <button-primary
                                                v-if="isLimitRequested('limit2') && !isLimitApproved('limit2')"
                                                class="mt-6"
                                                @click="reset(userLimit['limit2'])"
                                                :loading="loadingCancelButtons.limit2"
                                            >
                                                Zrušit
                                            </button-primary>
                                        </app-registration-col>
                                    </app-row>
                                </purchase-limit-content>
                            </validation-observer>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </app-registration-col>
            </app-row>
        </div>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import { AMLLimits } from '@lb-world/core/public/models/AMLLimit';

    import { ValidationObserver } from 'vee-validate';

    import PurchaseLimitHeader from '@/components/ui/purchase-limits/PurchaseLimitHeader';
    import PurchaseLimitContent from '@/components/ui/purchase-limits/PurchaseLimitContent';
    import AppSwitchField from '@/components/ui/form/AppSwitchField';
    import AppTextField from '@/components/ui/form/AppTextField';
    import PurchaseLimitFileOverview from '@/components/module-admin/user/purchase-limits/PurchaseLimitFileOverview';
    import DocumentLimitUploadDialog from '@/components/ui/dialogs/DocumentLimitUploadDialog';

    import WageOrSalary from '@/components/module-user/purchase-limits/source-details/WageOrSalary';
    import IncomeFromBusiness from '@/components/module-user/purchase-limits/source-details/IncomeFromBusiness';
    import MeansUsedForBusiness from '@/components/module-user/purchase-limits/source-details/MeansUsedForBusiness';
    import Loan from '@/components/module-user/purchase-limits/source-details/Loan';
    import PropertySale from '@/components/module-user/purchase-limits/source-details/PropertySale';
    import Inheritance from '@/components/module-user/purchase-limits/source-details/Inheritance';
    import Winnings from '@/components/module-user/purchase-limits/source-details/Winnings';
    import Gift from '@/components/module-user/purchase-limits/source-details/Gift';
    import Rent from '@/components/module-user/purchase-limits/source-details/Rent';
    import Other from '@/components/module-user/purchase-limits/source-details/Other';
    import AppCheckboxGroup from '../../../ui/form/AppCheckboxGroup';
    import AppRadioGroup from '../../../ui/form/AppRadioGroup';

    export default {
        name: 'PurchaseLimitsPanels',

        components: {
            AppRadioGroup,
            AppCheckboxGroup,
            DocumentLimitUploadDialog,
            PurchaseLimitFileOverview,
            AppTextField,
            AppSwitchField,
            PurchaseLimitContent,
            PurchaseLimitHeader,
            ValidationObserver,

            wage_or_salary: WageOrSalary,
            income_from_business: IncomeFromBusiness,
            means_used_for_business: MeansUsedForBusiness,
            loan: Loan,
            property_sale: PropertySale,
            inheritance: Inheritance,
            winnings: Winnings,
            gift: Gift,
            rent: Rent,
            other: Other
        },

        data: () => ({
            panelsCollapsed: [],

            loadingButtons: {
                limit1: false,
                limit2: false
            },

            loadingCancelButtons: {
                limit1: false,
                limit2: false
            },

            politicallyExposedPerson: false,

            source: {
                type: '',
                details: null
            },

            purpose: {
                types: '',
                additionalInfo: null
            },

            documentsUploadDialog: {
                open: false
            },

            purposeItems: [
                {
                    name: 'bo.aml.purposes.efk_token_value_speculation',
                    value: 'efk_token_value_speculation'
                },
                {
                    name: 'bo.aml.purposes.efk_token_holding',
                    value: 'efk_token_holding'
                },
                {
                    name: 'bo.aml.purposes.efk_token_selloff',
                    value: 'efk_token_selloff'
                },
                {
                    name: 'bo.aml.purposes.project_support',
                    value: 'project_support'
                },
                {
                    name: 'bo.aml.purposes.other',
                    value: 'other'
                }
            ]
        }),

        computed: {
            ...mapGetters('admin_aml', ['userLimit', 'userLimitFetched', 'userLimitError']),

            isLimit2Disabled() {
                return !this.isLimitApproved('limit1') || !this.isLimitRequested('limit2');
            }
        },

        methods: {
            openWikipedia() {
                const windowRef = window.open();

                windowRef.location.href = 'https://cs.wikipedia.org/wiki/Politicky_exponovaná_osoba';
            },

            isDocumentUploaded(limit) {
                return (
                    this.userLimit[limit].getDocumentFilename() && this.userLimit[limit].getDocumentFilename() !== null
                );
            },

            isLimitRequested(limit) {
                return this.userLimit[limit].isRequested();
            },

            isLimitApproved(limit) {
                return this.userLimit[limit].isApproved();
            },

            approve(limit) {
                this.loadingButtons[limit.id] = true;

                limit.setPeP(this.politicallyExposedPerson);
                limit.setSource(this.source.type, this.source.details);
                limit.setPurpose(this.purpose.types, this.purpose.additionalInfo);

                this.$refs['formAccomplish_' + limit.id]
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_aml/user:approve', limit.id)
                                .then(() => {
                                    this.panelsCollapsed = [];

                                    EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.loadingButtons[limit.id] = false;
                                });
                        } else {
                            this.loadingButtons[limit.id] = false;
                        }
                    })
                    .catch(() => {
                        this.loadingButtons[limit.id] = false;
                    });
            },

            reset(limit) {
                this.loadingCancelButtons[limit.id] = true;

                this.$store
                    .dispatch('admin_aml/user:reset', limit.id)
                    .then(() => {
                        this.panelsCollapsed = [];

                        EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.loadingCancelButtons[limit.id] = false;
                    });
            },

            uploadDocument(document) {
                this.documentsUploadDialog.params = document;
                this.documentsUploadDialog.open = true;
            },

            fillLimitDetails() {
                const { type, details } = this.userLimit[AMLLimits.LIMIT1].getSource();
                const { types, additionalInfo } = this.userLimit[AMLLimits.LIMIT1].getPurpose();

                this.source.type = type;
                this.source.details = details;
                this.purpose.types = types;
                this.purpose.additionalInfo = additionalInfo;

                this.politicallyExposedPerson = this.userLimit[AMLLimits.LIMIT1].getPeP();
            }
        },

        watch: {
            userLimitFetched() {
                this.fillLimitDetails();
            }
        },

        mounted() {
            if (this.userLimitFetched) {
                this.fillLimitDetails();
            }
        }
    };
</script>

<style scoped></style>
