<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('signIn.passwordRecoveryDialog.title')" :dialog="dialog" />

            <app-dialog-tabs
                centered
                :i18n-prefix="tabs.i18nPrefix"
                :tabs="tabs"
                :current-tab-index="tabIndex"
            ></app-dialog-tabs>

            <component
                :is="currentTab"
                class="py-6"
                @process:next="tabIndex += 1"
                @process:finished="dialog.open = false"
                @process:reset="tabIndex = 0"
            >
            </component>
        </app-card>
    </v-dialog>
</template>

<script>
    import AppDialogTabs from '../tabs/VtAppDialogTabs';
    import PasswordRecoveryUserEmail from './passwordRecovery/PasswordRecoveryUserEmail';
    import PasswordRecoveryLink from './passwordRecovery/PasswordRecoveryLink';
    import PasswordRecoveryNewPassword from './passwordRecovery/PasswordRecoveryNewPassword';

    export default {
        name: 'PasswordRecoveryDialog',

        components: {
            AppDialogTabs,

            userEmail: PasswordRecoveryUserEmail,
            verificationCode: PasswordRecoveryLink,
            newPassword: PasswordRecoveryNewPassword
        },

        data: () => ({
            tabIndex: 0
        }),

        props: ['dialog'],

        computed: {
            tabs() {
                return this.$store.getters['tabs/passwordRecovery'];
            },
            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened && this.dialog.email) {
                        this.$store.dispatch('user_passwordRecovery/store:email', this.dialog.email).then(() => {
                            this.tabIndex = 1;
                        });
                    } else if (opened) {
                        this.tabIndex = 0;
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
