<template>
    <div class="d-flex flex-column">
        <app-section-tabs v-if="$vuetify.breakpoint.mdAndUp" :tabs="usersTabs" :i18n-prefix="usersTabs.i18nPrefix" />

        <app-container unlimited-width fill-height section-padding>
            <router-view />
        </app-container>
    </div>
</template>

<script>
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';

    export default {
        name: 'Users',

        components: {
            AppSectionTabs
        },

        data: () => ({
            userDialog: {
                open: false,
                user: {}
            }
        }),

        computed: {
            usersTabs() {
                return this.$store.getters['tabs/admin:users'];
            }
        }
    };
</script>

<style scoped></style>
