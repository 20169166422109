import Vue from 'vue';
import VueRouter from 'vue-router';

import UserSignIn from '../views/sign-in/VtUserSignIn';
import AdminSignIn from '../views/sign-in/AdminSignIn';

import AccountLayout from '../views/account/VtAccountLayout';
import AdminLayout from '../views/admin/AdminLayout';

import Account from '../views/account/sections/VtAccount';
import Services from '../views/account/sections/VtServices';
import Documents from '../views/account/sections/VtDocuments';
import Limits from '@/views/account/sections/VtLimits';

import VtRegistrationLayout from '../views/registration/VtRegistrationLayout';
import UserDetails from '../views/registration/steps/UserDetails';
import EmailVerification from '../views/registration/steps/EmailVerification';
import UserAddress from '../views/registration/steps/UserAddress';

import AdminDashboard from '../views/admin/sections/Dashboard';
import AdminTransactions from '@/views/admin/sections/Transactions';
import AdminLimits from '@/views/admin/sections/Limits';

import AdminUsers from '../views/admin/sections/Users';
import AdminUsersList from '@/views/admin/sections/users/UsersList';
import AdminUsersTree from '@/views/admin/sections/users/UsersTree';

import AdminProducts from '@/views/admin/sections/Products';
import AdminProductsBrowse from '@/views/admin/sections/products/ProductsBrowse';
import AdminProductsExchange from '@/views/admin/sections/products/ProductsTrading';

import AdminDocuments from '../views/admin/sections/Documents';
import AdminDocumentsApprovals from '@/views/admin/sections/documents/DocumentsApproval';
import AdminDocumentsStorage from '@/views/admin/sections/documents/DocumentsStorage';

import AdminUser from '../views/admin/sections/User';
import AdminUserProfile from '../views/admin/sections/user/UserProfile';
import AdminUserDocuments from '../views/admin/sections/user/UserDocuments';
import AdminUserWallets from '../views/admin/sections/user/UserWallets';
import AdminUserProducts from '../views/admin/sections/user/UserProducts';
import AdminUserLimits from '@/views/admin/sections/user/UserLimits';

import WalletTransactionsTab from '@/views/admin/sections/transactions/WalletTransactionsTab';
import WithdrawalTransactionsTab from '@/views/admin/sections/transactions/WithdrawalTransactionsTab';

import ProductAccountsTab from '@/views/admin/sections/products/tabs/ProductAccountsTab';
import ProductTransactionsTab from '@/views/admin/sections/products/tabs/ProductTransactionsTab';

import NotFound from '../views/error/NotFound';
import Maintenance from '@/views/error/Maintenance';
import ServerError from '@/views/error/ServerError';

import { adminRouting, userRouting } from '@/middleware/auth';

import { getUserDefaultLanguage, setLanguage } from '@lb-world/core/public/locales/engine';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'SignIn',
        component: UserSignIn,
        children: [
            {
                path: 'account',
                component: AccountLayout,
                beforeEnter: userRouting,
                redirect: 'account/services',
                children: [
                    {
                        path: 'account',
                        name: 'account:account',
                        component: Account
                    },
                    {
                        path: 'documents',
                        name: 'account:documents',
                        component: Documents
                    },
                    {
                        path: 'services',
                        name: 'account:services',
                        component: Services
                    },
                    {
                        path: 'purchase-limits',
                        name: 'account:aml',
                        component: Limits
                    }
                ]
            }
        ]
    },
    {
        path: '/registration',
        component: VtRegistrationLayout,
        beforeEnter: userRouting,
        redirect: 'registration/user-details',
        children: [
            {
                path: 'user-details',
                name: 'registration:user',
                component: UserDetails
            },
            {
                path: 'email-verification',
                name: 'registration:email',
                component: EmailVerification
            },
            {
                path: 'user-address',
                name: 'registration:address',
                component: UserAddress
            }
        ]
    },
    {
        path: '/admin',
        component: AdminSignIn,
        children: [
            {
                path: 'account',
                component: AdminLayout,
                redirect: 'account/dashboard',
                beforeEnter: adminRouting,
                children: [
                    {
                        path: 'dashboard',
                        name: 'admin:dashboard',
                        component: AdminDashboard
                    },
                    {
                        path: 'users',
                        name: 'admin:users',
                        redirect: 'users/list',
                        component: AdminUsers,
                        children: [
                            {
                                path: 'list',
                                name: 'admin:users:list',
                                component: AdminUsersList
                            },
                            {
                                path: 'tree',
                                name: 'admin:users:tree',
                                component: AdminUsersTree
                            }
                        ]
                    },
                    {
                        path: 'users/:id',
                        name: 'admin:user',
                        component: AdminUser,
                        redirect: 'users/:id/profile',
                        children: [
                            {
                                path: 'profile',
                                name: 'admin:user:profile',
                                component: AdminUserProfile
                            },
                            {
                                path: 'documents',
                                name: 'admin:user:documents',
                                component: AdminUserDocuments
                            },
                            {
                                path: 'wallets',
                                name: 'admin:user:wallets',
                                component: AdminUserWallets
                            },
                            {
                                path: 'products',
                                name: 'admin:user:products',
                                component: AdminUserProducts
                            },
                            {
                                path: 'purchase-limits',
                                component: AdminUserLimits,
                                name: 'admin:user:aml'
                            }
                        ]
                    },
                    {
                        path: 'documents',
                        name: 'admin:users:documents',
                        component: AdminDocuments,
                        redirect: 'documents/approvals',
                        children: [
                            {
                                path: 'approvals',
                                name: 'admin:documents:approvals',
                                component: AdminDocumentsApprovals
                            },
                            {
                                path: 'storage',
                                name: 'admin:documents:storage',
                                component: AdminDocumentsStorage
                            }
                        ]
                    },
                    {
                        path: 'transactions',
                        component: AdminTransactions,
                        name: 'admin:transactions',
                        redirect: 'transactions/wallets',
                        children: [
                            {
                                path: 'wallets',
                                name: 'admin:transactions:wallets',
                                component: WalletTransactionsTab
                            },
                            {
                                path: 'withdrawals',
                                name: 'admin:transactions:withdrawals',
                                component: WithdrawalTransactionsTab
                            }
                        ]
                    },
                    {
                        path: 'products',
                        name: 'admin:products',
                        redirect: 'products/browse',
                        component: AdminProducts,
                        children: [
                            {
                                path: 'browse',
                                name: 'admin:products:browse',
                                component: AdminProductsBrowse,
                                redirect: 'browse/product-accounts',
                                children: [
                                    {
                                        path: 'product-accounts',
                                        name: 'admin:products:browse:productAccounts',
                                        component: ProductAccountsTab
                                    },
                                    {
                                        path: 'transactions',
                                        name: 'admin:products:browse:transactions',
                                        component: ProductTransactionsTab
                                    }
                                ]
                            },
                            {
                                path: 'trading',
                                name: 'admin:products:trading',
                                component: AdminProductsExchange
                            }
                        ]
                    },
                    {
                        path: 'purchase-limits',
                        component: AdminLimits,
                        name: 'admin:aml'
                    }
                ]
            }
        ]
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/server-error',
        name: 'serverError',
        component: ServerError
    },
    {
        path: '*',
        name: 'notFound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    Vue.$log.debug('[LANG] Router: Checking for language present');

    const lang = getUserDefaultLanguage();

    if (lang) {
        Vue.$log.debug('[LANG] Router: Language to be present', lang);

        setLanguage(lang).then(() => next());
    } else {
        Vue.$log.debug('[LANG] Router: No language preference found');

        next();
    }
});

export default router;
