<template>
    <v-app-bar>
        <v-tabs :class="['app-tabs', 'v-tabs__' + tabs.type]" :centered="centered" :show-arrows="!shortcutOnMobile">
            <template v-if="tabs.type === 'steps'">
                <v-tab
                    v-for="(tab, index) in tabs.data"
                    :key="tab.name"
                    :exact="exact"
                    :to="{ name: tab.path }"
                    disabled
                >
                    <span v-if="$vuetify.breakpoint.mdAndUp">
                        {{ index + 1 }}. {{ $t(i18nPrefix + '.' + tab.name) }}
                    </span>

                    <span v-else>{{ index + 1 }}.</span>
                </v-tab>
            </template>

            <template v-else-if="tabs.type === 'selection'">
                <v-tab v-for="tab in tabs.data" :key="tab.name" :to="{ name: tab.path }">
                    <font-awesome-icon v-if="tab.icon" :icon="['fad', tab.icon]" />

                    <span class="ml-3" v-if="!(shortcutOnMobile && $vuetify.breakpoint.xsOnly)">
                        {{ $t(i18nPrefix + '.' + tab.name) }}
                    </span>
                </v-tab>
            </template>
        </v-tabs>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppTabs',

        props: {
            exact: {
                type: Boolean,
                default: true
            },
            tabs: {
                required: true
            },
            i18nPrefix: {
                required: false,
                default: ''
            },
            centered: {
                type: Boolean
            },
            shortcutOnMobile: {
                type: Boolean
            }
        }
    };
</script>

<style scoped></style>
