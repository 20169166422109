<template>
    <validation-observer ref="formSupplyItemsAmounts">
        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :items-per-page="1000"
            :headers="headers"
        >
            <template v-slot:item.assignedAmount="{ item }">
                <div class="mt-6">
                    <app-text-field
                        :value="getSupplyItemAmount(item.minSaleRate)"
                        :rules="{ required: true, regex: numberRegex, max_value: item.efkAmount, decimals: 4 }"
                        @input="
                            supplyItemAmountAssigned({
                                priceLevel: item.minSaleRate,
                                amount: $event,
                                avgSaleRate: item.avgSaleRate
                            })
                        "
                    >
                        <template #append>
                            EFK
                        </template>
                    </app-text-field>
                </div>
            </template>

            <template v-slot:item.efkAmount="{ item }">
                <span v-if="assignable" v-color:primary class="font-weight-bold">
                    {{
                        formatAmount(
                            item.efkAmount - convertLocaleToNumber(getSupplyItemAmount(item.minSaleRate), 'rate')
                        )
                    }}
                </span>
                <span v-else>{{ formatAmount(item.efkAmount, 'efk') }}</span>
            </template>

            <template v-slot:item.avgSaleRate="{ item }">
                {{ formatAmount(item.avgSaleRate, 'rate') }}
            </template>

            <template v-slot:item.minSaleRate="{ item }">
                {{ formatAmount(item.minSaleRate, 'rate') }}
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>
    </validation-observer>
</template>

<script>
    import { core } from '@lb-world/core/public/components/tables/mixins';
    import { ValidationObserver } from 'vee-validate';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminProductExchangeSupplyTable',

        components: { AppTextField, ValidationObserver },

        mixins: [core],

        props: {
            assignable: Boolean
        },

        data: () => ({
            supplyItemsAmounts: [],
            validationReady: false
        }),

        computed: {
            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            headers() {
                let headersColumns = [
                    {
                        text: 'Objem pozice (EFK)',
                        value: 'efkAmount'
                    },
                    {
                        text: 'Průměrná prodejní hodnota',
                        value: 'avgSaleRate'
                    },
                    {
                        text: 'Minimální prodejní hodnota',
                        value: 'minSaleRate'
                    }
                ];

                if (this.assignable) {
                    headersColumns = [
                        {
                            text: 'Přiřazená částka',
                            value: 'assignedAmount'
                        },
                        ...headersColumns
                    ];
                }

                return headersColumns;
            }
        },

        methods: {
            resetValues() {
                this.supplyItemsAmounts = [];
                this.items.forEach(item => {
                    this.supplyItemsAmounts.push({
                        priceLevel: item.minSaleRate,
                        amount: '0'
                    });
                });
            },
            getSupplyItemAmount(priceLevel) {
                return this.supplyItemsAmounts.find(s => s.priceLevel === priceLevel)?.amount ?? 0;
            },
            supplyItemAmountAssigned(supplyItem) {
                if (this.validationReady) {
                    if (supplyItem.amount === '' || Number.isNaN(this.convertLocaleToNumber(supplyItem.amount))) {
                        return;
                    }

                    this.$refs.formSupplyItemsAmounts
                        .validate()
                        .then(result => {
                            if (result) {
                                const supplyItemIndex = this.supplyItemsAmounts.findIndex(
                                    s => s.priceLevel === supplyItem.priceLevel
                                );

                                this.$set(this.supplyItemsAmounts, supplyItemIndex, supplyItem);

                                this.$emit(
                                    'calculate',
                                    this.deepCopy(this.supplyItemsAmounts).map(i => {
                                        return {
                                            priceLevel: i.priceLevel,
                                            avgSaleRate: i.avgSaleRate,
                                            amount: this.convertLocaleToNumber(i.amount)
                                        };
                                    })
                                );
                            } else {
                                this.$emit('validity', false);
                            }
                        })
                        .catch(() => {
                            this.$emit('validity', false);
                        });
                }
            }
        },

        mounted() {
            if (this.assignable) {
                this.resetValues();

                setTimeout(() => {
                    this.validationReady = true;
                }, 250);
            }
        }
    };
</script>

<style scoped></style>
