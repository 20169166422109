<template>
    <v-expansion-panel-header>
        <div class="purchase-limit-header">
            <purchase-limits-icon
                :is-approved="limit.isApproved()"
                :is-requested="limit.isRequested()"
                :is-disabled="limitDisabled"
            />

            <div class="purchase-limit-header__container">
                <span class="purchase-limit-header__title" v-color:black>
                    <span v-if="limitDisabled" v-color:black.translucent>
                        {{ limit.name }}
                    </span>

                    <span v-else>{{ limit.name }}</span>

                    <v-progress-linear
                        v-if="$vuetify.breakpoint.xsOnly && showProgressBars && !limit.isApproved() && limit.amountMin"
                        :value="limitProgress"
                        class="purchase-limit-header__progress"
                    />
                </span>

                <p class="purchase-limit-header__range text-secondary">
                    {{ formatAmountRanges }}
                </p>

                <v-progress-linear
                    v-if="$vuetify.breakpoint.smAndUp && showProgressBars && !limit.isApproved() && limit.amountMin"
                    :value="limitProgress"
                    class="purchase-limit-header__progress"
                />
            </div>
        </div>
    </v-expansion-panel-header>
</template>

<script>
    import PurchaseLimitsIcon from '@/components/ui/purchase-limits/VtPurchaseLimitsIcon';

    export default {
        name: 'PurchaseLimitHeader',

        components: { PurchaseLimitsIcon },

        props: {
            showProgressBars: Boolean,
            limitDisabled: Boolean,

            userLimit: Object,

            limit: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            under: 'bo.aml.under',
            from: 'bo.aml.from',
            to: 'bo.aml.to',
            above: 'bo.aml.above'
        }),

        computed: {
            formatAmountRanges() {
                if (this.limit.amountMax && this.limit.amountMin) {
                    return (
                        this.$t(this.from) +
                        ' ' +
                        this.formatAmount(this.limit.amountMin, 'czk') +
                        ' CZK ' +
                        this.$t(this.to) +
                        ' ' +
                        this.formatAmount(this.limit.amountMax, 'czk') +
                        ' CZK'
                    );
                } else if (this.limit.amountMax) {
                    return this.$t(this.under) + ' ' + this.formatAmount(this.limit.amountMax, 'czk') + ' CZK';
                } else {
                    return this.$t(this.above) + ' ' + this.formatAmount(this.limit.amountMin, 'czk') + ' CZK';
                }
            },
            limitProgress() {
                return (this.userLimit?.usedLimit / this.limit?.amountMin) * 100;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
