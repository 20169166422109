<template>
    <div v-if="isUserMFAEnabled">
        <app-title> {{ $t('admin.users.user.profile.mfaSettings.title') }} (2FA) </app-title>

        <app-text>
            {{ $t('admin.users.user.profile.mfaSettings.description') }}
        </app-text>

        <app-row>
            <app-registration-col whole-screen>
                <button-wrapper :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'">
                    <button-secondary inline icon="power-off" @click="resetMFASettings" full-width>
                        {{ $t('admin.users.user.profile.mfaSettings.button') }}
                    </button-secondary>
                </button-wrapper>
            </app-registration-col>
        </app-row>

        <app-divider />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'MfaSettingsOverview',

        computed: {
            ...mapGetters('admin_userDetail', ['user']),

            isUserMFAEnabled() {
                return this.user?.multiFactorAuth?.active;
            }
        },

        methods: {
            resetMFASettings() {
                this.$store
                    .dispatch('mfa/reset', this.user?.id)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
            }
        }
    };
</script>

<style scoped></style>
