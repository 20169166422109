<template>
    <div>
        <app-title>{{ $t('admin.users.user.profile.salesman.title') }}</app-title>

        <app-row v-if="!commissionAccountFetched">
            <app-registration-col>
                <app-loader wrapper horizontal />
            </app-registration-col>
        </app-row>

        <app-row v-else-if="commissionAccountError">
            <app-registration-col>
                <app-error fetching horizontal />
            </app-registration-col>
        </app-row>

        <div v-if="commissionAccountFetched && !commissionAccountError">
            <app-row>
                <app-registration-col field>
                    <app-switch-field
                        align-start
                        :loading="commissionAccountSwitchLoading"
                        :label="$t('admin.users.user.profile.salesman.setSalesman')"
                        v-model="commissionAccount.isActive"
                        @change="commissionAccountActivityChange"
                    ></app-switch-field>
                </app-registration-col>
            </app-row>

            <app-row>
                <app-registration-col whole-screen>
                    <p class="text-start" v-if="!commissionAccount.isActive">
                        {{ $t('admin.users.user.profile.salesman.userSetSalesmanToSetShare') }}
                    </p>

                    <p
                        class="text-start"
                        v-else-if="commissionAccountData.share !== null"
                        v-html="
                            $t('admin.users.user.profile.salesman.currentShare', {
                                position: commissionAccountData.share
                            })
                        "
                    ></p>

                    <p class="text-start" v-else>
                        {{ $t('admin.users.user.profile.salesman.userNoShare') }}
                    </p>
                </app-registration-col>
            </app-row>

            <validation-observer ref="formCommissionAccountShare">
                <app-row>
                    <app-registration-col field>
                        <app-text-field
                            v-model="commissionAccount.share"
                            :disabled="!commissionAccount.isActive"
                            rules="numeric|max_value:100|min_value:1"
                            :placeholder="$t('admin.users.user.profile.salesman.commissionShare')"
                        >
                            <template #append>
                                %
                            </template>
                        </app-text-field>
                    </app-registration-col>

                    <app-registration-col field>
                        <button-wrapper justify="start">
                            <button-primary
                                full-width
                                :loading="commissionAccountShareButtonLoading"
                                @click="commissionAccountShareChange"
                            >
                                {{ $t('admin.users.user.profile.salesman.set') }}
                            </button-primary>
                        </button-wrapper>
                    </app-registration-col>
                </app-row>
            </validation-observer>
        </div>

        <app-divider />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import { ValidationObserver } from 'vee-validate';

    import AppSwitchField from '@/components/ui/form/AppSwitchField';
    import AppTextField from '@/components/ui/form/AppTextField';

    export default {
        name: 'CommissionAccountSettings',

        components: {
            AppTextField,
            AppSwitchField,
            ValidationObserver
        },

        data: () => ({
            commissionAccountSwitchLoading: false,
            commissionAccountShareButtonLoading: false,
            commissionAccount: {
                isActive: false,
                share: ''
            }
        }),

        computed: {
            ...mapGetters('admin_commissionAccount', {
                commissionAccountData: 'accountData',
                commissionAccountFetched: 'accountDataFetched',
                commissionAccountError: 'accountDataError'
            })
        },

        methods: {
            commissionAccountShareChange() {
                if (this.commissionAccount.share === '') {
                    this.commissionAccount.share = this.commissionAccountData.share;
                    return;
                }

                if (this.commissionAccount.share !== this.commissionAccountData.share?.toString()) {
                    this.commissionAccountShareButtonLoading = true;

                    this.$refs.formCommissionAccountShare
                        .validate()
                        .then(result => {
                            if (result) {
                                this.$store
                                    .dispatch('admin_commissionAccount/update', {
                                        isActive: this.commissionAccount.isActive,
                                        share: this.commissionAccount.share
                                    })
                                    .then(() => {
                                        EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
                                    })
                                    .catch(error => {
                                        EventBus.$emit('snackbar:show:danger', error);
                                    })
                                    .finally(() => {
                                        this.commissionAccountShareButtonLoading = false;
                                    });
                            } else {
                                this.commissionAccountShareButtonLoading = false;
                            }
                        })
                        .catch(() => {
                            this.commissionAccountShareButtonLoading = false;
                        });
                }
            },
            commissionAccountActivityChange() {
                this.commissionAccountSwitchLoading = true;

                this.$store
                    .dispatch('admin_commissionAccount/update', {
                        isActive: !this.commissionAccountData.isActive
                    })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));

                        setTimeout(() => {
                            this.commissionAccountSwitchLoading = false;
                        }, 250);
                    })
                    .catch(error => {
                        setTimeout(() => {
                            this.commissionAccount.isActive = !this.commissionAccount.isActive;
                            this.commissionAccountSwitchLoading = false;
                        }, 250);

                        EventBus.$emit('snackbar:show:danger', error);
                    });
            },
            fetchUserCommissionAccount() {
                this.$store
                    .dispatch('admin_commissionAccount/fetch')
                    .then(commissionAccount => {
                        this.setCommissionAccount(commissionAccount);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
            },
            setCommissionAccount(commissionAccount) {
                this.commissionAccount.isActive = commissionAccount.isActive;
                this.commissionAccount.share = commissionAccount.share;
            }
        },

        watch: {
            'commissionAccountData.isActive': {
                handler(isActive) {
                    this.commissionAccount.isActive = isActive;
                }
            },
            'commissionAccountData.share': {
                handler(share) {
                    this.commissionAccount.share = share;
                }
            }
        },

        mounted() {
            if (!this.commissionAccountFetched) {
                this.fetchUserCommissionAccount();
            } else {
                this.setCommissionAccount(this.commissionAccountData);
            }
        },

        beforeDestroy() {
            this.$store.dispatch('admin_commissionAccount/clear');
        }
    };
</script>

<style scoped></style>
