import axios from 'axios';

import Vue from 'vue';

import { parseErrorMessageFromAPI, API_SERVER_ERROR, API_INTERNAL_ERROR } from '@/utils';

export const SystemDocuments = {
    AGENCY_AGREEMENT: 'agency_agreement'
};

export const SystemDocumentRestrictions = [
    {
        type: 'agency_agreement',
        conditionGetter: 'admin_commissionAccount/accountDisabled',
        reason: 'admin.users.user.documents.documentRestrictions.userNotSalesman'
    }
];

export const DocumentSet = {
    USER: {
        fetchUrl: 'lbw/personal-documents?userId=',
        downloadUrlPrefix: 'lbw/personal-documents/',
        downloadUrlSuffix: '/create-download-url',
        storeKey: 'documents'
    },
    OVERVIEW: {
        fetchUrl: 'lbw/personal-documents/overviews/',
        storeKey: 'documentsOverview'
    },
    SYSTEM: {
        fetchUrl: 'com/document-list/',
        downloadUrlPrefix: 'com/documents/',
        downloadUrlSuffix: '/create-download-url',
        storeKey: 'systemDocuments',
        uploadUrl: 'com/documents/upload-url',
        dispatchUpload: 'admin_userDocuments/upload'
    }
};

const state = {
    documents: {
        fetched: false,
        fetchError: false,
        data: []
    },
    documentsOverview: {
        fetched: false,
        fetchError: false,
        data: []
    },
    systemDocuments: {
        fetched: false,
        fetchError: false,
        data: []
    }
};

const getters = {
    documents: state => state.documents.data,
    'documents:fetched': state => state.documents.fetched,
    'documents:error': state => state.documents.fetchError,

    documentsOverviewApproved: state => state.documentsOverview.data?.state === 'approved',
    documentsOverviewFetched: state => state.documentsOverview.fetched,
    documentsOverviewError: state => state.documentsOverview.fetchError,

    systemDocuments: state => state.systemDocuments.data.items ?? [],
    systemDocumentsFetched: state => state.systemDocuments.fetched,
    systemDocumentsError: state => state.systemDocuments.fetchError,
    systemDocumentsUploaded: state => {
        return document => {
            const sd = state.systemDocuments.data.items?.find(sd => sd.name === document);

            return sd && sd.id !== null;
        };
    }
};

const actions = {
    clear: ({ commit }, documentSet) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', documentSet.storeKey);

            resolve();
        });
    },
    fetch: ({ commit, rootGetters }, documentSet) => {
        const userId = rootGetters['admin_userDetail/id'];

        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] UserId detected', userId);

                axios
                    .get(`${documentSet.fetchUrl}${userId}`)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const userDocuments = response.data;

                        if (userDocuments) {
                            Vue.$log.debug('[ACTION] Returning user documents', userDocuments);

                            commit('save', {
                                key: documentSet.storeKey,
                                data: userDocuments
                            });

                            resolve();
                        } else {
                            commit('error', documentSet.storeKey);

                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        commit('error', 'documents');

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.error('[ACTION] UserId not found');

                commit('error', 'documents');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    upload: (_, document) => {
        Vue.$log.debug('[ACTION] Running action with API call', document);

        return new Promise((resolve, reject) => {
            axios
                .post('com/documents', document)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    download: (_, { documentSet, documentId }) => {
        Vue.$log.debug('[ACTION] Running action with API call', documentId);

        return new Promise((resolve, reject) => {
            axios
                .post(`${documentSet.downloadUrlPrefix}${documentId}${documentSet.downloadUrlSuffix}`)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data && response.data.url) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    save: (state, { key, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', key, data);

        state[key].fetched = true;
        state[key].fetchError = false;
        state[key].data = data;
    },
    error: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = true;
        state[key].fetchError = true;
        state[key].data = {};
    },
    clear: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = false;
        state[key].fetchError = false;
        state[key].data = [];
    },
    clearAll: state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        const keys = ['systemDocuments', 'documents'];

        for (const key of keys) {
            state[key].fetched = false;
            state[key].fetchError = false;
            state[key].data = [];
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
