import { render, staticRenderFns } from "./DigitizationUserDetails.vue?vue&type=template&id=bda330b0&scoped=true&"
import script from "./DigitizationUserDetails.vue?vue&type=script&lang=js&"
export * from "./DigitizationUserDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bda330b0",
  null
  
)

export default component.exports