import Vue from 'vue';
import Vuex from 'vuex';

import tabs from './tabs';

import admin from './admin';
import auth from './auth';
import documents from './documents';
import mfa from './mfa';

import user from './user/user';
import user_passwordRecovery from './user/passwordRecovery';
import user_aml from './user/aml';

import admin_userDocuments from './admin/documents';
import admin_commissionAccount from './admin/commissionAccount';
import admin_companyDigitization from './admin/companyDigitization';
import admin_productTrading from './admin/productTrading';
import admin_aml from './admin/aml';
import admin_userDetail from './admin/userDetail';
import admin_productAccounts from './admin/productAccounts';
import admin_products from './admin/products';
import admin_storage from './admin/storage';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        tabs,
        admin,
        auth,
        documents,
        mfa,

        user,
        user_passwordRecovery,
        user_aml,

        admin_aml,
        admin_commissionAccount,
        admin_companyDigitization,
        admin_productTrading,
        admin_userDocuments,
        admin_userDetail,
        admin_productAccounts,
        admin_products,
        admin_storage
    }
});
