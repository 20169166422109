<template>
    <v-row class="bottom-menu">
        <v-container>
            <v-row>
                <v-col>
                    {{ $t('bo.menu.account') }}
                </v-col>
            </v-row>
            <v-row>
                <v-list class="full-width">
                    <v-list-item-group>
                        <vt-language-item />
                        <v-list-item class="vt-list-item" link @click="redirectToApp('world')">
                            <v-list-item-icon>
                                <img height="20" :src="getImgUrl('settings')" alt="" />
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t('common.settings') }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="vt-list-item" link @click="signOut">
                            <v-list-item-icon>
                                <img height="20" :src="getImgUrl('logout')" alt="" />
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $t('topPanel.logOutButton') }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="vt-list-item" link>
                            <v-row
                                class="pointer mt-0"
                                @click="redirectToApp('world')"
                                v-ripple="{ class: `primary--text` }"
                            >
                                <v-col class="max-widht-154">
                                    <div class="user-name text-truncate">{{ fullname }}</div>
                                    <div class="user-email text-truncate">{{ email }}</div>
                                </v-col>
                                <v-col class="max-widht-65">
                                    <div class="user-avatar">
                                        <img class="pt-1" src="@/assets/icons/avatar.svg" />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-row>
        </v-container>
    </v-row>
</template>

<script>
    import VtLanguageItem from './VtLanguageItem';
    import { getAppUrlFormat } from '@/utils';
    import { LOGIN_NAMESPACE_ADMIN } from '@lb-world/core/public/utils/auth';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'VtBottomSidebarMenu',

        props: ['sections', 'fullname', 'email'],

        components: {
            VtLanguageItem
        },

        data() {
            return {
                logoutButton: {
                    loading: false
                },
                model: null
            };
        },

        methods: {
            signOut() {
                this.logoutButton.loading = true;

                const redirectPage =
                    this.$store.getters['auth/token:namespace'] === LOGIN_NAMESPACE_ADMIN ? '/admin' : '/';

                this.$store
                    .dispatch('auth/user:logout')
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.signedOut'));

                        this.$router.push(redirectPage);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.logoutButton.loading = false;
                    });
            },
            redirectToApp(app) {
                window.open(getAppUrlFormat(app) + '/account/account');
            },
            getImgUrl(name) {
                const images = require.context('../../../assets/icons/', false, /\.svg$/);
                return images('./' + name + '.svg');
            }
        }
    };
</script>

<style scoped lang="scss"></style>
