<template>
    <v-app-bar class="product-tabs">
        <v-tabs
            :centered="$vuetify.breakpoint.xsOnly"
            v-model="activeTab"
            :class="['v-tabs__' + tabs.type]"
            class="px-4"
            @change="tabChange"
            center-active
        >
            <template v-for="tab in tabs.data">
                <v-tab v-if="checkPredicate(tab)" :key="tab.name" class="ma-0 pa-4 tab-font v-tab-style">
                    <font-awesome-icon v-if="tab.icon" class="mr-3" :icon="['fad', tab.icon]"></font-awesome-icon>
                    <span class="font-weight-medium">{{ $t(i18nPrefix + '.' + tab.name) }}</span>
                </v-tab>
            </template>
        </v-tabs>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppCustomNavigationTabs',

        data: () => ({
            activeTab: 0
        }),

        props: {
            tabs: {
                required: true
            },
            i18nPrefix: {
                required: false,
                default: ''
            }
        },

        methods: {
            checkPredicate(tab) {
                return tab.predicate === undefined || this.$store.getters[tab.predicate];
            },

            tabChange() {
                this.$emit('change', this.activeTab);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-tabs:not(.v-tabs--vertical) .v-tab:not(.v-tab--active) {
        color: rgba(119, 117, 150, 0.8) !important;
    }
    .v-tab-style {
        width: auto;
        text-transform: capitalize;
    }
</style>
