<template>
    <v-expansion-panel-content>
        <app-row>
            <app-registration-col whole-screen>
                <slot />
            </app-registration-col>
        </app-row>
    </v-expansion-panel-content>
</template>

<script>
    export default {
        name: 'PurchaseLimitContent'
    };
</script>
