<template>
    <div v-if="!limitsOverviewFetched">
        <app-loader vertical />
    </div>
    <div v-else-if="limitsOverviewError">
        <app-error fetching vertical horizontal no-padding />
    </div>

    <div v-else>
        <p v-if="!isUserVerified">
            {{ $t('bo.aml.notVerified') }}
        </p>

        <div class="d-flex">
            <v-expansion-panels v-model="panelsCollapsed">
                <v-expansion-panel :disabled="!isUserVerified || limitsOverview['limit1'].isInactiveForUser()">
                    <purchase-limit-header
                        show-progress-bars
                        :limit="limitsOverview['limit1']"
                        :limit-disabled="!isUserVerified || limitsOverview['limit1'].isInactiveForUser()"
                    />

                    <purchase-limit-content>
                        <app-row>
                            <app-registration-col>
                                <validation-observer ref="formAccomplish_limit1">
                                    <div class="d-flex align-center">
                                        <p class="mr-1 mb-0">{{ $t('bo.aml.limit1.politicallyExposedPerson') }}</p>

                                        <div>
                                            <button-secondary inline @click="openWikipedia">
                                                <font-awesome-icon :icon="['fas', 'question-circle']" />
                                            </button-secondary>
                                        </div>
                                    </div>

                                    <app-radio-group
                                        v-model="politicallyExposedPerson"
                                        rules="required"
                                        :items="[
                                            { name: $t('bo.aml.politicallyExposedPerson.yes'), value: true },
                                            { name: $t('bo.aml.politicallyExposedPerson.no'), value: false }
                                        ]"
                                    />

                                    <div class="mt-6">
                                        <p class="text-start">
                                            {{ $t('bo.aml.limit1.statement') }}
                                        </p>

                                        <app-select-field
                                            v-model="source.type"
                                            class="mt-6"
                                            :items="sourceItems"
                                            rules="required"
                                        />
                                    </div>

                                    <component :is="source.type" :details="source.details" />

                                    <p class="mt-6">{{ $t('bo.aml.lbWorldResourcesPurpose') }}</p>

                                    <div class="d-flex flex-column justify-center">
                                        <app-checkbox-group
                                            rules="required"
                                            :items="purposeItems"
                                            v-model="purpose.type"
                                        />

                                        <div v-if="purpose.type.includes('other')">
                                            <p class="text-start">
                                                {{ $t('bo.aml.purposeOtherDetails') }}
                                            </p>

                                            <app-text-field
                                                class="mt-6"
                                                v-model="purpose.additionalInfo"
                                                :rules="{
                                                    required: purpose.type.includes('other')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <button-primary
                                        class="mt-6"
                                        @click="requestAML(limitsOverview['limit1'])"
                                        :loading="loadingButtons.limit1"
                                    >
                                        {{ $t('bo.aml.requestLimit') }}
                                    </button-primary>
                                </validation-observer>
                            </app-registration-col>
                        </app-row>
                    </purchase-limit-content>
                </v-expansion-panel>

                <v-expansion-panel :disabled="isLimitDisabled(limitsOverview['limit2'])">
                    <purchase-limit-header
                        :limit-disabled="!isLimit1Approved"
                        show-progress-bars
                        :limit="limitsOverview['limit2']"
                    />

                    <validation-observer ref="formAccomplish_limit2">
                        <purchase-limit-content>
                            <p class="text-start">
                                {{ $t('bo.aml.limit2.statement1') }}
                            </p>

                            <p class="text-start">
                                {{ $t('bo.aml.limit2.statement2') }}
                            </p>

                            <button-primary
                                class="mt-6"
                                @click="requestAML(limitsOverview['limit2'])"
                                :loading="loadingButtons.limit2"
                            >
                                {{ $t('bo.aml.requestLimit') }}
                            </button-primary>
                        </purchase-limit-content>
                    </validation-observer>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';
    import { AMLLimits, AMLSources, isLimit1Approved } from '@lb-world/core/public/models/AMLLimit';
    import { ValidationObserver } from 'vee-validate';
    import { UserRoles } from '@lb-world/core/public/static/roles';

    import PurchaseLimitHeader from '@/components/ui/purchase-limits/VtPurchaseLimitHeader';
    import PurchaseLimitContent from '@/components/ui/purchase-limits/VtPurchaseLimitContent';
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppSelectField from '@/components/ui/form/VtAppSelectField';

    import WageOrSalary from '@/components/module-user/purchase-limits/source-details/WageOrSalary';
    import IncomeFromBusiness from '@/components/module-user/purchase-limits/source-details/IncomeFromBusiness';
    import MeansUsedForBusiness from '@/components/module-user/purchase-limits/source-details/MeansUsedForBusiness';
    import Loan from '@/components/module-user/purchase-limits/source-details/Loan';
    import PropertySale from '@/components/module-user/purchase-limits/source-details/PropertySale';
    import Inheritance from '@/components/module-user/purchase-limits/source-details/Inheritance';
    import Winnings from '@/components/module-user/purchase-limits/source-details/Winnings';
    import Gift from '@/components/module-user/purchase-limits/source-details/Gift';
    import Rent from '@/components/module-user/purchase-limits/source-details/Rent';
    import Other from '@/components/module-user/purchase-limits/source-details/Other';
    import AppRadioGroup from '../../ui/form/AppRadioGroup';
    import AppCheckboxGroup from '../../ui/form/AppCheckboxGroup';

    export default {
        name: 'VtPurchaseLimitsPanels',

        components: {
            AppCheckboxGroup,
            AppRadioGroup,
            AppSelectField,
            AppTextField,
            PurchaseLimitContent,
            PurchaseLimitHeader,
            ValidationObserver,

            wage_or_salary: WageOrSalary,
            income_from_business: IncomeFromBusiness,
            means_used_for_business: MeansUsedForBusiness,
            loan: Loan,
            property_sale: PropertySale,
            inheritance: Inheritance,
            winnings: Winnings,
            gift: Gift,
            rent: Rent,
            other: Other
        },

        data: () => ({
            panelsCollapsed: [],

            limit2RequestAmount: 40000,

            loadingButtons: {
                limit1: false,
                limit2: false
            },

            politicallyExposedPerson: null,

            sourceItems: [],
            purposeItems: [
                {
                    name: 'bo.aml.purposes.efk_token_value_speculation',
                    value: 'efk_token_value_speculation'
                },
                {
                    name: 'bo.aml.purposes.efk_token_holding',
                    value: 'efk_token_holding'
                },
                {
                    name: 'bo.aml.purposes.efk_token_selloff',
                    value: 'efk_token_selloff'
                },
                {
                    name: 'bo.aml.purposes.project_support',
                    value: 'project_support'
                },
                {
                    name: 'bo.aml.purposes.other',
                    value: 'other'
                }
            ],

            source: {
                type: '',
                details: {}
            },

            purpose: {
                type: [],
                additionalInfo: null
            }
        }),

        computed: {
            ...mapGetters('user_aml', ['limitsOverview', 'limitsOverviewFetched', 'limitsOverviewError']),

            isLimit1Approved() {
                return isLimit1Approved(this.limitsOverview);
            },

            isUserVerified() {
                return this.$store.getters['auth/token:role'](UserRoles.VERIFIED);
            }
        },

        methods: {
            openWikipedia() {
                const windowRef = window.open();

                windowRef.location.href = 'https://cs.wikipedia.org/wiki/Politicky_exponovaná_osoba';
            },
            controlForLimitRequest(limitId) {
                const limitRequest = this.$route.query['limitRequest'];
                const autoRequest = limitId === AMLLimits.LIMIT1 && limitRequest;

                this.$log.debug('[AUTO_REQUEST] Controlling for auto request', limitRequest, autoRequest);

                if (autoRequest && limitRequest > this.limit3RequestAmount) {
                    this.$log.debug('[AUTO_REQUEST] Auto-request for limit', AMLLimits.LIMIT3);

                    return this.limitsOverview[AMLLimits.LIMIT3];
                } else if (autoRequest && limitRequest > this.limit2RequestAmount) {
                    this.$log.debug('[AUTO_REQUEST] Auto-request for limit', AMLLimits.LIMIT2);

                    return this.limitsOverview[AMLLimits.LIMIT2];
                } else {
                    this.$log.debug('[AUTO_REQUEST] No auto-request');

                    return null;
                }
            },
            isLimitDisabled(limit) {
                return !this.isLimit1Approved || limit.isInactiveForUser();
            },
            requestAML(limit, requestedAutomatically) {
                this.loadingButtons[limit.id] = true;

                limit.setPeP(this.politicallyExposedPerson);
                limit.setSource(this.source.type, this.source.details);
                limit.setPurpose(this.purpose.type, this.purpose.additionalInfo);

                this.$refs['formAccomplish_' + limit.id]
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('user_aml/limitsOverview:update', { limit, requestedAutomatically })
                                .then(() => {
                                    this.$emit('limit:update');

                                    this.panelsCollapsed = [];

                                    EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.loadingButtons[limit.id] = false;
                                });
                        } else {
                            this.loadingButtons[limit.id] = false;
                        }
                    })
                    .catch(() => {
                        this.loadingButtons[limit.id] = false;
                    });
            }
        },

        mounted() {
            for (const index in AMLSources) {
                this.sourceItems.push({
                    name: 'bo.aml.sources.' + AMLSources[index],
                    value: AMLSources[index]
                });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
