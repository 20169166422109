<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field
                        hide-hint
                        :placeholder="$t('filters.productAccountId')"
                        v-model="filters.productAccount"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        v-model="filters.product"
                        item-text="name"
                        :items="productTypes"
                        :placeholder="$t('filters.productType')"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.userId')" v-model="filters.user" />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
        >
            <template v-slot:item.amount="{ item }">
                <span v-if="item.amount">{{ formatAmount(item.amount, item.currency) }} {{ item.currency }}</span>
                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{ formatTimestamp(item.createdAt) }}
            </template>

            <template v-slot:item.type="{ item }">
                {{ $t('transactionTypes.' + item.type) }}
            </template>

            <template v-slot:item.subType="{ item }">
                <span v-if="item.subType">
                    {{ $t('transactionSubtypes.' + item.subType) }}
                </span>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';

    import { PTFactory } from '@lb-world/core/public/static/productTypes';

    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminProductAccountsTransactionsTable',

        components: { AppTextField, AppSelectField },

        mixins: [core, pagination, filtering],

        data: () => ({
            markAsPaidLoadingButton: {},
            productTypes: PTFactory.get()
        }),

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.userId'),
                        value: 'userId'
                    },
                    {
                        text: this.$t('table.columns.transactionId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.productAccountId'),
                        value: 'productAccountId'
                    },
                    {
                        text: this.$t('table.columns.createdAt'),
                        value: 'createdAt'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount'
                    },
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    },
                    {
                        text: this.$t('table.columns.subtype'),
                        value: 'subType'
                    }
                ];
            }
        }
    };
</script>

<style scoped></style>
