import { API_INTERNAL_ERROR, parseErrorMessageFromAPI } from '@/utils';

import AMLType, { AMLLimits } from '@lb-world/core/public/models/AMLLimit';

import Vue from 'vue';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import AMLRepository from '@lb-world/core/public/api/repositories/AMLRepository';

export const baseLimits = {
    limit1: new AMLType(AMLLimits.LIMIT1, 'Limit 1', undefined, 1000000),
    limit2: new AMLType(AMLLimits.LIMIT2, 'Limit 2', 1000000, undefined)
};

const state = {
    limitsOverview: new BaseDataset(baseLimits),
    purchaseLimit: new BaseDataset()
};

const getters = {
    limitsOverview: state => state.limitsOverview.data,
    limitsOverviewFetched: state => state.limitsOverview.fetched,
    limitsOverviewError: state => state.limitsOverview.fetchError,

    purchaseLimit: state => state.purchaseLimit.data,
    purchaseLimitFetched: state => state.purchaseLimit.fetched,
    purchaseLimitError: state => state.purchaseLimit.fetchError
};

const actions = {
    'limitsOverview:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUserState(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store:limits', {
                            key: 'limitsOverview',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'limitsOverview');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'limitsOverview');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'limitsOverview:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'limitsOverview');

            resolve();
        });
    },
    'limitsOverview:update': ({ commit, rootGetters }, { limit, requestedAutomatically }) => {
        Vue.$log.debug('[ACTION] Running action with API call', limit, requestedAutomatically);

        return new Promise((resolve, reject) => {
            AMLRepository.requestLimit(rootGetters['user/user:id'], limit.getData(), requestedAutomatically)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store:limits', {
                            key: 'limitsOverview',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'limitsOverview');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'limitsOverview');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'purchaseLimits:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUserLimit(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'purchaseLimit',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'purchaseLimit');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'purchaseLimit');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'purchaseLimits:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'purchaseLimit');

            resolve();
        });
    }
};

const mutations = {
    store: (state, { key, data }) => {
        state[key].storeData(data);
    },
    'store:limits': (state, { key, data }) => {
        const fn = function(response) {
            for (const index in this) {
                const aml = this[index];

                this[aml.id].setServerData(response[aml.id]);
            }
        };

        state[key].storeData(data, fn);
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    error: (state, key) => {
        state[key].storeError();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
