<template>
    <validation-observer ref="formNewPasswordRequired">
        <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000">
            <app-card dialog>
                <app-dialog-title :title="$t('admin.companyDigitization.companyDigitization')" :dialog="dialog" />

                <app-dialog-tabs centered :i18n-prefix="tabs.i18nPrefix" :tabs="tabs" :current-tab-index="tabIndex">
                </app-dialog-tabs>

                <component
                    :is="currentTab"
                    :digitizationInfo="digitization"
                    class="py-6"
                    @process:next="tabIndex += 1"
                    @process:prev="tabIndex -= 1"
                    @process:finished="onFinished"
                    @process:reset="tabIndex = 0"
                >
                </component>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import AppCol from '../../../ui/layout/AppCol';
    import AppTextField from '../../../ui/form/AppTextField';
    import AppSelectField from '@/components/ui/form/AppSelectField';
    import DigitizationCompanyInfo from '@/components/module-admin/user/companyDigitization/companyDigitizationDialog/DigitizationCompanyInfo';
    import DigitizationCompanyAddress from '@/components/module-admin/user/companyDigitization/companyDigitizationDialog/DigitizationCompanyAddress';
    import AppDialogTabs from '@/components/ui/tabs/AppDialogTabs';

    export default {
        name: 'CompanyDigitizationDialog',

        props: ['dialog'],

        components: {
            AppDialogTabs,
            AppSelectField,
            AppTextField,
            AppCol,
            ValidationObserver,

            companyInfo: DigitizationCompanyInfo,
            companyAddress: DigitizationCompanyAddress
        },

        data: () => ({
            tabIndex: 0,

            digitization: {
                name: '',
                surname: '',
                company: '',
                ico: '',
                dic: '',
                role: '',
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            }
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/admin:documents:companyDigitization'];
            },
            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            }
        },

        methods: {
            onFinished() {
                this.dialog.open = false;
                this.$emit('process:success');
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.tabIndex = 0;
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
