<template>
    <div v-if="!$store.getters['user/account:activated']" class="activate-account-container">
        <div>
            <img height="32" class="mr-4" src="@/assets/icons/activate-account-doc.svg" />
        </div>
        <span class="font-weight-500">{{ $t('ActivateAccountBanner.title') }}</span>
        <div class="activate-account-banner-style" />
        <span>
            {{ $t('ActivateAccountBanner.info') }}
            <a v-if="!this.$store.getters['user/user:digitized']" @click="dialog.open = true" class="font-weight-700">{{
                $t('ActivateAccountBanner.clickHere')
            }}</a>
            <router-link v-else :to="{ name: 'account:documents' }">
                <span class="font-weight-700">{{ $t('ActivateAccountBanner.clickHere') }}</span>
            </router-link>
        </span>
        <vt-user-digitization-dialog
            @finished="fetchDocuments"
            :dialog="dialog"
            :user="user"
            type="identification"
            bank-account
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import VtUserDigitizationDialog from '@/components/ui/dialogs/VtUserDigitizationDialog';
    import { DocumentSet } from '../../store/documents';

    export default {
        name: 'VtActivateAccountBanner',

        components: {
            VtUserDigitizationDialog
        },

        computed: {
            ...mapGetters('user', {
                user: 'user'
            })
        },

        data: () => ({
            dialog: {
                open: false
            }
        }),

        methods: {
            fetchDocuments() {
                this.$store.dispatch('documents/clear', DocumentSet.USER).then(() => {
                    this.$store.dispatch('documents/fetch', DocumentSet.USER);
                });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
