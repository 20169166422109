<template>
    <div class="full-width">
        <vt-activate-account-banner />
        <user-password-change-dialog
            :dialog="passwordChangeDialog"
            :pOldPassword="oldPassword"
            :pNewPassword="newPassword"
            @reset="resetPass"
        />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
                <vt-card full class="ma-0 ma-md-5">
                    <v-row class="ma-0 pa-0 px-4 bottom-line">
                        <app-custom-navigation-tabs
                            :i18n-prefix="userProfileTabs.i18nPrefix"
                            :tabs="userProfileTabs"
                            @change="tabChange"
                        ></app-custom-navigation-tabs>
                    </v-row>
                    <component
                        :is="currentTab"
                        :user="user"
                        @password:change="openPasswordChangeDialog"
                        @user:update="userUpdated"
                    ></component>
                </vt-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import UserPasswordChangeDialog from '../../../components/ui/dialogs/VtUserPasswordChangeDialog';
    import VtInfoBasicTab from '@/views/account/sections/tabs/VtInfoBasicTab';
    import VtInfoAddressTab from '@/views/account/sections/tabs/VtInfoAddressTab';
    import VtInfoBankAccountTab from '@/views/account/sections/tabs/VtInfoBankAccountTab';
    import VtInfoDocumentTab from '@/views/account/sections/tabs/VtInfoDocumentTab';
    import AccountActivationWidget from '@/components/module-user/VtAccountActivationWidget';
    import AppCustomNavigationTabs from '@/components/ui/tabs/VtAppCustomNavigationTabs';
    import VtActivateAccountBanner from '@/components/ui/VtActivateAccountBanner';

    export default {
        name: 'VtAccount',

        components: {
            AppCustomNavigationTabs,
            AccountActivationWidget,
            UserPasswordChangeDialog,
            VtActivateAccountBanner,

            userInfo: VtInfoBasicTab,
            userAddress: VtInfoAddressTab,
            bankAccount: VtInfoBankAccountTab,
            documents: VtInfoDocumentTab
        },

        computed: {
            ...mapGetters({
                user: 'user/user',
                userLanguage: 'user/user:language',
                languages: 'user/account:languages'
            }),
            userProfileTabs() {
                return this.$store.getters['tabs/account:user:info'];
            },
            currentTab() {
                return this.userProfileTabs.data[this.tabIndex].name;
            }
        },

        data: () => ({
            passwordChangeDialog: {
                open: false
            },
            oldPassword: '',
            newPassword: '',
            tabIndex: 0
        }),

        methods: {
            userUpdated() {
                EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
            },
            tabChange(id) {
                this.tabIndex = id;
            },
            resetPass() {
                console.log('reset');
                this.oldPassword = '';
                this.newPassword = '';
            },
            openPasswordChangeDialog(pass) {
                console.log(pass);
                this.oldPassword = pass.oldPassword;
                this.newPassword = pass.newPassword;
                this.passwordChangeDialog.open = true;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
