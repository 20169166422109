<template>
    <div>
        <app-row>
            <v-col>
                <v-item-group id="digit-box" class="d-flex justify-center">
                    <validation-provider
                        :name="$attrs.label"
                        rules="numeric"
                        v-slot="{ errors }"
                        v-for="i in digitBox.numberOfInputs"
                        :key="i"
                    >
                        <v-text-field
                            background-color="#F1F1F8"
                            outlined
                            v-model="digitBox.inputs[i - 1].value"
                            v-bind="$attrs"
                            v-on="$listeners"
                            :error-messages="errors"
                            class="v-input--is-digit-box"
                            ref="digitBoxInputs"
                            @keyup="digitBoxFieldInput(i - 1, $event)"
                            @focus="digitBoxFieldFocus"
                        >
                        </v-text-field>
                    </validation-provider>
                </v-item-group>
            </v-col>
        </app-row>

        <slot
            :eraseCode="eraseCode"
            :code="code"
            :paste-code="pasteCode"
            :numberOfInputs="digitBox.numberOfInputs"
        ></slot>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'AppDigitsBox',

        components: { ValidationProvider },

        props: ['activated'],

        data: () => ({
            digitBox: {
                inputs: [],
                numberOfInputs: 6
            }
        }),

        computed: {
            code() {
                return this.digitBox.inputs.reduce((acc, i) => acc + i.value, '');
            }
        },

        methods: {
            pasteCode() {
                navigator.clipboard
                    .readText()
                    .then(text => {
                        if (Number.isInteger(Number.parseInt(text))) {
                            this.digitBox.inputs[0].value = text;

                            this.controlPasswordSequence();
                            this.digitBoxFindFirstEmptyAndFocus();
                        } else {
                            EventBus.$emit('snackbar:show:danger', this.$t('paste_clipboard_error'));
                        }
                    })
                    .catch(() => {
                        EventBus.$emit('snackbar:show:danger', this.$t('paste_clipboard_error'));
                    });
            },

            eraseCode() {
                this.digitBox.inputs.forEach(i => (i.value = ''));

                this.digitBoxFindFirstEmptyAndFocus();
            },

            digitBoxFieldInput(index, e) {
                this.clearInvalidCharacters(index);
                this.controlBackspacePressed(index, e);
                this.controlPasswordSequence();
                this.digitBoxFindFirstEmptyAndFocus();
            },

            clearInvalidCharacters(index) {
                const field = this.digitBox.inputs[index];

                if (field.value !== '' && !Number.isInteger(Number.parseInt(field.value))) {
                    field.value = '';
                }
            },

            controlBackspacePressed(index, e) {
                if (e.keyCode === 8 && index !== 0) {
                    this.digitBox.inputs[index - 1].value = '';
                }
            },
            controlPasswordSequence() {
                let sequenceEntered = null;

                this.digitBox.inputs.forEach(field => {
                    if (field.value !== '' && field.value?.length === 6) {
                        sequenceEntered = field.value;
                    } else if (field.value !== '' && field.value?.length > 1) {
                        field.value = '';
                    }
                });

                if (sequenceEntered) {
                    this.digitBox.inputs.forEach((field, index) => {
                        field.value = sequenceEntered[index];
                    });
                }
            },

            digitBoxFieldFocus() {
                this.digitBoxFindFirstEmptyAndFocus();
            },

            digitBoxFindFirstEmptyAndFocus() {
                const firstEmpty = this.digitBox.inputs.find(i => !i.value.length);

                if (firstEmpty) {
                    this.$nextTick(() => {
                        this.$refs.digitBoxInputs[firstEmpty.id].focus();
                    });
                } else {
                    this.$nextTick(() => {
                        for (let i = 0; i < this.digitBox.numberOfInputs; i++) {
                            if (this.$refs.digitBoxInputs[i]) {
                                this.$refs.digitBoxInputs[i].blur();
                            }
                        }
                    });

                    this.$emit('finished', this.code);
                }
            }
        },

        watch: {
            boxInputs() {
                this.digitBoxFindFirstEmptyAndFocus();
            },

            activated() {
                this.eraseCode();
                this.digitBoxFindFirstEmptyAndFocus();
            }
        },

        mounted() {
            this.eraseCode();
            this.digitBoxFindFirstEmptyAndFocus();
        },

        created() {
            for (let i = 0; i < this.digitBox.numberOfInputs; i++) {
                this.digitBox.inputs.push({
                    id: i,
                    value: ''
                });
            }
        }
    };
</script>

<style lang="scss" scoped></style>
