<template>
    <v-container fill-height fluid class="d-flex flex-column pa-0 flex-nowrap container-style vtRegistration">
        <app-registration-drawer
            :drawer="drawer"
            :sections="tabs"
            v-if="$vuetify.breakpoint.smAndDown"
        ></app-registration-drawer>
        <app-header :key="$i18n.locale">
            <vt-app-toolbar
                class="width-100vw"
                :user-id="userId"
                :username="username"
                :returnButton="true"
                center-logo
                :hideDrawerPanel="$vuetify.breakpoint.mdAndUp"
                :hideAppPanel="$vuetify.breakpoint.smAndDown"
                :hide-user-panel="toolbar.hideUserPanel"
                @drawer:toggle="drawer = !drawer"
            ></vt-app-toolbar>
            <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2 vt-registration-title">{{ $t('reg.title') }}</div>
        </app-header>

        <v-container class="pa-0 mt-8 container-border-style">
            <vt-app-tabs :tabs="tabs" :i18n-prefix="tabs.i18nPrefix" centered :exact="false"></vt-app-tabs>
            <router-view @toolbar:update="updateToolbar" />
        </v-container>
    </v-container>
</template>

<script>
    import VtAppToolbar from '../../components/ui/VtAppToolbar';
    import VtAppTabs from '../../components/ui/tabs/VtAppTabs';
    import AppHeader from '../../components/ui/VtAppHeader';
    import AppRegistrationDrawer from '../../components/ui/drawers/VtAppRegistrationDrawer';

    export default {
        name: 'RegistrationLayout',

        components: {
            AppHeader,
            AppRegistrationDrawer,
            VtAppTabs,
            VtAppToolbar
        },

        data: () => ({
            toolbar: {
                disabled: true,
                logoutButton: false
            },
            drawer: false
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/registration'];
            },
            username() {
                return this.$store.getters['user/user:email'];
            },
            userId() {
                return this.$store.getters['user/user:id'];
            }
        },

        methods: {
            updateToolbar(toolbarOptions) {
                this.toolbar = toolbarOptions;
            }
        }
    };
</script>

<style scoped lang="scss"></style>
