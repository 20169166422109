<template>
    <v-app-bar>
        <v-tabs
            hide-slider
            :class="['app-tabs', 'v-tabs__' + tabs.type]"
            :centered="centered"
            :show-arrows="!shortcutOnMobile"
        >
            <template v-if="tabs.type === 'steps'">
                <v-tab
                    v-for="(tab, index) in tabs.data"
                    :key="tab.name"
                    :exact="exact"
                    :to="{ name: tab.path }"
                    disabled
                    class="ma-0 pa-0 tab-font v-tab-style"
                    @change="currentTab = index"
                >
                    <div class="step-number" :class="{ 'step-done': Number(index) < Number(currentTab || 0) }">
                        <!--                        <img height="16" v-if="Number(index) < Number(currentTab || 0)" src="@/assets/icons/registration-icon-check.svg" />-->
                        <svg
                            v-if="Number(index) < Number(currentTab || 0)"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                fill="#67B92E"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.0303 4.96967C12.3232 5.26257 12.3232 5.73744 12.0303 6.03033L7.5303 10.5303C7.38694 10.6737 7.19166 10.7529 6.98895 10.7499C6.78624 10.7469 6.59337 10.662 6.45429 10.5145L4.16892 8.09078C3.88476 7.78941 3.89871 7.31474 4.20008 7.03058C4.50145 6.74641 4.97612 6.76036 5.26028 7.06173L7.01578 8.92353L10.9697 4.96967C11.2626 4.67678 11.7374 4.67678 12.0303 4.96967Z"
                                fill="white"
                            />
                        </svg>

                        {{ index + 1 }}
                    </div>

                    <div v-if="!(shortcutOnMobile && $vuetify.breakpoint.xsOnly)" class="d-none d-sm-flex tab-font">
                        {{ $t(i18nPrefix + '.' + tab.name) }}
                    </div>
                </v-tab>
            </template>

            <template v-else-if="tabs.type === 'selection'">
                <v-tab v-for="tab in tabs.data" :key="tab.name" :to="{ name: tab.path }">
                    <font-awesome-icon v-if="tab.icon" :icon="['fad', tab.icon]" />

                    <span class="ml-3" v-if="!(shortcutOnMobile && $vuetify.breakpoint.xsOnly)">
                        {{ $t(i18nPrefix + '.' + tab.name) }}
                    </span>
                </v-tab>
            </template>
        </v-tabs>

        <div class="progress-bar-div-style">
            <div
                :style="{
                    width: lengthOfProgressBar + '%',
                    height: '6px',
                    transition: 'all 0.5s ease',
                    background: '#DEB8FF'
                }"
            ></div>
        </div>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'VtAppTabs',

        props: {
            exact: {
                type: Boolean,
                default: true
            },
            tabs: {
                required: true
            },
            i18nPrefix: {
                required: false,
                default: ''
            },
            centered: {
                type: Boolean
            },
            shortcutOnMobile: {
                type: Boolean
            }
        },
        mounted: function() {
            console.log(this.tabs);
        },
        computed: {
            lengthOfProgressBar: function() {
                const count = (this.tabs.data || []).length || 0;

                return ((this.currentTab + 1) / count) * 100;
            }
        },
        data() {
            return {
                currentTab: null
            };
        }
    };
</script>

<style scoped lang="scss"></style>
