var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"items-per-page":1000,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.czkAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.czkAmount, 'czk'))+" ")]}},{key:"item.efkAmount",fn:function(ref){
var item = ref.item;
return [(_vm.assignable)?_c('span',{directives:[{name:"color",rawName:"v-color:primary",arg:"primary"}],staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.calculateAmount, 'efk'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.formatAmount(item.efkAmount, 'efk')))])]}},{key:"item.maxPurchaseRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.maxPurchaseRate, 'rate'))+" ")]}},{key:"item.avgPurchaseRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.avgPurchaseRate, 'rate'))+" ")]}},{key:"item.maxPurchaseRateLB",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.maxPurchaseRateLB, 'rate'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-secondary',{attrs:{"inline":""},on:{"click":function($event){return _vm.$emit('tradingDialog:open', item)}}},[_vm._v(" "+_vm._s(_vm.$t('admin.products.exchange.trade'))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }