<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('admin.products.exchange.tradingDialog.title')" :dialog="dialog" />

            <app-text justify="center" v-if="dialog.tradingRateFormat">
                <span
                    v-html="
                        $t('admin.products.exchange.rateOverview.currentRate', {
                            rate: dialog.tradingRateFormat
                        })
                    "
                />
            </app-text>

            <alert-danger v-if="tradingRulesViolation">
                {{ $t('errors.trading_values_violation') }}
            </alert-danger>

            <app-card v-if="dialog.demandItem">
                <app-title justify="center">{{ $t('admin.products.exchange.tradingDialog.demandItem') }}</app-title>

                <app-divider />

                <admin-product-exchange-demand-table
                    assignable
                    :loading="false"
                    :fetch-error="false"
                    :items="[dialog.demandItem]"
                    :original-amount="demandEFKAmount"
                    :calculated-amount="totalSupplyAmount"
                />
            </app-card>

            <app-card v-if="dialog.supplyItems" class="mt-6">
                <app-title justify="center">{{ $t('admin.products.exchange.common.supply') }}</app-title>

                <app-divider />

                <admin-product-exchange-supply-table
                    ref="tradingDialogExchangeSupplyTable"
                    assignable
                    :loading="false"
                    :fetch-error="false"
                    :items="dialog.supplyItems"
                    @calculate="calculateAmount"
                    @validity="validityAmount"
                />

                <template #button-primary>
                    <button-primary
                        @click="confirmNewTrade"
                        :loading="confirmTradeLoadingButton"
                        :disabled="!controlTradeValidity"
                    >
                        {{ $t('admin.products.exchange.tradingDialog.submit') }}
                    </button-primary>
                </template>
            </app-card>
        </app-card>
    </v-dialog>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AdminProductExchangeSupplyTable from '@/components/ui/tables/AdminProductExchangeSupplyTable';
    import AdminProductExchangeDemandTable from '@/components/ui/tables/AdminProductExchangeDemandTable';
    import AlertDanger from '@/components/ui/AlertDanger';

    export default {
        name: 'ProductsTradingDialog',

        components: { AlertDanger, AdminProductExchangeDemandTable, AdminProductExchangeSupplyTable },

        props: ['dialog'],

        data: () => ({
            totalSupplyAmount: 0,
            supplyItemsAmounts: null,
            supplyItemsValid: true,

            confirmTradeLoadingButton: false
        }),

        computed: {
            tradingRulesViolation() {
                const tradingValues = this.supplyItemsAmounts?.filter(i => i.amount > 0);

                if (tradingValues && this.demandPriceLevel) {
                    return !tradingValues.every(i => i.avgSaleRate <= this.demandPriceLevel);
                }

                return false;
            },
            demandPriceLevel() {
                return this.dialog.demandItem?.maxPurchaseRate;
            },
            demandEFKAmount() {
                return this.dialog.demandItem?.efkAmount;
            },
            controlTradeValidity() {
                return (
                    this.supplyItemsAmounts !== null &&
                    this.supplyItemsValid &&
                    this.supplyItemsAmounts.filter(i => this.convertLocaleToNumber(i.amount) > 0)?.length > 0
                );
            }
        },

        methods: {
            calculateAmount(supplyItemsAmounts) {
                if (supplyItemsAmounts) {
                    const totalSupplyAmountReducer = (acc, item) => {
                        return acc + parseFloat(item.amount);
                    };

                    this.supplyItemsAmounts = supplyItemsAmounts.filter(i => i.amount > 0);
                    this.supplyItemsValid = true;
                    this.totalSupplyAmount = this.supplyItemsAmounts.reduce(totalSupplyAmountReducer, 0);
                }
            },
            validityAmount(valid) {
                this.supplyItemsValid = valid;
            },
            confirmNewTrade() {
                this.confirmTradeLoadingButton = true;

                const supplyItems = this.supplyItemsAmounts.map(i => {
                    return { priceLevel: i.priceLevel, amount: i.amount };
                });

                this.$store
                    .dispatch('admin_productTrading/newTrade', {
                        demandPriceLevel: this.demandPriceLevel,
                        supplyItems
                    })
                    .then(() => {
                        this.dialog.open = false;
                        this.$emit('trade');

                        EventBus.$emit('snackbar:show:success', this.$t('success.tradingSuccessful'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.confirmTradeLoadingButton = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        if (this.$refs.tradingDialogExchangeSupplyTable) {
                            this.$refs.tradingDialogExchangeSupplyTable.resetValues();
                            this.$refs.tradingDialogExchangeSupplyTable['formSupplyItemsAmounts']?.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
