<template>
    <div>
        <app-title>{{ $t('admin.users.menu.manageUsers') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <app-card>
                    <admin-users-table
                        :loading="!usersFetched"
                        :pages="usersPages"
                        :items="users"
                        :fetch-error="usersError"
                        :filters.sync="usersTable.filters"
                        :pre-applied="usersTable.preAppliedFilters"
                        :custom-default="usersTable.customDefaultFilters"
                        @pagination:change="fetchUsers"
                        @filter:update="filterUsers"
                        @filter:reset="filterUsers"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AdminUsersTable from '@/components/ui/tables/AdminUsersTable';

    export default {
        name: 'List',

        components: {
            AdminUsersTable
        },

        data: () => ({
            userDialog: {
                open: false,
                user: {}
            },
            usersTable: {
                preAppliedFilters: null,
                customDefaultFilters: {
                    allDataDigitized: null,
                    identityVerificationState: null
                },
                filters: {
                    id: '',
                    email: '',
                    name: '',
                    surname: '',
                    allDataDigitized: null,
                    identityVerificationState: null
                },
                page: 1
            }
        }),

        computed: {
            ...mapGetters('admin', {
                users: 'users',
                usersFetched: 'users:fetched',
                usersError: 'users:error',
                usersPages: 'users:pages',
                usersFilter: 'users:filter'
            })
        },

        methods: {
            fetchUsers(page) {
                this.usersTable.page = page;

                this.$store.dispatch('admin/users:clear').then(() => {
                    this.$store
                        .dispatch('admin/users:fetch', {
                            page: this.usersTable.page,
                            filter: this.usersTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterUsers(filter) {
                this.usersTable.page = 1;
                this.usersTable.filters = filter;

                this.$store.dispatch('admin/users:clear').then(() => {
                    this.$store
                        .dispatch('admin/users:fetch', {
                            page: this.usersTable.page,
                            filter: this.usersTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.usersFilter) {
                this.$set(this.usersTable, 'preAppliedFilters', this.usersFilter);
            }

            if (!this.usersFetched) {
                this.fetchUsers();
            }
        }
    };
</script>

<style scoped></style>
