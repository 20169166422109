<template>
    <div>
        <company-digitization-dialog
            :dialog="companyDigitizationDialog"
            @process:success="fetchCompanyDigitizationData"
        />

        <div>
            <v-skeleton-loader
                v-if="!companyDataFetched || !systemDocumentsFetched"
                :loading="true"
                type="text"
                width="500"
                class="d-flex align-center"
                :min-height="24"
                transition="fade-transition"
            >
            </v-skeleton-loader>

            <p v-else-if="companyDataError || systemDocumentsError" class="text-start">
                <font-awesome-icon v-color:danger :icon="['fas', 'times-circle']"></font-awesome-icon>

                <span class="ml-2">{{ $t('common.fetchingErrorOccurred') }}</span>
            </p>

            <p
                v-else-if="!isAgencyAgreementUploaded"
                class="text-container"
                v-html="$t('admin.companyDigitization.state.uploadDocument')"
            ></p>

            <p v-else-if="companyDigitized" class="text-container">
                {{ $t('admin.companyDigitization.state.alreadyDone') }}
            </p>

            <div v-else class="text-container d-flex align-center">
                <p class="text-start mb-0 mr-1">
                    {{ $t('admin.companyDigitization.state.available') }}
                </p>

                <button-wrapper justify="start">
                    <button-secondary inline small @click="companyDigitizationDialog.open = true">
                        {{ $t('admin.companyDigitization.digitize') }}
                    </button-secondary>
                </button-wrapper>
            </div>
        </div>
    </div>
</template>

<script>
    import CompanyDigitizationDialog from '@/components/module-admin/user/companyDigitization/CompanyDigitalizationDialog';

    import { mapGetters } from 'vuex';
    import { SystemDocuments } from '@/store/admin/documents';

    export default {
        name: 'CompanyDigitizationProcess',

        components: { CompanyDigitizationDialog },

        data: () => ({
            companyDigitizationDialog: {
                open: false
            }
        }),

        computed: {
            ...mapGetters('admin_companyDigitization', [
                'companyData',
                'companyDataFetched',
                'companyDigitized',
                'companyDataError'
            ]),
            ...mapGetters('admin_userDocuments', [
                'systemDocuments',
                'systemDocumentsFetched',
                'systemDocumentsError',
                'systemDocumentsUploaded'
            ]),

            isAgencyAgreementUploaded() {
                const document = SystemDocuments.AGENCY_AGREEMENT;

                return this.systemDocumentsUploaded(document);
            }
        },

        methods: {
            fetchCompanyDigitizationData() {
                this.$store.dispatch('admin_companyDigitization/clear').then(() => {
                    this.$store.dispatch('admin_companyDigitization/fetch').catch(() => {
                        /*TODO now returns error if not exists*/
                    });
                });
            }
        },

        mounted() {
            if (!this.companyDataFetched) {
                this.fetchCompanyDigitizationData();
            }
        }
    };
</script>

<style scoped lang="scss">
    .text-container {
        text-align: start !important;
    }
</style>
