<template>
    <v-container fluid class="pa-0">
        <v-container class="v-container-style" flat clipped-left app v-if="!hideToolBar">
            <v-row justify="space-between" align="center" class="text-center">
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="pa-0">
                    <img class="vertical-align-middle" src="@/assets/logos/lb-world-black.svg" alt="" />
                    <div
                        class="d-inline-flex"
                        :class="{
                            'ml-6': $vuetify.breakpoint.smAndUp && hideDrawerPanel,
                            'ml-2': $vuetify.breakpoint.xs && hideDrawerPanel
                        }"
                        v-if="!hideAppPanel"
                    >
                        <vt-app-menu />
                    </div>
                </v-col>

                <v-col cols="3" class="pa-0 text-left">
                    <v-btn
                        @click="$emit('drawer:toggle')"
                        v-if="!hideDrawerPanel"
                        :class="{ 'ml-8': $vuetify.breakpoint.smAndUp, 'ml-4': $vuetify.breakpoint.xs }"
                        icon
                    >
                        <img height="24" src="@/assets/logos/menu.svg" alt="" />
                    </v-btn>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="mb-2 vt-registration-title">
                        {{ $t('reg.title') }}
                    </div>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.smAndDown" cols="3" class="pa-0">
                    <img class="vertical-align-middle" src="@/assets/logos/lb-world-black.svg" alt="" />
                </v-col>

                <v-col cols="3" class="pa-0" :class="{ 'text-right': $vuetify.breakpoint.mdAndDown }">
                    <div
                        :class="{
                            'mr-6': $vuetify.breakpoint.md,
                            'mr-4': $vuetify.breakpoint.sm,
                            'mr-2': $vuetify.breakpoint.xs
                        }"
                    >
                        <vt-lang-menu />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import VtLangMenu from '../../components/ui/menu/VtLangMenu';
    import VtAppMenu from '../../components/ui/menu/VtAppMenu';

    export default {
        name: 'VtAppToolbar',

        components: {
            VtAppMenu,
            VtLangMenu
        },

        props: {
            hideToolBar: {
                type: Boolean
            },
            returnButton: {
                type: Boolean
            },
            hideUserPanel: {
                type: Boolean
            },
            hideAppPanel: {
                type: Boolean
            },
            hideDrawerPanel: {
                type: Boolean
            },
            userId: {
                type: String
            },
            username: {
                type: String
            }
        },

        data: () => ({
            toolbarHeight: 84,
            drawer: false
        })
    };
</script>

<style lang="scss" scoped></style>
