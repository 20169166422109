<template>
    <div class="app-header">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'AppHeader'
    };
</script>
