<template>
    <validation-observer ref="formIdentifyUserAddress">
        <app-card button-switch>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.placeOfBirth')"
                        v-model="user.personalInfo.placeOfBirth"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-country-select
                        :label="$t('admin.digitizationDialog.countryOfBirth')"
                        v-model="user.personalInfo.countryOfBirth"
                        rules="required"
                    />
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-country-select
                        :label="$t('admin.digitizationDialog.nationality')"
                        v-model="user.personalInfo.nationality"
                        rules="required"
                    />
                </app-registration-col>
                <app-registration-col field />
            </app-row>

            <app-row justify="center" class="mt-4">
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="
                            $t('address.streetLine', {
                                number: 1
                            })
                        "
                        v-model="user.address.line1"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        optional
                        :label="
                            $t('address.streetLine', {
                                number: 2
                            })
                        "
                        v-model="user.address.line2"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="$t('address.city')"
                        v-model="user.address.city"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="$t('address.postalCode')"
                        v-model="user.address.postalCode"
                    ></app-text-field>
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-country-select
                        :label="$t('address.country')"
                        v-model="user.address.country"
                        rules="required"
                    />
                </app-registration-col>
                <app-registration-col field />
            </app-row>

            <template #button-secondary>
                <button-secondary icon="arrow-alt-left" @click="$emit('process:prev')">
                    {{ $t('admin.digitizationDialog.back') }}
                </button-secondary>
            </template>

            <template #button-primary>
                <button-primary @click="submit">
                    {{ $t('admin.digitizationDialog.continue') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppCountrySelect from '@/components/ui/form/AppCountrySelect';

    export default {
        name: 'DigitizationUserAddress',

        components: {
            AppCountrySelect,
            AppTextField,
            ValidationObserver
        },

        props: {
            user: {
                type: Object,
                required: true
            }
        },

        methods: {
            submit() {
                this.$refs.formIdentifyUserAddress.validate().then(result => {
                    if (result) {
                        this.$emit('process:next');
                    }
                });
            }
        }
    };
</script>
