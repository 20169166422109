var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formDeclineDocumentDialog",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-dialog',{key:_vm.$i18n.locale,attrs:{"transition":"slide-y-transition","max-width":"1000"},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('app-card',{attrs:{"dialog":""},scopedSlots:_vm._u([{key:"button-primary",fn:function(){return [_c('button-primary',{attrs:{"disabled":invalid,"loading":_vm.buttonDeclineDocument.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.declineDocumentDialog.decline'))+" ")])]},proxy:true},{key:"button-secondary",fn:function(){return [_c('button-secondary',{on:{"click":function($event){_vm.dialog.open = false}}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.declineDocumentDialog.cancel'))+" ")])]},proxy:true}],null,true)},[_c('app-dialog-title',{attrs:{"title":_vm.$t('admin.users.user.documents.declineDocumentDialog.title'),"dialog":_vm.dialog}}),_c('app-card',[_c('p',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('admin.users.user.documents.declineDocumentDialog.overview', {
                            type: _vm.dialog.documentKey,
                            user: _vm.dialog.userEmail
                        })
                    )}}),_c('p',{staticClass:"mt-6"},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.declineDocumentDialog.fillReasonToDecline'))+" ")]),_c('app-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_c('app-registration-col',[_c('app-text-field',{attrs:{"label":_vm.$t('admin.users.user.documents.declineDocumentDialog.reason'),"rules":"required|max:80"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }