<template>
    <app-container page-padding unlimited-width>
        <app-title>{{ $t('admin.aml.title') }}</app-title>

        <purchase-limits-table-legend class="mt-3" />

        <app-row>
            <app-registration-col whole-screen>
                <app-card left>
                    <admin-purchase-limits-table
                        :loading="!usersFetched"
                        :items="users"
                        :pages="usersPages"
                        :fetch-error="usersError"
                        :filters.sync="amlTable.filters"
                        :pre-applied="amlTable.preAppliedFilters"
                        @pagination:change="fetchAMLUsers"
                        @filter:update="filterAMLUsers"
                        @filter:reset="filterAMLUsers"
                    />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AdminPurchaseLimitsTable from '@/components/ui/tables/AdminPurchaseLimitsTable';
    import PurchaseLimitsTableLegend from '@/components/ui/purchase-limits/PurchaseLimitsTableLegend';

    export default {
        name: 'Limits',

        components: {
            PurchaseLimitsTableLegend,
            AdminPurchaseLimitsTable
        },

        data: () => ({
            documentsUploadDialog: {
                open: false,
                user: {}
            },
            amlTable: {
                preAppliedFilters: null,
                customDefaultFilters: {
                    unapprovedRequests: null
                },
                filters: {
                    userId: '',
                    userEmail: '',
                    unapprovedRequests: null
                },
                page: 1
            }
        }),

        computed: mapGetters('admin_aml', ['users', 'usersFetched', 'usersError', 'usersPages', 'usersFilter']),

        methods: {
            fetchAMLUsers(page) {
                this.amlTable.page = page;

                this.$store.dispatch('admin_aml/users:clear').then(() => {
                    this.$store
                        .dispatch('admin_aml/users:fetch', {
                            page: this.amlTable.page,
                            filters: this.amlTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            filterAMLUsers(filter) {
                this.amlTable.page = 1;
                this.amlTable.filters = filter;

                this.$store.dispatch('admin_aml/users:clear').then(() => {
                    this.$store
                        .dispatch('admin_aml/users:fetch', {
                            page: this.amlTable.page,
                            filters: this.amlTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.usersFilter) {
                this.$set(this.amlTable, 'preAppliedFilters', this.usersFilter);
            }

            if (!this.usersFetched) {
                this.fetchAMLUsers();
            }
        }
    };
</script>

<style scoped></style>
