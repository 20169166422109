<template>
    <v-dialog
        transition="slide-y-transition"
        v-model="dialog.open"
        max-width="1000"
        :persistent="isUploading"
        :key="$i18n.locale"
    >
        <app-card dialog>
            <app-dialog-title :title="$t('bo.documents.uploadDialog.title')" :dialog="dialog" />

            <app-card v-if="isUploaded" class="py-9">
                <template #title>
                    <app-loader justify="center" />
                    <p class="mt-3">{{ $t('common.loading') }}</p>
                </template>

                <p>
                    {{ $t('bo.documents.uploadDialog.waitingForConfirmation') }}
                </p>
            </app-card>

            <div v-else class="app-upload">
                <div class="uppy-Dashboard"></div>
            </div>

            <template #button-primary v-if="!isUploading">
                <button-primary
                    @click="uploadDocuments"
                    :loading="uploadButton.loading"
                    :disabled="uploadButton.disabled"
                    full-width
                >
                    {{ $t('bo.documents.uploadDialog.upload') }}
                </button-primary>
            </template>
        </app-card>
    </v-dialog>
</template>

<script>
    import { convertToUppyLanguageFormat, getApiUrl } from '@/utils';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    const Uppy = require('@uppy/core');
    const Dashboard = require('@uppy/dashboard');
    const Webcam = require('@uppy/webcam');
    const AWS = require('@uppy/aws-s3');

    export default {
        name: 'DocumentLimitUploadDialog',

        props: ['dialog'],

        data: () => ({
            uppy: null,
            uploadButton: {
                disabled: true,
                loading: false
            },

            filename: null,

            isUploading: false,
            isUploaded: false
        }),

        methods: {
            uploadDocuments() {
                this.isUploading = true;

                this.uppy.upload();
            },
            documentUploadedToStorage() {
                const filename = this.filename;
                const type = this.dialog.params.limitType;

                this.$store
                    .dispatch(this.dialog.params.dispatchUrl, {
                        type,
                        documentFilename: filename
                    })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.documentUploaded'));

                        this.$emit('document:uploaded', type);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.dialog.open = false;
                    });
            },
            controlFileCount() {
                const files = this.uppy.getFiles();

                if (files && files.length) {
                    this.uploadButton.disabled = files.length <= 0;
                } else {
                    this.uploadButton.disabled = true;
                }
            },
            openModal() {
                this.$log.debug('[UPPY] Open modal with Uppy for documents');

                this.isUploading = false;
                this.isUploaded = false;

                this.uploadButton = {
                    disabled: true,
                    loading: false
                };

                const currentLang = convertToUppyLanguageFormat(this.$i18n.locale);

                const uppyLang = require('@uppy/locales/lib/' + currentLang);

                this.uppy = new Uppy({
                    debug: true,
                    locale: uppyLang,
                    restrictions: {
                        maxNumberOfFiles: 1
                    }
                });

                this.uppy
                    .use(Dashboard, {
                        target: '.uppy-Dashboard',
                        inline: true,
                        proudlyDisplayPoweredByUppy: false,
                        hideUploadButton: true,
                        height: 470,
                        plugins: ['Webcam']
                    })
                    .use(Webcam, {
                        target: Dashboard,
                        modes: ['picture'],
                        facingMode: 'environment'
                    })
                    .use(AWS, {
                        getUploadParameters: file => {
                            this.filename = file.name;

                            return fetch(getApiUrl() + this.dialog.params.uploadUrl, {
                                method: 'post',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: this.dialog.params.token
                                },
                                body: JSON.stringify({
                                    type: this.dialog.params.limitType,
                                    filename: file.name
                                })
                            })
                                .then(response => {
                                    return response.json();
                                })
                                .then(data => {
                                    return {
                                        method: data.method,
                                        url: data.url
                                    };
                                });
                        }
                    });

                if (this.uppy) {
                    this.$log.debug('[UPPY] Opening Uppy');

                    this.uppy.getPlugin('Dashboard').openModal();

                    this.uppy.on('file-added', () => {
                        this.$log.debug('[UPPY] File added to Uppy');

                        this.controlFileCount();
                    });

                    this.uppy.on('cancel-all', () => {
                        this.$log.debug('[UPPY] File added to Uppy');

                        this.isUploaded = false;
                    });

                    this.uppy.on('file-removed', () => {
                        this.$log.debug('[UPPY] File removed from Uppy');

                        this.controlFileCount();
                    });

                    this.uppy.on('complete', result => {
                        this.$log.debug('[UPPY] Upload completed with result', result);

                        if (result?.failed?.length <= 0) {
                            this.documentUploadedToStorage();

                            this.isUploaded = true;
                        }
                    });
                }
            },
            closeModal() {
                this.$log.debug('[UPPY] Closing modal');

                if (this.uppy) {
                    this.$log.debug('[UPPY] Destroying Uppy');

                    this.uppy.getPlugin('Dashboard').closeModal();
                    this.uppy.reset();
                    this.uppy.close();
                }
            }
        },

        watch: {
            'dialog.open': {
                handler(opened) {
                    this.$log.debug('[UPPY] Open handler called with:', opened);

                    this.$nextTick(() => {
                        if (opened) {
                            this.openModal();
                        } else {
                            setTimeout(() => {
                                this.closeModal();
                            }, 250);
                        }
                    });
                }
            }
        }
    };
</script>

<style scoped></style>
