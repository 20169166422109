<template>
    <validation-observer ref="formCompanyDigitizationAddress">
        <app-card button-switch>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="
                            $t('address.streetLine', {
                                number: 1
                            })
                        "
                        v-model="digitizationInfo.address.line1"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        optional
                        :label="
                            $t('address.streetLine', {
                                number: 2
                            })
                        "
                        v-model="digitizationInfo.address.line2"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="$t('address.city')"
                        v-model="digitizationInfo.address.city"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        rules="required"
                        :label="$t('address.postalCode')"
                        v-model="digitizationInfo.address.postalCode"
                    ></app-text-field>
                </app-registration-col>
            </app-row>

            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-country-select
                        :label="$t('address.country')"
                        v-model="digitizationInfo.address.country"
                        rules="required"
                    />
                </app-registration-col>
                <app-registration-col field />
            </app-row>

            <template #button-secondary>
                <button-secondary icon="arrow-alt-left" @click="$emit('process:prev')">
                    {{ $t('admin.digitizationDialog.back') }}
                </button-secondary>
            </template>

            <template #button-primary>
                <button-primary @click="submit" :loading="submitButtonLoading">
                    {{ $t('admin.digitizationDialog.continue') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppCountrySelect from '@/components/ui/form/AppCountrySelect';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'DigitizationCompanyAddress',

        components: {
            AppCountrySelect,
            AppTextField,
            ValidationObserver
        },

        props: {
            digitizationInfo: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            submitButtonLoading: false
        }),

        methods: {
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formCompanyDigitizationAddress
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_companyDigitization/digitize', this.digitizationInfo)
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.digitizationFinished'));
                                    this.$emit('process:finished');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
