<template>
    <div>
        <app-divider v-if="withDivider" :bottom-margin="0" />

        <app-row>
            <app-registration-col class="pt-0 pb-0">
                <app-flex>
                    <app-flex-item>
                        <purchase-limits-icon is-approved :size="iconSize" />

                        <span class="classic ml-2">
                            {{ $t('bo.aml.legend.approved') }}
                        </span>
                    </app-flex-item>

                    <app-flex-item>
                        <purchase-limits-icon is-requested :size="iconSize" />

                        <span class="classic ml-2">
                            {{ $t('bo.aml.legend.requested') }}
                        </span>
                    </app-flex-item>

                    <app-flex-item>
                        <purchase-limits-icon :size="iconSize" />

                        <span class="classic ml-2">
                            {{ $t('bo.aml.legend.notRequested') }}
                        </span>
                    </app-flex-item>
                </app-flex>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AppFlexItem from '@/components/ui/layout/VtAppFlexItem';
    import AppFlex from '@/components/ui/layout/VtAppFlex';
    import PurchaseLimitsIcon from '@/components/ui/purchase-limits/VtPurchaseLimitsIcon';

    export default {
        name: 'PurchaseLimitsTableLegend',

        components: { PurchaseLimitsIcon, AppFlex, AppFlexItem },

        props: {
            withDivider: Boolean
        },

        data: () => ({
            iconSize: 16
        })
    };
</script>

<style scoped></style>
