<template>
    <validation-observer ref="formDigitizationBankAccountEdit">
        <app-card button-switch>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('bankAccount.bankName')"
                        v-model="user.bankAccount.bankName"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        :label="$t('bankAccount.bankCode')"
                        v-model="user.bankAccount.code"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center" class="mt-2">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('bankAccount.accountPrefix')"
                        v-model="user.bankAccount.accountNumberPrefix"
                        optional
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        :label="$t('bankAccount.accountNumber')"
                        v-model="user.bankAccount.accountNumber"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center" class="mt-2">
                <app-registration-col field>
                    <app-text-field label="IBAN" v-model="user.bankAccount.iban" rules="required"></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field label="SWIFT" v-model="user.bankAccount.swift" rules="required"></app-text-field>
                </app-registration-col>
            </app-row>
            <template #button-secondary>
                <button-secondary icon="arrow-alt-left" @click="$emit('process:prev')">
                    {{ $t('admin.digitizationDialog.back') }}
                </button-secondary>
            </template>
            <template #button-primary>
                <button-primary @click="submit">
                    {{ $t('admin.digitizationDialog.continue') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'DigitizationUserBankAccount',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: {
            user: {
                type: Object,
                required: true
            }
        },

        methods: {
            submit() {
                this.$refs.formDigitizationBankAccountEdit.validate().then(result => {
                    if (result) {
                        this.$emit('process:next');
                    }
                });
            }
        }
    };
</script>

<style scoped></style>
