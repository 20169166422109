import store from '@/store/index';

import Vue from 'vue';

import { SignEntries } from '@/utils';

import { setLanguage } from '@lb-world/core/public/locales/engine';

import { checkForMaintenance, checkForServerError, MAINTENANCE_ROUTE, SERVER_ERROR_ROUTE } from '@/overlays';

export function includesRoute(to, route) {
    return to.matched.find(r => r.path === route);
}

export function controlTokenNamespace(entry, namespace) {
    Vue.$log.debug('[SYSTEM] controlTokenNamespace', entry, namespace);

    if (entry === namespace) {
        return { correct: true };
    } else {
        for (const [entryNamespace, entryDetails] of Object.entries(SignEntries)) {
            if (entryNamespace === namespace) {
                return {
                    correct: false,
                    route: entryDetails.to
                };
            }
        }
    }
}

export function checkAccessToken(entry) {
    Vue.$log.debug('[SYSTEM] checkAccessToken: Init', entry);

    return new Promise((resolve, reject) => {
        const token = store.getters['auth/token'];

        if (!token) {
            Vue.$log.debug('[SYSTEM] checkAccessToken: No token');

            store
                .dispatch('auth/token:store')
                .then(({ decodedToken, expiration }) => {
                    store.dispatch('auth/token:refresh', expiration);

                    Vue.$log.debug('[SYSTEM] checkAccessToken: Token fetched', decodedToken);

                    const control = controlTokenNamespace(entry, decodedToken.namespace);

                    Vue.$log.debug('[SYSTEM] checkAccessToken: controlNamespace', control);

                    if (control.correct) {
                        Vue.$log.debug('[SYSTEM] checkAccessToken: Namespace is correct, resolve()');

                        resolve();
                    } else {
                        Vue.$log.debug('[SYSTEM] checkAccessToken: Wrong namespace, reject()');

                        reject(control.route);
                    }
                })
                .catch(({ statusCode }) => {
                    Vue.$log.debug('[SYSTEM] checkAccessToken: Token not fetched, reject()', statusCode);

                    if (checkForMaintenance(statusCode)) {
                        reject(MAINTENANCE_ROUTE);
                    } else if (checkForServerError(statusCode)) {
                        reject(SERVER_ERROR_ROUTE);
                    }

                    reject();
                });
        } else {
            Vue.$log.debug('[SYSTEM] checkAccessToken: Token found, resolve()');

            resolve();
        }
    });
}

export function userFetch() {
    return new Promise((resolve, reject) => {
        const token = store.getters['auth/token'];

        if (token) {
            store.dispatch('auth/user:fetch', token).then(account => {
                Vue.$log.debug('[SYSTEM] user:fetch successful');

                if (account.language) {
                    setLanguage(account.language).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        } else {
            reject();
        }
    });
}

export function adminFetch() {
    return new Promise((resolve, reject) => {
        const token = store.getters['auth/token'];

        if (token) {
            store.dispatch('admin/account:fetch').then(account => {
                Vue.$log.debug('[SYSTEM] user:fetch successful');

                if (account.language) {
                    setLanguage(account.language).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        } else {
            reject();
        }
    });
}

export function userRouting(to, from, next) {
    Vue.$log.debug('[SYSTEM] Routing to', to.fullPath);

    const token = store.getters['auth/token'];

    // User is not logged in & want to begin new registration
    if (!token && to.path === '/registration/user-details') {
        next();

        return;
    }

    // User token is not fetched yet, returning control to UserSignIn
    // to fetch token & user
    if (!store.getters['auth/token:fetched']) {
        Vue.$log.debug('[SYSTEM] Token not fetched, returning control to SignIn');

        const queries = { ...to.query, redirect: to.path };

        next({
            path: '/',
            query: queries
        });

        return;
    }

    const account = store.getters['user/user'];

    const userAddress = account.address;
    const currentUserState = store.getters['user/account:states'].find(s => account.state === s.name);

    Vue.$log.debug('[SYSTEM] User routing: address, currentUserState', userAddress, currentUserState);

    if (token && userAddress) {
        Vue.$log.debug('[SYSTEM] User has both token & address');

        if (includesRoute(to, '/account')) {
            Vue.$log.debug('[ROUTING] Allowing access route to "/account/*"');

            next();
        } else {
            Vue.$log.debug('[ROUTING] Redirecting to route "account:services"');

            next({ name: 'account:services' });
        }
    } else if (token && currentUserState) {
        Vue.$log.debug('[SYSTEM] User has both token & current state');

        if (currentUserState.to === to.name) {
            Vue.$log.debug('[ROUTING] Allowing access to current reg. step: ', currentUserState.to);

            next();
        } else {
            Vue.$log.debug('[ROUTING] Redirecting to current reg. step: ', currentUserState.to);

            next({ name: currentUserState.to });
        }
    } else if (includesRoute(to, '/registration')) {
        Vue.$log.debug('[SYSTEM] User is heading to registration');

        if (to.name === 'registration:user') {
            Vue.$log.debug('[ROUTING] Allowing access to "registration:user"');
            next();
        } else {
            Vue.$log.debug('[ROUTING] Redirecting to route "registration:user"');

            next({ name: 'registration:user' });
        }
    } else {
        Vue.$log.debug('[ROUTING] Rerouting user to "/"');

        next('/');
    }
}

export function adminRouting(to, from, next) {
    Vue.$log.debug('[SYSTEM] Routing to', to.fullPath);

    // User token is not fetched yet, returning control to AdminSignIn
    // to fetch token & user
    if (!store.getters['auth/token:fetched']) {
        Vue.$log.debug('[SYSTEM] Token not fetched, returning control to SignIn');

        const queries = { ...to.query, redirect: to.path };

        next({
            path: '/admin',
            query: queries
        });

        return;
    }

    const token = store.getters['auth/token'];
    const account = store.getters['admin/account:fetched'];

    if (token && account) {
        setLanguage(store.getters['admin/account:language']).finally(() => {
            if (includesRoute(to, '/admin/account')) {
                Vue.$log.debug('[ROUTING] Allowing access to route containing "/admin/account"');

                next();
            } else {
                Vue.$log.debug('[ROUTING] Redirecting to route "admin:dashboard"');

                next({ name: 'admin:dashboard' });
            }
        });
    } else {
        if (includesRoute(to, '/admin')) {
            Vue.$log.debug('[ROUTING] Allowing access to "/admin"');

            next();
        } else {
            Vue.$log.debug('[ROUTING] Redirecting to route "/admin"');

            next('/admin');
        }
    }
}
