<template>
    <div>
        <admin-wallet-transactions-table
            :items="transactions"
            :loading="!transactionsFetched"
            :fetch-error="transactionsError"
            :filters.sync="transactionsTable.filters"
            :pages="transactionsPages"
            :pre-applied="transactionsTable.preAppliedFilters"
            @pagination:change="fetchTransactions"
            @filter:update="filterTransactions"
            @filter:reset="filterTransactions"
        />
    </div>
</template>

<script>
    import AdminWalletTransactionsTable from '@/components/ui/tables/AdminWalletTransactionsTable';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'WalletTransactionsTab',

        components: { AdminWalletTransactionsTable },

        data: () => ({
            transactionsTable: {
                preAppliedFilters: null,
                page: 1,
                filters: {
                    id: '',
                    user: '',
                    processed: ''
                }
            }
        }),

        computed: mapGetters('admin', [
            'transactions',
            'transactionsFetched',
            'transactionsFilter',
            'transactionsError',
            'transactionsPages'
        ]),

        methods: {
            fetchTransactions(page) {
                this.transactionsTable.page = page;

                this.$store.dispatch('admin/transactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterTransactions(filter) {
                this.transactionsTable.page = 1;
                this.transactionsTable.filters = filter;

                this.$store.dispatch('admin/transactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/transactions:fetch', {
                            page: this.transactionsTable.page,
                            filter: this.transactionsTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.transactionsFilter) {
                this.$set(this.transactionsTable, 'preAppliedFilters', this.transactionsFilter);
            }

            if (!this.transactionsFetched) {
                this.fetchTransactions();
            }
        }
    };
</script>

<style scoped></style>
