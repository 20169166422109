<template>
    <validation-observer ref="formRecoveryNewPassword" v-slot="{ valid }">
        <app-card>
            <app-title justify="center">{{ $t('signIn.passwordRecoveryDialog.newPassword.title') }}</app-title>

            <app-row justify="center">
                <app-col>
                    <app-text-field
                        v-model="password.newPassword"
                        rules="required|min:8"
                        type="password"
                        :label="$t('signIn.passwordRecoveryDialog.newPassword.newPassword')"
                    >
                    </app-text-field>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col>
                    <app-text-field
                        v-model="password.newPasswordConfirmation"
                        :rules="{ required: true, is: password.newPassword }"
                        type="password"
                        :label="$t('signIn.passwordRecoveryDialog.newPassword.newPasswordConfirmation')"
                    >
                    </app-text-field>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col>
                    <button-wrapper justify="center">
                        <g-recaptcha :data-sitekey="siteKey" :data-validate="() => valid" :data-callback="onSubmit">
                            <button-primary :loading="submitButtonLoading">
                                {{ $t('signIn.passwordRecoveryDialog.newPassword.setPassword') }}
                            </button-primary>
                        </g-recaptcha>
                    </button-wrapper>
                </app-col>
            </app-row>
        </app-card>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { APP_SITE_KEY } from '@/constants';

    import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppCol from '../../layout/AppCol';
    import AppTextField from '../../form/VtAppTextField';
    import { getAppUrlFormat } from '../../../../utils';

    export default {
        name: 'PasswordRecoveryNewPassword',

        components: {
            AppTextField,
            AppCol,
            ValidationObserver,
            gRecaptcha
        },

        data: () => ({
            siteKey: APP_SITE_KEY,

            submitButtonLoading: false,
            password: {
                newPassword: '',
                newPasswordConfirmation: ''
            }
        }),

        methods: {
            onSubmit(token) {
                this.$log.debug('[RECAPTCHA] Submitting..', token);

                this.submitButtonLoading = true;

                this.$refs.formRecoveryNewPassword
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$log.debug('[RECAPTCHA] Submit successful', token);

                            if (token) {
                                this.recoverPassword(token);
                            } else {
                                EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                            }
                        } else {
                            this.$log.debug('[RECAPTCHA] Submit failed');

                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.$log.debug('[RECAPTCHA] Submit failed');

                        this.submitButtonLoading = false;
                    });
            },
            recoverPassword(token) {
                this.$store
                    .dispatch('user_passwordRecovery/change', {
                        password: this.password.newPassword,
                        token
                    })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.passwordRecovered'));

                        this.$emit('process:finished');

                        window.location = getAppUrlFormat('broker', '/account/products?stake=max');
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.$store.dispatch('user_passwordRecovery/clear').then(() => {
                            this.$emit('process:reset');
                        });
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
