<template>
    <div class="full-width">
        <vt-activate-account-banner />
        <document-upload-dialog :dialog="documentsUploadDialog" @document:uploaded="fetchDocuments" />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
                <vt-card class="ma-0 ma-md-5 mb-8">
                    <vt-documents-box-overview
                        v-if="isUserDigitized"
                        :documents-state="documentsState"
                        :document-groups="documentGroups"
                        :error="documentsError"
                    ></vt-documents-box-overview>
                    <div class="vt-tab-title">
                        {{ $t('bo.documents.title') }}
                    </div>
                    <div v-if="isUserDigitized" class="vt-tab-desc mt-8">
                        {{ $t('bo.documents.documentTypes.info') }}
                    </div>
                    <div v-else class="vt-tab-desc mt-8">
                        {{ $t('bo.documents.documentTypes.verify') }}
                    </div>
                    <template v-if="isUserDigitized">
                        <vt-documents-group-overview
                            v-for="group in documentGroups"
                            :key="group.groupName"
                            class="mt-6 mr-2 mr-sm-8 full-width"
                            v-bind="group"
                            @document:upload="uploadDocument"
                        ></vt-documents-group-overview>
                    </template>
                    <vt-documents-conditions-download class="mt-8" />
                </vt-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import { UserRoles } from '@lb-world/core/public/static/roles';

    import { DocumentSet } from '@/store/documents';
    import VtDocumentsConditionsDownload from '../../../components/module-user/documents/VtDocumentsConditionsDownload';
    import VtDocumentsBoxOverview from '../../../components/module-user/documents/VtDocumentsBoxOverview';
    import VtDocumentsGroupOverview from '../../../components/module-user/documents/VtDocumentsGroupOverview';

    import DocumentUploadDialog from '@/components/ui/dialogs/VtDocumentUploadDialog';
    import VtActivateAccountBanner from '@/components/ui/VtActivateAccountBanner';

    export default {
        name: 'VtDocuments',

        components: {
            DocumentUploadDialog,
            VtDocumentsConditionsDownload,
            VtDocumentsBoxOverview,
            VtDocumentsGroupOverview,
            VtActivateAccountBanner
        },

        data: () => ({
            documentsUploadDialog: {
                open: false,
                documentKey: null
            }
        }),

        computed: {
            ...mapGetters('user', {
                userDigitized: 'user:digitized'
            }),
            ...mapGetters('documents', [
                'documentsState',
                'documentsGroupCount',
                'documentsGroupData',
                'documentsError'
            ]),

            isUserDigitized() {
                return this.$store.getters['user/user:digitized'];
            },

            documentGroups() {
                return [
                    {
                        name: 'required',
                        documentsCount: this.documentsGroupCount('requiredDocumentsCount'),
                        documents: this.documentsGroupData('required')
                    },
                    {
                        name: 'processing',
                        documentsCount: this.documentsGroupCount('uploadedDocumentsCount'),
                        documents: this.documentsGroupData('uploaded')
                    },
                    {
                        name: 'approved',
                        labelColor: 'success',
                        documentsCount: this.documentsGroupCount('approvedDocumentsCount'),
                        documents: this.documentsGroupData('approved')
                    },
                    {
                        name: 'declined',
                        labelColor: 'danger',
                        documentsCount: this.documentsGroupCount('declinedDocumentsCount'),
                        documents: this.documentsGroupData('declined')
                    }
                ];
            },

            userId() {
                return this.$store.getters['user/user:id'];
            },

            userToken() {
                return this.$store.getters['auth/token'];
            },

            isSalesman() {
                return this.$store.getters['auth/token:role'](UserRoles.SALESMAN);
            }
        },

        methods: {
            uploadDocument(documentKey) {
                this.documentsUploadDialog.documentSet = DocumentSet.USER;
                this.documentsUploadDialog.token = this.userToken;
                this.documentsUploadDialog.uploadBody = { type: documentKey, userId: this.userId };
                this.documentsUploadDialog.confirmBody = { type: documentKey, userId: this.userId };
                this.documentsUploadDialog.open = true;
            },
            fetchDocuments() {
                this.$store.dispatch('documents/clear', DocumentSet.USER).then(() => {
                    this.$store.dispatch('documents/fetch', DocumentSet.USER);
                });
            }
        },

        mounted() {
            if (!this.documentsFetched) {
                this.fetchDocuments();
            }
        }
    };
</script>

<style lang="scss" scoped></style>
