<template>
    <v-container fill-height fluid class="d-flex flex-row pa-0 ma-0 account">
        <vt-app-background />
        <vt-app-drawer :drawer="drawer" :sections="tabs" :fullname="fullname" :email="username"></vt-app-drawer>
        <vt-app-toolbar
            v-if="$vuetify.breakpoint.smAndDown"
            @drawer:toggle="drawer = !drawer"
            :username="username"
            :user-id="userId"
            :hideToolBar="$vuetify.breakpoint.mdAndUp"
            :hideAppPanel="true"
        ></vt-app-toolbar>
        <router-view />
    </v-container>
</template>

<script>
    import VtAppBackground from '../../components/ui/layout/VtAppBackground';
    import VtAppToolbar from '../../components/ui/VtAppToolbar';
    import VtAppDrawer from '../../components/ui/drawers/VtAppDrawer';

    export default {
        name: 'AccountLayout',

        components: {
            VtAppDrawer,
            VtAppBackground,
            VtAppToolbar
        },

        data: () => ({
            drawer: null
        }),

        computed: {
            tabs() {
                return this.$store.getters['tabs/account'];
            },
            username() {
                return this.$store.getters['user/user:email'];
            },
            fullname() {
                return this.$store.getters['user/user:fullName'];
            },
            userId() {
                return this.$store.getters['user/user:id'];
            }
        }
    };
</script>

<style scoped lang="scss"></style>
