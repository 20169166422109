<template>
    <div>
        <v-flex class="mt-10 mb-12 d-flex d-sm-none">
            <v-row class="justify-center">
                <div class="ala-carda">
                    {{ $t('reg.menu.emailVerification') }}
                </div>
            </v-row>
        </v-flex>

        <v-flex class="d-flex flex-row full-width mt-15 mb-10 px-2">
            <v-row class="justify-center py-2">
                <div class="ala-carda">
                    <img height="16" class="mx-3 align-self-center" src="@/assets/icons/info-green.svg" />
                    <div class="registration-email-verification-bar-text-container">
                        {{
                            $t('reg.emailVerification.accountCreated') +
                                ' ' +
                                $t('reg.emailVerification.returnToRegistration')
                        }}
                    </div>
                </div>
            </v-row>
        </v-flex>

        <app-row justify="center" class="mt-6">
            <app-registration-col>
                <app-card class="ala-carda">
                    <v-flex class="d-flex flex-row full-width my-16">
                        <v-row class="justify-center">
                            <div class="registration-email-verification-code">
                                {{ $t('reg.emailVerification.verificationCode') }}
                            </div>
                        </v-row>
                    </v-flex>

                    <v-flex class="d-flex flex-row full-width my-10">
                        <v-row class="justify-center">
                            <div class="px-3 px-sm-15 registration-email-verification-address">
                                {{
                                    $t('reg.emailVerification.verificationOnAddress') +
                                        ' ' +
                                        userEmail +
                                        ' ' +
                                        $t('reg.emailVerification.verificationEmailSent') +
                                        $t('reg.emailVerification.confirmEmailAddress')
                                }}
                            </div>
                        </v-row>
                    </v-flex>
                    <app-digits-box v-slot="{ eraseCode, code, numberOfInputs }">
                        <v-row>
                            <v-col class="pa-0 ma-0">
                                <button-secondary @click="eraseCode">
                                    <span class="resent-font pt-1">
                                        {{ $t('signIn.passwordRecoveryDialog.verificationCode.clearCode') }}
                                    </span>
                                </button-secondary>
                            </v-col>
                        </v-row>
                        <v-row class="mb-15">
                            <v-col buttons>
                                <button-secondary @click="resendVerificationCode">
                                    <img class="mr-2" src="@/assets/icons/registration-circle-arrow.svg" />
                                    <span class="resent-font pt-1">
                                        {{ $t('reg.emailVerification.resendVerificationEmail') }}
                                    </span>
                                </button-secondary>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <v-col class="text-left pr-0">
                                <vt-button-secondary
                                    class="return-button registration-address-button-secondary"
                                    small
                                    @click="$router.go(-1)"
                                >
                                    <span class="back-font">
                                        <img class="mr-2" src="@/assets/icons/button-arrow-left.svg" />
                                        {{ $t('reg.userDetails.return') }}
                                    </span>
                                </vt-button-secondary>
                            </v-col>
                            <v-col class="text-right pl-0 pr-0 max-width-fit-content" buttons>
                                <button-primary
                                    class="registration-email-button-primary-width"
                                    @click="processVerificationCode(code)"
                                    :loading="continueButton.loading"
                                    :disabled="code.length < numberOfInputs"
                                >
                                    {{ $t('signIn.passwordRecoveryDialog.verificationCode.continue') }}
                                    <img class="ml-2" src="@/assets/icons/button-arrow-right.svg" />
                                </button-primary>
                            </v-col>
                        </v-row>
                    </app-digits-box>
                </app-card>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppDigitsBox from '../../../components/ui/form/VtAppDigitsBox';

    export default {
        name: 'EmailVerification',

        components: {
            AppDigitsBox
        },

        data: () => ({
            newUserAddress: '',

            editEmailButtonLoading: false,

            continueButton: {
                loading: false
            }
        }),

        computed: {
            userEmail() {
                return this.$store.getters['user/user:email'];
            }
        },

        methods: {
            editAddress() {
                this.editEmailButtonLoading = true;

                this.$refs.formUserEditEmail
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('user/change:email', {
                                    email: this.newUserAddress
                                })
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.emailChanged'));
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.editEmailButtonLoading = false;
                                });
                        } else {
                            this.editEmailButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.editEmailButtonLoading = false;
                    });
            },
            resendVerificationCode() {
                this.$store
                    .dispatch('user/verificationCode:resend')
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.verificationEmailResent'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
            },
            processVerificationCode(code) {
                this.continueButton.loading = true;

                this.$store
                    .dispatch('user/verificationCode:verify', code)
                    .then(() => {
                        this.$router.replace('user-address');
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.continueButton.loading = false;
                    });
            }
        },

        mounted() {
            this.newUserAddress = this.userEmail;

            this.$emit('toolbar:update', {
                hideUserPanel: false
            });
        }
    };
</script>

<style scoped></style>
