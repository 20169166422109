<template>
    <div>
        <validation-observer ref="formAdminUserProfileEmailEdit">
            <app-row>
                <app-registration-col>
                    <p class="section-title text-start">
                        {{ $t('admin.users.user.profile.titleChangeEmail') }}
                    </p>
                </app-registration-col>
            </app-row>

            <app-row class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.users.user.profile.email')"
                        v-model="userEditEmail.personalInfo.email"
                        rules="required|email"
                    ></app-text-field>
                </app-registration-col>

                <app-registration-col field :class="{ 'pt-0': $vuetify.breakpoint.smAndDown }">
                    <button-secondary
                        inline
                        @click="editEmailAddress"
                        :loading="editEmailButtonLoading"
                        :disabled="userReadonly.email === userEditEmail.personalInfo.email"
                    >
                        {{ $t('admin.users.user.profile.changeEmailButton') }}
                    </button-secondary>
                </app-registration-col>
            </app-row>
        </validation-observer>

        <app-divider />

        <validation-observer ref="formAdminUserProfileInfoEdit" v-slot="{ invalid }">
            <app-row>
                <app-registration-col>
                    <p class="section-title text-start">
                        {{ $t('admin.users.user.profile.titleChangeInfo') }}
                    </p>
                </app-registration-col>
            </app-row>

            <app-row class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.users.user.profile.id')"
                        v-model="userReadonly.id"
                        readonly
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>

            <app-row class="mt-2">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.users.user.profile.name')"
                        v-model="userEdit.personalInfo.name"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.users.user.profile.surname')"
                        v-model="userEdit.personalInfo.surname"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>

            <app-row>
                <app-registration-col>
                    <button-primary full-width @click="submit" :disabled="invalid" :loading="submitButtonLoading">
                        {{ $t('admin.users.user.profile.confirm') }}
                    </button-primary>

                    <button-secondary
                        full-width
                        @click="reset"
                        :class="{
                            'ml-2': $vuetify.breakpoint.smAndUp,
                            'mt-2': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        {{ $t('admin.users.user.profile.reset') }}
                    </button-secondary>
                </app-registration-col>
            </app-row>
        </validation-observer>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'InfoBasicTab',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: ['user'],

        data: () => ({
            userReadonly: {
                id: '',
                email: ''
            },
            userEditEmail: {
                personalInfo: {
                    email: ''
                }
            },
            userEdit: {
                personalInfo: {
                    name: '',
                    surname: ''
                }
            },

            editEmailButtonLoading: false,
            submitButtonLoading: false
        }),

        methods: {
            editEmailAddress() {
                this.editEmailButtonLoading = true;

                this.$refs.formAdminUserProfileEmailEdit
                    .validate()
                    .then(result => {
                        if (result) {
                            const userId = this.$route.params.id;

                            this.$store
                                .dispatch('user/change:email', {
                                    email: this.userEditEmail.personalInfo.email,
                                    userId
                                })
                                .then(() => {
                                    this.userReadonly.email = this.userEditEmail.personalInfo.email;

                                    EventBus.$emit('snackbar:show:success', this.$t('success.emailChanged'));
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.editEmailButtonLoading = false;
                                });
                        } else {
                            this.editEmailButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.editEmailButtonLoading = false;
                    });
            },
            fillData() {
                const userParsed = JSON.parse(JSON.stringify(this.user));

                this.$set(
                    this.userEdit.personalInfo,
                    'surname',
                    userParsed.personalInfo?.surname ?? userParsed.surname
                );

                this.$set(this.userEdit.personalInfo, 'name', userParsed.personalInfo?.name ?? userParsed.name);

                this.$set(this.userEditEmail.personalInfo, 'email', userParsed.email);

                this.$set(this.userReadonly, 'id', userParsed.id);
                this.$set(this.userReadonly, 'email', userParsed.email);
            },
            reset() {
                this.fillData();
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formAdminUserProfileInfoEdit
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_userDetail/update', this.userEdit)
                                .then(() => {
                                    this.$emit('user:update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped></style>
