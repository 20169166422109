<template>
    <app-card>
        <app-title justify="center">{{ $t('bo.mfaDialog.stepOtp.title') }}</app-title>

        <app-text justify="center">{{ $t('bo.mfaDialog.stepOtp.description') }}</app-text>

        <app-digits-box
            v-slot="{ eraseCode, code, numberOfInputs, pasteCode }"
            @finished="otp = $event"
            :activated="activated"
        >
            <app-row justify="center">
                <app-registration-col>
                    <button-secondary icon="paste" @click="pasteCode">
                        {{ $t('bo.mfaDialog.stepOtp.pasteCode') }}
                    </button-secondary>
                </app-registration-col>

                <app-registration-col>
                    <button-secondary icon="eraser" @click="eraseCode">
                        {{ $t('bo.mfaDialog.stepOtp.clearCode') }}
                    </button-secondary>
                </app-registration-col>
            </app-row>

            <app-row>
                <app-registration-col whole-screen>
                    <app-divider
                        :top-padding="$vuetify.breakpoint.xsOnly ? 3 : 6"
                        :bottom-margin="$vuetify.breakpoint.xsOnly ? 3 : 6"
                    />

                    <button-secondary
                        v-if="!mfaActive && signedIn"
                        full-width
                        @click="$emit('process:prev')"
                        icon="arrow-alt-left"
                        icon-set="fad"
                        class="mr-0 mr-sm-3 mb-sm-0 mb-3"
                    >
                        {{ $t('bo.mfaDialog.stepOtp.return') }}
                    </button-secondary>

                    <button-primary
                        full-width
                        @click="$emit('process:finished', otp)"
                        :loading="loading"
                        :disabled="code.length < numberOfInputs"
                    >
                        {{ $t('bo.mfaDialog.stepOtp.finish') }}
                    </button-primary>
                </app-registration-col>
            </app-row>
        </app-digits-box>
    </app-card>
</template>

<script>
    import AppDigitsBox from '@/components/ui/form/VtAppDigitsBox';

    export default {
        name: 'StepOtp',

        components: { AppDigitsBox },

        props: {
            mfaActive: Boolean,
            loading: Boolean,
            activated: Boolean,
            signedIn: String
        },

        data: () => ({
            otp: null
        }),

        computed: {
            clipboard() {
                return navigator.clipboard.readText();
            }
        }
    };
</script>

<style scoped></style>
