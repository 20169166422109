<template>
    <app-row class="mt-6" v-if="!hasUserAddress">
        <app-registration-col whole-screen field>
            <hr />

            <p class="mt-6 mb-6">
                {{ $t('admin.users.user.profile.addressPhoneNotFilled') }}
            </p>

            <hr />
        </app-registration-col>
    </app-row>

    <validation-observer v-else ref="formAdminUserProfileAddressEdit" v-slot="{ invalid }">
        <app-row class="mt-6">
            <app-registration-col field>
                <app-text-field
                    :label="
                        $t('address.streetLine', {
                            number: '1'
                        })
                    "
                    v-model="userEdit.address.line1"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="
                        $t('address.streetLine', {
                            number: '2'
                        })
                    "
                    v-model="userEdit.address.line2"
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row class="mt-2">
            <app-registration-col field>
                <app-text-field
                    :label="$t('address.city')"
                    v-model="userEdit.address.city"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="$t('address.postalCode')"
                    v-model="userEdit.address.postalCode"
                    rules="required"
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row class="mt-2">
            <app-registration-col field>
                <app-country-select :label="$t('address.country')" v-model="userEdit.address.country" required />
            </app-registration-col>
            <app-registration-col field>
                <app-phone-number-field
                    v-model="userEdit.phone.number"
                    disabled-fetching-country
                    :default-country-dial="countryDial"
                    :placeholder="$t('address.phone')"
                    :rules="{ required: true, regex: numberRegexp }"
                    @country-changed="countryChanged"
                >
                </app-phone-number-field>
            </app-registration-col>
        </app-row>

        <app-row
            :class="{
                'mt-6': $vuetify.breakpoint.xsOnly
            }"
        >
            <app-registration-col>
                <button-primary full-width :disabled="invalid" :loading="submitButtonLoading" @click="submit">
                    {{ $t('address.submit') }}
                </button-primary>

                <button-secondary
                    full-width
                    @click="reset"
                    :class="{
                        'ml-2': $vuetify.breakpoint.smAndUp,
                        'mt-2': $vuetify.breakpoint.xsOnly
                    }"
                >
                    {{ $t('admin.users.user.profile.reset') }}
                </button-secondary>
            </app-registration-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import { getIntegerNumberRegex } from '@lb-world/core/public/utils/regexp';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppPhoneNumberField from '@/components/ui/form/AppPhoneNumberField';
    import AppCountrySelect from '@/components/ui/form/AppCountrySelect';

    export default {
        name: 'InfoAddressTab',

        components: {
            AppCountrySelect,
            AppPhoneNumberField,
            AppTextField,
            ValidationObserver
        },

        props: ['user'],

        data: () => ({
            userEdit: {
                phone: {
                    number: '',
                    countryCallingCode: ''
                },
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            },

            submitButtonLoading: false,

            numberRegexp: getIntegerNumberRegex()
        }),

        computed: {
            hasUserAddress() {
                return this.user && this.user.address;
            },
            countryDial() {
                return this.userEdit.phone.countryCallingCode?.length > 0
                    ? this.userEdit.phone.countryCallingCode
                    : this.user.phone.countryCallingCode;
            }
        },

        methods: {
            countryChanged(country) {
                this.userEdit.phone.countryCallingCode = '+' + country.dialCode;
            },
            fillData() {
                this.$set(this.userEdit, 'address', JSON.parse(JSON.stringify(this.user.address)));

                this.$set(this.userEdit, 'phone', JSON.parse(JSON.stringify(this.user.phone)));
            },
            reset() {
                this.fillData();
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formAdminUserProfileAddressEdit
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_userDetail/update', this.userEdit)
                                .then(() => {
                                    this.$emit('user:update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped></style>
