<template>
    <v-col v-if="biggerCol" class="col-md-7 col-12">
        <slot></slot>
    </v-col>

    <v-col v-else-if="smallerCol" class="col-md-5 col-12">
        <slot></slot>
    </v-col>
</template>

<script>
    export default {
        name: 'AppSectionNavigationCol',

        props: {
            biggerCol: {
                type: Boolean
            },

            smallerCol: {
                type: Boolean
            }
        }
    };
</script>

<style scoped></style>
