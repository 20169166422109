<template>
    <app-card table left class="py-6">
        <app-title>Fyzické potvrzení identifikace</app-title>

        <div v-if="!user.identityPhysicallyVerifiedBy">
            <app-row>
                <app-registration-col field>
                    <app-date-picker
                        :menu.sync="calendar.dateOfIdentification"
                        v-model="physicalIdentityVerification.date"
                        label="Datum identifikace"
                    ></app-date-picker>

                    <app-text-field
                        field
                        v-model="physicalIdentityVerification.verifiedBy"
                        placeholder="Identifikaci provedl/a"
                    ></app-text-field>
                </app-registration-col>
            </app-row>

            <app-row>
                <app-registration-col field>
                    <button-primary :loading="submitPhysicalVerificationLoading" @click="submitPhysicalVerification"
                        >Odeslat</button-primary
                    >
                </app-registration-col>
            </app-row>
        </div>

        <app-row v-else>
            <app-registration-col field>
                <p class="text-start">
                    Datum potvrzení: <mark>{{ user.identityPhysicallyVerifiedOn }}</mark>
                </p>

                <p class="text-start">
                    Potvrzení provedl: <mark>{{ user.identityPhysicallyVerifiedBy }}</mark>
                </p>
            </app-registration-col>
        </app-row>

        <app-divider />

        <!-- Update user's referral module -->
        <user-referral-update @update="$emit('user:fetch')" />

        <!-- Commission account settings -->
        <commission-account-settings />

        <mfa-settings-overview />

        <!-- Digitization company info -->
        <!-- Visible only if company already digitized, otherwise hidden -->
        <company-digitization-info />

        <!-- Tabs with user's info and state of digitization -->
        <app-title>{{ $t('admin.users.user.profile.userInfo') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <p v-if="!userDigitized" v-color:danger class="text-start">
                    {{ $t('admin.users.user.profile.digitization.available') }}
                </p>

                <p v-else class="text-start">
                    <font-awesome-icon v-color:primary :icon="['fad', 'dot-circle']" class="mr-1" />
                    {{ $t('admin.users.user.profile.digitization.completed') }}
                </p>
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col whole-screen>
                <hr />

                <app-custom-navigation-tabs
                    :i18n-prefix="userProfileTabs.i18nPrefix"
                    :tabs="userProfileTabs"
                    @change="tabChange"
                ></app-custom-navigation-tabs>

                <hr />
            </app-registration-col>
        </app-row>

        <component class="pt-3" :is="currentTab" :user="user" @user:update="userUpdated"></component>
    </app-card>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '../../../../components/ui/form/AppTextField';
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';
    import InfoBasicTab from '@/views/admin/sections/user/tabs/InfoBasicTab';
    import InfoAddressTab from '@/views/admin/sections/user/tabs/InfoAddressTab';
    import InfoBankAccountTab from '@/views/admin/sections/user/tabs/InfoBankAccountTab';
    import InfoAdvancedTab from '@/views/admin/sections/user/tabs/InfoAdvancedTab';
    import InfoDocumentTab from '@/views/admin/sections/user/tabs/InfoDocumentTab';
    import AppSwitchField from '@/components/ui/form/AppSwitchField';
    import AppSelectField from '@/components/ui/form/AppSelectField';
    import CommissionAccountSettings from '@/components/module-admin/user/profile/CommissionAccountSettings';
    import CompanyDigitizationInfo from '@/components/module-admin/user/companyDigitization/CompanyDigitizationInfo';
    import UserReferralUpdate from '@/components/module-admin/user/profile/UserReferralUpdate';
    import AppCustomNavigationTabs from '@/components/ui/tabs/AppCustomNavigationTabs';
    import MfaSettingsOverview from '@/components/module-admin/user/profile/MfaSettingsOverview';
    import AppDatePicker from '@/components/ui/form/AppDatePicker';

    export default {
        name: 'UserProfile',

        data: () => ({
            tabIndex: 0,

            submitButton: {
                loading: false
            },

            calendar: {
                dateOfIdentification: false
            },

            physicalIdentityVerification: {
                date: '',
                verifiedBy: ''
            },

            submitPhysicalVerificationLoading: false
        }),

        computed: {
            ...mapGetters('admin_userDetail', {
                userDigitized: 'user:digitized',
                userFetched: 'userFetched',
                user: 'user'
            }),
            userProfileTabs() {
                return this.$store.getters['tabs/admin:user:info'];
            },
            currentTab() {
                return this.userProfileTabs.data[this.tabIndex].name;
            }
        },

        components: {
            AppDatePicker,
            MfaSettingsOverview,
            AppCustomNavigationTabs,
            UserReferralUpdate,
            CompanyDigitizationInfo,
            CommissionAccountSettings,
            AppSelectField,
            AppSwitchField,
            AppSectionTabs,
            AppTextField,
            ValidationObserver,

            userInfo: InfoBasicTab,
            userAddress: InfoAddressTab,
            bankAccount: InfoBankAccountTab,
            advanced: InfoAdvancedTab,
            document: InfoDocumentTab
        },

        methods: {
            userUpdated() {
                EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));
            },
            tabChange(id) {
                this.tabIndex = id;
            },
            resetUser() {
                this.userEdit = JSON.parse(JSON.stringify(this.user));
            },
            submitPhysicalVerification() {
                this.submitPhysicalVerificationLoading = true;

                this.$store
                    .dispatch('admin_userDetail/update', {
                        physicalIdentityVerification: this.physicalIdentityVerification
                    })
                    .finally(() => {
                        this.submitPhysicalVerificationLoading = false;
                    });
            }
        },

        mounted() {
            this.resetUser();
        }
    };
</script>

<style scoped></style>
