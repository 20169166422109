<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-row class="mb-2">
            <v-col
                :class="{ 'color-red': errors.length }"
                cols="12"
                class="font-weight-thin text-caption ml-2 text-thin mb-n6 text-left font-size-12"
            >
                <div>{{ $attrs.label }}</div>
                <div class="mr-4" v-if="false && optional">{{ $t('forms.optionalField') }}</div>
            </v-col>
            <v-col cols="12">
                <v-select
                    single-line
                    v-bind="$attrs"
                    v-on="$listeners"
                    v-model="innerValue"
                    outlined
                    :hide-details="hideHint"
                    :error-messages="errors"
                    :label="placeholder || $attrs.label"
                    class="border-radius-8"
                >
                    <template #selection="{ item }">
                        <span v-if="$te(item.name)">
                            {{ $t(item.name) }}
                        </span>
                        <span v-else-if="item.name">
                            {{ item.name }}
                        </span>
                        <span v-else>
                            {{ item }}
                        </span>
                    </template>

                    <template #item="{ item }">
                        <span v-if="$te(item.name)">
                            {{ $t(item.name) }}
                        </span>
                        <span v-else-if="item.name">
                            {{ item.name }}
                        </span>
                        <span v-else>
                            {{ item }}
                        </span>
                    </template>

                    <template #no-data>
                        {{ $t('common.noData') }}
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppSelectField',

        components: {
            ValidationProvider
        },

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },
            value: {
                required: false
            },
            hideHint: {
                type: Boolean
            },
            placeholder: {
                type: String,
                default: ''
            }
        },

        data: () => ({
            innerValue: ''
        }),

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        mounted() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>

<style lang="scss">
    .v-text-field.v-select.error--text .v-select__slot .v-select__selections input::placeholder {
        color: colors.$color-warning;
    }
    .font-size-12 {
        font-size: 12px;
    }
    .border-radius-8 {
        border-radius: 8px !important;
    }
</style>
