import {
    PRODUCT_ID_DIGI_RENT,
    PRODUCT_ID_EFK_LIQUIDITY__TIME,
    PRODUCT_ID_EFK_LIQUIDITY__VALUE,
    PRODUCT_ID_EFK_PURCHASE,
    PRODUCT_ID_EFK_STAKE,
    PRODUCT_ID_4TRANS,
    PRODUCT_ID_EFK_PURCHASE_STAKE,
    PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY
} from '@/constants';

const state = {
    accountTabs: {
        type: 'selection',
        i18nPrefix: 'bo.menu',
        data: [
            {
                name: 'services',
                path: 'account:services',
                icon: 'layer-plus'
            },
            {
                name: 'account',
                path: 'account:account',
                icon: 'user-cog'
            },
            {
                name: 'documents',
                path: 'account:documents',
                icon: 'file-alt'
            },
            {
                name: 'aml',
                path: 'account:aml',
                icon: 'hand-paper'
            }
        ]
    },
    accountUserProfileTabs: {
        type: 'selection',
        i18nPrefix: 'bo.account.userDetails.menu',
        data: [
            {
                name: 'userInfo'
            },
            {
                name: 'userAddress'
            },
            {
                name: 'bankAccount'
            },
            {
                name: 'documents'
            }
        ]
    },
    adminTabs: {
        type: 'selection',
        i18nPrefix: 'admin.menu',
        data: [
            {
                name: 'dashboard',
                path: 'admin:dashboard',
                icon: 'th'
            },
            {
                name: 'users',
                path: 'admin:users',
                icon: 'users',
                subgroup: {
                    i18nPrefix: 'admin.users.menu',
                    data: [
                        {
                            name: 'manageUsers',
                            path: 'admin:users:list',
                            icon: 'list'
                        },
                        {
                            name: 'usersTree',
                            path: 'admin:users:tree',
                            icon: 'tree-alt'
                        }
                    ]
                }
            },
            {
                name: 'documents',
                path: 'admin:users:documents',
                icon: 'file-alt',
                subgroup: {
                    i18nPrefix: 'admin.documents.menu',
                    data: [
                        {
                            name: 'documentApprovals',
                            path: 'admin:documents:approvals',
                            icon: 'users'
                        },
                        {
                            name: 'documentStorage',
                            path: 'admin:documents:storage',
                            icon: 'folder-open'
                        }
                    ]
                }
            },
            {
                name: 'transactions',
                path: 'admin:transactions',
                icon: 'wallet'
            },
            {
                name: 'products',
                path: 'admin:products',
                icon: 'box-alt',
                subgroup: {
                    i18nPrefix: 'admin.products.menu',
                    data: [
                        {
                            name: 'browse',
                            path: 'admin:products:browse',
                            icon: 'list'
                        },
                        {
                            name: 'exchange',
                            path: 'admin:products:trading',
                            icon: 'sync'
                        }
                    ]
                }
            },
            {
                name: 'aml',
                path: 'admin:aml',
                icon: 'hand-paper'
            }
        ]
    },
    adminUsersTabs: {
        type: 'selection',
        i18nPrefix: 'admin.users.menu',
        data: [
            {
                name: 'manageUsers',
                path: 'admin:users:list',
                icon: 'list'
            },
            {
                name: 'usersTree',
                path: 'admin:users:tree',
                icon: 'tree-alt'
            }
        ]
    },
    adminDocumentsTabs: {
        type: 'selection',
        i18nPrefix: 'admin.documents.menu',
        data: [
            {
                name: 'documentApprovals',
                path: 'admin:documents:approvals',
                icon: 'users'
            },
            {
                name: 'documentStorage',
                path: 'admin:documents:storage',
                icon: 'folder-open'
            }
        ]
    },
    adminUserProfileTabs: {
        type: 'selection',
        i18nPrefix: 'admin.users.user.profile.menu',
        data: [
            {
                name: 'userInfo'
            },
            {
                name: 'userAddress'
            },
            {
                name: 'bankAccount'
            },
            {
                name: 'advanced',
                icon: 'dot-circle',
                predicate: 'admin_userDetail/user:digitized'
            },
            {
                name: 'document',
                icon: 'dot-circle',
                predicate: 'admin_userDetail/user:digitized'
            }
        ]
    },
    adminUserTabs: {
        type: 'selection',
        i18nPrefix: 'admin.users.user.menu',
        data: [
            {
                name: 'profile',
                path: 'admin:user:profile',
                icon: 'user'
            },
            {
                name: 'documents',
                path: 'admin:user:documents',
                icon: 'file-alt'
            },
            {
                name: 'wallets',
                path: 'admin:user:wallets',
                icon: 'wallet'
            },
            {
                name: 'products',
                path: 'admin:user:products',
                icon: 'box-alt'
            },
            {
                name: 'aml',
                path: 'admin:user:aml',
                icon: 'hand-paper'
            }
        ]
    },
    adminProductTabs: {
        type: 'selection',
        i18nPrefix: 'admin.products.menu',
        data: [
            {
                name: 'browse',
                path: 'admin:products:browse',
                icon: 'list'
            },
            {
                name: 'exchange',
                path: 'admin:products:trading',
                icon: 'sync'
            }
        ]
    },
    adminProductTypes: {
        i18nPrefix: 'productTypes',
        data: [
            {
                id: PRODUCT_ID_DIGI_RENT,
                name: 'digiRent',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_STAKE,
                name: 'efkStake',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_LIQUIDITY__TIME,
                name: 'efkLiquidityTime',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_LIQUIDITY__VALUE,
                name: 'efkLiquidityValue',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY,
                name: 'efkControlledLiquidity',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_PURCHASE,
                name: 'efkPurchase',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_4TRANS,
                name: '4trans',
                icon: 'box-alt'
            },
            {
                id: PRODUCT_ID_EFK_PURCHASE_STAKE,
                name: 'efkPurchaseStake',
                icon: 'box-alt'
            },
            {
                id: 'EFK_STAKE_RFI',
                name: 'efkStakeRfi',
                icon: 'box-alt'
            }
        ]
    },
    adminProductBrowseTabs: {
        type: 'selection',
        i18nPrefix: 'admin.users.user.products.menu',
        data: [
            {
                name: 'productAccounts',
                path: 'admin:products:browse:productAccounts',
                icon: 'box-alt'
            },
            {
                name: 'transactions',
                path: 'admin:products:browse:transactions',
                icon: 'list'
            }
        ]
    },
    adminTransactionsMenuTabs: {
        type: 'selection',
        i18nPrefix: 'admin.users.user.transactions.menu',
        data: [
            {
                name: 'wallet',
                path: 'admin:transactions:wallets',
                icon: 'wallet'
            },
            {
                name: 'withdrawal',
                path: 'admin:transactions:withdrawals',
                icon: 'arrow-alt-up'
            }
        ]
    },
    userIdentifyUserTabs: {
        type: 'steps',
        i18nPrefix: 'admin.digitizationDialog.menu',
        data: [
            {
                id: 1,
                name: 'userDetails',
                path: 'identify:details'
            },
            {
                id: 2,
                name: 'userAddress',
                path: 'identify:address'
            },
            {
                id: 3,
                name: 'userBankAccount',
                path: 'identify:bankAccount'
            },
            {
                id: 4,
                name: 'userDocument',
                path: 'identify:document'
            }
        ]
    },
    adminIdentifyUserTabs: {
        type: 'steps',
        i18nPrefix: 'admin.digitizationDialog.menu',
        data: [
            {
                id: 1,
                name: 'userDetails',
                path: 'identify:details'
            },
            {
                id: 2,
                name: 'userAddress',
                path: 'identify:address'
            },
            {
                id: 3,
                name: 'userDocument',
                path: 'identify:document'
            }
        ]
    },
    adminCompanyDigitizationTabs: {
        type: 'steps',
        i18nPrefix: 'admin.companyDigitization.menu',
        data: [
            {
                id: 1,
                name: 'companyInfo'
            },
            {
                id: 2,
                name: 'companyAddress'
            }
        ]
    },
    registrationTabs: {
        type: 'steps',
        i18nPrefix: 'reg.menu',
        data: [
            {
                id: 1,
                name: 'userDetails',
                path: 'registration:user'
            },
            {
                id: 2,
                name: 'emailVerification',
                path: 'registration:email'
            },
            {
                id: 3,
                name: 'userAddress',
                path: 'registration:address'
            }
        ]
    },
    passwordRecoveryTabs: {
        type: 'steps',
        i18nPrefix: 'signIn.passwordRecoveryDialog.menu',
        data: [
            {
                id: 1,
                name: 'userEmail',
                path: 'password:user'
            },
            {
                id: 2,
                name: 'verificationCode',
                path: 'password:code'
            },
            {
                id: 3,
                name: 'newPassword',
                path: 'password:change'
            }
        ]
    }
};

const getters = {
    account: state => state.accountTabs,
    'account:user:info': state => state.accountUserProfileTabs,

    admin: state => state.adminTabs,
    'admin:user': state => state.adminUserTabs,
    'admin:user:info': state => state.adminUserProfileTabs,
    'admin:products': state => state.adminProductTabs,
    'admin:products:types': state => state.adminProductTypes,
    'admin:products:browse': state => state.adminProductBrowseTabs,
    'admin:transactions:info': state => state.adminTransactionsMenuTabs,
    'admin:users': state => state.adminUsersTabs,
    'admin:documents': state => state.adminDocumentsTabs,

    'user:documents:identify': state => state.userIdentifyUserTabs,

    'admin:documents:identify': state => state.adminIdentifyUserTabs,
    'admin:documents:companyDigitization': state => state.adminCompanyDigitizationTabs,

    registration: state => state.registrationTabs,

    passwordRecovery: state => state.passwordRecoveryTabs
};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
