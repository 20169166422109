<template>
    <validation-observer ref="formNewPasswordRequired">
        <v-dialog persistent transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title :title="$t('admin.newPasswordRequiredDialog.title')" :dialog="dialog" />

                <app-card>
                    <app-title justify="center">{{
                        $t('admin.newPasswordRequiredDialog.requiredNewPassword')
                    }}</app-title>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('admin.newPasswordRequiredDialog.password')"
                                type="password"
                                v-model="password"
                                rules="required|min:8"
                            ></app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('admin.newPasswordRequiredDialog.newPassword')"
                                type="password"
                                v-model="newPassword"
                                rules="required|min:8"
                            ></app-text-field>
                        </app-col>
                    </app-row>

                    <app-row justify="center">
                        <app-col>
                            <app-text-field
                                :label="$t('admin.newPasswordRequiredDialog.newPasswordConfirmation')"
                                type="password"
                                v-model="newPasswordConfirmation"
                                :rules="{ required: true, is: newPassword }"
                            ></app-text-field>
                        </app-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <button-primary @click="changePassword" :loading="changePasswordButton.loading">
                        {{ $t('admin.newPasswordRequiredDialog.change') }}
                    </button-primary>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppCol from '../layout/AppCol';
    import AppTextField from '../form/VtAppTextField';

    export default {
        name: 'NewPasswordRequiredDialog',

        props: ['dialog'],

        components: {
            AppTextField,
            AppCol,
            ValidationObserver
        },

        data: () => ({
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',

            changePasswordButton: {
                loading: false
            }
        }),

        methods: {
            changePassword() {
                this.changePasswordButton.loading = true;

                this.$refs.formNewPasswordRequired
                    .validate()
                    .then(result => {
                        if (result) {
                            const { password, newPassword } = this;

                            this.$store
                                .dispatch('admin/account:password:change', {
                                    password,
                                    newPassword
                                })
                                .then(() => {
                                    this.dialog.open = false;

                                    EventBus.$emit('snackbar:show:success', this.$t('success.passwordChanged'));
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.changePasswordButton.loading = false;
                                });
                        } else {
                            this.changePasswordButton.loading = false;
                        }
                    })
                    .catch(() => {
                        this.changePasswordButton.loading = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.password = '';
                        this.newPassword = '';
                        this.newPasswordConfirmation = '';

                        if (this.$refs.formNewPasswordRequired) {
                            this.$refs.formNewPasswordRequired.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
