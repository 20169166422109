var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-filter-bar',_vm._g({},_vm.$listeners),[_c('app-row',{attrs:{"justify":"center"}},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"hide-hint":"","placeholder":_vm.$t('filters.productAccountId')},model:{value:(_vm.filters.productAccount),callback:function ($$v) {_vm.$set(_vm.filters, "productAccount", $$v)},expression:"filters.productAccount"}})],1),_c('app-registration-col',{attrs:{"field":""}},[_c('app-select-field',{attrs:{"hide-hint":"","item-text":"name","items":_vm.productTypes,"placeholder":_vm.$t('filters.productType')},model:{value:(_vm.filters.product),callback:function ($$v) {_vm.$set(_vm.filters, "product", $$v)},expression:"filters.product"}})],1)],1),_c('app-row',{attrs:{"justify":"center"}},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"hide-hint":"","placeholder":_vm.$t('filters.userId')},model:{value:(_vm.filters.user),callback:function ($$v) {_vm.$set(_vm.filters, "user", $$v)},expression:"filters.user"}})],1)],1)],1),_c('hr',{staticClass:"mt-6"}),_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(_vm.formatAmount(item.amount, item.currency))+" "+_vm._s(item.currency))]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(_vm._s(_vm.$t('common.none')))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('transactionTypes.' + item.type))+" ")]}},{key:"item.subType",fn:function(ref){
var item = ref.item;
return [(item.subType)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('transactionSubtypes.' + item.subType))+" ")]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }