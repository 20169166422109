<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field v-model="filters.id" :placeholder="$t('filters.transactionId')" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field v-model="filters.user" :placeholder="$t('filters.user')" />
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col field>
                    <app-select-field
                        :label="$t('filters.processed')"
                        hide-hint
                        v-model="filters.processed"
                        item-text="name"
                        :items="stateTxsTypes"
                    />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        :label="$t('filters.withdrawalOrigin')"
                        hide-hint
                        v-model="filters.withdrawalOrigin"
                        item-text="name"
                        :items="withdrawalOriginTypes"
                    />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:item.amount="{ item }">
                {{ formatAmount(item.withdrawalInfo.amount, item.currency) }}
                {{ item.currency }}
            </template>

            <template v-slot:item.processed="{ item }">
                <font-awesome-icon
                    v-color:[activeColor(item)]
                    :icon="['fas', item.withdrawalInfo.isProcessed ? 'check-circle' : 'times-circle']"
                />
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{ formatTimestamp(item.createdAt) }}
            </template>

            <template v-slot:item.origin="{ item }">
                <span v-if="item.withdrawalInfo && item.withdrawalInfo.origin">
                    {{ item.withdrawalInfo.origin }}
                </span>
                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:header.actions="{ header }">
                <span class="ml-6">{{ header.text }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-start">
                    <v-menu
                        v-if="item.withdrawalInfo && item.withdrawalInfo.address"
                        offset-y
                        :nudge-width="200"
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on }">
                            <button-secondary
                                v-on="on"
                                inline
                                small
                                icon="question-circle"
                                class="mr-6"
                                :icon-button="$vuetify.breakpoint.xsOnly"
                            >
                                <span v-if="$vuetify.breakpoint.smAndUp">
                                    {{ $t('common.info') }}
                                </span>
                            </button-secondary>
                        </template>

                        <app-card left class="pa-4">
                            <app-text-stack smaller-value>
                                <template #key>
                                    {{ $t('common.address') }}
                                </template>

                                <div class="d-flex flex-column align-start">
                                    {{ item.withdrawalInfo.address }}

                                    <button-secondary
                                        icon="copy"
                                        inline
                                        @click="copyReceiverAddress(item.withdrawalInfo.address)"
                                        >{{ $t('common.copy') }}</button-secondary
                                    >
                                </div>
                            </app-text-stack>
                        </app-card>
                    </v-menu>

                    <div v-if="!item.withdrawalInfo.isProcessed">
                        <button-secondary
                            v-if="$vuetify.breakpoint.smAndUp"
                            small
                            inline
                            @click="processWithdrawal(item)"
                            icon="check"
                            icon-set="fas"
                        >
                            {{ $t('admin.withdrawalConfirmation.table.approve') }}
                        </button-secondary>

                        <button-secondary
                            v-else
                            inline
                            small
                            @click="processWithdrawal(item)"
                            icon-set="fas"
                            icon="check"
                            icon-button
                        />
                    </div>

                    <div class="ml-6" v-if="!item.withdrawalInfo.isProcessed && item.currency === 'EFK'">
                        <button-secondary
                            v-if="$vuetify.breakpoint.smAndUp"
                            small
                            inline
                            @click="cancelWithdrawal(item)"
                            icon="times"
                            icon-set="fas"
                            :loading="cancelButtonLoading[item.id]"
                        >
                            {{ $t('admin.withdrawalConfirmation.table.cancel') }}
                        </button-secondary>

                        <button-secondary
                            v-else
                            inline
                            small
                            @click="cancelWithdrawal(item)"
                            icon-set="fas"
                            icon="times"
                            icon-button
                            :loading="cancelButtonLoading[item.id]"
                        />
                    </div>
                </div>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminWithdrawalTransactionsTable',

        components: { AppTextField, AppSelectField },

        mixins: [core, pagination, filtering],

        data: () => ({
            cancelButtonLoading: {},

            withdrawalOriginTypes: [
                {
                    name: 'withdrawalOriginTypes.ALL',
                    value: ''
                },
                {
                    name: 'withdrawalOriginTypes.BROKER',
                    value: 'lbbroker'
                },
                {
                    name: 'withdrawalOriginTypes.FINANCE',
                    value: 'lbfinance'
                }
            ]
        }),

        computed: {
            stateTxsTypes() {
                return this.$store.getters['admin/stateTxsTypes'];
            },

            headers() {
                return [
                    {
                        text: this.$t('table.columns.transactionId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.origin'),
                        value: 'origin'
                    },
                    {
                        text: this.$t('table.columns.userId'),
                        value: 'userId'
                    },
                    {
                        text: this.$t('table.columns.createdAt'),
                        value: 'createdAt'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount'
                    },
                    {
                        text: this.$t('table.columns.processed'),
                        value: 'processed',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions',
                        align: 'start'
                    }
                ];
            }
        },

        methods: {
            activeColor(item) {
                return item.withdrawalInfo.isProcessed ? 'success' : 'danger';
            },

            processWithdrawal(item) {
                this.$emit('transaction:open', item);
            },

            cancelWithdrawal(transaction) {
                this.cancelButtonLoading[transaction.id] = true;

                this.$store
                    .dispatch('admin/withdrawalTransactions:cancel', transaction)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.cancelButtonLoading[transaction.id] = true;
                    });
            },

            copyReceiverAddress(address) {
                navigator.clipboard
                    .writeText(address)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.textCopied'));
                    })
                    .catch(() => {
                        EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                    });
            }
        },

        watch: {
            items() {
                this.cancelButtonLoading = {};

                for (const item of this.items) {
                    this.$set(this.cancelButtonLoading, item.id, false);
                }
            }
        }
    };
</script>

<style scoped></style>
