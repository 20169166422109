import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import ProductsRepository from '@lb-world/core/public/api/repositories/ProductsRepository';
import TableDataset from '@lb-world/core/public/models/store/TableDataset';

const state = {
    collection: {
        productAccounts: new TableDataset(),
        productAccountsTransactions: new TableDataset()
    },

    user: {
        productAccounts: new TableDataset(),
        productAccountsTransactions: new TableDataset()
    }
};

const getters = {
    'user:pa': state => state.user.productAccounts.data.items ?? [],
    'user:paFetched': state => state.user.productAccounts.fetched,
    'user:paError': state => state.user.productAccounts.fetchError,
    'user:paPages': state => state.user.productAccounts.getPages(),

    'user:paTransactions': state => state.user.productAccountsTransactions.data.items ?? [],
    'user:paTransactionsFetched': state => state.user.productAccountsTransactions.fetched,
    'user:paTransactionsError': state => state.user.productAccountsTransactions.fetchError,
    'user:paTransactionsPages': state => state.user.productAccountsTransactions.getPages(),

    pa: state => state.collection.productAccounts.data.items ?? [],
    paFilter: state => state.collection.productAccounts.filter,
    paFetched: state => state.collection.productAccounts.fetched,
    paError: state => state.collection.productAccounts.fetchError,
    paPages: state => state.collection.productAccounts.getPages(),

    paTransactions: state => state.collection.productAccountsTransactions.data.items ?? [],
    paTransactionsFilter: state => state.collection.productAccountsTransactions.filter,
    paTransactionsFetched: state => state.collection.productAccountsTransactions.fetched,
    paTransactionsError: state => state.collection.productAccountsTransactions.fetchError,
    paTransactionsPages: state => state.collection.productAccountsTransactions.getPages()
};

const actions = {
    'user:pa:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', { namespace: 'user', key: 'productAccounts' });

            resolve();
        });
    },
    'user:pat:clear': ({ commit }) => {
        return new Promise(resolve => {
            commit('clear', { namespace: 'user', key: 'productAccountsTransactions' });

            resolve();
        });
    },
    'user:pa:fetch': ({ commit, rootGetters }, { userId, productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            const filters = { product: productId };

            ProductsRepository.getPA({
                data: { user: rootGetters['admin_userDetail/id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const productAccounts = response.data;

                    Vue.$log.debug('[ACTION] Received response', productAccounts);

                    if (productAccounts) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', productAccounts);

                        commit('store', {
                            key: 'productAccounts',
                            namespace: 'user',
                            data: productAccounts
                        });

                        resolve();
                    } else {
                        commit('error', { namespace: 'user', key: 'productAccounts' });

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', { namespace: 'user', key: 'productAccounts' });

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:pat:fetch': ({ commit, rootGetters }, { userId, productId, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            const filters = { product: productId };

            ProductsRepository.getPATransactions({
                data: { user: rootGetters['admin_userDetail/id'] },
                columnOptions: { filters },
                apiOptions: { page }
            })
                .then(response => {
                    const productAccountsTransactions = response.data;

                    Vue.$log.debug('[ACTION] Received response', productAccountsTransactions);

                    if (productAccountsTransactions) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', productAccountsTransactions);

                        commit('store', {
                            key: 'productAccountsTransactions',
                            namespace: 'user',
                            data: productAccountsTransactions
                        });

                        resolve();
                    } else {
                        commit('error', { namespace: 'user', key: 'productAccountsTransactions' });

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', { namespace: 'user', key: 'productAccountsTransactions' });

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'pa:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', { namespace: 'collection', key: 'productAccounts' });

            resolve();
        });
    },
    'pat:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', { namespace: 'collection', key: 'productAccountsTransactions' });

            resolve();
        });
    },
    'pa:fetch': ({ commit }, { filters, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', filters, page);

        return new Promise((resolve, reject) => {
            ProductsRepository.getPA({ columnOptions: { filters }, apiOptions: { page } })
                .then(response => {
                    const productAccounts = response.data;

                    Vue.$log.debug('[ACTION] Received response', productAccounts);

                    if (productAccounts) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', productAccounts);

                        commit('store', {
                            namespace: 'collection',
                            key: 'productAccounts',
                            data: productAccounts,
                            filter: filters
                        });

                        resolve();
                    } else {
                        commit('error', { namespace: 'collection', key: 'productAccounts' });

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', { namespace: 'collection', key: 'productAccounts' });

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'pat:fetch': ({ commit }, { filters, page = 1 }) => {
        Vue.$log.debug('[ACTION] Running action with API call', filters, page);

        return new Promise((resolve, reject) => {
            ProductsRepository.getPATransactions({ columnOptions: { filters }, apiOptions: { page } })
                .then(response => {
                    const productAccountsTransactions = response.data;

                    Vue.$log.debug('[ACTION] Received response', productAccountsTransactions);

                    if (productAccountsTransactions) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', productAccountsTransactions);

                        commit('store', {
                            namespace: 'collection',
                            key: 'productAccountsTransactions',
                            data: productAccountsTransactions,
                            filter: filters
                        });

                        resolve();
                    } else {
                        commit('error', { namespace: 'collection', key: 'productAccountsTransactions' });

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', { namespace: 'collection', key: 'productAccountsTransactions' });

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'pat:markAsPaid': ({ commit }, productAccountId) => {
        Vue.$log.debug('[ACTION] Running action with API call', productAccountId);

        return new Promise((resolve, reject) => {
            ProductsRepository.markAsPaid(productAccountId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('replace', { namespace: 'user', key: 'productAccounts', data: response.data });
                        commit('replace', { namespace: 'collection', key: 'productAccounts', data: response.data });

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'pat:activateProduct': ({ commit }, productAccountId) => {
        Vue.$log.debug('[ACTION] Running action with API call', productAccountId);

        return new Promise((resolve, reject) => {
            ProductsRepository.activateProduct(productAccountId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('replace', { namespace: 'user', key: 'productAccounts', data: response.data });
                        commit('replace', { namespace: 'collection', key: 'productAccounts', data: response.data });

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'pat:closeProduct': ({ commit }, { productAccountId, productType }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productAccountId);

        return new Promise((resolve, reject) => {
            ProductsRepository.closeProduct(productAccountId, productType)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('replace', { namespace: 'user', key: 'productAccounts', data: response.data });
                        commit('replace', { namespace: 'collection', key: 'productAccounts', data: response.data });

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'pat:cancelProduct': ({ commit }, { productAccountId, cancellationReason }) => {
        Vue.$log.debug('[ACTION] Running action with API call', productAccountId);

        return new Promise((resolve, reject) => {
            ProductsRepository.cancelProduct(productAccountId, cancellationReason)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('replace', { namespace: 'user', key: 'productAccounts', data: response.data });
                        commit('replace', { namespace: 'collection', key: 'productAccounts', data: response.data });

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    clearUser: state => {
        state.user.productAccounts.clearData();
        state.user.productAccountsTransactions.clearData();
    },

    store: (state, { key, namespace, data, filter }) => {
        state[namespace][key].storeData(data, filter);
    },
    error: (state, { namespace, key }) => {
        state[namespace][key].storeError();
    },
    clear: (state, { namespace, key }) => {
        state[namespace][key].clearData();
    },
    replace: (state, { namespace, key, data }) => {
        const predicate = pa => pa.id === data.id;

        state[namespace][key].replaceItem(predicate, data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
