<template>
    <div class="switch-container" :class="{ centered }">
        <v-switch
            class="app-switch-field"
            :disabled="loading"
            :loading="loading"
            :label="label"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template v-slot:label>
                <slot></slot>

                <button-secondary
                    v-if="$slots['button'] && $vuetify.breakpoint.mdAndUp"
                    :loading="buttonLoading"
                    @click="onButtonPressed"
                    inline
                >
                    <slot name="button"></slot>
                </button-secondary>
            </template>
        </v-switch>

        <button-secondary
            :loading="buttonLoading"
            @click="onButtonPressed"
            inline
            v-if="$slots['button'] && $vuetify.breakpoint.smAndDown"
        >
            <slot name="button"></slot>
        </button-secondary>
    </div>
</template>

<script>
    export default {
        name: 'AppSwitchField',

        model: {
            prop: 'inputValue',
            event: 'change'
        },

        props: {
            label: {
                type: String
            },
            value: {
                type: Boolean
            },
            buttonHref: {
                type: String
            },
            buttonLoading: {
                type: Boolean
            },
            buttonEvents: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            centered: {
                type: Boolean
            }
        },

        data: () => ({
            innerValue: ''
        }),

        methods: {
            onButtonPressed() {
                this.innerValue = !this.innerValue;

                if (this.buttonEvents) {
                    this.$emit('button:click');
                } else if (this.buttonHref) {
                    window.open(this.buttonHref, '_blank');
                }
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        mounted() {
            if (this.value) {
                this.innerValue = this.value;
            } else {
                this.innerValue = false;
            }
        }
    };
</script>

<style lang="scss"></style>
