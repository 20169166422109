<template>
    <div>
        <withdrawal-confirmation-dialog
            :dialog="withdrawalConfirmationDialog"
            @transaction:processed="$emit('transactions:fetch')"
        />

        <admin-user-detail-withdrawal-txs-table
            :items="transactions"
            :loading="!transactionsFetched"
            :fetch-error="transactionsError"
            :pages="transactionsPages"
            @transaction:open="openWithdrawalDialog"
            @pagination:change="fetchTransactions"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import WithdrawalConfirmationDialog from '@/components/module-admin/user/withdrawals/WithdrawalConfirmationDialog';
    import AdminUserDetailWithdrawalTxsTable from '@/components/ui/tables/AdminUserDetailWithdrawalTxsTable';

    export default {
        name: 'TransactionWithdrawalTab',

        components: {
            AdminUserDetailWithdrawalTxsTable,
            WithdrawalConfirmationDialog
        },

        props: ['userId'],

        computed: mapGetters('admin', {
            transactionsFetched: 'userDetail:withdrawalTransactions:fetched',
            transactionsError: 'userDetail:withdrawalTransactions:error',
            transactionsPages: 'userDetail:withdrawalTransactions:pages',
            transactions: 'userDetail:withdrawalTransactions'
        }),

        data: () => ({
            withdrawalConfirmationDialog: {
                open: false
            }
        }),

        methods: {
            openWithdrawalDialog(transaction) {
                this.withdrawalConfirmationDialog.transaction = transaction;
                this.withdrawalConfirmationDialog.open = true;
            },
            fetchTransactions(page) {
                this.$store.dispatch('admin/userDetail:withdrawalTransactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/userDetail:withdrawalTransactions:fetch', {
                            userId: this.userId,
                            page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        }
    };
</script>

<style scoped></style>
