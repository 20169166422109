<template>
    <div>
        <vt-user-digitization-dialog
            @finished="fetchDocuments"
            :dialog="dialog"
            :user="user"
            type="identification"
            bank-account
        />

        <app-title>{{ $t('bo.activationWidget.title') }}</app-title>

        <app-text v-if="!accountActivated">
            {{ $t('bo.activationWidget.description') }}
        </app-text>

        <app-row v-if="!accountActivated">
            <app-registration-col>
                <p class="account-activation" v-for="step in activationSteps" :key="step.id">
                    <span class="account-activation__icon">
                        <font-awesome-icon
                            v-if="isStepResolved(step)"
                            v-color:success
                            :icon="['far', 'check-circle']"
                        ></font-awesome-icon>

                        <font-awesome-icon
                            v-else
                            v-color:black.translucent
                            :icon="['far', 'circle']"
                        ></font-awesome-icon>
                    </span>

                    <span v-if="isStepResolved(step)" v-color:black> {{ step.id }}. {{ $t(step.key) }} </span>

                    <span v-else v-color:black.translucent> {{ step.id }}. {{ $t(step.key) }} </span>
                </p>
            </app-registration-col>
        </app-row>

        <app-text v-if="accountActivated">
            {{ $t('bo.activationWidget.completed') }}
        </app-text>

        <app-row v-else-if="!userDigitized">
            <app-registration-col>
                <button-secondary icon="pencil" inline @click="dialog.open = true">
                    {{ $t('bo.activationWidget.digitize') }}
                </button-secondary>
            </app-registration-col>
        </app-row>

        <app-row v-else-if="userDigitized && !isSectionActive('account:documents')">
            <app-registration-col>
                <button-secondary icon="arrow-alt-right" inline @click="$router.push({ name: 'account:documents' })">
                    {{ $t('bo.activationWidget.toDocuments') }}
                </button-secondary>
            </app-registration-col>
        </app-row>

        <app-text v-else-if="userDigitized && isSectionActive('account:documents')">
            {{ $t('bo.activationWidget.uploadDocumentsBelow') }}
        </app-text>

        <app-divider />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import VtUserDigitizationDialog from '@/components/ui/dialogs/VtUserDigitizationDialog';
    import { DocumentSet } from '../../store/documents';

    export default {
        name: 'AccountActivationWidget',

        components: { VtUserDigitizationDialog },

        data: () => ({
            dialog: {
                open: false
            },
            activationSteps: [
                {
                    id: 1,
                    key: 'bo.activationWidget.steps.digitization',
                    condition: 'user/user:digitized'
                },
                {
                    id: 2,
                    key: 'bo.activationWidget.steps.documentsUploadedAndApproved',
                    condition: 'user/user:approved'
                }
            ]
        }),

        computed: mapGetters('user', {
            user: 'user',
            userDigitized: 'user:digitized',
            accountActivated: 'account:activated'
        }),

        methods: {
            fetchDocuments() {
                this.$store.dispatch('documents/clear', DocumentSet.USER).then(() => {
                    this.$store.dispatch('documents/fetch', DocumentSet.USER);
                });
            },
            isSectionActive(sectionName) {
                return this.$route.name === sectionName;
            },
            isStepResolved(step) {
                return this.resolveCondition(step.condition);
            },
            resolveCondition(condition) {
                return this.$store.getters[condition];
            }
        }
    };
</script>

<style scoped lang="scss"></style>
