<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <div class="vue-tel-input-container">
            <vue-tel-input
                :key="defaultDial"
                :default-country="defaultDial"
                :disabled-fetching-country="disabledFetchingCountry"
                v-model="innerValue"
                :placeholder="placeholder"
                :class="{ 'vue-tel-input--is-invalid': errors.length }"
                v-on="$listeners"
                enabled-country-code
            ></vue-tel-input>

            <p v-if="innerValue.length > 0" class="vue-tel-input__label">
                {{ label }}
            </p>

            <p v-if="errors.length > 0" class="vue-tel-input__error">
                {{ errors[0] }}
            </p>
        </div>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    import countries from '@/countries';

    export default {
        name: 'AppPhoneNumberField',

        components: {
            ValidationProvider
        },

        data: () => ({
            innerValue: ''
        }),

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },
            value: { type: null },
            label: {
                type: String
            },
            placeholder: {
                type: String
            },
            disabledFetchingCountry: {
                type: Boolean
            },
            defaultCountryDial: {
                type: String
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        computed: {
            defaultDial() {
                const dial = this.defaultCountryDial?.replace('+', '');

                return countries.find(c => c.dial === dial)?.code ?? '';
            }
        },

        created() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>

<style scoped></style>
