<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.wage_or_salary.employeeOrCompany') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.wage_or_salary.employeeOrCompany')"
            v-model="details['Zaměstnavatel (firma)']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.wage_or_salary.position') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.wage_or_salary.position')"
            v-model="details['Označení pracovní pozice']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'WageOrSalary',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Zaměstnavatel (firma)', '');
            this.$set(this.details, 'Označení pracovní pozice', '');
        }
    };
</script>

<style scoped></style>
