<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.id')" v-model="filters.userId" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.email')" v-model="filters.userEmail" />
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col whole-screen>
                    <app-switch-field
                        centered
                        :true-value="true"
                        :false-value="null"
                        v-model="filters.unapprovedRequests"
                        :label="$t('filters.unapprovedRequests-name')"
                    ></app-switch-field>
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template #item.userId="{ item }">
                {{ getUsername(item) }}
            </template>

            <template #item.limit1="{ item }">
                <purchase-limits-icon :is-approved="item.limit1.approved" :is-requested="item.limit1.requested" />
            </template>

            <template #item.limit2="{ item }">
                <purchase-limits-icon :is-approved="item.limit2.approved" :is-requested="item.limit2.requested" />
            </template>

            <template #item.actions="{ item }">
                <button-secondary inline icon="hand-paper" @click="openUserDetailProfile(item)">
                    {{ $t('admin.aml.limits') }}
                </button-secondary>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners"></table-pagination>
    </div>
</template>

<script>
    import { core, pagination, filtering } from '@lb-world/core/public/components/tables/mixins';

    import AppSwitchField from '@/components/ui/form/AppSwitchField';
    import PurchaseLimitsIcon from '@/components/ui/purchase-limits/VtPurchaseLimitsIcon';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminPurchaseLimitsTable',

        components: { AppTextField, PurchaseLimitsIcon, AppSwitchField },

        mixins: [core, pagination, filtering],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.user'),
                        value: 'userId'
                    },
                    {
                        text: 'Limit 1',
                        value: 'limit1',
                        align: 'center'
                    },
                    {
                        text: 'Limit 2',
                        value: 'limit2',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions'
                    }
                ];
            }
        },

        methods: {
            getUsername(item) {
                return item.userEmail ?? item.userId;
            },
            getName(item) {
                return item.personalInfo.name ?? item.name;
            },
            getSurname(item) {
                return item.personalInfo.surname ?? item.surname;
            },
            openUserDetailProfile(item) {
                const routeName = this.$route.name;

                this.$store
                    .dispatch('admin_userDetail/meta', {
                        key: 'returnTo',
                        data: routeName
                    })
                    .then(() => {
                        this.$router.push({
                            name: 'admin:user:aml',
                            params: { id: item.userId }
                        });
                    });
            }
        }
    };
</script>

<style scoped lang="scss">
    .purchase-limit-header__icon {
        height: 18px;
        width: 18px;
    }
</style>
