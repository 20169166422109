<template>
    <v-menu bottom offset-y content-class="rounded-xl elevation-2">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :class="menu ? 'btn-lang-div-menu' : 'btn-hov btn-lang-div'"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                v-ripple="{ class: `primary--text` }"
            >
                <div>
                    <img
                        class="mr-2"
                        height="24"
                        :src="require('@lb-world/core/public/assets/flags/' + currentLanguage + '.svg')"
                    />
                    <img
                        v-if="!menu"
                        height="6"
                        class="icon-down-arrow mb-05"
                        src="@/assets/icons/lang-menu-arrow-down.svg"
                    />
                </div>
            </v-btn>
        </template>
        <div class="pa-6 rounded-xl lang-modal-card">
            <div class="px-3 mb-4 pb-2 lang-modal-title">
                {{ $t('overlays.language.title') }}
            </div>
            <v-row>
                <v-col
                    v-for="language in languages"
                    @click="changeLanguage(language)"
                    :key="language.id"
                    class="relative pa-0 pt-2 text-center btn-hov pointer lang-btn"
                    cols="6"
                >
                    <img
                        class="mb-1"
                        height="20"
                        :src="require('@lb-world/core/public/assets/flags/' + language.iso + '.svg')"
                    />
                    <div
                        :style="languageSelectedVisibility(language)"
                        class="languages-button__selected-rectangle"
                    ></div>
                </v-col>
            </v-row>
        </div>
    </v-menu>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { setLanguage } from '@lb-world/core/public/locales/engine';
    import { getStoreNameByNamespace } from '@lb-world/core/public/utils/auth';

    import { LANGUAGES } from '@lb-world/core/public/locales/list';

    import { mapGetters } from 'vuex';

    export default {
        name: 'VtLangMenu',

        props: {
            menu: {
                type: Boolean,
                default: false
            },
            justify: {
                type: String,
                default: 'center',
                validator: v => ['center', 'start', 'end'].includes(v)
            }
        },

        data: () => ({
            languages: LANGUAGES,
            languageButtonsLoading: {}
        }),

        computed: {
            ...mapGetters('auth', {
                token: 'token',
                tokenNamespace: 'token:namespace'
            }),

            currentLanguage() {
                return this.$i18n.locale;
            }
        },

        methods: {
            languageSelectedVisibility(language) {
                return {
                    visibility: this.currentLanguage === language.iso ? 'visible' : 'hidden'
                };
            },
            changeLanguage(language) {
                this.languageButtonsLoading[language.id] = true;

                if (!this.token) {
                    setLanguage(language.iso).then(() => {
                        this.$emit('change');

                        this.languageButtonsLoading[language.id] = false;
                    });
                } else {
                    const storeNamespace = getStoreNameByNamespace(this.tokenNamespace);

                    if (!storeNamespace) {
                        return;
                    }

                    this.$store
                        .dispatch(storeNamespace + '/update', {
                            user: { language: language.iso }
                        })
                        .then(() => {
                            EventBus.$emit('snackbar:show:success', this.$t('success.languageChanged'));

                            setLanguage(language.iso).then(() => {
                                this.$emit('change');

                                this.languageButtonsLoading[language.id] = false;
                            });
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);

                            this.languageButtonsLoading[language.id] = false;
                        });
                }
            }
        },

        mounted() {
            for (const l of this.languages) {
                this.$set(this.languageButtonsLoading, l.id, false);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
