<template>
    <div>
        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
        >
            <template v-slot:item.type="{ item }">
                {{ $t('documentTypes.' + item.type + '.name') }}
            </template>

            <template v-slot:item.state="{ item }">
                <span v-color:[getDocumentTextColor(item)]>
                    {{ $t('admin.users.user.documents.documentState.' + item.state) }}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div v-if="item.state === 'uploaded'" class="d-flex justify-center">
                    <button-secondary
                        v-if="$vuetify.breakpoint.smAndUp"
                        small
                        class="mr-2"
                        @click="declineDocument(item)"
                        :loading="declineButtonLoading[item.type]"
                    >
                        {{ $t('admin.users.user.documents.decline') }}
                    </button-secondary>

                    <button-secondary
                        v-else
                        icon-button
                        small
                        class="mr-2"
                        @click="declineDocument(item)"
                        :loading="declineButtonLoading[item.type]"
                        icon="times"
                        icon-set="fas"
                    >
                    </button-secondary>

                    <button-primary
                        v-if="$vuetify.breakpoint.smAndUp"
                        small
                        @click="acceptDocument(item)"
                        :loading="acceptButtonLoading[item.type]"
                    >
                        {{ $t('admin.users.user.documents.accept') }}
                    </button-primary>

                    <button-primary
                        v-else
                        small
                        @click="acceptDocument(item)"
                        :loading="acceptButtonLoading[item.type]"
                        icon-set="fas"
                        icon="check"
                        icon-button
                    >
                    </button-primary>
                </div>

                <span v-else v-color:black.translucent>
                    {{ $t('common.none') }}
                </span>
            </template>

            <template v-slot:item.document="{ item }">
                <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <button-secondary
                            v-on="on"
                            inline
                            class="select-box-activator mr-md-6 mr-2"
                            icon="question-circle"
                        >
                            <span v-if="$vuetify.breakpoint.smAndUp">
                                {{ $t('common.info') }}
                            </span>
                        </button-secondary>
                    </template>

                    <app-card left class="pa-4">
                        <app-text-stack smaller-value>
                            <template #key>
                                {{ $t('admin.users.user.documents.created') }}
                            </template>

                            {{ formatTimestamp(item.createdAt) }}
                        </app-text-stack>

                        <hr class="mt-3" />

                        <app-text-stack class="mt-3" smaller-value>
                            <template #key>
                                {{ $t('admin.users.user.documents.filename') }}
                            </template>

                            {{ item.filename }}
                        </app-text-stack>

                        <hr class="mt-3" v-if="item.note" />

                        <app-text-stack v-if="item.note" class="mt-3" smaller-value>
                            <template #key>
                                {{ $t('admin.users.user.documents.note') }}
                            </template>

                            {{ item.note }}
                        </app-text-stack>
                    </app-card>
                </v-menu>

                <button-secondary icon="eye" inline @click="downloadDocument(item.id)">
                    <span v-if="$vuetify.breakpoint.smAndUp">
                        {{ $t('admin.users.user.documents.display') }}
                    </span>
                </button-secondary>
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { core } from '@lb-world/core/public/components/tables/mixins';
    import { DocumentSet } from '@/store/admin/documents';

    export default {
        name: 'AdminUserDetailDocumentsTable',

        mixins: [core],

        data: () => ({
            declineButtonLoading: {},
            acceptButtonLoading: {}
        }),

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    },
                    {
                        text: this.$t('table.columns.document'),
                        value: 'document',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.state'),
                        value: 'state',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions',
                        align: 'center'
                    }
                ];
            }
        },

        methods: {
            getDocumentTextColor(item) {
                switch (item.state) {
                    case 'approved':
                        return 'success';

                    case 'declined':
                        return 'danger';

                    default:
                        return null;
                }
            },
            downloadDocument(documentId) {
                const windowRef = window.open();

                this.$store
                    .dispatch('admin_userDocuments/download', {
                        documentSet: DocumentSet.USER,
                        documentId
                    })
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
            },
            acceptDocument(document) {
                this.$set(this.acceptButtonLoading, document.type, true);
                this.$set(this.declineButtonLoading, document.type, true);

                const documentId = document.id;

                const process = {
                    action: 'approve'
                };

                this.$store
                    .dispatch('documents/process', { documentId, process })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.documentApproved'));

                        this.$emit('document:approve');
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.$set(this.acceptButtonLoading, document.type, false);
                        this.$set(this.declineButtonLoading, document.type, false);
                    });
            },
            declineDocument(document) {
                this.$emit('document:openDeclineDialog', document);
            }
        },

        mounted() {
            for (const index in this.items) {
                const document = this.items[index];

                if (document.type) {
                    this.acceptButtonLoading[document.type] = false;
                    this.declineButtonLoading[document.type] = false;
                }
            }
        }
    };
</script>

<style scoped></style>
