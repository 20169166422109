<template>
    <div>
        <app-divider />

        <app-title>{{ $t('admin.aml.documentUpload.title') }}</app-title>

        <app-row>
            <app-registration-col>
                <p class="text-start" v-html="$t('admin.aml.documentUpload.description')"></p>
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col>
                <div v-if="documentUploaded" class="document-uploaded">
                    <font-awesome-icon :icon="['fad', 'file-archive']" v-color:success />

                    <span class="classic">
                        {{ $t('admin.aml.documentUpload.uploaded') }}
                    </span>
                </div>

                <div v-else class="document-not-uploaded">
                    <font-awesome-icon :icon="['fad', 'file-archive']" v-color:danger />

                    <span class="classic">
                        {{ $t('admin.aml.documentUpload.notUploaded') }}
                    </span>

                    <button-secondary
                        class="document-not-uploaded__button"
                        inline
                        @click="uploadDocument"
                        :loading="uploadButtonLoading"
                    >
                        {{ $t('admin.aml.documentUpload.notUploadedButton') }}
                    </button-secondary>
                </div>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    export default {
        name: 'PurchaseLimitFileOverview',

        data: () => ({
            uploadButtonLoading: false
        }),

        props: {
            userLimit: {
                type: Object,
                required: true
            }
        },

        computed: {
            documentUploaded() {
                return this.userLimit.getDocumentFilename() && this.userLimit.getDocumentFilename() !== null;
            },
            userId() {
                return this.$store.getters['admin_userDetail/id'];
            },
            userToken() {
                return this.$store.getters['auth/token'];
            },
            limitType() {
                return this.userLimit.id;
            }
        },

        methods: {
            uploadDocument() {
                const document = {
                    uploadUrl: `lbw/users/${this.userId}/aml/documents/create-upload-url`,
                    dispatchUrl: `admin_aml/user:upload`,
                    token: this.userToken,
                    limitType: this.limitType
                };

                this.$emit('document:upload', document);
            }
        }
    };
</script>

<style scoped lang="scss">
    .document-uploaded span {
        text-align: start;
        margin-left: spacing.$spacing-2;
    }

    .document-not-uploaded {
        display: flex;
        align-items: center;

        span {
            text-align: start;
            margin-left: spacing.$spacing-2;
            margin-bottom: 0;
        }

        .document-not-uploaded__button {
            margin-left: spacing.$spacing-1;
        }
    }
</style>
