<template>
    <app-select-field
        v-bind="$attrs"
        :items="countries"
        item-text="name"
        item-value="code"
        :rules="required ? 'required' : ''"
        @change="$emit('change', $event)"
    />
</template>

<script>
    import AppSelectField from '@/components/ui/form/VtAppSelectField';

    import countries from '@/countries';

    export default {
        name: 'AppCountrySelect',

        components: {
            AppSelectField
        },

        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            required: {
                type: Boolean
            }
        },

        data: () => ({
            countries
        })
    };
</script>

<style lang="scss" scoped></style>
