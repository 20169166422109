<template>
    <div class="full-width">
        <vt-activate-account-banner />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 ma-md-5 pa-0">
                <vt-service-card v-for="service in deployedServices" :key="service.id" :service="service" />
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { LBServicesFactory } from '@lb-world/core/public/static/apps';
    import VtActivateAccountBanner from '@/components/ui/VtActivateAccountBanner';
    import VtServiceCard from '@/components/ui/cards/VtServiceCard';

    export default {
        name: 'VtServices',

        components: {
            VtServiceCard,
            VtActivateAccountBanner
        },

        computed: {
            deployedServices() {
                return LBServicesFactory.servicesDeployed();
            }
        },

        data: () => ({
            dialog: {
                open: false
            }
        })
    };
</script>

<style scoped lang="scss"></style>
