<template>
    <v-row>
        <v-col cols="12" class="mb-2 mt-0 vt-tab-title">
            {{ $t('bo.account.userDetails.others') }}
        </v-col>
        <v-col cols="12">
            <v-switch
                inset
                class="agree-switch-font"
                :loading="marketingConsentLoading"
                v-model="marketingConsent"
                @change="marketingConsentChange"
            >
                <template v-slot:label>
                    <span>
                        {{ $t('reg.userDetails.marketingConsentAccept') }}
                    </span>
                </template>
            </v-switch>
        </v-col>
    </v-row>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'MarketingConsentOption',

        data: () => ({
            marketingConsentLoading: false,
            marketingConsent: null
        }),

        methods: {
            marketingConsentChange() {
                this.marketingConsentLoading = true;

                this.$store
                    .dispatch('user/update', {
                        user: {
                            marketingConsent: this.marketingConsent
                        }
                    })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));

                        setTimeout(() => {
                            this.marketingConsentLoading = false;
                        }, 250);
                    })
                    .catch(error => {
                        setTimeout(() => {
                            this.marketingConsent = !this.marketingConsent;
                            this.marketingConsentLoading = false;
                        }, 250);

                        EventBus.$emit('snackbar:show:danger', error);
                    });
            }
        },

        mounted() {
            this.marketingConsent = this.$store.getters['user/user:marketingConsent'];
        }
    };
</script>

<style scoped></style>
