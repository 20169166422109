<template>
    <admin-product-accounts-table
        :items="productAccounts"
        :loading="!productAccountsFetched"
        :fetch-error="productAccountsError"
        :pages="productAccountsPages"
        :filters="{}"
        @pagination:change="fetchProductAccounts"
    >
    </admin-product-accounts-table>
</template>

<script>
    import AdminProductAccountsTable from '@/components/ui/tables/AdminProductAccountsTable';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'ProductAccountsTab',

        components: {
            AdminProductAccountsTable
        },

        props: {
            productId: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            }
        },

        computed: mapGetters('admin_productAccounts', {
            productAccounts: 'user:pa',
            productAccountsFetched: 'user:paFetched',
            productAccountsError: 'user:paError',
            productAccountsPages: 'user:paPages'
        }),

        methods: {
            fetchProductAccounts(page) {
                this.$store.dispatch('admin_productAccounts/user:pa:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/user:pa:fetch', {
                            userId: this.userId,
                            productId: this.productId,
                            page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        watch: {
            productId() {
                this.fetchProductAccounts();
            }
        },

        mounted() {
            this.fetchProductAccounts();
        }
    };
</script>

<style scoped></style>
