var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(!_vm.hideToolBar)?_c('v-container',{staticClass:"v-container-style",attrs:{"flat":"","clipped-left":"","app":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"space-between","align":"center"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('img',{staticClass:"vertical-align-middle",attrs:{"src":require("@/assets/logos/lb-world-black.svg"),"alt":""}}),(!_vm.hideAppPanel)?_c('div',{staticClass:"d-inline-flex",class:{
                        'ml-6': _vm.$vuetify.breakpoint.smAndUp && _vm.hideDrawerPanel,
                        'ml-2': _vm.$vuetify.breakpoint.xs && _vm.hideDrawerPanel
                    }},[_c('vt-app-menu')],1):_vm._e()]):_vm._e(),_c('v-col',{staticClass:"pa-0 text-left",attrs:{"cols":"3"}},[(!_vm.hideDrawerPanel)?_c('v-btn',{class:{ 'ml-8': _vm.$vuetify.breakpoint.smAndUp, 'ml-4': _vm.$vuetify.breakpoint.xs },attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('drawer:toggle')}}},[_c('img',{attrs:{"height":"24","src":require("@/assets/logos/menu.svg"),"alt":""}})]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mb-2 vt-registration-title"},[_vm._v(" "+_vm._s(_vm.$t('reg.title'))+" ")]):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('img',{staticClass:"vertical-align-middle",attrs:{"src":require("@/assets/logos/lb-world-black.svg"),"alt":""}})]):_vm._e(),_c('v-col',{staticClass:"pa-0",class:{ 'text-right': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"cols":"3"}},[_c('div',{class:{
                        'mr-6': _vm.$vuetify.breakpoint.md,
                        'mr-4': _vm.$vuetify.breakpoint.sm,
                        'mr-2': _vm.$vuetify.breakpoint.xs
                    }},[_c('vt-lang-menu')],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }