<template>
    <div v-if="companyDigitized">
        <app-title>{{ $t('admin.companyDigitization.companyInfo') }}</app-title>

        <v-skeleton-loader
            v-if="!companyDataFetched"
            :loading="true"
            type="paragraph"
            width="500"
            transition="fade-transition"
            class="ml-4 mt-6 mb-6"
        >
        </v-skeleton-loader>

        <div v-else>
            <app-row>
                <app-registration-col whole-screen>
                    <hr />

                    <app-custom-navigation-tabs
                        :i18n-prefix="tabs.i18nPrefix"
                        :tabs="parseTabs(tabs)"
                        @change="tabChange"
                    ></app-custom-navigation-tabs>

                    <hr />
                </app-registration-col>
            </app-row>

            <component :is="currentTab" :company="companyData" class="pt-9"></component>
        </div>

        <app-divider />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';
    import CompanyInfo from '@/components/module-admin/user/companyDigitization/companyDigitizationInfo/CompanyInfo';
    import CompanyAddress from '@/components/module-admin/user/companyDigitization/companyDigitizationInfo/CompanyAddress';
    import AppCustomNavigationTabs from '@/components/ui/tabs/AppCustomNavigationTabs';

    export default {
        name: 'CompanyDigitizationInfo',

        components: {
            AppCustomNavigationTabs,
            AppSectionTabs,
            AppTextField,

            companyInfo: CompanyInfo,
            companyAddress: CompanyAddress
        },

        data: () => ({
            tabIndex: 0,
            company: {
                name: '',
                surname: '',
                company: '',
                ico: '',
                dic: '',
                role: '',
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            }
        }),

        computed: {
            ...mapGetters('admin_companyDigitization', ['companyData', 'companyDataFetched', 'companyDigitized']),
            tabs() {
                return this.$store.getters['tabs/admin:documents:companyDigitization'];
            },
            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            }
        },

        methods: {
            parseTabs(tabs) {
                const t = JSON.parse(JSON.stringify(tabs));

                t.type = 'selection';

                return t;
            },
            tabChange(id) {
                this.tabIndex = id;
            },
            fetchCompanyDigitizationData() {
                this.$store.dispatch('admin_companyDigitization/clear').then(() => {
                    this.$store.dispatch('admin_companyDigitization/fetch').catch(() => {
                        /*TODO now returns error if not exists*/
                    });
                });
            }
        },

        mounted() {
            if (!this.companyDataFetched) {
                this.fetchCompanyDigitizationData();
            }
        },

        beforeDestroy() {
            this.$store.dispatch('admin_companyDigitization/clear');
        }
    };
</script>

<style scoped></style>
