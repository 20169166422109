<template>
    <v-container fluid class="pa-0">
        <v-toolbar :height="toolbarHeight">
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" class="ml-4" @click.stop="$emit('drawer:toggle')">
                <font-awesome-icon size="lg" :icon="['far', 'bars']"></font-awesome-icon>
            </v-app-bar-nav-icon>

            <div class="mr-6">
                <app-quick-selection-menu
                    v-if="$vuetify.breakpoint.mdAndUp"
                    :hide-app-switcher="$store.getters['auth/token:isAdmin']"
                />
            </div>

            <button-secondary v-if="returnButton" icon="arrow-alt-circle-left" inline @click="$router.push('/')">
                {{ $t('common.return') }}
            </button-secondary>

            <div :class="['app-logo__wrapper', { centerLogo }]">
                <app-logo size="md"></app-logo>
            </div>

            <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>

            <user-info-panel
                class="mr-5"
                :hide="hideUserPanel"
                :username="username"
                :user-id="userId"
            ></user-info-panel>
        </v-toolbar>
    </v-container>
</template>

<script>
    import UserInfoPanel from './VtUserInfoPanel';

    export default {
        name: 'AppToolbar',

        components: {
            UserInfoPanel
        },

        props: {
            returnButton: {
                type: Boolean
            },
            hideUserPanel: {
                type: Boolean
            },
            centerLogo: {
                type: Boolean
            },
            userId: {
                type: String
            },
            username: {
                type: String
            }
        },

        data: () => ({
            toolbarHeight: 84,
            drawer: false
        })
    };
</script>

<style lang="scss" scoped>
    .app-logo__wrapper {
        @include media.resolve('sm-and-down') {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .app-logo__wrapper.centerLogo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
