<template>
    <div class="account-tab pa-8">
        <validation-observer ref="formChangeAddress">
            <v-row>
                <v-col cols="12" sm="6" md="12" lg="6" class="pr-4">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('address.mainTitleAboutYou') }}
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('admin.digitizationDialog.dateOfBirth')"
                                v-model="userEdit.personalInfo.dateOfBirth"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('address.bornPlace')"
                                v-model="userEdit.personalInfo.placeOfBirth"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-country-select
                                readonly
                                :label="$t('address.state')"
                                v-model="userEdit.personalInfo.countryOfBirth"
                                :placeholder="$t('address.statePlaceholder')"
                                rules="required"
                            />
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-country-select
                                readonly
                                :label="$t('admin.digitizationDialog.nationality')"
                                v-model="userEdit.personalInfo.nationality"
                                rules="required"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="12" lg="6" class="pr-4">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('address.mainTitleAboutYourAdress') }}
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="12" sm="8" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                rules="required"
                                :label="
                                    $t('address.streetLine', {
                                        number: 1
                                    })
                                "
                                v-model="userEdit.address.line1"
                                :placeholder="$t('address.addressPlaceholder')"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('address.number')"
                                v-model="userEdit.address.line2"
                                placeholder=" "
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('address.city')"
                                v-model="userEdit.address.city"
                                :placeholder="$t('address.cityPlaceholder')"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('address.postalCode')"
                                v-model="userEdit.address.postalCode"
                                placeholder=" "
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" class="ma-0 mb-n8">
                            <app-country-select
                                readonly
                                :label="$t('address.country')"
                                v-model="userEdit.address.country"
                                :placeholder="$t('address.countryPlaceholder')"
                                rules="required"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppCountrySelect from '@/components/ui/form/VtAppCountrySelect';

    import countries, { convertCodeToName } from '@/countries';

    export default {
        name: 'VtInfoAddressTab',

        components: {
            AppTextField,
            ValidationObserver,
            AppCountrySelect
        },

        props: ['user'],

        data: () => ({
            calendar: {
                dateOfBirth: false
            },
            userEdit: {
                personalInfo: {
                    countryOfBirth: '',
                    dateOfBirth: '',
                    nationality: '',
                    placeOfBirth: '',
                    degree: '',
                    name: '',
                    surname: '',
                    sex: ''
                },
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            }
        }),

        computed: {
            country() {
                return convertCodeToName(this.userEdit.address.country, countries);
            },
            hasUserAddress() {
                return this.user && this.user.address;
            }
        },

        methods: {
            fillData() {
                this.$set(this.userEdit, 'address', JSON.parse(JSON.stringify(this.user.address || {})));
                this.$set(this.userEdit, 'personalInfo', JSON.parse(JSON.stringify(this.user.personalInfo || {})));
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped lang="scss"></style>
