var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formSupplyItemsAmounts"},[_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"items-per-page":1000,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.assignedAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-6"},[_c('app-text-field',{attrs:{"value":_vm.getSupplyItemAmount(item.minSaleRate),"rules":{ required: true, regex: _vm.numberRegex, max_value: item.efkAmount, decimals: 4 }},on:{"input":function($event){return _vm.supplyItemAmountAssigned({
                            priceLevel: item.minSaleRate,
                            amount: $event,
                            avgSaleRate: item.avgSaleRate
                        })}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" EFK ")]},proxy:true}],null,true)})],1)]}},{key:"item.efkAmount",fn:function(ref){
                        var item = ref.item;
return [(_vm.assignable)?_c('span',{directives:[{name:"color",rawName:"v-color:primary",arg:"primary"}],staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatAmount( item.efkAmount - _vm.convertLocaleToNumber(_vm.getSupplyItemAmount(item.minSaleRate), 'rate') ))+" ")]):_c('span',[_vm._v(_vm._s(_vm.formatAmount(item.efkAmount, 'efk')))])]}},{key:"item.avgSaleRate",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.avgSaleRate, 'rate'))+" ")]}},{key:"item.minSaleRate",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.minSaleRate, 'rate'))+" ")]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }