var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('documentTypes.' + item.type + '.name'))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"color",rawName:"v-color:[getDocumentTextColor(item)]",arg:_vm.getDocumentTextColor(item)}]},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.documentState.' + item.state))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.state === 'uploaded')?_c('div',{staticClass:"d-flex justify-center"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('button-secondary',{staticClass:"mr-2",attrs:{"small":"","loading":_vm.declineButtonLoading[item.type]},on:{"click":function($event){return _vm.declineDocument(item)}}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.decline'))+" ")]):_c('button-secondary',{staticClass:"mr-2",attrs:{"icon-button":"","small":"","loading":_vm.declineButtonLoading[item.type],"icon":"times","icon-set":"fas"},on:{"click":function($event){return _vm.declineDocument(item)}}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('button-primary',{attrs:{"small":"","loading":_vm.acceptButtonLoading[item.type]},on:{"click":function($event){return _vm.acceptDocument(item)}}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.accept'))+" ")]):_c('button-primary',{attrs:{"small":"","loading":_vm.acceptButtonLoading[item.type],"icon-set":"fas","icon":"check","icon-button":""},on:{"click":function($event){return _vm.acceptDocument(item)}}})],1):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","nudge-width":200,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button-secondary',_vm._g({staticClass:"select-box-activator mr-md-6 mr-2",attrs:{"inline":"","icon":"question-circle"}},on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.info'))+" ")]):_vm._e()])]}}],null,true)},[_c('app-card',{staticClass:"pa-4",attrs:{"left":""}},[_c('app-text-stack',{attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.created'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatTimestamp(item.createdAt))+" ")]),_c('hr',{staticClass:"mt-3"}),_c('app-text-stack',{staticClass:"mt-3",attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.filename'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.filename)+" ")]),(item.note)?_c('hr',{staticClass:"mt-3"}):_vm._e(),(item.note)?_c('app-text-stack',{staticClass:"mt-3",attrs:{"smaller-value":""},scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.note'))+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.note)+" ")]):_vm._e()],1)],1),_c('button-secondary',{attrs:{"icon":"eye","inline":""},on:{"click":function($event){return _vm.downloadDocument(item.id)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.display'))+" ")]):_vm._e()])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }