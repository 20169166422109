<template>
    <v-expansion-panel-header>
        <div class="purchase-limit-header">
            <purchase-limits-icon
                :is-approved="limit.isApproved()"
                :is-requested="limit.isRequested()"
                :is-disabled="limitDisabled"
            />

            <div class="purchase-limit-header__container">
                <span class="purchase-limit-header__title" v-color:black>
                    <span v-if="limitDisabled" v-color:black.translucent>
                        {{ limit.name }}
                    </span>

                    <span v-else>{{ limit.name }}</span>

                    <v-progress-linear
                        v-if="$vuetify.breakpoint.xsOnly && showProgressBars && !limit.isApproved() && limit.amountMin"
                        :value="limitProgress"
                        class="purchase-limit-header__progress"
                    />
                </span>

                <p class="purchase-limit-header__range text-secondary">
                    {{ formatAmountRanges }}
                </p>

                <v-progress-linear
                    v-if="$vuetify.breakpoint.smAndUp && showProgressBars && !limit.isApproved() && limit.amountMin"
                    :value="limitProgress"
                    class="purchase-limit-header__progress"
                />
            </div>
        </div>
    </v-expansion-panel-header>
</template>

<script>
    import PurchaseLimitsIcon from '@/components/ui/purchase-limits/PurchaseLimitsIcon';

    export default {
        name: 'PurchaseLimitHeader',

        components: { PurchaseLimitsIcon },

        props: {
            showProgressBars: Boolean,
            limitDisabled: Boolean,

            userLimit: Object,

            limit: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            under: 'bo.aml.under',
            above: 'bo.aml.above'
        }),

        computed: {
            formatAmountRanges() {
                if (this.limit.amountMax && this.limit.amountMin) {
                    return (
                        this.formatAmount(this.limit.amountMin, 'czk') +
                        ' CZK' +
                        ' - ' +
                        this.formatAmount(this.limit.amountMax, 'czk') +
                        ' CZK'
                    );
                } else if (this.limit.amountMax) {
                    return this.$t(this.under) + ' ' + this.formatAmount(this.limit.amountMax, 'czk') + ' CZK';
                } else {
                    return this.$t(this.above) + ' ' + this.formatAmount(this.limit.amountMin, 'czk') + ' CZK';
                }
            },
            limitProgress() {
                return (this.userLimit?.usedLimit / this.limit?.amountMin) * 100;
            }
        }
    };
</script>

<style scoped lang="scss">
    .purchase-limit-header {
        display: flex;
        align-items: center;

        .purchase-limit-header__container {
            display: flex;
            align-items: center;

            @include media.resolve('xs-only') {
                flex-direction: column;
                align-items: start;
            }

            .purchase-limit-header__title {
                @include textUtils.section-title;

                margin-left: spacing.$spacing-3;

                display: flex;
                align-items: center;
            }

            .purchase-limit-header__range {
                margin-bottom: 0;
                margin-left: spacing.$spacing-6;

                @include media.resolve('xs-only') {
                    margin-top: spacing.$spacing-1;
                    margin-left: spacing.$spacing-3;
                }
            }

            .purchase-limit-header__progress {
                width: spacing.$spacing-12;
                height: spacing.$spacing-1;

                border-radius: spacing.$spacing-2;

                margin-left: spacing.$spacing-6;
                margin-right: spacing.$spacing-2;

                @include media.resolve('xs-only') {
                    margin-bottom: 4px;
                }
            }
        }
    }
</style>
