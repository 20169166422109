<template>
    <validation-observer ref="formCancelProductDialog" v-slot="{ invalid }">
        <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title
                    :title="$t('admin.users.user.products.efkControlledLiquidityCancelDialog.title')"
                    :dialog="dialog"
                />

                <app-card>
                    <p class="mt-6">
                        {{ $t('admin.users.user.products.efkControlledLiquidityCancelDialog.fillReasonToDecline') }}
                    </p>

                    <app-row justify="center" class="mt-6">
                        <app-registration-col>
                            <app-text-field
                                :label="$t('admin.users.user.products.efkControlledLiquidityCancelDialog.reason')"
                                rules="required|max:80"
                                v-model="reason"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <button-primary @click="submit" :disabled="invalid">
                        {{ $t('admin.users.user.products.efkControlledLiquidityCancelDialog.confirm') }}
                    </button-primary>
                </template>
                <template #button-secondary>
                    <button-secondary @click="dialog.open = false">
                        {{ $t('admin.users.user.documents.declineDocumentDialog.cancel') }}
                    </button-secondary>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import AppTextField from '../form/VtAppTextField';

    export default {
        name: 'EfkControlledLiquidityCancelDialog',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: ['dialog'],

        data: () => ({
            reason: ''
        }),

        methods: {
            submit() {
                this.$emit('product:cancel', {
                    itemId: this.dialog.item.id,
                    reason: this.reason
                });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.reason = '';

                        if (this.$refs.formCancelProductDialog) {
                            this.$refs.formCancelProductDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
