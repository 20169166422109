<template>
    <v-navigation-drawer :value="drawer" absolute class="app-nav-drawer" app>
        <div>
            <app-card>
                <app-quick-selection-menu hide-app-switcher />
            </app-card>

            <button-wrapper class="ml-4 mr-4 pt-6">
                <button-secondary @click="$router.push('/')" full-width form icon="arrow-alt-circle-left">
                    {{ $t('common.return') }}
                </button-secondary>
            </button-wrapper>

            <v-list-item two-line v-if="user.email">
                <v-list-item-icon>
                    <font-awesome-icon size="lg" class="mr-2 mt-1" :icon="['fad', 'user-circle']"></font-awesome-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="user.personalInfo.name && user.personalInfo.surname">
                    <v-list-item-title class="ml-0">
                        {{ user.personalInfo.name + ' ' + user.personalInfo.surname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ user.email }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <button-wrapper class="ml-4 mr-4" v-if="user.email">
                <button-primary @click="signOut" full-width :loading="logoutButtonLoading">
                    {{ $t('topPanel.logOutButton') }}
                </button-primary>
            </button-wrapper>

            <v-divider class="mt-6" v-if="user.email"> </v-divider>

            <p class="section-title drawer-title-padding">Steps</p>

            <v-list>
                <template v-for="(section, index) in sections.data">
                    <v-list-item :key="section.id" link exact disabled :to="{ name: section.path }">
                        <v-list-item-title>
                            <span class="mr-2">{{ index + 1 }}.</span>

                            {{ $t(sections.i18nPrefix + '.' + section.name) }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'AppRegistrationDrawer',

        props: ['drawer', 'sections'],

        data: () => ({
            logoutButtonLoading: false
        }),

        computed: {
            user() {
                return this.$store.getters['user/user'];
            }
        },

        methods: {
            signOut() {
                this.logoutButtonLoading = true;

                this.$store
                    .dispatch('auth/user:logout')
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.signedOut'));

                        this.$router.push('/');
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.logoutButtonLoading = false;
                    });
            }
        }
    };
</script>

<style lang="scss" scoped></style>
