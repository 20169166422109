import Vue from 'vue';

import { parseErrorMessageFromAPI, UserStates, API_INTERNAL_ERROR } from '@/utils';

import { LANGUAGES, FALLBACK_LANGUAGE } from '@lb-world/core/public/locales/list';

import UserDataset from '@lb-world/core/public/models/store/UserDataset';
import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

const state = {
    user: new UserDataset(),
    languages: LANGUAGES,
    accountStates: [
        {
            name: UserStates.CREATED,
            to: 'registration:email',
            type: 'registration'
        },
        {
            name: UserStates.EMAIL_VERIFIED,
            to: 'registration:address',
            type: 'registration'
        },
        {
            name: UserStates.REGISTERED,
            to: 'account:services',
            type: 'account'
        },
        {
            name: UserStates.VERIFIED,
            to: 'account:services',
            type: 'account'
        }
    ]
};

const getters = {
    user: state => state.user.data,
    'user:fetched': state => state.user.fetched,
    'user:role': state => state.user.data.role,
    'user:email': state => state.user.data.email,
    'user:id': state => state.user.data.id,
    'user:mfaActive': state => state.user.data?.multiFactorAuth?.active,
    'user:fullName': state => state.user.getFullName(),
    'user:state': state => state.user.data.state,
    'user:address': state => state.user.data.address,
    'user:marketingConsent': state => state.user.data.marketingConsent,
    'user:language': state => state.user.data.language ?? FALLBACK_LANGUAGE,
    'user:digitized': state => state.user.data.allDataDigitized,
    'user:approved': state => state.user.data.identityVerificationState === 'approved',

    'account:activated': (state, getters) => getters['user:digitized'] && getters['user:approved'],
    'account:states': state => state.accountStates,
    'account:languages': state => state.languages,
    'account:state': (state, getters) => state.accountStates.find(s => getters['user:state'] === s.name)
};

const actions = {
    create: ({ commit }, { user, token }) => {
        Vue.$log.debug('[ACTION]Running action with API call', user);

        return new Promise((resolve, reject) => {
            UserRepository.create(user, token)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('user:save', response.data);

                        resolve(user);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    update: ({ commit, getters }, { user, userId = getters['user:id'] }) => {
        Vue.$log.debug('[ACTION] Running action with API call', user);

        return new Promise((resolve, reject) => {
            UserRepository.update(userId, user)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    commit('update', response.data);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'verificationCode:verify': ({ commit, getters }, verificationCode) => {
        return new Promise((resolve, reject) => {
            UserRepository.verifyEmail(getters['user:id'], verificationCode)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    commit('update', { state: UserStates.EMAIL_VERIFIED });

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'verificationCode:resend': ({ getters }) => {
        return new Promise((resolve, reject) => {
            UserRepository.resendVerificationEmail(getters['user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'change:email': ({ commit, getters }, { email, userId = getters['user:id'] }) => {
        return new Promise((resolve, reject) => {
            Vue.$log.debug('[ACTION] Running action with API call', email, userId);

            if (userId) {
                UserRepository.changeEmail(userId, email)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        commit('update', { email });

                        resolve();
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.error('[ACTION] UserID is not defined, cant verify e-mail address');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'change:password': ({ getters }, { password, newPassword, token }) => {
        Vue.$log.debug('[ACTION] Running action with API call', password, newPassword);

        return new Promise((resolve, reject) => {
            UserRepository.changePassword(getters['user:id'], password, newPassword, token)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, user) => {
        state.user.storeData(user);
    },
    update: (state, user) => {
        state.user.updateData(user);
    },
    clear: state => {
        state.user.clearData();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
