<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-switch-field
                        true-value="approved"
                        :false-value="null"
                        v-model="filters.identityVerificationState"
                        :label="$t('filters.identityVerificationState-name')"
                    ></app-switch-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-switch-field
                        :true-value="false"
                        :false-value="null"
                        v-model="filters.allDataDigitized"
                        :label="$t('filters.allDataDigitized-name')"
                    ></app-switch-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint placeholder="ID" v-model="filters.id" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.email')" v-model="filters.email" />
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.name')" v-model="filters.name" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.surname')" v-model="filters.surname" />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:item.id="{ item }">
                {{ item.id }}

                <v-tooltip right v-if="item.allDataDigitized">
                    <template v-slot:activator="{ on, attrs }">
                        <font-awesome-icon
                            v-color:primary
                            v-bind="attrs"
                            v-on="on"
                            :icon="['fad', 'dot-circle']"
                            class="ml-1"
                        />
                    </template>

                    <span>{{ $t('admin.users.digitized') }}</span>
                </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
                <button-secondary inline icon="eye" @click="openUserDetailProfile(item)">
                    {{ $t('admin.users.show') }}
                </button-secondary>
            </template>

            <template v-slot:item.name="{ item }">
                {{ getName(item) }}
            </template>

            <template v-slot:item.surname="{ item }">
                {{ getSurname(item) }}
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners"></table-pagination>
    </div>
</template>

<script>
    import AppSwitchField from '@/components/ui/form/AppSwitchField';

    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminUsersTable',

        components: { AppTextField, AppSwitchField },

        mixins: [core, pagination, filtering],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.userId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.e-mail'),
                        value: 'email'
                    },
                    {
                        text: this.$t('table.columns.name'),
                        value: 'name'
                    },
                    {
                        text: this.$t('table.columns.surname'),
                        value: 'surname'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions'
                    }
                ];
            }
        },

        methods: {
            getName(item) {
                return item.personalInfo.name ?? item.name;
            },
            getSurname(item) {
                return item.personalInfo.surname ?? item.surname;
            },
            openUserDetailProfile(item) {
                const routeName = this.$route.name;

                this.$store
                    .dispatch('admin_userDetail/meta', {
                        key: 'returnTo',
                        data: routeName
                    })
                    .then(() => {
                        this.$router.push({
                            name: 'admin:user:profile',
                            params: { id: item.id }
                        });
                    });
            }
        }
    };
</script>

<style scoped></style>
