<template>
    <v-menu
        v-model="menuOpened"
        ref="menu"
        :close-on-content-click="false"
        min-width="290px"
        transition="scale-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <app-text-field
                :label="label"
                rules="required"
                :value="value"
                v-bind="attrs"
                v-on="on"
                readonly
            ></app-text-field>
        </template>

        <v-date-picker :value="value" no-title scrollable @change="datePicked" :locale="locale" />
    </v-menu>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AppDatePicker',

        components: { AppTextField },

        props: {
            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            menu: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            menuOpened: {
                get() {
                    return this.menu;
                },
                set(value) {
                    this.$emit('update:menu', value);
                }
            },
            locale() {
                return this.$i18n.locale;
            }
        },

        methods: {
            datePicked(value) {
                this.$emit('input', value);

                this.menuOpened = false;
            }
        }
    };
</script>
