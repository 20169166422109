import Vue from 'vue';

import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

import { parseErrorMessageFromAPI } from '@/utils';
import { API_SERVER_ERROR, API_INTERNAL_ERROR } from '@lb-world/core/public/api/utils';

import axios from 'axios';

const state = {
    meta: {
        returnTo: '',
        id: ''
    },
    user: new BaseDataset()
};

const getters = {
    id: state => state.meta.id,
    returnTo: state => state.meta.returnTo,

    userFetched: state => state.user.fetched,
    userError: state => state.user.fetchError,
    user: state => state.user.data,
    'user:email': state => state.user.data?.email,
    'user:digitized': state => state.user.data?.allDataDigitized,
    'user:digitizationVerified': state => state.user.data?.dataVerified
};

const actions = {
    meta: ({ commit }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call', data);

        return new Promise(resolve => {
            commit('store:meta', data);

            resolve();
        });
    },

    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'user');
            commit('admin_productAccounts/clearUser', null, { root: true });
            commit('admin_userDocuments/clearAll', null, { root: true });

            resolve();
        });
    },

    fetch: ({ commit, getters }) => {
        return new Promise((resolve, reject) => {
            UserRepository.get(getters.id)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const user = response.data;

                    if (user) {
                        commit('store', {
                            key: 'user',
                            data: user
                        });

                        resolve();
                    } else {
                        commit('error', 'user');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'user');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    update: ({ commit, getters }, user) => {
        Vue.$log.debug('[ACTION] Running action with API call', user);

        const userId = getters['id'];

        return new Promise((resolve, reject) => {
            if (userId) {
                Vue.$log.debug('[ACTION] User id defined', userId, user);

                axios
                    .patch(`lbw/users/${userId}`, user)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            commit('update', { key: 'user', data: response.data });

                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] User id not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    },

    'update:referral': ({ getters }, referralId) => {
        Vue.$log.debug('[ACTION] Running action with API call', referralId);

        return new Promise((resolve, reject) => {
            UserRepository.changeReferral(getters.id, referralId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    verifyDigitization: ({ commit }, { userId }) => {
        Vue.$log.debug('[ACTION] Running action with API call', userId);

        return new Promise((resolve, reject) => {
            if (userId) {
                axios
                    .post(`lbw/users/${userId}/confirm-data-verification`)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const user = response.data;

                        if (user) {
                            commit('store', {
                                key: 'user',
                                data: user
                            });

                            resolve();
                        } else {
                            commit('error', 'user');

                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        commit('error', 'user');

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] UserId is not defined');

                commit('error', 'user');

                reject(API_INTERNAL_ERROR);
            }
        });
    }
};

const mutations = {
    'store:meta': (state, { key, data }) => {
        Vue.set(state.meta, key, data);
    },

    store: (state, { key, data }) => {
        state[key].storeData(data);
    },
    update: (state, { key, data }) => {
        state[key].updateData(data);
    },
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
