<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.means_used_for_business.name') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.means_used_for_business.name')"
            v-model="details['Název']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.means_used_for_business.id') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.means_used_for_business.id')"
            v-model="details['IČO']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.means_used_for_business.residence') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.means_used_for_business.residence')"
            v-model="details['Sídlo']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.means_used_for_business.subject') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.means_used_for_business.subject')"
            v-model="details['Přesný popis předmětu podnikání']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'MeansUsedForBusiness',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Název', '');
            this.$set(this.details, 'IČO', '');
            this.$set(this.details, 'Sídlo', '');
            this.$set(this.details, 'Přesný popis předmětu podnikání', '');
        }
    };
</script>

<style scoped></style>
