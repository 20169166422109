<template>
    <app-card>
        <div v-if="!secretGenerator.fetched" class="secret-generator-container">
            <div class="secret-generator-components">
                <app-loader wrapper vertical>
                    {{ $t('bo.mfaDialog.generatingSecret.title') }}
                </app-loader>

                <app-text justify="center">
                    {{ $t('bo.mfaDialog.generatingSecret.description') }}
                </app-text>
            </div>
        </div>

        <app-error v-else-if="secretGenerator.fetchError" />

        <div v-else>
            <app-title justify="center">{{ $t('bo.mfaDialog.stepScan.title') }}</app-title>

            <app-text justify="center">
                <span v-html="$t('bo.mfaDialog.stepScan.description')" />
            </app-text>

            <qrcode-vue :value="authenticationString" class="mt-6" />

            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field :value="secretGenerator.data" hide-hint readonly>
                        <template #append>
                            <span @click="copyReferralEmail" style="cursor: pointer">
                                <font-awesome-icon v-color:primary :icon="['fad', 'copy']" />
                            </span>
                        </template>
                    </app-text-field>
                </app-registration-col>
            </app-row>
        </div>

        <app-divider
            :top-padding="$vuetify.breakpoint.xsOnly ? 3 : 6"
            :bottom-margin="$vuetify.breakpoint.xsOnly ? 3 : 6"
        />

        <button-primary
            @click="$emit('process:next')"
            :disabled="secretGenerator.fetchError"
            :loading="!secretGenerator.fetched"
        >
            {{ $t('bo.mfaDialog.stepScan.continue') }}
        </button-primary>
    </app-card>
</template>

<script>
    import QrcodeVue from 'qrcode.vue';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'StepScan',

        components: {
            AppTextField,
            QrcodeVue
        },

        props: ['secretGenerator'],

        data: () => ({
            issuer: 'LBworld'
        }),

        computed: {
            userEmail() {
                return this.$store.getters['user/user:email'];
            },

            authenticationString() {
                return `otpauth://totp/${this.userEmail}?secret=${this.secretGenerator.data}&issuer=${this.issuer}`;
            }
        },

        methods: {
            copyReferralEmail() {
                navigator.clipboard
                    .writeText(this.secretGenerator.data ?? null)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.textCopied'));
                    })
                    .catch(() => {
                        EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                    });
            }
        }
    };
</script>

<style scoped lang="scss">
    .secret-generator-container {
        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 320px;

        .secret-generator-components {
            display: flex;
            flex-direction: column;
        }
    }
</style>
