<template>
    <app-container id="account" shadow fill-height increased-width>
        <new-password-required-dialog :dialog="dialog"></new-password-required-dialog>

        <app-navigation-drawer
            :drawer="drawer"
            :sections="tabs"
            v-if="$vuetify.breakpoint.smAndDown"
        ></app-navigation-drawer>

        <app-header :key="$i18n.locale">
            <app-toolbar @drawer:toggle="drawer = !drawer" :username="username" :user-id="userId"></app-toolbar>

            <app-tabs :tabs="tabs" :i18n-prefix="tabs.i18nPrefix" v-if="$vuetify.breakpoint.mdAndUp"> </app-tabs>
        </app-header>

        <app-container unlimited-width>
            <router-view />
        </app-container>
    </app-container>
</template>

<script>
    import AppTabs from '../../components/ui/tabs/AppTabs';
    import AppToolbar from '../../components/ui/AppToolbar';
    import AppHeader from '../../components/ui/AppHeader';
    import NewPasswordRequiredDialog from '@/components/ui/dialogs/NewPasswordRequiredDialog';

    export default {
        name: 'AdminLayout',

        components: {
            NewPasswordRequiredDialog,
            AppHeader,
            AppToolbar,
            AppTabs
        },

        data: () => ({
            drawer: false,
            dialog: {
                open: false
            }
        }),

        computed: {
            passwordChangeRequired() {
                return this.$store.getters['admin/account:passwordChangeRequired'];
            },
            tabs() {
                return this.$store.getters['tabs/admin'];
            },
            username() {
                return this.$store.getters['auth/token:username'];
            },
            userId() {
                return this.$store.getters['auth/token:id'];
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (this.passwordChangeRequired) this.dialog.open = true;
            });
        }
    };
</script>

<style scoped></style>
