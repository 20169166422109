<template>
    <app-expansion-panel
        :title="$t('admin.exports.title')"
        :additional-data="
            `${this.$t('admin.exports.dailyReport.title')}, ${this.$t('admin.exports.levelsExport.title')}, ${this.$t(
                'admin.exports.pairedTransactions.title'
            )}`
        "
    >
        <app-text>
            <span v-html="$t('admin.exports.dailyReport.description')" />
        </app-text>

        <app-row class="mt-3">
            <app-registration-col whole-screen>
                <validation-observer ref="formExportPurchaseAccounts">
                    <div class="params-container">
                        <div class="params-container__item">
                            <app-date-picker
                                :label="$t('admin.exports.dailyReport.date')"
                                :menu.sync="calendarDailyReport.day"
                                v-model="dailyReport.date"
                            />
                        </div>

                        <div class="params-container__item">
                            <button-primary
                                @click="exportDailyReport"
                                full-width
                                :loading="exportLoadingButtons.dailyReport"
                            >
                                {{ $t('admin.exports.button') }}
                            </button-primary>
                        </div>
                    </div>
                </validation-observer>
            </app-registration-col>
        </app-row>

        <app-divider />

        <app-text>
            <span v-html="$t('admin.exports.levelsExport.description')" />
        </app-text>

        <app-row class="mt-3">
            <app-registration-col whole-screen>
                <validation-observer ref="formExportMatchedLevels">
                    <div class="params-container">
                        <div class="params-container__item">
                            <app-date-picker
                                :label="$t('admin.exports.levelsExport.from')"
                                :menu.sync="calendarMatchedLevels.from"
                                v-model="matchedLevels.from"
                            />
                        </div>

                        <div class="params-container__item">
                            <app-date-picker
                                :label="$t('admin.exports.levelsExport.to')"
                                :menu.sync="calendarMatchedLevels.to"
                                v-model="matchedLevels.to"
                            />
                        </div>

                        <div class="params-container__item">
                            <button-primary
                                @click="exportMatchedLevels"
                                full-width
                                :loading="exportLoadingButtons.matchedLevels"
                            >
                                {{ $t('admin.exports.button') }}
                            </button-primary>
                        </div>
                    </div>
                </validation-observer>
            </app-registration-col>
        </app-row>

        <app-divider />

        <app-text>
            <span v-html="$t('admin.exports.pairedTransactions.description')" />
            <span v-html="$t('admin.exports.pairedTransactions.attention')" />
        </app-text>

        <app-row class="mt-3">
            <app-registration-col whole-screen>
                <validation-observer ref="formExportPairedTransactions">
                    <div class="params-container">
                        <div class="params-container__item">
                            <app-date-picker
                                :label="$t('admin.exports.pairedTransactions.from')"
                                :menu.sync="calendarPairedTransactions.from"
                                v-model="pairedTransactions.from"
                            />
                        </div>

                        <div class="params-container__item">
                            <app-date-picker
                                :label="$t('admin.exports.pairedTransactions.to')"
                                :menu.sync="calendarPairedTransactions.to"
                                v-model="pairedTransactions.to"
                            />
                        </div>

                        <div class="params-container__item">
                            <button-primary
                                @click="exportPairedTransactions"
                                full-width
                                :loading="exportLoadingButtons.pairedTransactions"
                            >
                                {{ $t('admin.exports.button') }}
                            </button-primary>
                        </div>
                    </div>
                </validation-observer>
            </app-registration-col>
        </app-row>
    </app-expansion-panel>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { ValidationObserver } from 'vee-validate';

    import { getToday } from '@/utils';

    import AppDatePicker from '@/components/ui/form/AppDatePicker';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'ExportExpansionPanel',

        components: { AppExpansionPanel, AppDatePicker, ValidationObserver },

        data: () => ({
            exportLoadingButtons: {
                dailyReport: false,
                matchedLevels: false,
                pairedTransactions: false
            },

            dailyReport: {
                date: ''
            },

            matchedLevels: {
                from: '',
                to: ''
            },

            pairedTransactions: {
                from: '',
                to: ''
            },

            calendarDailyReport: {
                day: false
            },

            calendarMatchedLevels: {
                from: false,
                to: false
            },

            calendarPairedTransactions: {
                from: false,
                to: false
            }
        }),

        methods: {
            downloadCSVData(data, exportFilename) {
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + escape(data);
                anchor.target = '_blank';
                anchor.download = exportFilename;
                anchor.click();
            },

            downloadBinaryData(data, filename) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
            },

            exportDailyReport() {
                this.exportLoadingButtons.dailyReport = true;

                this.$refs.formExportPurchaseAccounts
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_productTrading/export', {
                                    name: 'efk-trading/exports/daily-report',
                                    options: `date=${this.dailyReport.date}`,
                                    binary: true
                                })
                                .then(({ data, filename }) => {
                                    this.downloadBinaryData(data, filename);
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.exportLoadingButtons.dailyReport = false;
                                });
                        } else {
                            this.exportLoadingButtons.dailyReport = false;
                        }
                    })
                    .catch(() => {
                        this.exportLoadingButtons.dailyReport = false;
                    });
            },

            exportMatchedLevels() {
                this.exportLoadingButtons.matchedLevels = true;

                this.$refs.formExportMatchedLevels
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_productTrading/export', {
                                    name: 'efk-trading/exports/matched-levels',
                                    options: `from=${this.matchedLevels.from}&to=${this.matchedLevels.to}`
                                })
                                .then(({ data, filename }) => {
                                    this.downloadCSVData(data, filename);
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.exportLoadingButtons.matchedLevels = false;
                                });
                        } else {
                            this.exportLoadingButtons.matchedLevels = false;
                        }
                    })
                    .catch(() => {
                        this.exportLoadingButtons.matchedLevels = false;
                    });
            },

            exportPairedTransactions() {
                this.exportLoadingButtons.pairedTransactions = true;

                this.$refs.formExportPairedTransactions
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('admin_productTrading/export', {
                                    name: 'efk-trading/exports/paired-transactions',
                                    options: `from=${this.pairedTransactions.from}&to=${this.pairedTransactions.to}`,
                                    binary: true
                                })
                                .then(({ data, filename }) => {
                                    this.downloadBinaryData(data, filename);
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.exportLoadingButtons.pairedTransactions = false;
                                });
                        } else {
                            this.exportLoadingButtons.pairedTransactions = false;
                        }
                    })
                    .catch(() => {
                        this.exportLoadingButtons.pairedTransactions = false;
                    });
            }
        },

        mounted() {
            const today = getToday();

            if (today) {
                this.dailyReport.date = today;
            }
        }
    };
</script>

<style scoped lang="scss">
    .params-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include media.resolve('md-and-down') {
            justify-content: space-between;
        }

        .params-container__item {
            @include media.resolve('lg-and-up') {
                margin-right: 36px;
            }

            @include media.resolve('md-and-down') {
                width: 32%;
            }

            @include media.resolve('sm-and-down') {
                width: 48%;
            }

            @include media.resolve('xs-only') {
                width: 100%;
            }
        }
    }

    .expansion-header {
        display: flex;
        align-items: center;

        @include media.resolve('xs-only') {
            flex-direction: column;
            align-items: start;
        }

        .expansion-header__additional-info {
            margin-left: spacing.$spacing-6;
            margin-top: 0;

            @include media.resolve('xs-only') {
                margin-left: 0;
                margin-top: spacing.$spacing-3;
            }
        }
    }
</style>
