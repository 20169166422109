<template>
    <div>
        <efk-controlled-liquidity-cancel-dialog :dialog="productCancelDialog" @product:cancel="cancelProduct" />
        <table-filter-bar v-on="$listeners" v-if="filteringEnabled">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.productAccountId')" v-model="filters.id" />
                </app-registration-col>

                <app-registration-col field>
                    <app-select-field
                        hide-hint
                        v-model="filters.product"
                        item-text="name"
                        :items="productTypes"
                        :placeholder="$t('filters.productType')"
                        @change="resetRateFilters"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.user')" v-model="filters.user" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.vs')" v-model="filters.vs" />
                </app-registration-col>
            </app-row>

            <app-row justify="center" v-if="displayRateFilters">
                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.rateFrom')" v-model="filters.rateFrom" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field hide-hint :placeholder="$t('filters.rateTo')" v-model="filters.rateTo" />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" v-if="filteringEnabled" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
        >
            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>

            <template v-slot:header.actions="{ header }">
                <span class="ml-4">{{ header.text }}</span>
            </template>

            <template v-slot:item.productId="{ item }">
                <span>{{ $t('productTypes.' + item.productId) }}</span>
            </template>

            <template v-slot:item.daysUntilExpiration="{ item }">
                <span v-if="item.daysUntilExpiration"
                    >{{ item.daysUntilExpiration }}
                    {{ $tc('admin.products.daysUntilExpiration', item.daysUntilExpiration) }}</span
                >

                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
            </template>

            <template v-slot:item.minSaleRate="{ item }">
                <span>{{ item.minSaleRate }} USD/EFK</span>
            </template>

            <template v-slot:item.maxPurchaseRate="{ item }">
                <span>{{ item.maxPurchaseRate }} USD/EFK</span>
            </template>

            <template v-slot:item.balance="{ item }">
                <div v-if="item.closed" class="d-flex align-center justify-start">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <font-awesome-icon class="mr-1" v-color:success :icon="['fad', 'arrow-alt-up']" />

                                <span v-if="item.totalProceeds">
                                    {{ formatAmount(item.totalProceeds, item.currency) }}
                                    {{ item.currency }}
                                </span>

                                <span v-else v-color:black.translucent>{{ $t('common.none') }}</span>
                            </span>
                        </template>

                        <span>{{ $t('transactionTypes.product_proceeds') }}</span>
                    </v-tooltip>
                </div>

                <div v-else class="d-flex align-center justify-start">
                    <v-tooltip top v-if="item.fillProgress !== undefined">
                        <template #activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <app-progress :value="item.fillProgress" />
                            </span>
                        </template>

                        <span>{{ item.fillProgress }}%</span>
                    </v-tooltip>

                    <span>{{ formatAmount(item.balance, item.currency) }}</span>
                    <span class="ml-1 mr-1">{{ item.currency }}</span>

                    <v-tooltip top v-if="item.balanceCZK">
                        <template #activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <font-awesome-icon :icon="['fas', 'question-circle']"></font-awesome-icon>
                            </span>
                        </template>

                        <span> {{ formatAmount(item.balanceCZK, 'czk') }} CZK </span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:item.purchaseAmount="{ item }">
                <span>{{ formatAmount(item.purchaseAmount, item.purchaseCurrency) }}</span>
                <span class="ml-1">{{ item.purchaseCurrency }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-start">
                    <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <button-secondary
                                v-on="on"
                                inline
                                small
                                class="mr-0"
                                icon="question-circle"
                                :icon-button="$vuetify.breakpoint.xsOnly"
                            >
                                <span v-if="$vuetify.breakpoint.smAndUp">
                                    {{ $t('common.info') }}
                                </span>
                            </button-secondary>
                        </template>

                        <app-card left class="pa-4">
                            <app-text-stack v-if="item.active !== undefined" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.active.title') }}
                                </template>

                                {{ $t('admin.products.active.' + item.active) }}
                            </app-text-stack>

                            <hr v-if="item.active !== undefined" class="mt-3 mb-3" />

                            <app-text-stack v-if="item.productId === 'EFK_PURCHASE_STAKE' && item.phase" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.phase.title') }}
                                </template>

                                {{ $t('admin.products.phase.' + item.phase) }}
                            </app-text-stack>

                            <hr
                                v-if="item.productId === 'EFK_PURCHASE_STAKE' && item.purchasePhaseEndedAt"
                                class="mt-3 mb-3"
                            />

                            <app-text-stack
                                v-if="item.productId === 'EFK_PURCHASE_STAKE' && item.purchasePhaseEndedAt"
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.products.purchasePhaseEndedAt') }}
                                </template>

                                <span>{{ formatTimestamp(item.purchasePhaseEndedAt) }}</span>
                            </app-text-stack>

                            <hr v-if="item.productId === 'EFK_PURCHASE_STAKE'" class="mt-3 mb-3" />

                            <app-text-stack
                                v-if="item.productId === 'EFK_STAKE' && item.interestRate !== undefined"
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.products.interestRate') }}
                                </template>

                                {{ item.interestRate }}% p.a.
                            </app-text-stack>

                            <hr
                                v-if="item.productId === 'EFK_STAKE' && item.interestRate !== undefined"
                                class="mt-3 mb-3"
                            />

                            <app-text-stack
                                v-if="
                                    item.productId === 'PARTICIPATION_4TRANS' &&
                                        item.interestPaymentAmount !== undefined
                                "
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.products.quarterlyInterestPayment') }}
                                </template>

                                {{ formatAmount(item.interestPaymentAmount, 'czk') }} CZK
                            </app-text-stack>

                            <hr
                                v-if="
                                    item.productId === 'PARTICIPATION_4TRANS' &&
                                        item.interestPaymentAmount !== undefined
                                "
                                class="mt-3 mb-3"
                            />

                            <app-text-stack
                                v-if="item.productId === 'PARTICIPATION_4TRANS' && item.totalAppreciation !== undefined"
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.products.totalAppreciation') }}
                                </template>

                                {{ formatAmount(item.totalAppreciation, 'czk') }}
                            </app-text-stack>

                            <hr
                                v-if="item.productId === 'PARTICIPATION_4TRANS' && item.totalAppreciation !== undefined"
                                class="mt-3 mb-3"
                            />

                            <app-text-stack v-if="item.minSaleRate !== undefined" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.minSaleRate') }}
                                </template>

                                {{ item.minSaleRate }} USD/EFK
                            </app-text-stack>

                            <app-text-stack v-if="item.saleRate !== undefined" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.saleRate') }}
                                </template>

                                {{ item.saleRate }} USD/EFK
                            </app-text-stack>

                            <hr
                                v-if="item.minSaleRate !== undefined || item.saleRate !== undefined"
                                class="mt-3 mb-3"
                            />

                            <app-text-stack v-if="item.maxPurchaseRate !== undefined" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.maxPurchaseRate') }}
                                </template>

                                {{ item.maxPurchaseRate }} USD/EFK
                            </app-text-stack>

                            <hr v-if="item.maxPurchaseRate !== undefined" class="mt-3 mb-3" />

                            <app-text-stack v-if="item.durationUnit" smaller-value>
                                <template #key>
                                    {{ $t('admin.users.user.documents.duration') }}
                                </template>

                                {{ item.duration + ' ' + $tc('common.durations.' + item.durationUnit, item.duration) }}
                            </app-text-stack>

                            <hr v-if="item.durationUnit" class="mt-3 mb-3" />

                            <app-text-stack smaller-value>
                                <template #key>
                                    {{ $t('admin.users.user.documents.created') }}
                                </template>

                                <span>{{ formatTimestamp(item.openedAt) }}</span>
                            </app-text-stack>

                            <hr class="mt-3 mb-3" />

                            <app-text-stack v-if="item.productId !== 'EFK_CONTROLLED_LIQUIDITY'" smaller-value>
                                <template #key>
                                    {{ $t('admin.products.activated') }}
                                </template>

                                <span v-if="item.paidAt !== undefined">
                                    <span v-if="item.paidAt !== null">{{ formatTimestamp(item.paidAt) }}</span>
                                    <span v-else>{{ $t('admin.products.notPaid') }}</span>
                                </span>

                                <span v-else>{{ formatTimestamp(item.openedAt) }}</span>
                            </app-text-stack>

                            <app-text-stack
                                v-else-if="
                                    item.productId === 'EFK_CONTROLLED_LIQUIDITY' &&
                                        item.activatedAt !== undefined &&
                                        item.activatedAt !== null
                                "
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.products.activated') }}
                                </template>

                                <span>{{ formatTimestamp(item.activatedAt) }}</span>
                            </app-text-stack>

                            <app-text-stack
                                v-if="
                                    item.productId === 'EFK_CONTROLLED_LIQUIDITY' &&
                                        item.canceledAt !== undefined &&
                                        item.canceledAt !== null
                                "
                                smaller-value
                            >
                                <template #key>
                                    {{ $t('admin.users.user.products.accountState.cancelled') }}
                                </template>

                                <span>{{ formatTimestamp(item.canceledAt) }}</span>
                            </app-text-stack>

                            <app-text-stack
                                v-if="item.cancellationReason !== undefined && item.cancellationReason !== null"
                                smaller-value
                                class="mt-3"
                            >
                                <template #key>
                                    {{ $t('admin.products.cancellationReason') }}
                                </template>

                                {{ item.cancellationReason }}
                            </app-text-stack>
                        </app-card>
                    </v-menu>

                    <button-secondary
                        v-if="productFeatureManageECLProduct(item)"
                        icon="check"
                        icon-set="fas"
                        v-color:primary
                        inline
                        small
                        @click="activateProduct(item)"
                        :loading="markAsPaidLoadingButton[item.id]"
                    >
                        {{ $t('admin.products.markAsPaid') }}
                    </button-secondary>

                    <button-secondary
                        v-if="productFeatureManageECLProduct(item)"
                        icon="times"
                        icon-set="fas"
                        v-color:danger
                        inline
                        small
                        @click="onCancelProduct(item)"
                        :loading="markAsPaidLoadingButton[item.id]"
                    >
                        {{ $t('admin.products.cancel') }}...
                    </button-secondary>

                    <button-secondary
                        v-if="productFeatureMarkAsPaid(item)"
                        icon="check"
                        icon-set="fas"
                        v-color:primary
                        inline
                        small
                        @click="markAsPaid(item)"
                        :loading="markAsPaidLoadingButton[item.id]"
                    >
                        {{ $t('admin.products.markAsPaid') }}
                    </button-secondary>

                    <button-secondary
                        v-if="productFeatureClose(item)"
                        icon="times"
                        icon-set="fas"
                        v-color:danger
                        inline
                        small
                        @click="closeProduct(item)"
                        :loading="productForceCloseButtonLoading[item.id]"
                    >
                        {{ $t('admin.products.forceClose') }}
                    </button-secondary>
                </div>
            </template>

            <template v-slot:item.state="{ item }">
                <span v-color:[getStateTextColor(item)]>
                    {{ $t('admin.users.user.products.accountState.' + getItemState(item)) }}
                </span>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';

    import {
        PRODUCT_ID_EFK_PURCHASE,
        PRODUCT_ID_EFK_LIQUIDITY__VALUE,
        PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY
    } from '@/constants';
    import { mapGetters } from 'vuex';

    import { PTFactory } from '@lb-world/core/public/static/productTypes';

    import AppProgress from '@/components/ui/AppProgress';
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import EfkControlledLiquidityCancelDialog from '@/components/ui/dialogs/EfkControlledLiquidityCancelDialog';

    export default {
        name: 'AdminProductAccountsTable',

        components: { EfkControlledLiquidityCancelDialog, AppSelectField, AppTextField, AppProgress },

        mixins: [core, pagination, filtering],

        props: {
            filteringEnabled: Boolean
        },

        data: () => ({
            markAsPaidLoadingButton: {},
            productForceCloseButtonLoading: {},
            productTypes: PTFactory.get(),
            productCancelDialog: {
                item: null,
                open: false
            }
        }),

        computed: {
            ...mapGetters('admin_productAccounts', {
                productAccountsFilter: 'paFilter'
            }),

            displayRateFilters() {
                return (
                    this.filters.product === PRODUCT_ID_EFK_PURCHASE ||
                    this.filters.product === PRODUCT_ID_EFK_LIQUIDITY__VALUE
                );
            },

            headers() {
                let h = [
                    {
                        text: this.$t('table.columns.userId'),
                        value: 'userId'
                    },
                    {
                        text: this.$t('table.columns.productAccountId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.productType'),
                        value: 'productId'
                    },
                    {
                        text: this.$t('table.columns.balance'),
                        value: 'balance'
                    },
                    {
                        text: this.$t('table.columns.purchaseAmount'),
                        value: 'purchaseAmount'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions',
                        align: 'start'
                    },
                    {
                        text: this.$t('table.columns.state'),
                        value: 'state'
                    }
                ];

                const productType = this.productAccountsFilter?.product;

                if (productType === PRODUCT_ID_EFK_PURCHASE || productType === PRODUCT_ID_EFK_LIQUIDITY__VALUE) {
                    h = [
                        ...h,
                        {
                            text: this.$t('table.columns.daysUntilExpiration'),
                            value: 'daysUntilExpiration'
                        }
                    ];
                }

                if (productType === PRODUCT_ID_EFK_PURCHASE) {
                    h = [
                        ...h,
                        {
                            text: this.$t('admin.products.maxPurchaseRate'),
                            value: 'maxPurchaseRate'
                        }
                    ];
                } else if (productType === PRODUCT_ID_EFK_LIQUIDITY__VALUE) {
                    h = [
                        ...h,
                        {
                            text: this.$t('admin.products.minSaleRate'),
                            value: 'minSaleRate'
                        }
                    ];
                } else if (productType === PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY) {
                    h = [
                        ...h,
                        {
                            text: this.$t('admin.products.saleRate'),
                            value: 'saleRate'
                        }
                    ];
                }

                return h;
            }
        },

        watch: {
            items() {
                this.refreshLoadingButtonListeners();
            }
        },

        methods: {
            productFeatureMarkAsPaid(item) {
                if (item.productId === 'EFK_CONTROLLED_LIQUIDITY') {
                    return false;
                }

                const predicateEFKPurchase =
                    item.productId === 'EFK_PURCHASE' &&
                    item.active !== undefined &&
                    !item.active &&
                    item.state !== 'closed';

                const predicateParticipation4Trans =
                    item.productId === 'PARTICIPATION_4TRANS' && !item.paid && item.state !== 'closed';

                const predicateEFKPurchaseStake =
                    item.productId === 'EFK_PURCHASE_STAKE' && !item.active && !item.closed;

                return predicateEFKPurchase || predicateParticipation4Trans || predicateEFKPurchaseStake;
            },

            productFeatureManageECLProduct(item) {
                return item.productId === 'EFK_CONTROLLED_LIQUIDITY' && item.waitingForAction;
            },

            productFeatureClose(item) {
                if (item.productId === 'EFK_CONTROLLED_LIQUIDITY') {
                    return false;
                }

                const predicateEFKLiquidityValue = item.productId === 'EFK_LIQUIDITY__VALUE' && item.state === 'open';

                const predicateEFKPurchase =
                    item.productId === 'EFK_PURCHASE_STAKE' &&
                    item.active !== undefined &&
                    item.active &&
                    item.phase === 'stake';

                const predicateEFKStakeRfi = item.productId === 'EFK_STAKE_RFI' && item.canBeClosed;

                return predicateEFKLiquidityValue || predicateEFKPurchase || predicateEFKStakeRfi;
            },

            resetRateFilters() {
                this.filters.rateFrom = '';
                this.filters.rateTo = '';
            },
            refreshLoadingButtonListeners() {
                this.$set(this, 'markAsPaidLoadingButton', {});
                this.$set(this, 'productForceCloseButtonLoading', {});

                for (const item of this.items) {
                    this.$set(this.markAsPaidLoadingButton, item.id, false);
                    this.$set(this.productForceCloseButtonLoading, item.id, false);
                }
            },
            markAsPaid(item) {
                EventBus.$emit('snackbar:show:confirmation', {
                    text: this.$t('snackbar.confirmation.transaction'),
                    hookId: 'admin:markAsPaid',
                    params: { itemId: item.id }
                });
            },
            activateProduct(item) {
                EventBus.$emit('snackbar:show:confirmation', {
                    text: this.$t('snackbar.confirmation.transaction'),
                    hookId: 'admin:activateProduct',
                    params: { itemId: item.id }
                });
            },
            closeProduct(item) {
                EventBus.$emit('snackbar:show:confirmation', {
                    text: this.$t('snackbar.confirmation.transaction'),
                    hookId: 'admin:closeProduct',
                    params: { productId: item.id, productType: item.productId }
                });
            },
            onCancelProduct(item) {
                this.productCancelDialog.item = item;
                this.productCancelDialog.open = true;
            },
            cancelProduct(event) {
                EventBus.$emit('snackbar:show:confirmation', {
                    text: this.$t('snackbar.confirmation.transaction'),
                    hookId: 'admin:cancelProduct',
                    params: { productId: event.itemId, cancellationReason: event.reason }
                });
            },
            getItemState(item) {
                if (item.state === 'open' && item.paidAt === null) {
                    return 'awaitingActivation';
                } else if (item.waitingForAction) {
                    return 'awaitingActivation';
                } else if (item.productId === 'EFK_CONTROLLED_LIQUIDITY' && item.state === 'closed') {
                    return 'cancelled';
                } else if (item.state === 'open') {
                    return 'activated';
                }

                return item.state;
            },
            getStateTextColor(item) {
                const state = this.getItemState(item);

                switch (state) {
                    case 'activated':
                        return 'success';

                    case 'awaitingActivation':
                        return 'gray';

                    case 'closed':
                        return 'danger';

                    default:
                        return null;
                }
            }
        },

        mounted() {
            EventBus.$on('admin:markAsPaid', ({ itemId }) => {
                this.markAsPaidLoadingButton[itemId] = true;

                this.$store
                    .dispatch('admin_productAccounts/pat:markAsPaid', itemId)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionMarkAsPaid'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        if (Object.keys(this.markAsPaidLoadingButton).includes(itemId)) {
                            this.markAsPaidLoadingButton[itemId] = false;
                        }
                    });
            });

            EventBus.$on('admin:activateProduct', ({ itemId }) => {
                this.markAsPaidLoadingButton[itemId] = true;

                this.$store
                    .dispatch('admin_productAccounts/pat:activateProduct', itemId)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionMarkAsPaid'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        if (Object.keys(this.markAsPaidLoadingButton).includes(itemId)) {
                            this.markAsPaidLoadingButton[itemId] = false;
                        }
                    });
            });

            EventBus.$on('admin:closeProduct', ({ productId, productType }) => {
                this.productForceCloseButtonLoading[productId] = true;

                this.$store
                    .dispatch('admin_productAccounts/pat:closeProduct', { productAccountId: productId, productType })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionForceProductClose'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        if (Object.keys(this.productForceCloseButtonLoading).includes(productId)) {
                            this.productForceCloseButtonLoading[productId] = false;
                        }
                    });
            });

            EventBus.$on('admin:cancelProduct', ({ productId, cancellationReason }) => {
                this.productForceCloseButtonLoading[productId] = true;

                this.$store
                    .dispatch('admin_productAccounts/pat:cancelProduct', {
                        productAccountId: productId,
                        cancellationReason
                    })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.transactionForceProductClose'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        if (Object.keys(this.productForceCloseButtonLoading).includes(productId)) {
                            this.productForceCloseButtonLoading[productId] = false;
                        }
                    });
            });
        }
    };
</script>

<style scoped></style>
