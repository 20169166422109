<template>
    <div class="alert">
        <app-text justify="center" v-color:white>
            <span class="alert__content">
                <font-awesome-icon class="mr-1" :icon="['fas', 'exclamation-circle']" />

                <slot />
            </span>
        </app-text>
    </div>
</template>

<script>
    export default {
        name: 'AlertDanger'
    };
</script>

<style scoped lang="scss">
    .alert {
        position: sticky;
        top: spacing.$spacing-1;
        left: 0;
        right: 0;
        z-index: 1000;

        box-shadow: 2px 8px 10px -5px colors.$black-25;

        border-radius: spacing.$spacing-1;
        margin-bottom: spacing.$spacing-6;

        background-color: colors.$color-warning;

        .alert__content {
            color: white;
        }
    }
</style>
