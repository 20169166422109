<template>
    <div>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    label="Druh dokladu"
                    :value="$t('documentTypes.' + user.identityDocumentInfo.type)"
                    rules="required"
                    readonly
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    label="Číslo dokladu"
                    v-model="user.identityDocumentInfo.documentNumber"
                    rules="required"
                    readonly
                ></app-text-field>
            </app-registration-col>
        </app-row>
        <app-row>
            <app-registration-col field>
                <app-text-field label="Platnost dokladu od" v-model="user.identityDocumentInfo.dateOfIssue" readonly />
            </app-registration-col>
            <app-registration-col field>
                <app-text-field label="Platnost dokladu do" v-model="user.identityDocumentInfo.dateOfExpiry" readonly />
            </app-registration-col>
        </app-row>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    v-model="user.identityDocumentInfo.authority"
                    label="Vydavatel dokladu"
                    rules="required"
                    readonly
                ></app-text-field>
            </app-registration-col>

            <app-registration-col field>
                <app-text-field
                    :value="parseCountry(user.identityDocumentInfo.authorityCountry)"
                    label="Stát vydání dokladu"
                    rules="required"
                    readonly
                    :disabled="parseCountry(user.identityDocumentInfo.authorityCountry) === null"
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col field>
                <app-switch-field
                    v-model="user.identityDocumentInfo.politicallyExposedPerson"
                    label="Politicky exponovaná osoba"
                    readonly
                />
            </app-registration-col>
            <app-registration-col field>
                <app-switch-field v-model="user.identityDocumentInfo.sanctions" label="Sankce" readonly />
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppSwitchField from '@/components/ui/form/AppSwitchField';

    import countries, { convertCodeToName } from '@/countries';

    export default {
        name: 'InfoDocumentTab',

        components: {
            AppSwitchField,
            AppTextField
        },

        props: ['user'],

        methods: {
            parseCountry(value) {
                return convertCodeToName(value, countries);
            }
        }
    };
</script>

<style scoped></style>
