import { API_INTERNAL_ERROR, parseErrorMessageFromAPI } from '@/utils';

import Vue from 'vue';

import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

const state = {
    recoveryState: {
        email: '',
        code: ''
    }
};

const actions = {
    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear');

            resolve();
        });
    },
    'store:code': ({ commit }, code) => {
        Vue.$log.debug('[ACTION] Running action with API call', code);

        return new Promise(resolve => {
            commit('store', { key: 'code', data: code });

            resolve();
        });
    },
    'store:email': ({ commit }, email) => {
        return new Promise(resolve => {
            commit('store', { key: 'email', data: email });

            resolve();
        });
    },
    request: ({ commit }, { email, token }) => {
        Vue.$log.debug('[ACTION] Running action with API call', email);

        return new Promise((resolve, reject) => {
            UserRepository.pwRecoveryRequest(email, token)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'email', data: email });

                        resolve();
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    change: ({ state }, { password, token }) => {
        Vue.$log.debug('[ACTION] Running action with API call', password);

        return new Promise((resolve, reject) => {
            const { code, email } = state.recoveryState;

            UserRepository.pwRecoveryChange(code, email, password, token)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, { key, data }) => {
        state.recoveryState[key] = data;
    },
    clear: state => {
        state.recoveryState.code = '';
        state.recoveryState.email = '';
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {}
};
