<template>
    <div>
        <app-card table left class="py-6">
            <app-title>{{ $t('admin.users.user.wallets.addToWallet') }}</app-title>

            <validation-observer ref="formAddToWallet">
                <app-row class="mt-4">
                    <app-registration-col field>
                        <app-text-field
                            :label="$t('admin.users.user.wallets.amount')"
                            v-model="manualTransaction.amount"
                            :rules="{
                                required: true,
                                regex: numberRegex,
                                decimals: currencyDecimals,
                                min_value: currencyMinValue
                            }"
                        ></app-text-field>
                    </app-registration-col>
                    <app-registration-col field>
                        <app-select-field
                            @change="manualTransactionCryptoChange"
                            :value="manualTransaction.cryptoCurrency"
                            :items="currencies"
                            :placeholder="$t('admin.users.user.wallets.wallet')"
                        ></app-select-field>
                    </app-registration-col>
                </app-row>
                <app-row>
                    <app-registration-col field>
                        <app-text-field
                            :label="$t('admin.users.user.wallets.transactionId')"
                            v-model="manualTransaction.transactionHash"
                            rules="required"
                        ></app-text-field>
                    </app-registration-col>
                    <app-registration-col field>
                        <button-wrapper justify="start">
                            <button-primary full-width @click="addToWallet" :loading="manualTransactionButton.loading">
                                {{ $t('admin.users.user.wallets.addToWallet') }}
                            </button-primary>
                        </button-wrapper>
                    </app-registration-col>
                </app-row>
            </validation-observer>

            <app-divider />

            <app-title>{{ $t('admin.users.user.wallets.userWallets') }}</app-title>

            <app-row v-if="userWalletsError">
                <app-registration-col>
                    <app-error fetching horizontal />
                </app-registration-col>
            </app-row>

            <app-row v-else-if="!userWalletsFetched">
                <app-registration-col>
                    <app-loader wrapper horizontal />
                </app-registration-col>
            </app-row>

            <app-row v-else>
                <app-registration-col class="pa-0">
                    <app-flex>
                        <template v-for="wallet in userWallets">
                            <app-flex-item :key="wallet.currency">
                                <app-text-stack
                                    v-color:[wallet.currency].translucent
                                    style="padding: 12px; border-radius: 8px"
                                    bigger-key
                                    :key="wallet.currency"
                                >
                                    <template #key>
                                        <div class="d-flex align-center">
                                            <img
                                                style="height: 14px"
                                                class="mr-1"
                                                :src="
                                                    require('@lb-world/core/public/assets/crypto/' +
                                                        wallet.currency +
                                                        '.svg')
                                                "
                                            />

                                            <span v-color:[wallet.currency]>{{ wallet.currency }}</span>
                                        </div>
                                    </template>

                                    <span class="popover-category mr-2">
                                        {{ formatAmount(wallet.balance, wallet.currency) }}
                                    </span>
                                </app-text-stack>
                            </app-flex-item>
                        </template>
                    </app-flex>
                </app-registration-col>
            </app-row>

            <app-divider />

            <app-title>{{ $t('admin.users.user.wallets.walletTransactions') }}</app-title>

            <app-row>
                <app-registration-col whole-screen class="pt-0">
                    <app-custom-navigation-tabs
                        :i18n-prefix="transactionsTabs.i18nPrefix"
                        :tabs="transactionsTabs"
                        @change="tabChange"
                    ></app-custom-navigation-tabs>

                    <hr />

                    <component
                        :is="currentTab"
                        :user-id="this.userId"
                        class="py-9"
                        @transactions:fetch="fetchTransactions"
                    ></component>

                    <table-divider />
                </app-registration-col>
            </app-row>
        </app-card>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';
    import { ValidationObserver } from 'vee-validate';

    import { getDecimalNumberRegex } from '@lb-world/core/public/utils/regexp';

    import Currencies from '@lb-world/core/public/utils/currencies';

    import AppTextField from '../../../../components/ui/form/AppTextField';
    import AppSelectField from '@/components/ui/form/AppSelectField';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import TableDivider from '@lb-world/core/public/components/tables/TableDivider';
    import AppSectionTabs from '@/components/ui/tabs/AppSectionTabs';
    import TransactionWalletTab from '@/views/admin/sections/user/tabs/TransactionWalletTab';
    import TransactionWithdrawalTab from '@/views/admin/sections/user/tabs/TransactionWithdrawalTab';
    import AppCustomNavigationTabs from '@/components/ui/tabs/AppCustomNavigationTabs';
    import AppFlex from '@/components/ui/layout/AppFlex';

    export default {
        name: 'UserWallets',

        components: {
            AppFlex,
            AppCustomNavigationTabs,
            AppSectionTabs,
            TableDivider,
            AppFlexItem,
            AppSelectField,
            AppTextField,
            ValidationObserver,

            wallet: TransactionWalletTab,
            withdrawal: TransactionWithdrawalTab
        },

        data: () => ({
            tabIndex: 0,
            currencyData: {
                EFK: { decimals: 4, minValue: 0.0001 },
                USDT: { decimals: 2, minValue: 0.01 },
                CZK: { decimals: 2, minValue: 0.01 }
            },
            manualTransaction: {
                cryptoCurrency: 'EFK',
                amount: '',
                transactionHash: ''
            },
            userId: '',
            manualTransactionButton: {
                loading: false
            }
        }),

        computed: {
            currencyMinValue() {
                return (
                    this.currencyData[this.manualTransaction.cryptoCurrency]?.minValue ?? this.currencyData.EFK.minValue
                );
            },
            currencyDecimals() {
                return (
                    this.currencyData[this.manualTransaction.cryptoCurrency]?.decimals ?? this.currencyData.EFK.decimals
                );
            },
            transactionsTabs() {
                return this.$store.getters['tabs/admin:transactions:info'];
            },
            currentTab() {
                return this.transactionsTabs.data[this.tabIndex].name;
            },
            currencies() {
                return Currencies.getCurrencies();
            },

            numberRegex() {
                return getDecimalNumberRegex(this.$i18n.locale);
            },

            ...mapGetters({
                userWallets: 'admin/userDetail:wallets',
                userWalletsError: 'admin/userDetail:wallets:error',
                userWalletsFetched: 'admin/userDetail:wallets:fetched',

                userTransactions: 'admin/userDetail:transactions',
                userTransactionsFetched: 'admin/userDetail:transactions:fetched',
                userTransactionsError: 'admin/userDetail:transactions:error',
                userTransactionsPages: 'admin/userDetail:transactions:pages'
            })
        },

        methods: {
            tabChange(id) {
                this.tabIndex = id;
            },
            fetchWallets() {
                this.userId = this.$route.params.id;

                this.$store.dispatch('admin/userDetail:wallets:fetch', this.userId).catch(error => {
                    EventBus.$emit('snackbar:show:danger', error);
                });
            },
            fetchTransactions(page) {
                this.userId = this.$route.params.id;

                this.$store.dispatch('admin/userDetail:transactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/userDetail:transactions:fetch', {
                            page,
                            userId: this.userId
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });

                this.$store.dispatch('admin/userDetail:withdrawalTransactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/userDetail:withdrawalTransactions:fetch', {
                            page,
                            userId: this.userId
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },

            manualTransactionCryptoChange(value) {
                this.manualTransaction.amount = '';
                this.manualTransaction.transactionHash = '';
                this.manualTransaction.cryptoCurrency = value;

                this.$refs.formAddToWallet.reset();
            },

            addToWallet() {
                this.manualTransactionButton.loading = true;

                this.$refs.formAddToWallet
                    .validate()
                    .then(result => {
                        if (result) {
                            const manualTransaction = this.deepCopy(this.manualTransaction);
                            const userId = this.userId;

                            manualTransaction.amount = this.convertLocaleToNumber(manualTransaction.amount);

                            this.$store
                                .dispatch('admin/userDetail:wallets:newTransaction', { userId, manualTransaction })
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                                    this.manualTransaction.amount = '';
                                    this.manualTransaction.transactionHash = '';

                                    this.$refs.formAddToWallet.reset();

                                    this.$store.dispatch('admin/userDetail:wallets:clear').then(() => {
                                        this.$store.dispatch('admin/userDetail:transactions:clear').then(() => {
                                            this.fetchWallets();
                                            this.fetchTransactions();
                                        });
                                    });
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.manualTransactionButton.loading = false;
                                });
                        } else {
                            this.manualTransactionButton.loading = false;
                        }
                    })
                    .catch(() => {
                        this.manualTransactionButton.loading = false;
                    });
            }
        },

        mounted() {
            this.fetchWallets();
            this.fetchTransactions();
        }
    };
</script>

<style scoped></style>
