<template>
    <validation-observer ref="formUserDetails">
        <app-row justify="center">
            <app-registration-col class="ma-0 pa-0">
                <app-card class="ala-carda">
                    <div class="main-title justify-center mt-5">{{ $t('reg.userDetails.title') }}</div>
                    <app-row justify="center" class="mt-6 mb-0">
                        <app-registration-col specialField class="pt-0 pb-0">
                            <app-text-field
                                name="given-name|firstname"
                                :placeholder="$t('reg.userDetails.firstnamePlaceholder')"
                                rules="required"
                                :label="$t('reg.userDetails.name')"
                                v-model="user.name"
                            ></app-text-field>
                        </app-registration-col>
                        <app-registration-col specialField class="pt-0 pb-0">
                            <app-text-field
                                name="family-name|lastname"
                                :placeholder="$t('reg.userDetails.surnamePlaceholder')"
                                rules="required"
                                :label="$t('reg.userDetails.surname')"
                                v-model="user.surname"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-0 mb-0">
                        <app-registration-col specialWholeScreen class="pt-0 pb-0">
                            <app-text-field
                                rules="required|email"
                                :placeholder="$t('reg.userDetails.emailPlaceholder')"
                                :label="$t('reg.userDetails.email')"
                                type="email"
                                v-model="user.email"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-0 mb-0">
                        <app-registration-col specialWholeScreen class="pt-0 pb-0">
                            <app-text-field
                                rules="email"
                                :placeholder="$t('reg.userDetails.referralEmailPlaceholder')"
                                optional
                                :label="$t('reg.userDetails.referralEmail')"
                                type="email"
                                v-model="referral.email"
                                :readonly="referral.cookieSet"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-0 mb-0">
                        <app-registration-col specialWholeScreen class="pt-0 pb-0">
                            <app-text-field
                                :label="$t('reg.userDetails.password')"
                                :placeholder="$t('reg.userDetails.passwordPlaceholder')"
                                rules="required|min:8"
                                type="password"
                                v-model="user.password"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-0 mb-0">
                        <app-registration-col specialWholeScreen class="pt-0 pb-0">
                            <app-text-field
                                :label="$t('reg.userDetails.confirmPassword')"
                                :placeholder="$t('reg.userDetails.confirmPasswordPlaceholder')"
                                v-model="confirmPassword"
                                type="password"
                                :rules="{ required: true, is: user.password }"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center">
                        <app-registration-col whole-screen class="pa-0 pl-3">
                            <button-wrapper :justify="$vuetify.breakpoint.smAndUp ? 'center' : 'start'">
                                <vt-checkbox
                                    class="agree-font"
                                    justify="center"
                                    :centered="$vuetify.breakpoint.smAndUp"
                                    v-model="acceptTerms"
                                >
                                    {{ $t('reg.userDetails.accept') }}
                                </vt-checkbox>
                                <div class="align-self-center">
                                    <a
                                        class="agree-font-underline"
                                        href="https://lbw.fra1.digitaloceanspaces.com/VOP_LB.pdf"
                                    >
                                        {{ $t('reg.userDetails.generalTermsAndConditions') }}
                                    </a>
                                </div>
                            </button-wrapper>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center">
                        <app-registration-col whole-screen class="pa-0 pl-3">
                            <button-wrapper
                                :class="{ 'pl-6': $vuetify.breakpoint.smAndUp }"
                                :justify="$vuetify.breakpoint.smAndUp ? 'center' : 'start'"
                            >
                                <vt-checkbox
                                    class="agree-font"
                                    centered
                                    justify="center"
                                    v-model="user.marketingConsent"
                                >
                                    {{ $t('reg.userDetails.marketingConsentAccept') }}
                                </vt-checkbox>
                            </button-wrapper>
                        </app-registration-col>
                    </app-row>
                    <app-row justify="center" class="mt-4 flex-nowrap">
                        <v-col class="text-left pr-0">
                            <vt-button-secondary
                                class="return-button registration-address-button-secondary"
                                small
                                @click="$router.go(-1)"
                            >
                                <span class="back-font">
                                    <img class="mr-2" src="@/assets/icons/button-arrow-left.svg" />
                                    {{ $t('reg.userDetails.return') }}
                                </span>
                            </vt-button-secondary>
                        </v-col>

                        <v-col class="text-right pl-0 pr-0">
                            <g-recaptcha :data-sitekey="siteKey" :data-validate="() => true" :data-callback="onSubmit">
                                <button-primary
                                    class="registration-user-detail-button-primary"
                                    :loading="submitLoading"
                                    :disabled="!acceptTerms"
                                    full-width
                                >
                                    {{ $t('reg.userDetails.continue') }}
                                    <img class="ml-2" src="@/assets/icons/button-arrow-right.svg" />
                                </button-primary>
                            </g-recaptcha>
                        </v-col>
                    </app-row>
                </app-card>
            </app-registration-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import Cookies from 'js-cookie';
    import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { LOGIN_NAMESPACE_USERS } from '@lb-world/core/public/utils/auth';
    import { APP_SITE_KEY, COOKIE_REFERRAL_EMAIL } from '@/constants';
    import { ValidationObserver } from 'vee-validate';

    import AppTextField from '@/components/ui/form/VtAppTextField';
    import VtCheckbox from '@/components/ui/form/VtCheckbox';

    export default {
        name: 'UserDetails',

        components: {
            AppTextField,
            ValidationObserver,
            gRecaptcha,
            VtCheckbox
        },

        data: () => ({
            siteKey: APP_SITE_KEY,

            user: {
                name: '',
                surname: '',
                email: '',
                password: '',
                marketingConsent: true
            },

            referral: {
                cookieSet: false,
                email: ''
            },

            submitLoading: false,
            confirmPassword: '',
            acceptTerms: false
        }),

        methods: {
            onSubmit(token) {
                this.$log.debug('[RECAPTCHA] Submitting..', token);

                this.submitLoading = true;

                this.$refs.formUserDetails
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$log.debug('[RECAPTCHA] Submit successful', token);

                            if (token) {
                                this.createUser(token);
                            } else {
                                EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                            }
                        } else {
                            this.$log.debug('[RECAPTCHA] Submit failed');

                            this.submitLoading = false;
                        }
                    })
                    .catch(() => {
                        this.$log.debug('[RECAPTCHA] Submit failed');

                        this.submitLoading = false;
                    });
            },
            createUser(token) {
                let language = this.$i18n.locale;

                if (!language) {
                    this.$log.error(
                        '[REG] Error when setting language to API in UserDetails. Setting fallback language'
                    );

                    language = this.$store.getters['user/user:language'];
                }
                let user = { ...this.user, language: language };

                if (this.referral.email.length) {
                    user.referralEmail = this.referral.email;
                }

                this.$store
                    .dispatch('user/create', {
                        user,
                        token
                    })
                    .then(user => {
                        const { email, password } = user;
                        const namespace = LOGIN_NAMESPACE_USERS;

                        this.$store
                            .dispatch('auth/user:login', {
                                email,
                                password,
                                namespace
                            })
                            .then(redirect => {
                                const form = document.createElement('form');
                                document.body.appendChild(form);
                                form.method = 'post';
                                form.action = redirect;
                                form.submit();
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);

                                this.submitLoading = false;
                            });
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitLoading = false;
                    });
            },
            referralControlQuery() {
                const referralQuery = this.$route.query['referral'];

                if (!Cookies.get(COOKIE_REFERRAL_EMAIL) && referralQuery) {
                    Cookies.set(COOKIE_REFERRAL_EMAIL, referralQuery);
                }
            },
            referralPrefillCookie() {
                const referralCookie = Cookies.get(COOKIE_REFERRAL_EMAIL);

                if (referralCookie) {
                    this.referral.email = referralCookie;
                    this.referral.cookieSet = true;
                }
            }
        },

        mounted() {
            this.referralControlQuery();
            this.referralPrefillCookie();

            this.$emit('toolbar:update', {
                hideUserPanel: true
            });
        }
    };
</script>

<style scoped lang="scss"></style>
