<template>
    <validation-observer ref="formIdentifyUserDocument">
        <app-card button-switch>
            <app-text justify="center">
                {{ $t('admin.digitizationDialog.uploadDocumentsDescription') }}
            </app-text>

            <app-divider :top-padding="0" :bottom-padding="0" />

            <app-title justify="center">
                <span v-html="$t('admin.digitizationDialog.documentNumber', { number: 1 })" />
            </app-title>

            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.document.type')"
                        :value="$t('documentTypes.ID_CARD')"
                        rules="required"
                        readonly
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.document.documentNumber')"
                        v-model="user.identityDocumentInfo.documentNumber"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-date-picker
                        :label="$t('admin.digitizationDialog.document.dateOfIssue')"
                        :menu.sync="calendar.dateOfIssue"
                        v-model="user.identityDocumentInfo.dateOfIssue"
                    />
                </app-registration-col>
                <app-registration-col field>
                    <app-date-picker
                        :label="$t('admin.digitizationDialog.document.dateOfExpiry')"
                        :menu.sync="calendar.dateOfExpiry"
                        v-model="user.identityDocumentInfo.dateOfExpiry"
                    />
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.document.authority')"
                        v-model="user.identityDocumentInfo.authority"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>

                <app-registration-col field>
                    <app-country-select
                        :label="$t('admin.digitizationDialog.document.authorityCountry')"
                        v-model="user.identityDocumentInfo.authorityCountry"
                        rules="required"
                    />
                </app-registration-col>
            </app-row>

            <app-row justify="center" v-if="adminVerification">
                <app-registration-col whole-screen>
                    <app-switch-field
                        centered
                        :label="$t('admin.digitizationDialog.sanctions')"
                        v-model="user.identityDocumentInfo.sanctions"
                    />
                </app-registration-col>
            </app-row>

            <app-divider :top-padding="0" :bottom-padding="0" />

            <app-title justify="center">
                <span v-html="$t('admin.digitizationDialog.documentNumber', { number: 2 })" />
            </app-title>

            <app-row>
                <app-registration-col whole-screen>
                    <app-select-field
                        v-model="user.identityDocumentInfo.otherIdentityDocumentType"
                        :items="secondaryDocuments"
                        item-text="name"
                        item-value="value"
                        :placeholder="$t('admin.digitizationDialog.selectSecondaryDocument')"
                        rules="required"
                    />
                </app-registration-col>
            </app-row>
            <template #button-secondary>
                <button-secondary icon="arrow-alt-left" @click="$emit('process:prev')">
                    {{ $t('admin.digitizationDialog.back') }}
                </button-secondary>
            </template>

            <template #button-primary>
                <button-primary @click="submit" :loading="submitButtonLoading">
                    {{ $t('admin.digitizationDialog.submit') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import AppDatePicker from '@/components/ui/form/VtAppDatePicker';
    import AppSwitchField from '@/components/ui/form/AppSwitchField';
    import AppCountrySelect from '@/components/ui/form/VtAppCountrySelect';

    import { ValidationObserver } from 'vee-validate';
    import { DTFactory } from '@lb-world/core/public/static/documentTypes';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'DigitizationUserDocument',

        components: {
            AppCountrySelect,
            AppSwitchField,
            AppDatePicker,
            AppSelectField,
            AppTextField,
            ValidationObserver
        },

        props: {
            user: {
                type: Object,
                required: true
            },
            type: String,
            adminVerification: Boolean,
            userId: String
        },

        data: () => ({
            documentTypes: DTFactory.get(),
            secondaryDocuments: DTFactory.getSecondary(),
            submitButtonLoading: false,
            calendar: {
                dateOfIssue: false,
                dateOfExpiry: false
            }
        }),

        methods: {
            submit() {
                this.submitButtonLoading = true;

                this.$set(this.user.identityDocumentInfo, 'type', 'ID_CARD');

                this.$refs.formIdentifyUserDocument
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('user/update', {
                                    user: this.user,
                                    userId: this.userId
                                })
                                .then(() => {
                                    if (this.adminVerification) {
                                        this.$store
                                            .dispatch('admin_userDetail/verifyDigitization', { userId: this.userId })
                                            .then(() => {
                                                EventBus.$emit(
                                                    'snackbar:show:success',
                                                    this.$t('success.' + this.type + 'Finished')
                                                );
                                                this.$emit('process:finished');
                                            });
                                    } else {
                                        EventBus.$emit(
                                            'snackbar:show:success',
                                            this.$t('success.' + this.type + 'Finished')
                                        );

                                        this.$emit('process:finished');
                                    }
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
