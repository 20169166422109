var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-filter-bar',_vm._g({},_vm.$listeners),[_c('app-row',{attrs:{"justify":"center"}},[_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"hide-hint":"","placeholder":_vm.$t('filters.id')},model:{value:(_vm.filters.userId),callback:function ($$v) {_vm.$set(_vm.filters, "userId", $$v)},expression:"filters.userId"}})],1),_c('app-registration-col',{attrs:{"field":""}},[_c('app-text-field',{attrs:{"hide-hint":"","placeholder":_vm.$t('filters.email')},model:{value:(_vm.filters.userEmail),callback:function ($$v) {_vm.$set(_vm.filters, "userEmail", $$v)},expression:"filters.userEmail"}})],1)],1),_c('app-row',{attrs:{"justify":"center"}},[_c('app-registration-col',{attrs:{"whole-screen":""}},[_c('app-switch-field',{attrs:{"centered":"","true-value":true,"false-value":null,"label":_vm.$t('filters.unapprovedRequests-name')},model:{value:(_vm.filters.unapprovedRequests),callback:function ($$v) {_vm.$set(_vm.filters, "unapprovedRequests", $$v)},expression:"filters.unapprovedRequests"}})],1)],1)],1),_c('hr',{staticClass:"mt-6"}),_c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"headers":_vm.headers,"page":_vm.pages.current},on:{"update:page":function($event){return _vm.$set(_vm.pages, "current", $event)}},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUsername(item))+" ")]}},{key:"item.limit1",fn:function(ref){
var item = ref.item;
return [_c('purchase-limits-icon',{attrs:{"is-approved":item.limit1.approved,"is-requested":item.limit1.requested}})]}},{key:"item.limit2",fn:function(ref){
var item = ref.item;
return [_c('purchase-limits-icon',{attrs:{"is-approved":item.limit2.approved,"is-requested":item.limit2.requested}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-secondary',{attrs:{"inline":"","icon":"hand-paper"},on:{"click":function($event){return _vm.openUserDetailProfile(item)}}},[_vm._v(" "+_vm._s(_vm.$t('admin.aml.limits'))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])}),_c('table-pagination',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }