import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);

/* Account tab icons */
import {
    faUserCog,
    faLayerPlus,
    faHistory,
    faShield,
    faTh,
    faUser,
    faUsers,
    faUserCircle,
    faRedoAlt,
    faFileAlt,
    faUsersCog,
    faUserEdit,
    faArrowAltCircleLeft,
    faWallet,
    faEraser,
    faCopy,
    faEye,
    faQuestionCircle as fadQuestionCircle,
    faBoxAlt,
    faTreeAlt,
    faExclamationCircle as fadExclamationCircle,
    faArrowAltUp,
    faArrowAltDown,
    faArrowAltLeft,
    faArrowAltRight,
    faSearchPlus,
    faSearchMinus,
    faArrowsAlt,
    faList,
    faCogs,
    faDotCircle,
    faMapMarkedAlt,
    faWrench,
    faBomb,
    faPencil,
    faHandPaper,
    faFileArchive,
    faSync,
    faFileCheck,
    faFolderOpen,
    faDownload,
    faTrashAlt,
    faUserTie,
    faPowerOff,
    faLockAlt,
    faPaste
} from '@fortawesome/pro-duotone-svg-icons';

import { faBars, faCircle, faClock, faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import {
    faCheckCircle,
    faTimesCircle,
    faTimes,
    faCheck,
    faPencilAlt,
    faChevronDown,
    faPlus,
    faMinus,
    faGlobe,
    faWindowMinimize,
    faChevronLeft,
    faQuestionCircle as fasQuestionCircle,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faLockAlt,
    faExclamationTriangle,
    faDotCircle,
    faPowerOff,
    faUserCog,
    faLayerPlus,
    faHistory,
    faShield,
    faArrowAltCircleLeft,
    faTh,
    faUsers,
    faUserCircle,
    faRedoAlt,
    faFileAlt,
    faUsersCog,
    faCopy,
    faCheckCircle,
    faTimesCircle,
    faBars,
    faTimes,
    faCheck,
    faPencilAlt,
    faUserEdit,
    faChevronDown,
    faWallet,
    faBoxAlt,
    faUser,
    faEye,
    faEraser,
    fasQuestionCircle,
    fadQuestionCircle,
    faTreeAlt,
    fasExclamationCircle,
    fadExclamationCircle,
    faPlus,
    faMinus,
    faGlobe,
    faWindowMinimize,
    faArrowAltUp,
    faArrowAltDown,
    faArrowAltLeft,
    faArrowAltRight,
    faSearchPlus,
    faSearchMinus,
    faArrowsAlt,
    faList,
    faCogs,
    faMapMarkedAlt,
    faWrench,
    faBomb,
    faChevronLeft,
    faPencil,
    faCircle,
    farCheckCircle,
    faHandPaper,
    faClock,
    faFileArchive,
    faSync,
    faFileCheck,
    faFolderOpen,
    faDownload,
    faTrashAlt,
    faUserTie,
    faPaste
);
