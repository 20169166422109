export const CURRENT_APP = 'world';
export const CURRENT_APP_VERSION = '1.16';

export const APP_SITE_KEY = '6LfmTO4ZAAAAAAZo5M6rY6GjdHsqmINll6PwdiLM';

// This constant is automatically detected by @lb-world/core package
export const APP_PRIMARY_COLOR = '#741cc0';

export const TABLE_ITEMS_PER_PAGE = 10;

export const DOCUMENT_PRODUCT_SPECS_DIGI_RENT = 'lbbroker-product-specs--digi-rent';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE = 'lbbroker-product-specs--stake-efk';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE_III = 'lbbroker-product-specs--stake-efk-iii';
export const DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI = 'lbbroker-product-specs--stake-efk-rfi';
export const DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY = 'lbbroker-product-specs--liquidity-value';
export const DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY = 'lbbroker-product-specs--controlled_liquidity';
export const DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE = 'lbbroker-product-specs--efk-purchase';
export const DOCUMENT_PRODUCT_SPECS_4TRANS = 'lbbroker-product-specs--4trans';
export const DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE = 'lbbroker-product-specs--efk-purchase-stake';

export const PRODUCT_ID_DIGI_RENT = 'DIGI_RENT';
export const PRODUCT_ID_EFK_STAKE = 'EFK_STAKE';
export const PRODUCT_ID_EFK_LIQUIDITY__TIME = 'EFK_LIQUIDITY__TIME';
export const PRODUCT_ID_EFK_LIQUIDITY__VALUE = 'EFK_LIQUIDITY__VALUE';
export const PRODUCT_ID_EFK_CONTROLLED_LIQUIDITY = 'EFK_CONTROLLED_LIQUIDITY';
export const PRODUCT_ID_EFK_PURCHASE = 'EFK_PURCHASE';
export const PRODUCT_ID_4TRANS = 'PARTICIPATION_4TRANS';
export const PRODUCT_ID_EFK_PURCHASE_STAKE = 'EFK_PURCHASE_STAKE';

export const COOKIE_REFERRAL_EMAIL = 'lbwReferralEmail';
