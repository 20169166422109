<template>
    <font-awesome-icon
        v-if="isDisabled && isApproved"
        v-color:success.translucent
        :style="{ height: size + 'px', width: size + 'px' }"
        :icon="['far', 'check-circle']"
    />

    <font-awesome-icon
        v-else-if="isApproved"
        v-color:success
        :style="{ height: size + 'px', width: size + 'px' }"
        :icon="['far', 'check-circle']"
    />

    <font-awesome-icon
        v-else-if="isDisabled && isRequested"
        v-color:primary.translucent
        :style="{ height: size + 'px', width: size + 'px' }"
        :icon="['far', 'clock']"
    />

    <font-awesome-icon
        v-else-if="isRequested"
        v-color:primary
        :style="{ height: size + 'px', width: size + 'px' }"
        :icon="['far', 'clock']"
    />

    <font-awesome-icon
        v-else
        v-color:black.translucent
        :style="{ height: size + 'px', width: size + 'px' }"
        :icon="['far', 'circle']"
    />
</template>

<script>
    export default {
        name: 'PurchaseLimitsIcon',

        props: {
            size: {
                type: Number,
                default: 18
            },
            isApproved: Boolean,
            isRequested: Boolean,
            isDisabled: Boolean
        }
    };
</script>
