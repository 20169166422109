<template>
    <app-row class="mt-6">
        <app-registration-col>
            <p class="text-center text-sm-start" v-html="$t('bo.aml.purchaseLimitsDescriptions.description1')" />
            <p class="text-center text-sm-start mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.description2')" />
            <p class="text-center text-sm-start mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.description3')" />
            <p class="text-center text-sm-start mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.description4')" />
            <p class="text-center text-sm-start mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.description5')" />
            <p class="text-center text-sm-start mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.description6')" />

            <p class="text-footnote mt-6" v-html="$t('bo.aml.purchaseLimitsDescriptions.asterisk1')"></p>
        </app-registration-col>
    </app-row>
</template>

<script>
    export default {
        name: 'PurchaseLimitsDescription'
    };
</script>
