<template>
    <validation-observer ref="formDeclineDocumentDialog" v-slot="{ invalid }">
        <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
            <app-card dialog>
                <app-dialog-title
                    :title="$t('admin.users.user.documents.declineDocumentDialog.title')"
                    :dialog="dialog"
                />

                <app-card>
                    <p
                        v-html="
                            $t('admin.users.user.documents.declineDocumentDialog.overview', {
                                type: dialog.documentKey,
                                user: dialog.userEmail
                            })
                        "
                    ></p>

                    <p class="mt-6">
                        {{ $t('admin.users.user.documents.declineDocumentDialog.fillReasonToDecline') }}
                    </p>

                    <app-row justify="center" class="mt-6">
                        <app-registration-col>
                            <app-text-field
                                :label="$t('admin.users.user.documents.declineDocumentDialog.reason')"
                                rules="required|max:80"
                                v-model="reason"
                            ></app-text-field>
                        </app-registration-col>
                    </app-row>
                </app-card>

                <template #button-primary>
                    <button-primary @click="submit" :disabled="invalid" :loading="buttonDeclineDocument.loading">
                        {{ $t('admin.users.user.documents.declineDocumentDialog.decline') }}
                    </button-primary>
                </template>
                <template #button-secondary>
                    <button-secondary @click="dialog.open = false">
                        {{ $t('admin.users.user.documents.declineDocumentDialog.cancel') }}
                    </button-secondary>
                </template>
            </app-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppTextField from '../form/VtAppTextField';

    export default {
        name: 'DocumentsApprovalDeclineDialog',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: ['dialog'],

        data: () => ({
            reason: '',
            buttonDeclineDocument: {
                loading: false
            }
        }),

        methods: {
            submit() {
                this.buttonDeclineDocument.loading = true;

                const documentId = this.dialog.documentId;
                const reason = this.reason;

                const process = {
                    action: 'decline',
                    note: reason
                };

                this.$refs.formDeclineDocumentDialog
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('documents/process', {
                                    documentId,
                                    process
                                })
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.documentDeclined'));

                                    this.$emit('process:success');

                                    this.dialog.open = false;
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.buttonDeclineDocument.loading = false;
                                });
                        } else {
                            this.buttonDeclineDocument.loading = false;
                        }
                    })
                    .catch(() => {
                        this.buttonDeclineDocument.loading = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.reason = '';

                        if (this.$refs.formDeclineDocumentDialog) {
                            this.$refs.formDeclineDocumentDialog.reset();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
