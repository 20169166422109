<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.winnings.amount') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.winnings.amount')"
            v-model="details['Hodnota výhry']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.winnings.type') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.winnings.type')"
            v-model="details['Typ hry']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.winnings.date') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.winnings.date')"
            v-model="details['Datum výhry']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.winnings.operator') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.winnings.operator')"
            v-model="details['Provozovatel hazardní hry']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'Winnings',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Hodnota výhry', '');
            this.$set(this.details, 'Typ hry', '');
            this.$set(this.details, 'Datum výhry', '');
            this.$set(this.details, 'Provozovatel hazardní hry', '');
        }
    };
</script>

<style scoped></style>
