<template>
    <admin-product-accounts-transactions-table
        :items="paTransactions"
        :loading="!paTransactionsFetched"
        :fetch-error="paTransactionsError"
        :pages="paTransactionsPages"
        :filters.sync="productTable.filters"
        :pre-applied="productTable.preAppliedFilters"
        @pagination:change="fetchPATransactions"
        @filter:update="filterPATransactions"
        @filter:reset="filterPATransactions"
    />
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AdminProductAccountsTransactionsTable from '@/components/ui/tables/AdminProductAccountsTransactionsTable';

    export default {
        name: 'ProductTransactionsTab',

        components: { AdminProductAccountsTransactionsTable },

        data: () => ({
            productTable: {
                preAppliedFilters: null,
                page: 1,
                filters: {
                    productAccount: '',
                    user: '',
                    product: ''
                }
            }
        }),

        computed: mapGetters('admin_productAccounts', [
            'paTransactions',
            'paTransactionsFilter',
            'paTransactionsFetched',
            'paTransactionsPages',
            'paTransactionsError'
        ]),

        methods: {
            fetchPATransactions(page) {
                this.productTable.page = page;

                this.$store.dispatch('admin_productAccounts/pat:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/pat:fetch', {
                            page,
                            filters: this.productTable.filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            },
            filterPATransactions(filters) {
                this.productTable.filters = filters;
                this.productTable.page = 1;

                this.$store.dispatch('admin_productAccounts/pat:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/pat:fetch', {
                            page: this.productTable.page,
                            filters
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (this.paTransactionsFilter) {
                this.$set(this.productTable, 'preAppliedFilters', this.paTransactionsFilter);
            }

            if (!this.paTransactionsFetched) {
                this.fetchPATransactions();
            }
        }
    };
</script>

<style scoped></style>
