<template>
    <validation-observer ref="formRecoveryUserEmail" v-slot="{ valid }">
        <app-card>
            <app-title justify="center">{{ $t('signIn.passwordRecoveryDialog.userEmail.title') }}</app-title>

            <app-row justify="center">
                <app-col>
                    <p>
                        {{ $t('signIn.passwordRecoveryDialog.userEmail.labelFillEmail') }}
                    </p>
                    <p>
                        {{ $t('signIn.passwordRecoveryDialog.userEmail.labelRecoveryLink') }}
                    </p>
                </app-col>
            </app-row>

            <app-row justify="center">
                <app-col>
                    <app-text-field
                        v-model="email"
                        rules="required|email"
                        :label="$t('signIn.passwordRecoveryDialog.userEmail.email')"
                    >
                    </app-text-field>
                </app-col>
            </app-row>

            <template #button-primary>
                <g-recaptcha :data-sitekey="siteKey" :data-validate="() => valid" :data-callback="onSubmit">
                    <button-primary :loading="submitButtonLoading">
                        {{ $t('signIn.passwordRecoveryDialog.userEmail.submit') }}
                    </button-primary>
                </g-recaptcha>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { APP_SITE_KEY } from '@/constants';

    import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppCol from '../../layout/AppCol';
    import AppTextField from '../../form/VtAppTextField';

    export default {
        name: 'PasswordRecoveryUserEmail',

        components: {
            AppTextField,
            AppCol,
            ValidationObserver,
            gRecaptcha
        },

        data: () => ({
            siteKey: APP_SITE_KEY,

            email: '',
            submitButtonLoading: false
        }),

        methods: {
            onSubmit(token) {
                this.$log.debug('[RECAPTCHA] Submitting..', token);

                this.submitButtonLoading = true;

                this.$refs.formRecoveryUserEmail
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$log.debug('[RECAPTCHA] Submit successful', token);

                            if (token) {
                                this.submitEmail(token);
                            } else {
                                EventBus.$emit('snackbar:show:danger', this.$t('error.general_error'));
                            }
                        } else {
                            this.$log.debug('[RECAPTCHA] Submit failed');

                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.$log.debug('[RECAPTCHA] Submit failed');

                        this.submitButtonLoading = false;
                    });
            },
            submitEmail(token) {
                this.$store
                    .dispatch('user_passwordRecovery/request', {
                        email: this.email,
                        token
                    })
                    .then(() => {
                        this.$emit('process:next');
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
