<template>
    <div>
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.property_sale.desc') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.property_sale.desc')"
            v-model="details['Popis prodaného majetku']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.property_sale.date') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.property_sale.date')"
            v-model="details['Datum prodeje']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.property_sale.amount') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.property_sale.amount')"
            v-model="details['Přijatá hodnota (cena prodeje)']"
            rules="required"
            :readonly="isAdmin"
        />
        <app-text-field
            :label="isAdmin ? $t('bo.aml.purposesSpecific.property_sale.buyerInfo') : ''"
            :placeholder="$t('bo.aml.purposesSpecific.property_sale.buyerInfo')"
            v-model="details['Informace o kupujícím']"
            rules="required"
            :readonly="isAdmin"
        />
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'PropertySale',

        components: {
            AppTextField
        },

        props: {
            details: Object
        },

        computed: {
            isAdmin() {
                return this.$store.getters['auth/token:isAdmin'];
            }
        },

        mounted() {
            for (const key in this.details) {
                delete this.details[key];
            }

            this.$set(this.details, 'Popis prodaného majetku', '');
            this.$set(this.details, 'Datum prodeje', '');
            this.$set(this.details, 'Přijatá hodnota (cena prodeje)', '');
            this.$set(this.details, 'Informace o kupujícím', '');
        }
    };
</script>

<style scoped></style>
