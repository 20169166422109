<template>
    <div class="account-tab pa-8">
        <validation-observer ref="formAdminUserProfileDocuments" v-slot="{ invalid }">
            <v-row>
                <v-col cols="12" sm="8" md="12" lg="8" xl="6" class="pr-4">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('documentAccount.title') }}
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-select-field
                                :label="$t('admin.digitizationDialog.document.type')"
                                :items="documentTypes"
                                v-model="userEdit.identityDocumentInfo.type"
                                rules="required"
                                item-text="name"
                                item-value="value"
                            ></app-select-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('documentAccount.number')"
                                v-model="userEdit.identityDocumentInfo.documentNumber"
                                hide-hint
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-date-picker
                                :label="$t('admin.digitizationDialog.document.dateOfIssue')"
                                :menu.sync="calendar.dateOfIssue"
                                rules="required"
                                v-model="userEdit.identityDocumentInfo.dateOfIssue"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-date-picker
                                :label="$t('admin.digitizationDialog.document.dateOfExpiry')"
                                :menu.sync="calendar.dateOfExpiry"
                                rules="required"
                                v-model="userEdit.identityDocumentInfo.dateOfExpiry"
                            />
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('documentAccount.issuedBy')"
                                hide-hintinvalid
                                v-model="userEdit.identityDocumentInfo.authority"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-country-select
                                :label="$t('admin.digitizationDialog.document.authorityCountry')"
                                v-model="userEdit.identityDocumentInfo.authorityCountry"
                                rules="required"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div
                        class="d-flex flex-row flex-wrap full-width"
                        :class="{ 'place-content-around': $vuetify.breakpoint.xs }"
                    >
                        <button-primary :disabled="invalid" :loading="submitButtonLoading" @click="submit">
                            {{ $t('bo.account.userDetails.saveChanges') }}
                        </button-primary>
                        <button-secondary class="ml-2" :loading="submitButtonLoading" @click="reset">
                            {{ $t('bo.account.userDetails.reset') }}
                        </button-secondary>
                    </div>
                </v-col>
            </v-row>
        </validation-observer>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import AppCountrySelect from '@/components/ui/form/VtAppCountrySelect';
    import { DTFactory } from '@lb-world/core/public/static/documentTypes';
    import AppDatePicker from '@/components/ui/form/VtAppDatePicker';

    import { ValidationObserver } from 'vee-validate';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'InfoDocumentTab',

        components: {
            AppTextField,
            AppDatePicker,
            AppSelectField,
            AppCountrySelect,
            ValidationObserver
        },
        props: ['user'],

        data: () => ({
            documentTypes: DTFactory.get(),
            userEdit: {
                personalInfo: {
                    countryOfBirth: '',
                    dateOfBirth: '',
                    nationality: '',
                    placeOfBirth: '',
                    degree: '',
                    name: '',
                    surname: '',
                    sex: ''
                },
                identityDocumentInfo: {
                    authority: '',
                    authorityCountry: '',
                    dateOfExpiry: '',
                    dateOfIssue: '',
                    documentNumber: '',
                    type: ''
                }
            },
            calendar: {
                dateOfIssue: false,
                dateOfExpiry: false
            },

            submitButtonLoading: false
        }),
        methods: {
            fillData() {
                this.$set(
                    this.userEdit,
                    'identityDocumentInfo',
                    JSON.parse(JSON.stringify(this.user.identityDocumentInfo || {}))
                );
                this.$set(this.userEdit, 'personalInfo', JSON.parse(JSON.stringify(this.user.personalInfo || {})));
            },
            reset() {
                this.fillData();
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formAdminUserProfileDocuments
                    .validate()
                    .then(result => {
                        if (result) {
                            this.$store
                                .dispatch('user/update', { user: this.userEdit })
                                .then(() => {
                                    this.$emit('user:update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped lang="scss"></style>
