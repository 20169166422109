import Vue from 'vue';

import { API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import StorageRepository from '@lb-world/core/public/api/repositories/StorageRepository';

export const DocumentSet = {
    STORAGE_FILE: {
        uploadUrl: 'com/storage/files/upload-url',
        dispatchUpload: 'admin_storage/file:confirmUpload'
    }
};

const state = {
    storage: new BaseDataset()
};

const getters = {
    storage: state => state.storage.data,
    storageFetched: state => state.storage.fetched,
    storageError: state => state.storage.fetchError,

    categories: state => state.storage.data?.categories ?? []
};

const actions = {
    'storage:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'storage');

            resolve();
        });
    },
    'storage:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.get()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'storage',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'storage');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'storage');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'storage:update': (_, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.update(data)
                .then(response => {
                    if (response.data) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'category:create': ({ commit }, category) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.createCategory(category)
                .then(response => {
                    if (response.data) {
                        const fn = function(category) {
                            if (this.categories) {
                                this.categories.push(category);
                            }
                        };

                        commit('storeItem', { data: response.data, fn });

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'category:remove': ({ commit }, categoryId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.removeCategory(categoryId)
                .then(response => {
                    if (response.data) {
                        const fn = function(data) {
                            if (this.categories) {
                                const categoryIndex = this.categories.findIndex(c => c.id === data);

                                this.categories.splice(categoryIndex, 1);
                            }
                        };

                        commit('remove', { data: categoryId, fn });

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'file:confirmUpload': ({ commit }, file) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.confirmFileUpload(file)
                .then(response => {
                    if (response.data) {
                        const fn = function(file) {
                            if (this.categories && file.categoryId) {
                                const categoryIndex = this.categories.findIndex(c => c.id === file.categoryId);

                                if (categoryIndex !== undefined) {
                                    this.categories[categoryIndex]?.files?.push(file);
                                }
                            }
                        };

                        commit('storeItem', { data: response.data, fn });

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'file:remove': ({ commit }, fileId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            StorageRepository.removeFile(fileId)
                .then(response => {
                    if (response.data) {
                        const fn = function(data) {
                            if (this.categories) {
                                for (const index in this.categories) {
                                    const fileIndex = this.categories[index]?.files?.findIndex(f => f.id === data);

                                    if (fileIndex !== undefined) {
                                        this.categories[index].files.splice(fileIndex, 1);
                                    }
                                }
                            }
                        };

                        commit('remove', { data: fileId, fn });

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    'file:download': (_, fileId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            Vue.$log.debug('[ACTION] UserID is defined', fileId);

            StorageRepository.downloadFile(fileId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve(response.data.url);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, { key, data }) => {
        state[key].storeData(data);
    },
    storeItem: (state, { data, fn }) => {
        state.storage.storeData(data, fn);
    },
    remove: (state, { data, fn }) => {
        state.storage.removeData(data, fn);
    },
    error: (state, key) => {
        state[key].storeError();
    },
    clear: (state, key) => {
        state[key].clearData();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
