<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('admin.digitizationDialog.' + type + 'Title')" :dialog="dialog" />

            <app-dialog-tabs centered :i18n-prefix="tabs.i18nPrefix" :tabs="tabs" :current-tab-index="tabIndex">
            </app-dialog-tabs>

            <component
                :is="currentTab"
                :user="identifiedUser"
                :user-id="user.id"
                :type="type"
                class="py-6"
                :admin-verification="adminVerification"
                @process:next="tabIndex += 1"
                @process:prev="tabIndex -= 1"
                @process:finished="dialog.open = false"
                @process:reset="tabIndex = 0"
            >
            </component>
        </app-card>
    </v-dialog>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    import AppTextField from '@/components/ui/form/AppTextField';
    import AppSelectField from '@/components/ui/form/AppSelectField';
    import AppDialogTabs from '@/components/ui/tabs/AppDialogTabs';

    import DigitizationUserDetails from '@/components/ui/dialogs/digitizationDialog/DigitizationUserDetails';
    import DigitizationUserDocument from '@/components/ui/dialogs/digitizationDialog/DigitizationUserDocument';
    import DigitizationUserAddress from '@/components/ui/dialogs/digitizationDialog/DigitizationUserAddress';
    import DigitizationUserBankAccount from '@/components/ui/dialogs/digitizationDialog/DigitizationUserBankAccount';

    export default {
        name: 'UserDigitizationDialog',

        props: {
            dialog: {
                type: Object,
                required: true
            },
            user: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                validator: v => ['digitization', 'identification'].includes(v),
                default: 'digitization'
            },
            bankAccount: Boolean,
            adminVerification: Boolean
        },

        components: {
            AppDialogTabs,
            AppSelectField,
            AppTextField,
            ValidationObserver,

            userDetails: DigitizationUserDetails,
            userAddress: DigitizationUserAddress,
            userDocument: DigitizationUserDocument,
            userBankAccount: DigitizationUserBankAccount
        },

        data: () => ({
            tabIndex: 0,
            identifiedUser: {}
        }),

        computed: {
            tabs() {
                return this.bankAccount
                    ? this.$store.getters['tabs/user:documents:identify']
                    : this.$store.getters['tabs/admin:documents:identify'];
            },
            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            }
        },

        methods: {
            digitizedByUser(userParsed) {
                if (this.user.allDataDigitized && !this.user.dataVerified) {
                    this.$set(this.identifiedUser, 'personalInfo', userParsed.personalInfo);

                    this.$set(this.identifiedUser, 'address', userParsed.address);

                    this.$set(this.identifiedUser, 'identityDocumentInfo', userParsed.identityDocumentInfo);

                    return true;
                }

                return false;
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.tabIndex = 0;

                        if (!this.user) {
                            return;
                        }

                        const userParsed = JSON.parse(JSON.stringify(this.user));

                        if (!this.digitizedByUser(userParsed)) {
                            this.identifiedUser.personalInfo = {
                                name: '',
                                surname: '',
                                degree: '',
                                sex: '',
                                dateOfBirth: '',
                                personalNumber: '',
                                placeOfBirth: '',
                                countryOfBirth: '',
                                nationality: ''
                            };

                            if (userParsed.name) {
                                this.$set(this.identifiedUser.personalInfo, 'name', userParsed.name);
                            } else {
                                this.$set(this.identifiedUser.personalInfo, 'name', userParsed.personalInfo?.name);
                            }

                            if (userParsed.surname) {
                                this.$set(this.identifiedUser.personalInfo, 'surname', userParsed.surname);
                            } else {
                                this.$set(
                                    this.identifiedUser.personalInfo,
                                    'surname',
                                    userParsed.personalInfo?.surname
                                );
                            }

                            this.$set(this.identifiedUser, 'phone', userParsed.phone);

                            this.$set(this.identifiedUser, 'address', userParsed.address);

                            if (!this.adminVerification) {
                                this.$set(this.identifiedUser, 'bankAccount', userParsed.bankAccount);
                            }

                            this.identifiedUser.identityDocumentInfo = {
                                type: '',
                                documentNumber: '',
                                dateOfIssue: '',
                                dateOfExpiry: '',
                                authority: '',
                                authorityCountry: '',
                                politicallyExposedPerson: false,
                                sanctions: false
                            };
                        }
                    }
                }
            }
        }
    };
</script>
