<template>
    <sign-in v-if="!adminFetched" admin :namespace="namespace"></sign-in>

    <router-view v-else />
</template>

<script>
    import SignIn from './SignIn';

    import { LOGIN_NAMESPACE_ADMIN } from '@lb-world/core/public/utils/auth';

    import { adminFetch, checkAccessToken } from '@/middleware/auth';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'AdminSignIn',

        components: { SignIn },

        data: () => ({
            namespace: LOGIN_NAMESPACE_ADMIN
        }),

        computed: {
            adminFetched() {
                return this.$store.getters['admin/account:fetched'];
            }
        },

        methods: {
            checkAccessToken() {
                if (this.adminFetched) {
                    return;
                }

                const fallbackRoute = {
                    path: '/admin',
                    queries: {}
                };
                const route = this.$route.query.redirect ?? {
                    name: 'admin:dashboard'
                };

                this.$log.debug('[SYSTEM] Preparing to checkAccessToken (fallbackRoute, route)', fallbackRoute, route);

                checkAccessToken(this.namespace)
                    .then(() => {
                        adminFetch()
                            .then(() => {
                                this.pushRoute(route);
                            })
                            .catch(error => {
                                if (error) EventBus.$emit('snackbar:danger:show', error);
                            });
                    })
                    .catch(route => {
                        this.pushRoute(route ?? fallbackRoute);
                    });
            },
            pushRoute(route) {
                this.$log.debug(
                    '[SYSTEM] Pushing route (from, to)',
                    this.$route?.path,
                    typeof route === 'object' ? route.path : route
                );

                const routePath = typeof route === 'object' ? route.path : route;

                if (this.$route?.path !== routePath) {
                    this.$router.replace(route);
                }
            }
        },

        updated() {
            this.$log.debug('[SYSTEM] AdminSignIn component updated');

            this.checkAccessToken();
        },

        mounted() {
            this.$log.debug('[SYSTEM] AdminSignIn component mounted');

            this.checkAccessToken();
        }
    };
</script>

<style scoped></style>
