<template>
    <div>
        <app-row>
            <app-registration-col whole-screen>
                <v-expansion-panels v-model="panelsCollapsed">
                    <parameters-expansion-panel @update="paramsUpdated" />

                    <rate-expansion-panel />

                    <export-expansion-panel />
                </v-expansion-panels>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import ParametersExpansionPanel from '@/components/module-admin/user/products/trading/ParametersExpansionPanel';
    import RateExpansionPanel from '@/components/module-admin/user/products/trading/RateExpansionPanel';
    import ExportExpansionPanel from '@/components/module-admin/user/products/trading/ExportExpansionPanel';

    export default {
        name: 'TradingMetaOverview',

        components: { ExportExpansionPanel, RateExpansionPanel, ParametersExpansionPanel },

        data: () => ({
            panelsCollapsed: []
        }),

        computed: {
            ...mapGetters('admin_productTrading', ['tradingParametersError', 'tradingParametersFetched'])
        },

        methods: {
            paramsUpdated() {
                this.panelsCollapsed = [];

                this.$emit('update');
            }
        }
    };
</script>

<style lang="scss"></style>
