<template>
    <app-container id="sign-in" fill-height-mobile :shadow="$vuetify.breakpoint.smAndUp">
        <password-recovery-dialog :dialog="passwordRecoveryDialog"></password-recovery-dialog>

        <app-row justify="center">
            <app-sign-col>
                <app-row>
                    <app-sign-col half class="apps-view-col">
                        <div class="apps-view-col__wrapper">
                            <app-slogan-integrations-view></app-slogan-integrations-view>

                            <app-language-picker floating />

                            <p class="app-version">
                                <span>v</span>
                                <span class="app-version__current">{{ appVersion }}</span>
                            </p>
                        </div>
                    </app-sign-col>
                    <app-sign-col half class="form-col">
                        <validation-observer ref="formSignIn">
                            <app-row justify="center">
                                <app-sign-col full>
                                    <div class="form-col__top-background"></div>
                                    <div class="form-col__logo-wrapper">
                                        <app-logo size="xl" :logo-only="$vuetify.breakpoint.smAndDown"> </app-logo>
                                    </div>
                                </app-sign-col>
                            </app-row>

                            <div
                                v-if="!token && tokenFetched"
                                class="align-center"
                                :class="{ 'mt-9': admin, 'mt-3': !admin }"
                            >
                                <app-row justify="center">
                                    <app-sign-col full>
                                        <app-text-field
                                            hide-hint
                                            :rules="{
                                                required: true,
                                                email: !admin
                                            }"
                                            :label="$t(admin ? 'signIn.username' : 'signIn.email')"
                                            v-model="user.email"
                                            @keyup.enter="signIn"
                                        ></app-text-field>
                                    </app-sign-col>
                                </app-row>
                                <app-row justify="center" class="mt-2">
                                    <app-sign-col full>
                                        <app-text-field
                                            hide-hint
                                            rules="required"
                                            type="password"
                                            :label="$t('signIn.password')"
                                            v-model="user.password"
                                            @keyup.enter="signIn"
                                        ></app-text-field>

                                        <button-wrapper
                                            v-if="!admin"
                                            :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'center'"
                                        >
                                            <button-secondary @click="passwordRecoveryDialog.open = true">
                                                {{ $t('signIn.forgotYourPasswordButton') }}
                                            </button-secondary>
                                        </button-wrapper>
                                    </app-sign-col>
                                </app-row>
                                <app-row justify="center">
                                    <app-sign-col full :half="admin && $vuetify.breakpoint.mdAndUp">
                                        <app-switch-field
                                            :centered="admin"
                                            :class="
                                                'justify-'.concat(
                                                    $vuetify.breakpoint.xsOnly || admin ? 'center' : 'start'
                                                )
                                            "
                                            v-model="rememberMe"
                                            class="mt-0"
                                        >
                                            {{ $t('signIn.rememberMe') }}
                                        </app-switch-field>
                                    </app-sign-col>
                                </app-row>
                                <app-row justify="center">
                                    <app-sign-col button>
                                        <button-wrapper
                                            :justify="$vuetify.breakpoint.smAndDown || admin ? 'center' : 'start'"
                                        >
                                            <button-primary @click="signIn" full-width :loading="signInButton.loading">
                                                {{ $t('signIn.signInButton') }}
                                            </button-primary>
                                        </button-wrapper>
                                    </app-sign-col>
                                    <app-sign-col button v-if="!admin">
                                        <button-wrapper
                                            :justify="$vuetify.breakpoint.smAndDown || admin ? 'center' : 'end'"
                                        >
                                            <button-secondary to="/registration" full-width>
                                                {{ $t('signIn.registerNewAccountButton') }}
                                            </button-secondary>
                                        </button-wrapper>
                                    </app-sign-col>
                                </app-row>
                            </div>

                            <div v-else class="form-col__inner">
                                <div>
                                    <app-row justify="center">
                                        <app-sign-col full>
                                            <app-loader vertical>
                                                {{ $t('signIn.loading') }}
                                            </app-loader>
                                        </app-sign-col>
                                    </app-row>

                                    <app-row justify="center" class="mt-6">
                                        <app-sign-col full>
                                            <p class="text-center">
                                                {{ $t('signIn.pleaseWait') }}
                                            </p>
                                        </app-sign-col>
                                    </app-row>
                                </div>
                            </div>
                        </validation-observer>
                    </app-sign-col>
                </app-row>
            </app-sign-col>
        </app-row>
    </app-container>
</template>

<script>
    import AppTextField from '../../components/ui/form/VtAppTextField';
    import AppSwitchField from '../../components/ui/form/AppSwitchField';
    import AppSloganIntegrationsView from '../../components/ui/AppSloganIntegrationsView';
    import AppSignCol from '../../components/ui/layout/VtAppSignCol';
    import PasswordRecoveryDialog from '../../components/ui/dialogs/PasswordRecoveryDialog';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { ValidationObserver } from 'vee-validate';

    import { CURRENT_APP_VERSION } from '@/constants';

    import { setLanguage } from '@lb-world/core/public/locales/engine';

    export default {
        name: 'SignIn',

        components: {
            PasswordRecoveryDialog,
            AppSignCol,
            AppSloganIntegrationsView,
            AppSwitchField,
            AppTextField,
            ValidationObserver
        },

        data: () => ({
            appVersion: CURRENT_APP_VERSION,
            user: {
                email: '',
                password: ''
            },
            rememberMe: false,
            signInButton: {
                loading: false
            },
            passwordRecoveryDialog: {
                open: false
            }
        }),

        props: {
            admin: {
                type: Boolean
            },
            namespace: {
                type: String,
                required: true
            }
        },

        computed: {
            token() {
                return this.$store.getters['auth/token'];
            },
            tokenFetched() {
                return this.$store.getters['auth/token:fetched'];
            }
        },

        methods: {
            signIn({ otp }) {
                return new Promise((resolve, reject) => {
                    this.signInButton.loading = true;

                    const { email, password } = this.user;
                    const { namespace, rememberMe } = this;

                    const continueUrl = this.$route.query.continue;

                    this.$log.debug('[APP] Route', this.$route);

                    this.$refs.formSignIn
                        .validate()
                        .then(result => {
                            if (result) {
                                this.$store
                                    .dispatch('auth/user:login', {
                                        email,
                                        password,
                                        namespace,
                                        continueUrl,
                                        rememberMe,
                                        otp
                                    })
                                    .then(redirect => {
                                        EventBus.$emit('snackbar:show:success', this.$t('success.signedIn'));

                                        const form = document.createElement('form');
                                        document.body.appendChild(form);
                                        form.method = 'post';
                                        form.action = redirect;
                                        form.submit();

                                        resolve();
                                    })
                                    .catch(errorResponse => {
                                        if (errorResponse?.otpRequired) {
                                            EventBus.$emit('mfa:activate', {
                                                mode: 'otp',
                                                callback: this.signIn
                                            });
                                        } else {
                                            EventBus.$emit('snackbar:show:danger', errorResponse?.error);
                                        }

                                        this.signInButton.loading = false;

                                        reject();
                                    });
                            } else {
                                this.signInButton.loading = false;
                            }
                        })
                        .catch(() => {
                            this.signInButton.loading = false;

                            reject();
                        });
                });
            }
        },

        mounted() {
            const routeQuery = this.$route.query;

            if (routeQuery.lang && this.$store.getters['user/account:languages'].find(l => l.iso === routeQuery.lang)) {
                setLanguage(routeQuery.lang);
            }

            if (routeQuery.email) {
                this.$nextTick(() => {
                    this.passwordRecoveryDialog.open = true;
                    this.passwordRecoveryDialog.email = routeQuery.email;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .app-version {
        position: absolute;
        top: 12px;
        left: 16px;

        .app-version__current {
            font-weight: bold;
            color: colors.$color-primary;
        }
    }

    .apps-view-col {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        padding: spacing.$spacing-9;

        .apps-view-col__wrapper {
            margin-top: spacing.$spacing-9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .form-col {
        position: relative;

        padding: spacing.$spacing-9;

        min-height: 475px;

        @include media.resolve('xs-only') {
            box-shadow: -1px -5px 15px -17px rgba(0, 0, 0, 0.75);
        }

        @include media.resolve('sm-and-up') {
            box-shadow: -1px 0 20px -17px rgba(0, 0, 0, 0.75);
        }

        .form-col__top-background {
            position: absolute;
            height: 75px;
            top: 0;
            left: spacing.$spacing-12;
            right: spacing.$spacing-12;
            background-color: colors.$color-primary-3;

            @include media.resolve('sm-and-down') {
                display: none;
            }
        }

        .form-col__logo-wrapper {
            display: flex;
            justify-content: center;
        }

        .form-col__inner {
            height: calc(100% - 70px);
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details {
        display: none;
    }
</style>
