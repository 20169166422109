<template>
    <div class="account-tab pa-8">
        <v-row>
            <v-col cols="12" sm="6" md="12" lg="6" class="pr-4">
                <validation-observer ref="formChangeInfo">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('bo.account.userDetails.title') }}
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" sm="6" offset-sm6 class="ma-0 offset-sm-6 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('admin.digitizationDialog.degree')"
                                v-model="userEdit.personalInfo.degree"
                                optional
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" offset-sm6 class="ma-0 offset-sm-6 mb-n8">
                            <app-select-field
                                readonly
                                v-model="userEdit.personalInfo.sex"
                                :items="sexItems"
                                item-text="name"
                                item-value="value"
                                :label="$t('admin.digitizationDialog.sex')"
                                rules="required"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                hide-hint
                                :label="$t('bo.account.userDetails.name')"
                                placeholder=" "
                                v-model="userEdit.personalInfo.name"
                                rules="required"
                                type="text"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('bo.account.userDetails.surname')"
                                placeholder=" "
                                v-model="userEdit.personalInfo.surname"
                                rules="required"
                                type="text"
                                hide-hint
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n4 mb-n8">
                            <app-text-field
                                readonly
                                :label="$t('bo.account.userDetails.email')"
                                placeholder=" "
                                v-model="userEdit.email"
                                hide-hint
                                type="email"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n4 ">
                            <app-text-field
                                readonly
                                :label="$t('address.phone')"
                                placeholder=" "
                                :value="userEdit.phone.countryCallingCode + ' ' + userEdit.phone.number"
                                hide-hint
                                type="email"
                            ></app-text-field>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-col>
            <v-col cols="12" sm="6" md="12" lg="6" class="pr-4">
                <validation-observer ref="formChangePass" v-slot="{ invalid }">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('bo.account.userDetails.changePassword') }}
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="mb-n8">
                            <app-text-field
                                hide-hint
                                rules="required|min:8"
                                type="password"
                                placeholder=" "
                                v-model="oldPassword"
                                :label="$t('bo.account.changePasswordDialog.password')"
                                @keydown.enter="passChange"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-n8 mt-n3">
                            <app-text-field
                                hide-hint
                                rules="required|min:8"
                                type="password"
                                v-model="newPassword"
                                placeholder=" "
                                :label="$t('bo.account.changePasswordDialog.newPassword')"
                                @keydown.enter="passChange"
                            ></app-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="mt-4 text-center text-sm-left">
                            <vt-button-secondary @click="passChange" :disabled="invalid">
                                {{ $t('bo.account.userDetails.changePasswordBtn') }}
                            </vt-button-secondary>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="6" class="mt-4 mb-2">
                <vt-mfa-widget />
            </v-col>
            <v-col cols="12" lg="6" class="mt-n8 mt-lg-4 mb-2">
                <marketing-consent-option />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import MarketingConsentOption from '@/components/module-user/account/VtMarketingConsentOption';
    import VtMfaWidget from '@/components/module-user/account/VtMfaWidget';
    import { getIntegerNumberRegex } from '@lb-world/core/public/utils/regexp';

    export default {
        name: 'VtInfoBasicTab',

        components: {
            AppTextField,
            AppSelectField,
            VtMfaWidget,
            ValidationObserver,
            MarketingConsentOption
        },

        props: ['user'],

        data: () => ({
            newPassword: '',
            oldPassword: '',
            userEdit: {
                email: '',
                phone: {
                    number: '',
                    countryCallingCode: ''
                },
                personalInfo: {
                    countryOfBirth: '',
                    dateOfBirth: '',
                    nationality: '',
                    placeOfBirth: '',
                    degree: '',
                    name: '',
                    surname: '',
                    sex: ''
                },
                bankAccount: {
                    bankName: '',
                    swift: '',
                    iban: '',
                    accountNumberPrefix: '',
                    accountNumber: '',
                    code: ''
                }
            },
            numberRegexp: getIntegerNumberRegex()
        }),

        methods: {
            countryChanged(country) {
                this.userEdit.phone.countryCallingCode = '+' + country.dialCode;
            },
            passChange() {
                this.$refs.formChangePass.validate().then(result => {
                    if (result) {
                        this.$emit('password:change', { newPassword: this.newPassword, oldPassword: this.oldPassword });
                        this.reset();
                    }
                });
            },
            resetPass() {
                this.newPassword = '';
                this.oldPassword = '';
            },
            fillData() {
                const userParsed = JSON.parse(JSON.stringify(this.user));

                this.$set(this.userEdit, 'personalInfo', userParsed.personalInfo);
                this.$set(this.userEdit, 'phone', userParsed.phone);
                this.$set(this.userEdit, 'email', userParsed.email);
            }
        },

        computed: {
            countryDial() {
                return this.userEdit.phone.countryCallingCode?.length > 0
                    ? this.userEdit.phone.countryCallingCode
                    : this.user.phone.countryCallingCode;
            },
            sexItems() {
                return [
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.male'),
                        value: 'M'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.female'),
                        value: 'F'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.xGender'),
                        value: 'X'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.oGender'),
                        value: 'O'
                    }
                ];
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped lang="scss"></style>
