<template>
    <div>
        <document-upload-dialog :dialog="uploadDocumentDialog" @document:uploaded="documentUploaded" />

        <app-title>{{ $t('admin.users.user.documents.systemDocuments') }}</app-title>

        <table-divider />

        <app-row>
            <app-registration-col whole-screen>
                <admin-user-detail-system-documents-table
                    :loading="!documentsFetched"
                    :items="documents"
                    :fetch-error="documentsError"
                    @document:upload="uploadDocument"
                />
            </app-registration-col>
        </app-row>

        <app-divider />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import { DocumentSet } from '@/store/admin/documents';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import TableDivider from '@lb-world/core/public/components/tables/TableDivider';
    import AdminUserDetailSystemDocumentsTable from '@/components/ui/tables/AdminUserDetailSystemDocumentsTable';
    import DocumentUploadDialog from '@/components/ui/dialogs/DocumentUploadDialog';

    export default {
        name: 'SystemDocumentsOverview',

        components: {
            DocumentUploadDialog,
            AdminUserDetailSystemDocumentsTable,
            TableDivider
        },

        computed: {
            commissionAccountFetched() {
                return this.$store.getters['admin_commissionAccount/accountDataFetched'];
            },
            userId() {
                return this.$store.getters['admin_userDetail/id'];
            },
            token() {
                return this.$store.getters['auth/token'];
            },
            ...mapGetters('admin_userDocuments', {
                documents: 'systemDocuments',
                documentsFetched: 'systemDocumentsFetched',
                documentsError: 'systemDocumentsError'
            })
        },

        data: () => ({
            uploadDocumentDialog: {
                documentKey: null,
                open: false
            }
        }),

        methods: {
            documentUploaded(documentKey) {
                this.fetchSystemDocuments();

                this.$emit('document:upload', documentKey);
            },
            uploadDocument(documentId) {
                this.uploadDocumentDialog.documentSet = DocumentSet.SYSTEM;
                this.uploadDocumentDialog.uploadBody = { type: documentId, userId: this.userId };
                this.uploadDocumentDialog.confirmBody = { type: documentId, userId: this.userId };
                this.uploadDocumentDialog.token = this.token;
                this.uploadDocumentDialog.open = true;
            },
            fetchSystemDocuments() {
                this.$store.dispatch('admin_userDocuments/fetch', DocumentSet.SYSTEM);
            },
            fetchUserCommissionAccount() {
                this.$store.dispatch('admin_commissionAccount/fetch').catch(error => {
                    EventBus.$emit('snackbar:show:danger', error);
                });
            }
        },

        mounted() {
            this.fetchSystemDocuments();

            // Fetching commission account state to determine
            // if possible to upload 'Agency agreement'.
            if (!this.commissionAccountFetched) {
                this.fetchUserCommissionAccount();
            }
        }
    };
</script>

<style scoped></style>
