<template>
    <v-col fill-height class="pa-4">
        <div class="vt-card" :class="{ 'px-8': !fullWidth && !full, 'py-8': !fullHeight && !full, 'pa-0': full }">
            <slot></slot></div
    ></v-col>
</template>

<script>
    export default {
        name: 'VtCard',
        props: {
            fullWidth: Boolean,
            fullHeight: Boolean,
            full: Boolean
        }
    };
</script>

<style scoped lang="scss"></style>
