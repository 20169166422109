<template>
    <div>
        <products-trading-dialog :dialog="tradingDialog" @trade="fetchProductItems" />

        <trading-meta-overview @update="fetchProductItems" />

        <app-row>
            <app-section-navigation-col bigger-col>
                <app-card>
                    <app-title>{{ $t('admin.products.exchange.common.demand') }}</app-title>

                    <app-divider />

                    <admin-product-exchange-demand-table
                        :items="demandItems"
                        :loading="!tradeItemsFetched"
                        :fetch-error="tradeItemsError"
                        @tradingDialog:open="openTradingDialog"
                    />
                </app-card>
            </app-section-navigation-col>

            <app-section-navigation-col smaller-col class="pl-md-0">
                <app-card>
                    <app-title>{{ $t('admin.products.exchange.common.supply') }}</app-title>

                    <app-divider />

                    <admin-product-exchange-supply-table
                        :items="supplyItems"
                        :loading="!tradeItemsFetched"
                        :fetch-error="tradeItemsError"
                    />
                </app-card>
            </app-section-navigation-col>
        </app-row>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AppSectionNavigationCol from '@/components/ui/layout/AppSectionNavigationCol';
    import AdminProductExchangeDemandTable from '@/components/ui/tables/AdminProductExchangeDemandTable';
    import AdminProductExchangeSupplyTable from '@/components/ui/tables/AdminProductExchangeSupplyTable';
    import TradingMetaOverview from '@/components/module-admin/user/products/trading/TradingMetaOverview';
    import ProductsTradingDialog from '@/components/module-admin/user/products/trading/ProductsTradingDialog';

    export default {
        name: 'ProductsTrading',

        components: {
            ProductsTradingDialog,
            TradingMetaOverview,
            AdminProductExchangeSupplyTable,
            AdminProductExchangeDemandTable,
            AppSectionNavigationCol
        },

        data: () => ({
            tradingDialog: {
                open: false
            }
        }),

        computed: {
            ...mapGetters('admin_productTrading', [
                'tradingParametersFetched',
                'tradeItemsFetched',
                'tradeItemsError',
                'demandItems',
                'supplyItems',
                'tradingRate'
            ]),

            getRateFormat() {
                return `${this.tradingRate.rate} ${this.tradingRate.quoteCurrency}/${this.tradingRate.baseCurrency}`;
            }
        },

        methods: {
            openTradingDialog(item) {
                this.$set(this.tradingDialog, 'demandItem', item);
                this.$set(this.tradingDialog, 'supplyItems', this.supplyItems);
                this.tradingDialog.tradingRateFormat = this.getRateFormat;
                this.tradingDialog.open = true;
            },
            fetchProductItems() {
                this.$store.dispatch('admin_productTrading/items:clear').then(() => {
                    this.$store.dispatch('admin_productTrading/items:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });
            },
            fetchProductsParameters() {
                this.$store.dispatch('admin_productTrading/parameters:clear').then(() => {
                    this.$store
                        .dispatch('admin_productTrading/parameters:fetch')
                        .then(() => {
                            this.fetchProductItems();
                        })
                        .catch(error => {
                            this.$store.dispatch('admin_productTrading/items:error');

                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        mounted() {
            if (!this.tradingParametersFetched) {
                this.fetchProductsParameters();
            }
        }
    };
</script>

<style scoped></style>
