import axios from 'axios';

import Vue from 'vue';

import { parseErrorMessageFromAPI, API_SERVER_ERROR, API_INTERNAL_ERROR } from '@/utils';

const state = {
    companyDigitization: {
        fetched: false,
        fetchError: false,
        data: []
    }
};

const getters = {
    companyData: state => state.companyDigitization.data,
    companyDataFetched: state => state.companyDigitization.fetched,
    companyDataError: state => state.companyDigitization.fetchError,
    companyDigitized: state => state.companyDigitization.data?.company
};

const actions = {
    fetch: ({ rootGetters, commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const userId = rootGetters['admin_userDetail/id'];

        return new Promise((resolve, reject) => {
            if (userId) {
                axios
                    .get(`lbw/users/${userId}/legal`)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        const companyDigitizationData = response.data;

                        if (companyDigitizationData) {
                            commit('save', companyDigitizationData);

                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        if (error.status === 404) {
                            Vue.$log.error('[ACTION] Company digitization is not available for this user');

                            commit('save', {});
                        } else {
                            commit('error');

                            reject(parseErrorMessageFromAPI(error));
                        }
                    });
            } else {
                Vue.$log.debug('[ACTION] UserId is not defined');

                commit('error');

                reject(API_INTERNAL_ERROR);
            }
        });
    },
    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear');

            resolve();
        });
    },
    digitize: ({ rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const userId = rootGetters['admin_userDetail/id'];

        return new Promise((resolve, reject) => {
            if (userId) {
                axios
                    .patch(`lbw/users/${userId}/legal`, data)
                    .then(response => {
                        Vue.$log.debug('[ACTION] Received response', response.data);

                        if (response.data) {
                            resolve();
                        } else {
                            reject(API_SERVER_ERROR);
                        }
                    })
                    .catch(error => {
                        Vue.$log.error('[ACTION] Received error', error);

                        reject(parseErrorMessageFromAPI(error));
                    });
            } else {
                Vue.$log.debug('[ACTION] UserId is not defined');

                reject(API_INTERNAL_ERROR);
            }
        });
    }
};

const mutations = {
    save: (state, company) => {
        Vue.$log.debug('[MUTATION] Running mutation', company);

        state.companyDigitization.fetched = true;
        state.companyDigitization.fetchError = false;
        state.companyDigitization.data = company;
    },
    clear: state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.companyDigitization.fetched = false;
        state.companyDigitization.fetchError = false;
        state.companyDigitization.data = {};
    },
    error: state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.companyDigitization.fetched = true;
        state.companyDigitization.fetchError = true;
        state.companyDigitization.data = {};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
