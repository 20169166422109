var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"app-table",attrs:{"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"color",rawName:"v-color:[getDocumentTextColor(item)]",arg:_vm.getDocumentTextColor(item)}]},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.documentState.' + (item.id !== null ? 'uploaded' : 'notUploaded')))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('documentTypes.' + item.name + '.name'))+" ")]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.id !== null)?_c('button-secondary',{attrs:{"icon":"eye","inline":""},on:{"click":function($event){return _vm.downloadSystemDocument(item.id)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.display'))+" ")]):_vm._e()]):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id === null)?_c('div',[_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.isDocumentRestrictionActive(item.name)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('button-primary',{attrs:{"icon-set":"fas","icon":_vm.isDocumentRestrictionActive(item.name) ? 'question-circle' : '',"disabled":_vm.isDocumentRestrictionActive(item.name),"small":""},on:{"click":function($event){return _vm.uploadDocument(item)}}},[_vm._v(" "+_vm._s(_vm.$t('admin.users.user.documents.upload'))+" ")])],1)]}}],null,true)},[(_vm.isDocumentRestricted(item.name))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.isDocumentRestricted(item.name).reason))+" ")]):_vm._e()])],1):_c('span',{directives:[{name:"color",rawName:"v-color:black.translucent",arg:"black",modifiers:{"translucent":true}}]},[_vm._v(" "+_vm._s(_vm.$t('common.none'))+" ")])]}},{key:"loading",fn:function(){return [_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.loadingData'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [(!_vm.fetchError)?_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")]):_c('p',{staticClass:"v-data-table--info"},[_vm._v(" "+_vm._s(_vm.$t('table.fetchError'))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }