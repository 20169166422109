<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000">
        <app-card dialog button-switch>
            <app-dialog-title :title="$t('bo.mfaDialog.title')" :dialog="dialog" />

            <app-dialog-tabs
                v-if="!mfaActive && signedIn"
                centered
                :i18n-prefix="tabs.i18nPrefix"
                :tabs="tabs"
                :mode="mode"
                :current-tab-index="tabIndex"
            />

            <div v-if="!mfaActive && signedIn">
                <component
                    class="py-6"
                    :is="currentTab"
                    :mfa-active="mfaActive"
                    :signed-in="signedIn"
                    :secret-generator="secretGenerator"
                    :loading="buttonLoading"
                    :activated="activated"
                    @process:next="tabIndex += 1"
                    @process:prev="tabIndex -= 1"
                    @process:finished="onOtpEntered"
                />
            </div>

            <step-otp
                v-else
                :mfa-active="mfaActive"
                :signed-in="signedIn"
                :loading="buttonLoading"
                :activated="activated"
                @process:finished="onOtpEntered"
            />
        </app-card>
    </v-dialog>
</template>

<script>
    import StepScan from '@/components/module-user/account/mfaDialog/StepScan';
    import StepOtp from '@/components/module-user/account/mfaDialog/StepOtp';
    import AppDialogTabs from '@/components/ui/tabs/VtAppDialogTabs';
    import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

    export default {
        name: 'MfaDialog',

        props: {
            dialog: Object,
            callback: Function,
            userId: String,

            mode: {
                type: String,
                validator: v => ['enable', 'disable', 'otp'].includes(v)
            }
        },

        components: {
            AppDialogTabs,

            stepScan: StepScan,
            stepOtp: StepOtp
        },

        data: () => ({
            tabIndex: 0,
            buttonLoading: false,

            activated: false,

            secretGenerator: new BaseDataset(),

            tabs: {
                type: 'steps',
                i18nPrefix: 'bo.mfaDialog.menu',
                data: [
                    {
                        id: 1,
                        name: 'stepScan'
                    },
                    {
                        id: 2,
                        name: 'stepOtp'
                    }
                ]
            }
        }),

        computed: {
            mfaActive() {
                return this.$store.getters['user/user:mfaActive'];
            },

            signedIn() {
                return this.$store.getters['auth/token'];
            },

            currentTab() {
                return this.tabs.data[this.tabIndex].name;
            }
        },

        methods: {
            onOtpEntered(otp) {
                this.buttonLoading = true;

                this.dialog
                    ?.callback({ otp, mode: this.mode })
                    .then(() => {
                        this.dialog.open = false;
                    })
                    .catch(() => {
                        this.activated = !this.activated;
                    })
                    .finally(() => {
                        this.buttonLoading = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    this.activated = !this.activated;

                    if (opened && this.tabIndex !== 0) {
                        this.tabIndex = 0;
                    }

                    if (opened && !this.mfaActive && this.signedIn && !this.secretGenerator.fetched) {
                        setTimeout(() => {
                            this.$store
                                .dispatch('mfa/generateSecret')
                                .then(secret => {
                                    this.secretGenerator.storeData(secret);
                                })
                                .catch(() => {
                                    this.secretGenerator.storeError();
                                });
                        }, 1000);
                    } else if (!opened) {
                        this.secretGenerator.clearData();
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
