<template>
    <app-card>
        <app-row justify="center">
            <app-col class="d-flex justify-center pb-0">
                <font-awesome-icon
                    v-color:success
                    size="2x"
                    class="mr-2"
                    :icon="['fas', 'check-circle']"
                ></font-awesome-icon>
            </app-col>
        </app-row>

        <app-row justify="center">
            <app-col>
                <p class="section-title">
                    <span>{{ $t('signIn.passwordRecoveryDialog.verificationCode.title') }}</span>
                </p>
            </app-col>
        </app-row>
        <app-row justify="center">
            <app-col full-space>
                <p>
                    {{ $t('signIn.passwordRecoveryDialog.verificationCode.labelRecoverySent') }}
                </p>
                <p>
                    {{ $t('signIn.passwordRecoveryDialog.verificationCode.labelContinueByInstructions') }}
                </p>
            </app-col>
        </app-row>
        <app-digits-box class="mt-3" v-slot="{ eraseCode, code, numberOfInputs }">
            <app-row justify="center">
                <app-col buttons>
                    <button-secondary icon="eraser" @click="eraseCode">
                        {{ $t('signIn.passwordRecoveryDialog.verificationCode.clearCode') }}
                    </button-secondary>
                </app-col>
                <app-col buttons>
                    <button-primary
                        @click="submitCode(code)"
                        :loading="submitButton.loading"
                        :disabled="code.length < numberOfInputs"
                    >
                        {{ $t('signIn.passwordRecoveryDialog.verificationCode.continue') }}
                    </button-primary>
                </app-col>
            </app-row>
        </app-digits-box>
    </app-card>
</template>

<script>
    import AppCol from '../../layout/AppCol';
    import AppDigitsBox from '../../form/VtAppDigitsBox';

    export default {
        name: 'PasswordRecoveryLink',

        components: {
            AppDigitsBox,
            AppCol
        },

        data: () => ({
            submitButton: {
                loading: false
            }
        }),

        methods: {
            submitCode(code) {
                this.submitButton.loading = true;

                this.$store
                    .dispatch('user_passwordRecovery/store:code', code)
                    .then(() => {
                        this.$emit('process:next');
                    })
                    .finally(() => {
                        this.submitButton.loading = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
