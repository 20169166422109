<template>
    <validation-observer ref="formCurrencyWithdrawalDialogCZK">
        <app-row justify="center" v-if="bankAccount">
            <app-registration-col>
                <app-card text-white increased-shadow narrow v-color:world>
                    <app-title text-white justify="center">{{ bankAccount.getBankAccount() }}</app-title>

                    <app-row justify="center">
                        <app-registration-col>
                            <p style="color: #f0f0f0">{{ bankAccount.getBankName() }}</p>
                            <p style="color: #f0f0f0">{{ bankAccount.getBankIBAN() }}</p>
                            <p style="color: #f0f0f0">{{ bankAccount.getBankSwift() }}</p>
                        </app-registration-col>
                    </app-row>
                </app-card>
            </app-registration-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'WithdrawalCZK',

        components: { ValidationObserver },

        props: ['bankAccount'],

        data: () => ({
            withdrawal: {
                amount: ''
            }
        }),

        methods: {
            resetValues() {}
        }
    };
</script>

<style scoped></style>
