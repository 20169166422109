<template>
    <v-container id="sign-in-view" fill-height fluid class="d-flex flex-column flex-sm-row">
        <video autoplay muted loop class="bg-video">
            <source src="@/assets/lb-bg.mp4" type="video/mp4" />
        </video>
        <div class="sign-in-view"></div>

        <password-recovery-dialog :dialog="passwordRecoveryDialog"></password-recovery-dialog>

        <v-flex class="z-index1" v-if="$vuetify.breakpoint.mdAndUp">
            <div class="d-flex flex-column justify-center">
                <vt-signup-left-card id="world" class="mb-10 pointer" :small="false" :checked="true">
                    <img height="60" src="@/assets/logos/lb-world.svg" />
                </vt-signup-left-card>
                <vt-signup-left-card id="broker" :small="true" :checked="false">
                    <img height="40" src="@/assets/logos/lb-broker.svg" />
                </vt-signup-left-card>
                <vt-signup-left-card id="finance" :small="true" :checked="false">
                    <img height="40" src="@/assets/logos/lb-finance.svg" />
                </vt-signup-left-card>
                <vt-signup-left-card id="technology" :small="true" :checked="false">
                    <img height="40" src="@/assets/logos/lb-technology.svg" />
                </vt-signup-left-card>
                <vt-signup-left-card id="incubator" :small="true" :checked="false">
                    <img height="40" src="@/assets/logos/lb-incubator.svg" />
                </vt-signup-left-card>
            </div>
        </v-flex>
        <v-flex class="z-index1">
            <div class="signin-panel d-flex justify-center pa-4 pb-8 pa-sm-14">
                <div class="d-flex flex-column full-width align-items-center">
                    <v-flex class="d-flex flex-row full-width mb-8">
                        <v-flex class="app-version-span"> V {{ appVersion }}</v-flex>
                        <v-flex class="text-right">
                            <div class="float-right">
                                <vt-app-menu v-if="$vuetify.breakpoint.smAndDown"></vt-app-menu>
                                <vt-lang-menu />
                            </div>
                        </v-flex>
                    </v-flex>
                    <v-flex class="ma-auto ma-md-0 align-self-start">
                        <div>
                            <v-flex class="d-flex flex-column flex-md-row">
                                <v-flex class="mr-sm-6">
                                    <div class="sign-in-title">
                                        {{ $t('signIn.title') }}
                                    </div>
                                </v-flex>
                                <v-flex>
                                    <img height="60" src="@/assets/logos/lb-world-black.svg" />
                                </v-flex>
                            </v-flex>
                        </div>
                    </v-flex>
                    <v-flex class="mb-10 ml-auto ml-md-0 mr-auto">
                        <div class="sign-in-down-title">
                            {{ $t('signIn.downTitle') }}
                        </div>
                    </v-flex>
                    <v-flex class="full-width align-self-start">
                        <validation-observer ref="formSignIn">
                            <div>
                                <v-row>
                                    <v-col cols="12" class="mb-n10">
                                        <app-text-field
                                            hide-hint
                                            :rules="{
                                                required: true,
                                                email: !admin
                                            }"
                                            :label="$t(admin ? 'signIn.username' : 'signIn.email')"
                                            :placeholder="$t(admin ? 'signIn.enterUsername' : 'signIn.enterEmail')"
                                            v-model="user.email"
                                            @keyup.enter="signIn"
                                        ></app-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <app-text-field
                                            hide-hint
                                            rules="required"
                                            type="password"
                                            :label="$t('signIn.password')"
                                            :placeholder="$t('signIn.enterPassword')"
                                            v-model="user.password"
                                            @keyup.enter="signIn"
                                        ></app-text-field>
                                    </v-col>
                                    <v-col cols="12" class="mt-n8 py-0">
                                        <v-row>
                                            <v-col cols="6" class="ma-0">
                                                <div class="width-140px">
                                                    <vt-checkbox v-model="rememberMe">
                                                        {{ $t('signIn.rememberMe') }}
                                                    </vt-checkbox>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" class="text-right mb-1 align-self-center">
                                                <a
                                                    v-if="!admin"
                                                    href="#"
                                                    @click.stop.prevent="passwordRecoveryDialog.open = true"
                                                >
                                                    {{ $t('signIn.forgotYourPasswordButton') }}
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" class="py-0 mt-8">
                                        <button-primary @click="signIn" full-width :loading="signInButton.loading">{{
                                            $t('signIn.signInButton')
                                        }}</button-primary>
                                    </v-col>
                                    <v-col cols="12" class="py-0 mt-3">
                                        <vt-button-secondary to="/registration" fullWidth>{{
                                            $t('signIn.registerNewAccountButton')
                                        }}</vt-button-secondary>
                                    </v-col>
                                </v-row>
                            </div>
                        </validation-observer>
                    </v-flex>
                </div>
            </div>
        </v-flex>
    </v-container>
</template>

<script>
    import VtCheckbox from '../../components/ui/form/VtCheckbox';
    import VtSignupLeftCard from '../../components/ui/cards/VtSignupLeftCard';
    import AppTextField from '../../components/ui/form/VtAppTextField';
    import VtLangMenu from '../../components/ui/menu/VtLangMenu';
    import VtAppMenu from '../../components/ui/menu/VtAppMenu';

    import PasswordRecoveryDialog from '../../components/ui/dialogs/PasswordRecoveryDialog';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { ValidationObserver } from 'vee-validate';

    import { CURRENT_APP_VERSION } from '@/constants';

    import { getAppUrlFormat } from '@/utils';

    import { setLanguage } from '@lb-world/core/public/locales/engine';

    export default {
        name: 'VtSignIn',

        components: {
            VtCheckbox,
            VtSignupLeftCard,
            VtLangMenu,
            VtAppMenu,
            PasswordRecoveryDialog,
            AppTextField,
            ValidationObserver
        },

        data: () => ({
            appVersion: CURRENT_APP_VERSION,
            user: {
                email: '',
                password: ''
            },
            rememberMe: false,
            signInButton: {
                loading: false
            },
            passwordRecoveryDialog: {
                open: false
            }
        }),

        props: {
            admin: {
                type: Boolean
            },
            namespace: {
                type: String,
                required: true
            }
        },

        computed: {
            token() {
                return this.$store.getters['auth/token'];
            },
            tokenFetched() {
                return this.$store.getters['auth/token:fetched'];
            }
        },

        methods: {
            redirectToApp(app) {
                window.location = getAppUrlFormat(app);
            },
            signIn({ otp }) {
                return new Promise((resolve, reject) => {
                    this.signInButton.loading = true;

                    const { email, password } = this.user;
                    const { namespace, rememberMe } = this;

                    const continueUrl = this.$route.query.continue;

                    this.$log.debug('[APP] Route', this.$route);

                    this.$refs.formSignIn
                        .validate()
                        .then(result => {
                            if (result) {
                                this.$store
                                    .dispatch('auth/user:login', {
                                        email,
                                        password,
                                        namespace,
                                        continueUrl,
                                        rememberMe,
                                        otp
                                    })
                                    .then(redirect => {
                                        EventBus.$emit('snackbar:show:success', this.$t('success.signedIn'));

                                        const form = document.createElement('form');
                                        document.body.appendChild(form);
                                        form.method = 'post';
                                        form.action = redirect;
                                        form.submit();
                                        resolve();
                                    })
                                    .catch(errorResponse => {
                                        if (errorResponse?.otpRequired) {
                                            EventBus.$emit('mfa:activate', {
                                                mode: 'otp',
                                                callback: this.signIn
                                            });
                                        } else {
                                            EventBus.$emit('snackbar:show:danger', errorResponse?.error);
                                        }

                                        this.signInButton.loading = false;
                                        reject();
                                    });
                            } else {
                                this.signInButton.loading = false;
                            }
                        })
                        .catch(() => {
                            this.signInButton.loading = false;
                            reject();
                        });
                });
            }
        },

        mounted() {
            const routeQuery = this.$route.query;

            if (routeQuery.lang && this.$store.getters['user/account:languages'].find(l => l.iso === routeQuery.lang)) {
                setLanguage(routeQuery.lang);
            }

            if (routeQuery.email) {
                this.$nextTick(() => {
                    this.passwordRecoveryDialog.open = true;
                });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
