<template>
    <div>
        <table-filter-bar v-on="$listeners">
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field v-model="filters.id" :placeholder="$t('filters.transactionId')" />
                </app-registration-col>

                <app-registration-col field>
                    <app-text-field v-model="filters.user" :placeholder="$t('filters.user')" />
                </app-registration-col>
            </app-row>
        </table-filter-bar>

        <hr class="mt-6" />

        <v-data-table
            class="app-table"
            hide-default-footer
            :loading="loading"
            disable-sort
            :items="items"
            :headers="headers"
            :page.sync="pages.current"
        >
            <template v-slot:item.amount="{ item }">
                <span> {{ formatAmount(item.amount, item.currency) }} {{ item.currency }} </span>
            </template>

            <template v-slot:item.type="{ item }">
                {{ $t('transactionTypes.' + item.type) }}
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{ formatTimestamp(item.createdAt) }}
            </template>

            <template v-slot:loading>
                <p class="v-data-table--info">
                    {{ $t('table.loadingData') }}
                </p>
            </template>

            <template v-slot:no-data>
                <p v-if="!fetchError" class="v-data-table--info">
                    {{ $t('common.noData') }}
                </p>
                <p v-else class="v-data-table--info">
                    {{ $t('table.fetchError') }}
                </p>
            </template>
        </v-data-table>

        <table-pagination v-on="$listeners" />
    </div>
</template>

<script>
    import { core, filtering, pagination } from '@lb-world/core/public/components/tables/mixins';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    export default {
        name: 'AdminWalletTransactionsTable',
        components: { AppTextField },
        mixins: [core, pagination, filtering],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.transactionId'),
                        value: 'id'
                    },
                    {
                        text: this.$t('table.columns.userId'),
                        value: 'userId'
                    },
                    {
                        text: this.$t('table.columns.createdAt'),
                        value: 'createdAt'
                    },
                    {
                        text: this.$t('table.columns.type'),
                        value: 'type'
                    },
                    {
                        text: this.$t('table.columns.amount'),
                        value: 'amount'
                    }
                ];
            }
        }
    };
</script>

<style scoped></style>
