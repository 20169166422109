<template>
    <div>
        <products-settings />

        <app-title>{{ $t('admin.products.menu.browse') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <app-card left class="pt-0">
                    <app-tabs :i18n-prefix="productInfoTabs.i18nPrefix" :tabs="productInfoTabs" />

                    <hr />

                    <router-view class="py-6" />
                </app-card>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AppTabs from '@/components/ui/tabs/AppTabs';
    import ProductsSettings from '../../../../components/module-admin/user/products/ProductsSettings';

    export default {
        name: 'ProductsBrowse',

        components: {
            ProductsSettings,
            AppTabs
        },

        computed: {
            productInfoTabs() {
                return this.$store.getters['tabs/admin:products:browse'];
            }
        }
    };
</script>

<style scoped></style>
