<template>
    <app-expansion-panel
        :title="$t('admin.products.exchange.rateOverview.title')"
        :additional-data="getRateFormat"
        :loading="!tradeItemsFetched"
        :disabled="!tradeItemsFetched || tradeItemsError"
    >
        <app-text>
            <span
                v-html="
                    $t('admin.products.exchange.rateOverview.currentRate', {
                        rate: getRateFormat
                    })
                "
            />
        </app-text>

        <app-text>
            <span
                v-html="
                    $t('admin.products.exchange.rateOverview.lastUpdate', {
                        date: lastRateUpdate
                    })
                "
            />
        </app-text>
    </app-expansion-panel>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'RateExpansionPanel',
        components: { AppExpansionPanel },
        computed: {
            ...mapGetters('admin_productTrading', [
                'tradingRate',
                'tradeItemsFetched',
                'tradeItemsError',
                'tradingRate'
            ]),

            lastRateUpdate() {
                return this.formatTimestamp(this.tradingRate?.timestamp);
            },

            getRateFormat() {
                return `${this.tradingRate?.rate} ${this.tradingRate?.quoteCurrency}/${this.tradingRate?.baseCurrency}`;
            }
        }
    };
</script>

<style scoped lang="scss">
    .expansion-header {
        display: flex;
        align-items: center;

        @include media.resolve('xs-only') {
            flex-direction: column;
            align-items: start;
        }

        .expansion-header__additional-info {
            margin-left: spacing.$spacing-6;
            margin-top: 0;

            @include media.resolve('xs-only') {
                margin-left: 0;
                margin-top: spacing.$spacing-3;
            }
        }
    }
</style>
