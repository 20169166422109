<template>
    <app-card left no-margin title-height="item" class="document-card">
        <div class="text-center">
            <div class="title-style">
                {{ $t('documentTypes.' + documentKey + '.name') }}
            </div>
            <div class="document-status-style">
                {{ $t('bo.documents.documentTypes.documentStatus')
                }}<span
                    class="document-status-font"
                    :class="groupName === 'approved' ? 'color-true' : groupName === 'declined' ? 'color-false' : ''"
                    >{{ $t('bo.documents.' + groupName) }}</span
                >
            </div>
            <div v-if="!canUpload" class="document-description2-style">
                {{ $t('documentTypes.' + documentKey + '.description') }}
            </div>
            <div v-if="canUpload" @click="uploadDocument()" class="d-flex justify-center">
                <div>
                    <div class="document-description-style">
                        {{ $t('documentTypes.' + documentKey + '.description') }}.
                    </div>
                    <img class="mr-2" src="@/assets/icons/upload-icon.svg" />
                    <div class="upload-file-text">{{ $t('bo.documents.uploadDialog.uploadFile') }}</div>
                </div>
            </div>
        </div>
    </app-card>
</template>

<script>
    export default {
        name: 'DocumentCard',

        props: {
            document: {
                type: Object
            },
            groupName: {
                type: String
            }
        },

        computed: {
            documentKey() {
                return this.document.type;
            },
            canUpload() {
                return this.groupName === 'required' || this.groupName === 'declined';
            }
        },

        methods: {
            uploadDocument() {
                console.log('tu sme!!!');
                console.log(this.document);
                this.$emit('document:upload', this.documentKey);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
