<template>
    <v-data-table
        class="app-table"
        hide-default-footer
        :loading="loading"
        disable-sort
        :items="items"
        :headers="headers"
    >
        <template v-slot:item.state="{ item }">
            <span v-color:[getDocumentTextColor(item)]>
                {{ $t('admin.users.user.documents.documentState.' + (item.id !== null ? 'uploaded' : 'notUploaded')) }}
            </span>
        </template>

        <template v-slot:item.name="{ item }">
            {{ $t('documentTypes.' + item.name + '.name') }}
        </template>

        <template v-slot:item.document="{ item }">
            <button-secondary v-if="item.id !== null" icon="eye" inline @click="downloadSystemDocument(item.id)">
                <span v-if="$vuetify.breakpoint.smAndUp">
                    {{ $t('admin.users.user.documents.display') }}
                </span>
            </button-secondary>

            <span v-else v-color:black.translucent>
                {{ $t('common.none') }}
            </span>
        </template>

        <template v-slot:item.actions="{ item }">
            <div v-if="item.id === null">
                <v-tooltip left :disabled="!isDocumentRestrictionActive(item.name)">
                    <template #activator="{ on }">
                        <span v-on="on">
                            <button-primary
                                icon-set="fas"
                                :icon="isDocumentRestrictionActive(item.name) ? 'question-circle' : ''"
                                :disabled="isDocumentRestrictionActive(item.name)"
                                small
                                @click="uploadDocument(item)"
                            >
                                {{ $t('admin.users.user.documents.upload') }}
                            </button-primary>
                        </span>
                    </template>

                    <span v-if="isDocumentRestricted(item.name)">
                        {{ $t(isDocumentRestricted(item.name).reason) }}
                    </span>
                </v-tooltip>
            </div>

            <span v-else v-color:black.translucent>
                {{ $t('common.none') }}
            </span>
        </template>

        <template v-slot:loading>
            <p class="v-data-table--info">
                {{ $t('table.loadingData') }}
            </p>
        </template>

        <template v-slot:no-data>
            <p v-if="!fetchError" class="v-data-table--info">
                {{ $t('common.noData') }}
            </p>
            <p v-else class="v-data-table--info">
                {{ $t('table.fetchError') }}
            </p>
        </template>
    </v-data-table>
</template>

<script>
    import { core } from '@lb-world/core/public/components/tables/mixins';

    import { DocumentSet, SystemDocumentRestrictions } from '@/store/admin/documents';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'AdminUserDetailSystemDocumentsTable',

        mixins: [core],

        computed: {
            headers() {
                return [
                    {
                        text: this.$t('table.columns.type'),
                        value: 'name'
                    },
                    {
                        text: this.$t('table.columns.document'),
                        value: 'document',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.state'),
                        value: 'state',
                        align: 'center'
                    },
                    {
                        text: this.$t('table.columns.actions'),
                        value: 'actions',
                        align: 'center'
                    }
                ];
            }
        },

        methods: {
            getDocumentTextColor(item) {
                return item.id === null ? 'danger' : 'success';
            },
            uploadDocument(document) {
                this.$emit('document:upload', document.name);
            },
            isDocumentRestricted(documentType) {
                return SystemDocumentRestrictions.find(sd => sd.type === documentType);
            },
            downloadSystemDocument(documentId) {
                const windowRef = window.open();

                this.$store
                    .dispatch('admin_userDocuments/download', {
                        documentSet: DocumentSet.SYSTEM,
                        documentId
                    })
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
            },
            isDocumentRestrictionActive(documentType) {
                const conditionGetter = this.isDocumentRestricted(documentType)?.conditionGetter;

                return conditionGetter && this.$store.getters[conditionGetter];
            }
        }
    };
</script>

<style scoped></style>
