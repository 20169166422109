<template>
    <app-container page-padding unlimited-width>
        <app-title>{{ $t('admin.transactions.title') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <app-card left class="pt-0">
                    <app-tabs :i18n-prefix="transactionsTabs.i18nPrefix" :tabs="transactionsTabs" />

                    <hr />

                    <router-view class="py-6" />
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import AppTabs from '@/components/ui/tabs/AppTabs';

    export default {
        name: 'Transactions',

        components: {
            AppTabs
        },

        computed: {
            transactionsTabs() {
                return this.$store.getters['tabs/admin:transactions:info'];
            }
        }
    };
</script>

<style scoped></style>
