<template>
    <div>
        <app-title>{{ $t('admin.exports.title') }}</app-title>

        <app-text>
            <span v-html="$t('admin.exports.unprocessedWithdrawals.description')" />
        </app-text>

        <app-row>
            <app-registration-col>
                <button-primary @click="exportData" full-width :loading="exportLoadingButton">
                    {{ $t('admin.exports.button') }}
                </button-primary>
            </app-registration-col>
        </app-row>

        <app-divider />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'ExportUnprocessedWithdrawals',

        data: () => ({
            exportLoadingButton: false
        }),

        methods: {
            downloadBinaryData(data, filename) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
            },

            exportData() {
                this.exportLoadingButton = true;

                this.$store
                    .dispatch('admin_productTrading/export', {
                        name: 'wallet-withdrawals/unprocessed-withdrawals-xls',
                        binary: true
                    })
                    // eslint-disable-next-line no-unused-vars
                    .then(({ data, filename }) => {
                        this.downloadBinaryData(data, filename);
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.exportLoadingButton = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
