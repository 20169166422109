<template>
    <div class="full-width d-flex flex-column">
        <div class="ma-0 pa-0 vt-tab-title">
            {{ $t('bo.documents.download.title') }}
        </div>
        <v-row class="max-width-card">
            <v-col>
                <div class="card-div">
                    <div class="card-title">
                        {{ $t('bo.documents.download.generalTermsAndConditionsText') }}
                    </div>
                    <button-secondary
                        class="download-file-font"
                        inline
                        icon="file-alt"
                        @click="openProductDocument('generalTerms')"
                        :loading="documentButtonsLoading.generalTerms"
                        ><span class="download-file-font">{{
                            $t('bo.documents.download.generalTermsAndConditions')
                        }}</span>
                    </button-secondary>
                </div>
            </v-col>
            <v-col>
                <div class="card-div">
                    <div class="card-title">{{ $t('bo.documents.download.efkStakeProductConditionsText') }} III</div>
                    <button-secondary
                        class="download-file-font"
                        inline
                        icon="file-alt"
                        @click="openProductDocument('efkStakeIII')"
                        :loading="documentButtonsLoading.efkStakeIII"
                        ><span class="download-file-font">{{
                            $t('bo.documents.download.efkStakeProductConditions')
                        }}</span>
                    </button-secondary>
                </div>
            </v-col>
        </v-row>
        <v-row class="max-width-card">
            <v-col>
                <div class="card-div">
                    <div class="card-title">
                        {{ $t('bo.documents.download.efkLiquidityProductConditionsText') }}
                    </div>
                    <button-secondary
                        class="download-file-font"
                        inline
                        icon="file-alt"
                        @click="openProductDocument('efkLiquidity')"
                        :loading="documentButtonsLoading.efkLiquidity"
                        ><span class="download-file-font">{{
                            $t('bo.documents.download.efkLiquidityProductConditions')
                        }}</span>
                    </button-secondary>
                </div>
            </v-col>
            <v-col>
                <div class="card-div">
                    <div class="card-title">
                        {{ $t('bo.documents.download.efkPurchaseStakeProductConditionsText') }}
                    </div>
                    <button-secondary
                        class="download-file-font"
                        inline
                        icon="file-alt"
                        @click="openProductDocument('efkPurchaseStake')"
                        :loading="documentButtonsLoading.efkPurchaseStake"
                        ><span class="download-file-font">{{
                            $t('bo.documents.download.efkPurchaseStakeProductConditions')
                        }}</span>
                    </button-secondary>
                </div>
            </v-col>
        </v-row>
        <v-row class="max-width-card">
            <v-col>
                <div class="card-div">
                    <div class="card-title">
                        {{ $t('bo.documents.download.efkStakeRfiProductConditionsText') }}
                    </div>
                    <button-secondary
                        class="download-file-font"
                        inline
                        icon="file-alt"
                        @click="openProductDocument('efkStakeRfi')"
                        :loading="documentButtonsLoading.efkStakeRfi"
                        ><span class="download-file-font">{{
                            $t('bo.documents.download.efkStakeRfiProductConditions')
                        }}</span>
                    </button-secondary>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {
        DOCUMENT_PRODUCT_SPECS_DIGI_RENT,
        DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY,
        DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY,
        DOCUMENT_PRODUCT_SPECS_EFK_STAKE,
        DOCUMENT_PRODUCT_SPECS_EFK_STAKE_III,
        DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI,
        DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE,
        DOCUMENT_PRODUCT_SPECS_4TRANS,
        DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE
    } from '@/constants';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'DocumentsConditionsDownload',

        components: {},

        data: () => ({
            documentButtonsLoading: {
                generalTerms: false,
                digiRent: false,
                efkStake: false,
                efkStakeIII: false,
                efkStakeRfi: false,
                efkLiquidity: false,
                efkPurchase: false,
                efkPurchaseStake: false,
                trans4: false
            }
        }),

        methods: {
            openProductDocument(id) {
                this.documentButtonsLoading[id] = true;

                let documentId;

                switch (id) {
                    case 'generalTerms':
                        if (this.$i18n.locale.substring(0, 2) == 'cs') {
                            window.open('https://lbw.fra1.digitaloceanspaces.com/VOP_LB.pdf', '_blank');
                        } else {
                            window.open('https://lbw.fra1.digitaloceanspaces.com/GTC_LB.pdf', '_blank');
                        }

                        this.documentButtonsLoading[id] = false;
                        return;

                    case 'digiRent':
                        documentId = DOCUMENT_PRODUCT_SPECS_DIGI_RENT;
                        break;

                    case 'efkStake':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_STAKE;
                        break;

                    case 'efkStakeIII':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_STAKE_III + '--' + this.$i18n.locale.substring(0, 2);
                        break;

                    case 'efkStakeRfi':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_STAKE_RFI + '--' + this.$i18n.locale.substring(0, 2);
                        break;

                    case 'efkLiquidity':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_LIQUIDITY + '--' + this.$i18n.locale.substring(0, 2);
                        break;

                    case 'efkControlledLiquidity':
                        documentId =
                            DOCUMENT_PRODUCT_SPECS_EFK_CONTROLLED_LIQUIDITY + '--' + this.$i18n.locale.substring(0, 2);
                        break;

                    case 'efkPurchase':
                        documentId = DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE;
                        break;

                    case 'efkPurchaseStake':
                        documentId =
                            DOCUMENT_PRODUCT_SPECS_EFK_PURCHASE_STAKE + '--' + this.$i18n.locale.substring(0, 2);
                        break;

                    case '4trans':
                        documentId = DOCUMENT_PRODUCT_SPECS_4TRANS;
                        break;
                }

                const windowRef = window.open();

                this.$store
                    .dispatch('documents/document:request', documentId)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:error', error);
                    })
                    .finally(() => {
                        this.documentButtonsLoading[id] = false;
                    });
            }
        }
    };
</script>

<style scoped></style>
