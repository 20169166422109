<template>
    <v-data-table
        class="app-table"
        hide-default-footer
        :loading="loading"
        disable-sort
        :items="items"
        :items-per-page="1000"
        :headers="headers"
    >
        <template v-slot:item.czkAmount="{ item }">
            {{ formatAmount(item.czkAmount, 'czk') }}
        </template>

        <template v-slot:item.efkAmount="{ item }">
            {{ formatAmount(item.efkAmount, 'efk') }}
        </template>

        <template v-slot:item.maxPurchaseRate="{ item }">
            {{ formatAmount(item.maxPurchaseRate, 'rate') }}
        </template>

        <template v-slot:item.avgPurchaseRate="{ item }">
            {{ formatAmount(item.avgPurchaseRate, 'rate') }}
        </template>

        <template v-slot:item.maxPurchaseRateLB="{ item }">
            {{ formatAmount(item.maxPurchaseRateLB, 'rate') }}
        </template>

        <template v-slot:item.efkAmount="{ item }">
            <span v-if="assignable" v-color:primary class="font-weight-bold">
                {{ formatAmount(calculateAmount, 'efk') }}
            </span>

            <span v-else>{{ formatAmount(item.efkAmount, 'efk') }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
            <button-secondary @click="$emit('tradingDialog:open', item)" inline>
                {{ $t('admin.products.exchange.trade') }}
            </button-secondary>
        </template>

        <template v-slot:loading>
            <p class="v-data-table--info">
                {{ $t('table.loadingData') }}
            </p>
        </template>

        <template v-slot:no-data>
            <p v-if="!fetchError" class="v-data-table--info">
                {{ $t('common.noData') }}
            </p>
            <p v-else class="v-data-table--info">
                {{ $t('table.fetchError') }}
            </p>
        </template>
    </v-data-table>
</template>

<script>
    import { core } from '@lb-world/core/public/components/tables/mixins';

    export default {
        name: 'AdminProductExchangeDemandTable',

        mixins: [core],

        props: {
            assignable: Boolean,
            calculatedAmount: Number,
            originalAmount: Number
        },

        computed: {
            calculateAmount() {
                return this.originalAmount - this.calculatedAmount;
            },
            headers() {
                return [
                    {
                        text: 'Objem pozice (CZK)',
                        value: 'czkAmount'
                    },
                    {
                        text: 'Maximální nákupní hodnota',
                        value: 'maxPurchaseRate'
                    },
                    {
                        text: 'Průměrná nákupní hodnota',
                        value: 'avgPurchaseRate'
                    },
                    {
                        text: 'Maximální nákupní hodnota LB',
                        value: 'maxPurchaseRateLB'
                    },
                    {
                        text: 'Objem pozice (EFK)',
                        value: 'efkAmount'
                    },
                    this.assignable
                        ? ''
                        : {
                              text: this.$t('table.columns.actions'),
                              value: 'actions',
                              align: 'center'
                          }
                ];
            }
        }
    };
</script>

<style scoped></style>
