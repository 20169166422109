<template>
    <div class="account-tab pa-8">
        <validation-observer ref="formAdminUserProfileBankAccountEdit" v-slot="{ invalid }">
            <v-row>
                <v-col cols="12" sm="8" md="12" lg="8" xl="6" class="pr-4">
                    <v-row>
                        <v-col cols="12" class="vt-tab-title">
                            {{ $t('bo.account.userDetails.title') }}
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col cols="12" sm="8" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('bankAccount.bankName')"
                                :placeholder="$t('bankAccount.bankNamePlaceholder')"
                                v-model="userEdit.bankAccount.bankName"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('bankAccount.bankCode')"
                                placeholder=" "
                                v-model="userEdit.bankAccount.code"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('bankAccount.accountPrefix')"
                                :placeholder="$t('bankAccount.accountPrefixPlaceholder')"
                                v-model="userEdit.bankAccount.accountNumberPrefix"
                                optional
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="ma-0 mb-n8">
                            <app-text-field
                                :label="$t('bankAccount.accountNumber')"
                                :placeholder="$t('bankAccount.accountNumberPlaceholder')"
                                v-model="userEdit.bankAccount.accountNumber"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-text-field
                                label="IBAN"
                                :placeholder="$t('bankAccount.IbanPlaceholder')"
                                v-model="userEdit.bankAccount.iban"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 mb-n8">
                            <app-text-field
                                label="SWIFT"
                                :placeholder="$t('bankAccount.SwiftPlaceholder')"
                                v-model="userEdit.bankAccount.swift"
                                rules="required"
                            ></app-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div
                        class="d-flex flex-row flex-wrap full-width"
                        :class="{ 'place-content-around': $vuetify.breakpoint.xs }"
                    >
                        <button-primary :disabled="invalid" :loading="submitButtonLoading" @click="submit">
                            {{ $t('bankAccount.submit') }}
                        </button-primary>
                        <button-secondary
                            class="ml-2"
                            :disabled="invalid"
                            :loading="submitButtonLoading"
                            @click="reset"
                        >
                            {{ $t('bo.account.userDetails.reset') }}
                        </button-secondary>
                    </div>
                </v-col>
            </v-row>
        </validation-observer>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/VtAppTextField';

    import { ValidationObserver } from 'vee-validate';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'InfoBankAccount',

        components: {
            AppTextField,
            ValidationObserver
        },

        props: ['user'],

        data: () => ({
            userEdit: {
                bankAccount: {
                    bankName: '',
                    swift: '',
                    iban: '',
                    accountNumberPrefix: '',
                    accountNumber: '',
                    code: ''
                }
            },

            submitButtonLoading: false
        }),

        methods: {
            fillData() {
                this.$set(this.userEdit, 'bankAccount', JSON.parse(JSON.stringify(this.user.bankAccount || {})));
            },
            reset() {
                this.fillData();
            },
            submit() {
                this.submitButtonLoading = true;

                this.$refs.formAdminUserProfileBankAccountEdit
                    .validate()
                    .then(result => {
                        if (result) {
                            if (this.userEdit.bankAccount.accountNumberPrefix === '') {
                                this.userEdit.bankAccount.accountNumberPrefix = null;
                            }

                            this.$store
                                .dispatch('user/update', { user: this.userEdit })
                                .then(() => {
                                    this.$emit('user:update');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.submitButtonLoading = false;
                                });
                        } else {
                            this.submitButtonLoading = false;
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);

                        this.submitButtonLoading = false;
                    });
            }
        },

        watch: {
            user() {
                this.fillData();
            }
        },

        mounted() {
            this.fillData();
        }
    };
</script>

<style scoped lang="scss"></style>
