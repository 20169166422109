import Vue from 'vue';

import { parseErrorMessageFromAPI, API_SERVER_ERROR } from '@/utils';

import CARepository from '@lb-world/core/public/api/repositories/CARepository';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';

const state = {
    commissionAccount: new BaseDataset()
};

const getters = {
    accountData: state => state.commissionAccount.data,
    accountDataFetched: state => state.commissionAccount.fetched,
    accountDataError: state => state.commissionAccount.fetchError,

    accountDisabled: state => !state.commissionAccount.data?.isActive ?? true
};

const actions = {
    clear: ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear');

            resolve();
        });
    },
    fetch: ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.get(rootGetters['admin_userDetail/id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const commissionAccount = response.data;

                    if (commissionAccount) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', commissionAccount);

                        commit('store', commissionAccount);

                        resolve(commissionAccount);
                    } else {
                        commit('error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    update: ({ commit, rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call', data);

        return new Promise((resolve, reject) => {
            CARepository.update(rootGetters['admin_userDetail/id'], data)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', response.data);

                        resolve();
                    } else {
                        commit('error');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'fetch:detail': (_, userId) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            CARepository.get(userId)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const commissionAccount = response.data;

                    if (commissionAccount) {
                        Vue.$log.debug('[ACTION] Returning user wallet transactions', commissionAccount);

                        resolve(commissionAccount);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    clear: state => {
        state.commissionAccount.clearData();
    },
    store: (state, data) => {
        state.commissionAccount.storeData(data);
    },
    error: state => {
        state.commissionAccount.storeError();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
