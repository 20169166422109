import moment from 'moment-timezone';

import EventBus from '@lb-world/core/public/plugins/eventBus';

export const API_INTERNAL_ERROR = 'internal_error';
export const API_SERVER_ERROR = 'server_error';

export const SignEntries = {
    administrators: {
        to: '/admin'
    },
    users: {
        to: '/'
    }
};

export const UserStates = {
    CREATED: 'created',
    EMAIL_VERIFIED: 'email_verified',
    REGISTERED: 'registered',
    VERIFIED: 'verified'
};

import Vue from 'vue';

export const getUserDifferenceAttributes = (originUser, editUser) => {
    const user = {};

    for (const attr in originUser) {
        if (typeof originUser[attr] === 'object') {
            for (const objAttr in originUser[attr]) {
                if (originUser[attr][objAttr] !== editUser[attr][objAttr]) {
                    user[attr] = editUser[attr];
                }
            }
        } else if (originUser[attr] !== editUser[attr]) {
            user[attr] = editUser[attr];
        }
    }

    return user;
};

export const createCustomErrorKey = (errorKey, attribute) => {
    return 'errors.attributes.' + errorKey + '.' + attribute;
};

export const convertToUppyLanguageFormat = function(lang) {
    return lang.replace('-', '_');
};

export const parseErrorMessageFromAPI = function(errorObject) {
    if (errorObject.data && errorObject.data.data) {
        const errorKey = errorObject.data.data.errorKey;
        const attributes = errorObject.data.data.attributes;

        if (errorKey && attributes) {
            return { errorKey, attributes };
        } else if (errorKey) {
            return { errorKey };
        } else {
            return API_SERVER_ERROR;
        }
    }

    return API_SERVER_ERROR;
};

export const JWTDecode = function(token) {
    const decode = require('jwt-decode');

    return decode(token).user;
};

export const JWTRefreshOnExpiration = function(expiration) {
    const renewIn = (expiration - moment().unix()) * 1000;
    const renewReserve = 60 * 1000;

    setTimeout(function() {
        Vue.$log.debug('[JWT] Running refresh event in JWTRefreshOnExpiration', renewIn - renewReserve);

        EventBus.$emit('jwt:refresh');
    }, renewIn - renewReserve);
};

export const getAppUrlFormat = function(app, section) {
    let prefix = '';

    if (process.env.VUE_APP_MODE === 'development') {
        prefix = 'dev.';
    } else if (app === 'world') {
        prefix = 'account.';
    }

    return 'https://' + prefix + 'lb' + app + '.eu' + (section ?? '');
};

export function getApiUrl() {
    return process.env.VUE_APP_MODE !== 'development' ? 'https://api.lbworld.eu/v1/' : 'https://api.dev.lbworld.eu/v1/';
}

export function getWorldAppUrl() {
    return process.env.VUE_APP_MODE !== 'development' ? 'https://account.lbworld.eu' : 'https://dev.lbworld.eu';
}

export const getToday = () => {
    return moment().format('YYYY-MM-DD');
};
