<template>
    <admin-product-accounts-transactions-table
        :items="paTransactions"
        :loading="!paTransactionsFetched"
        :fetch-error="paTransactionsError"
        :pages="paTransactionsPages"
        :filters="{}"
        @pagination:change="fetchProductAccountsTransactions"
    >
    </admin-product-accounts-transactions-table>
</template>

<script>
    import AdminProductAccountsTransactionsTable from '@/components/ui/tables/AdminProductAccountsTransactionsTable';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    export default {
        name: 'ProductTransactionsTab',

        components: { AdminProductAccountsTransactionsTable },

        props: {
            productId: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            }
        },

        computed: mapGetters('admin_productAccounts', {
            paTransactions: 'user:paTransactions',
            paTransactionsFetched: 'user:paTransactionsFetched',
            paTransactionsError: 'user:paTransactionsError',
            paTransactionsPages: 'user:paTransactionsPages'
        }),

        methods: {
            fetchProductAccountsTransactions(page) {
                this.$store.dispatch('admin_productAccounts/user:pat:clear').then(() => {
                    this.$store
                        .dispatch('admin_productAccounts/user:pat:fetch', {
                            productId: this.productId,
                            page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        },

        watch: {
            productId() {
                this.fetchProductAccountsTransactions();
            }
        },

        mounted() {
            this.fetchProductAccountsTransactions();
        }
    };
</script>

<style scoped></style>
