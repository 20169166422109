<template>
    <validation-observer ref="formCurrencyWithdrawalDialogEFK">
        <app-row justify="center" class="mt-6">
            <app-col>
                <app-text-field
                    :label="$t('admin.withdrawalConfirmation.dialog.transactionHash')"
                    v-model="withdrawal.transactionHash"
                    rules="required"
                    @input="$emit('change', withdrawal)"
                ></app-text-field>
            </app-col>
        </app-row>
    </validation-observer>
</template>

<script>
    import AppCol from '@/components/ui/layout/AppCol';
    import AppTextField from '@/components/ui/form/VtAppTextField';

    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'WithdrawalEFK',

        components: { AppTextField, AppCol, ValidationObserver },

        data: () => ({
            withdrawal: {
                transactionHash: ''
            }
        }),

        methods: {
            resetValues() {
                this.withdrawal.transactionHash = '';

                this.$refs['formCurrencyWithdrawalDialogEFK'].reset();
            }
        }
    };
</script>

<style scoped></style>
