<template>
    <admin-user-detail-wallet-txs-table
        :items="transactions"
        :loading="!transactionsFetched"
        :fetch-error="transactionsError"
        :pages="transactionsPages"
        @pagination:change="fetchTransactions"
    />
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AdminUserDetailWalletTxsTable from '@/components/ui/tables/AdminUserDetailWalletTxsTable';

    export default {
        name: 'TransactionWalletTab',

        components: { AdminUserDetailWalletTxsTable },

        props: ['userId'],

        computed: {
            transactionsFetched() {
                return this.$store.getters['admin/userDetail:transactions:fetched'];
            },
            transactionsError() {
                return this.$store.getters['admin/userDetail:transactions:error'];
            },
            transactionsPages() {
                return this.$store.getters['admin/userDetail:transactions:pages'];
            },
            transactions() {
                return this.$store.getters['admin/userDetail:transactions'];
            }
        },

        methods: {
            fetchTransactions(page) {
                this.$store.dispatch('admin/userDetail:transactions:clear').then(() => {
                    this.$store
                        .dispatch('admin/userDetail:transactions:fetch', {
                            userId: this.userId,
                            page
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);
                        });
                });
            }
        }
    };
</script>

<style scoped></style>
