<template>
    <validation-observer ref="formIdentifyUserDetails">
        <app-card>
            <app-row justify="center" class="mt-6">
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.degree')"
                        v-model="user.personalInfo.degree"
                        optional
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-select-field
                        v-model="user.personalInfo.sex"
                        :items="sexItems"
                        item-text="name"
                        item-value="value"
                        :placeholder="$t('admin.digitizationDialog.sex')"
                        rules="required"
                    />
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-text-field
                        v-model="user.personalInfo.name"
                        :label="$t('admin.digitizationDialog.name')"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        v-model="user.personalInfo.surname"
                        :label="$t('admin.digitizationDialog.surname')"
                        rules="required"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <app-row justify="center">
                <app-registration-col field>
                    <app-date-picker
                        :menu.sync="calendar.dateOfBirth"
                        v-model="user.personalInfo.dateOfBirth"
                        :label="$t('admin.digitizationDialog.dateOfBirth')"
                    ></app-date-picker>
                </app-registration-col>
                <app-registration-col field>
                    <app-text-field
                        :label="$t('admin.digitizationDialog.personalNumber')"
                        rules="required"
                        v-model="user.personalInfo.personalNumber"
                    ></app-text-field>
                </app-registration-col>
            </app-row>
            <template #button-primary>
                <button-primary @click="submit">
                    {{ $t('admin.digitizationDialog.continue') }}
                </button-primary>
            </template>
        </app-card>
    </validation-observer>
</template>

<script>
    import AppSelectField from '@/components/ui/form/VtAppSelectField';
    import AppTextField from '@/components/ui/form/VtAppTextField';
    import AppDatePicker from '@/components/ui/form/VtAppDatePicker';

    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'DigitizationUserDetails',

        components: {
            AppDatePicker,
            AppTextField,
            AppSelectField,
            ValidationObserver
        },

        props: {
            user: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            calendar: {
                dateOfBirth: false
            }
        }),

        computed: {
            sexItems() {
                return [
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.male'),
                        value: 'M'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.female'),
                        value: 'F'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.xGender'),
                        value: 'X'
                    },
                    {
                        name: this.$t('admin.digitizationDialog.sexItems.oGender'),
                        value: 'O'
                    }
                ];
            }
        },

        methods: {
            submit() {
                this.$refs.formIdentifyUserDetails.validate().then(result => {
                    if (result) {
                        this.$emit('process:next');
                    }
                });
            }
        }
    };
</script>

<style scoped></style>
