<template>
    <v-container
        class="card-container d-flex flex-row py-4 mb-2"
        :class="{ 'card-container-backgroud-sm': $vuetify.breakpoint.smAndDown }"
    >
        <v-row @click="redirectToApp" class="py-2 align-items-center pointer">
            <v-col cols="3" class="pa-3 ml-1 mr-4 text-center d-flex content-center">
                <vt-big-checkbox-icon class="d-flex" :checked="checked" :small="small" />
            </v-col>
            <v-col cols="6" class="pa-0 ml-n2 d-flex">
                <slot></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VtBigCheckboxIcon from '../icons/VtBigCheckboxIcon';
    import { getAppUrlFormat } from '@/utils';

    export default {
        name: 'VtSignupLeftCard',

        components: {
            VtBigCheckboxIcon
        },

        props: {
            checked: Boolean,
            small: Boolean,
            id: String
        },

        methods: {
            redirectToApp() {
                // this.checked=true
                window.location = getAppUrlFormat(this.id);
            }
        }
    };
</script>

<style scoped lang="scss"></style>
