<template>
    <div>
        <app-title>{{ $t('admin.documents.title') }}</app-title>

        <app-row>
            <app-registration-col whole-screen>
                <app-card>
                    <admin-users-approval-table
                        :loading="!usersApprovalDataFetched"
                        :items="usersApproval"
                        :pages="usersApprovalDataPages"
                        :fetch-error="usersApprovalDataError"
                        @pagination:change="fetchUserApprovals"
                    >
                    </admin-users-approval-table>
                </app-card>
            </app-registration-col>
        </app-row>
    </div>
</template>

<script>
    import AdminUsersApprovalTable from '@/components/ui/tables/AdminUsersApprovalTable';

    import { mapGetters } from 'vuex';

    export default {
        name: 'DocumentsApproval',

        components: { AdminUsersApprovalTable },

        computed: mapGetters('admin', {
            usersApproval: 'users:approval',
            usersApprovalDataFetched: 'users:approval:fetched',
            usersApprovalDataError: 'users:approval:error',
            usersApprovalDataPages: 'users:approval:pages'
        }),

        data: () => ({
            documentsUploadDialog: {
                open: false,
                user: {}
            }
        }),

        methods: {
            fetchUserApprovals(page) {
                this.$store.dispatch('admin/users:approval:clear').then(() => {
                    this.$store.dispatch('admin/users:approval:fetch', {
                        page,
                        filter: {
                            identityVerificationState: 'requested'
                        }
                    });
                });
            }
        },

        created() {
            if (!this.usersApprovalDataFetched) {
                this.fetchUserApprovals();
            }
        }
    };
</script>

<style scoped></style>
