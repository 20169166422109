<template>
    <v-app-bar class="section-tabs">
        <v-tabs :class="['app-tabs', 'v-tabs__' + tabs.type]" center-active>
            <template>
                <v-tab v-for="tab in tabs.data" :key="tab.name" :to="{ name: tab.path }">
                    <font-awesome-icon v-if="tab.icon" class="mr-3" :icon="['fad', tab.icon]"></font-awesome-icon>
                    {{ $t(i18nPrefix + '.' + tab.name) }}
                </v-tab>
            </template>
        </v-tabs>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppSectionTabs',

        props: {
            tabs: {
                required: true
            },
            i18nPrefix: {
                required: false,
                default: ''
            }
        }
    };
</script>

<style lang="scss">
    .section-tabs {
        padding-left: 48px;

        background-color: colors.$color-primary-5 !important;

        .v-tabs-slider-wrapper {
            display: none !important;
        }
    }
</style>
