<template>
    <app-container unlimited-width page-padding>
        <app-detail-header :loading="!userFetched" icon="users" :on-back-pressed="returnFromDetail">
            {{ userEmail }}
        </app-detail-header>

        <app-row>
            <app-registration-col whole-screen class="pt-0">
                <app-tabs
                    shortcut-on-mobile
                    class="app-nav-drawer"
                    :i18n-prefix="userTabs.i18nPrefix"
                    :tabs="userTabs"
                />

                <keep-alive>
                    <router-view @user:fetch="fetchUser" v-if="userFetched" />

                    <app-card v-else>
                        <app-row>
                            <app-registration-col whole-screen>
                                <app-loader :size="20" horizontal>
                                    {{ $t('common.loadingUser') }}
                                </app-loader>
                            </app-registration-col>
                        </app-row>
                    </app-card>
                </keep-alive>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import { mapGetters } from 'vuex';

    import AppTabs from '@/components/ui/tabs/AppTabs';
    import AppDetailHeader from '@lb-world/core/public/components/utils/AppDetailHeader';

    export default {
        name: 'User',

        components: {
            AppDetailHeader,
            AppTabs
        },

        props: ['returnTo'],

        computed: {
            ...mapGetters('admin_userDetail', {
                userFetched: 'userFetched',
                userEmail: 'user:email'
            }),

            userTabs() {
                return this.$store.getters['tabs/admin:user'];
            }
        },

        methods: {
            fetchUser() {
                return new Promise((resolve, reject) => {
                    const userId = this.$route.params.id;

                    this.$store.dispatch('admin_userDetail/clear').then(() => {
                        this.$store
                            .dispatch('admin_userDetail/meta', {
                                key: 'id',
                                data: userId
                            })
                            .then(() => {
                                this.$store
                                    .dispatch('admin_userDetail/fetch')
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch(error => {
                                        EventBus.$emit('snackbar:show:danger', error);

                                        reject();
                                    });
                            })
                            .catch(error => {
                                EventBus.$emit('snackbar:show:danger', error);
                            });
                    });
                });
            },
            returnFromDetail() {
                this.$store.dispatch('admin_userDetail/clear').then(() => {
                    const returnTo = this.$store.getters['admin_userDetail/returnTo'];

                    if (returnTo) {
                        this.$router.push({ name: returnTo });
                    } else {
                        this.$router.push({ name: 'admin:users' });
                    }
                });
            }
        },

        beforeDestroy() {
            this.$store.dispatch('admin_userDetail/clear');
        },

        mounted() {
            this.fetchUser();
        }
    };
</script>

<style scoped></style>
