<template>
    <div class="full-width">
        <vt-activate-account-banner />
        <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
                <vt-card class="ma-0 ma-md-5">
                    <div class="vt-tab-title">
                        {{ $t('bo.limits.title') }}
                    </div>
                    <v-row class="max-width-card mt-6 place-content-space-around ml-n10">
                        <div class="d-flex flex-column mt-4">
                            <div class="actual-limit-font">
                                {{ $t('bo.limits.actualLimit') }}
                            </div>
                            <div class="mt-4 amount-span">
                                <span v-if="!isEmpty(purchaseLimit.limit)">
                                    {{ formatAmount(purchaseLimit.limit, 'czk') }} CZK
                                </span>
                                <span v-else>
                                    {{ $t('bo.limits.infinity') }}
                                </span>
                            </div>
                        </div>
                        <div class="d-flex flex-column mt-4">
                            <div class="actual-limit-font">
                                {{ $t('bo.limits.balanceForBuy') }}
                            </div>
                            <div class="mt-4 amount-span">
                                <span v-if="!isEmpty(purchaseLimit.remainingLimit)">
                                    {{ formatAmount(purchaseLimit.remainingLimit, 'czk') }} CZK
                                </span>
                                <span v-else>
                                    {{ $t('bo.limits.infinity') }}
                                </span>
                            </div>
                        </div>
                        <div class="d-flex flex-column mt-4">
                            <div class="actual-limit-font">
                                {{ $t('bo.limits.totalPurchased') }}
                            </div>
                            <div class="mt-4 amount-span">
                                <span v-if="!isEmpty(purchaseLimit.usedLimit)">
                                    {{ formatAmount(purchaseLimit.usedLimit, 'czk') }} CZK
                                </span>
                            </div>
                        </div>
                    </v-row>
                    <v-row class="mt-8 ml-0 max-width-card">
                        <div class="pa-2 warning-card">
                            <img height="16" class="mx-2 align-self-center" src="@/assets/icons/info.svg" />
                            <div class="warning-card-font">
                                {{ $t('bo.limits.warningRequredType') }}
                            </div>
                        </div>
                    </v-row>

                    <v-row class="mt-8 max-width-card">
                        <v-col class="text-md-start pl-md-0 warning-card-font">
                            {{ $t('bo.limits.starInfo') }}
                            <purchase-limits-description v-if="showAdvDesc" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-8 ml-0">
                        <v-col cols="12" class="pa-0 ma-0">
                            <vt-purchase-limits-panels @limit:update="fetchPurchaseLimit" />
                        </v-col>
                    </v-row>
                </vt-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';
    import { AMLLimits } from '@lb-world/core/public/models/AMLLimit';
    import { mapGetters } from 'vuex';

    import VtPurchaseLimitsPanels from '@/components/module-user/purchase-limits/VtPurchaseLimitsPanels';
    import PurchaseLimitsDescription from '@/components/module-user/purchase-limits/VtPurchaseLimitsDescription';
    import VtActivateAccountBanner from '@/components/ui/VtActivateAccountBanner';

    export default {
        name: 'VtLimits',

        components: {
            VtActivateAccountBanner,
            PurchaseLimitsDescription,
            VtPurchaseLimitsPanels
        },

        data() {
            return {
                showAdvDesc: false
            };
        },

        computed: {
            ...mapGetters('user_aml', ['purchaseLimit', 'purchaseLimitFetched', 'purchaseLimitError']),
            limits: function() {
                let result = [];
                Object.keys(AMLLimits).map(function(key) {
                    result.push({
                        name: key,
                        value: AMLLimits[key]
                    });
                });
                return result;
            }
        },

        methods: {
            fetchLimits() {
                this.$store.dispatch('user_aml/limitsOverview:clear').then(() => {
                    this.$store.dispatch('user_aml/limitsOverview:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });
            },

            fetchPurchaseLimit() {
                this.$store.dispatch('user_aml/purchaseLimits:clear').then(() => {
                    this.$store.dispatch('user_aml/purchaseLimits:fetch').catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    });
                });
            }
        },

        mounted() {
            this.fetchLimits();
            this.fetchPurchaseLimit();
        }
    };
</script>

<style scoped lang="scss"></style>
