import { API_INTERNAL_ERROR, parseErrorMessageFromAPI } from '@/utils';

import Vue from 'vue';

import MFARepository from '@lb-world/core/public/api/repositories/MFARepository';

const actions = {
    generateSecret: ({ rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            MFARepository.generateSecret(rootGetters['user/user:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data?.secretKey) {
                        resolve(response.data?.secretKey);
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    toggle: ({ commit, rootGetters }, { mode, otp }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        let f;

        switch (mode) {
            case 'enable':
                f = MFARepository.enable;
                break;

            case 'disable':
                f = MFARepository.disable;
                break;
        }

        return new Promise((resolve, reject) => {
            if (!f) {
                reject(API_INTERNAL_ERROR);
            }

            f(rootGetters['user/user:id'], otp)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('user/update', { multiFactorAuth: { active: mode === 'enable' } }, { root: true });

                        resolve();
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },

    reset: ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            MFARepository.reset(rootGetters['admin_userDetail/id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit(
                            'admin_userDetail/update',
                            { key: 'user', data: { multiFactorAuth: { active: false } } },
                            { root: true }
                        );

                        resolve();
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

export default {
    namespaced: true,
    state: {},
    actions,
    mutations: {},
    getters: {}
};
