<template>
    <div>
        <svg
            v-if="!checked"
            :width="small ? 24 : 32"
            :height="small ? 24 : 32"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24Z"
                fill="#00000000"
                stroke="#000000"
                stroke-opacity="0.14"
                stroke-width="1.5"
            />
        </svg>

        <svg
            v-if="checked"
            :width="small ? 24 : 32"
            :height="small ? 24 : 32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#833EAC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.1667 11.8293C22.6111 12.2686 22.6111 12.9809 22.1667 13.4203L15.3392 20.1703C15.1217 20.3853 14.8254 20.5041 14.5178 20.4996C14.2103 20.4952 13.9176 20.3678 13.7066 20.1465L10.2392 16.5109C9.80806 16.0589 9.82923 15.3469 10.2865 14.9206C10.7437 14.4944 11.4639 14.5153 11.895 14.9674L14.5585 17.76L20.5575 11.8293C21.0018 11.3899 21.7223 11.3899 22.1667 11.8293Z"
                fill="white"
            />
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'VtCheckboxIcon',
        props: {
            small: Boolean,
            checked: Boolean
        }
    };
</script>
