<template>
    <vt-sign-in v-if="!isFirstRegistrationStep && !userFetched" :namespace="namespace"></vt-sign-in>

    <router-view v-else />
</template>

<script>
    import VtSignIn from './VtSignIn';

    import { LOGIN_NAMESPACE_USERS } from '@lb-world/core/public/utils/auth';

    import { checkAccessToken, userFetch } from '@/middleware/auth';

    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'UserSignIn',

        components: { VtSignIn },

        data: () => ({
            namespace: LOGIN_NAMESPACE_USERS,
            allowedQueryParameters: ['limitRequest']
        }),

        computed: {
            userFetched() {
                return this.$store.getters['user/user:fetched'];
            },
            tokenFetched() {
                return this.$store.getters['auth/token:fetched'];
            },
            isFirstRegistrationStep() {
                return this.$route.name === 'registration:user';
            }
        },

        methods: {
            checkAccessToken() {
                if (this.userFetched) {
                    return;
                }

                const fallbackRoute = {
                    path: '/',
                    query: {}
                };

                const route = this.$route.query.redirect
                    ? { path: this.$route.query.redirect }
                    : { name: 'account:services' };

                route.query = {};

                for (const routeQuery in this.$route.query) {
                    if (this.allowedQueryParameters.includes(routeQuery)) {
                        route.query[routeQuery] = this.$route.query[routeQuery];
                    }
                }

                checkAccessToken(this.namespace)
                    .then(() => {
                        userFetch()
                            .then(() => {
                                this.pushRoute(route);
                            })
                            .catch(error => {
                                if (error) EventBus.$emit('snackbar:danger:show', error);
                            });
                    })
                    .catch(route => {
                        this.pushRoute(route ?? fallbackRoute);
                    });
            },
            pushRoute(route) {
                this.$log.debug(
                    '[SYSTEM] Pushing route (from, to)',
                    this.$route?.path,
                    route === 'object' ? route.path : route
                );

                const routePath = typeof route === 'object' ? route.path : route;

                if (this.$route?.path !== routePath) {
                    this.$router.replace(route);
                }
            }
        },

        updated() {
            this.$log.debug('[SYSTEM] UserSignIn component updated');

            this.checkAccessToken();
        },

        mounted() {
            this.$log.debug('[SYSTEM] UserSignIn component mounted');

            this.checkAccessToken();
        }
    };
</script>

<style scoped></style>
