<template>
    <div class="app-document-storage">
        <document-upload-dialog :dialog="uploadFileDialog" @document:uploaded="onFileAdded" />

        <app-error v-if="storageError" fetching horizontal />

        <app-row v-else-if="!storageFetched">
            <app-registration-col whole-screen>
                <app-card>
                    <app-row>
                        <app-registration-col>
                            <app-loader>{{ $t('table.loadingData') }}</app-loader>
                        </app-registration-col>
                    </app-row>
                </app-card>
            </app-registration-col>
        </app-row>

        <app-row v-else>
            <app-registration-col whole-screen>
                <v-expansion-panels v-model="openedPanels">
                    <app-expansion-panel :title="$t('admin.documents.storage.panels.filesManagement.title')">
                        <app-row>
                            <app-registration-col>
                                <p class="popover-category text-center text-sm-start">
                                    {{ $t('admin.documents.storage.panels.filesManagement.uploadFile.title') }}
                                </p>
                            </app-registration-col>
                        </app-row>

                        <app-text>
                            {{ $t('admin.documents.storage.panels.filesManagement.uploadFile.description') }}
                        </app-text>

                        <app-row>
                            <app-registration-col field>
                                <app-text-field
                                    :label="$t('admin.documents.storage.panels.filesManagement.uploadFile.fileName')"
                                    v-model="uploadFileData.name"
                                />
                            </app-registration-col>

                            <app-registration-col field>
                                <app-text-field
                                    :label="
                                        $t('admin.documents.storage.panels.filesManagement.uploadFile.fileDescription')
                                    "
                                    v-model="uploadFileData.description"
                                />
                            </app-registration-col>
                        </app-row>

                        <app-row>
                            <app-registration-col field>
                                <app-select-field
                                    v-model="uploadFileData.categoryId"
                                    :items="categories"
                                    item-text="title"
                                    item-value="id"
                                    :label="$t('admin.documents.storage.panels.filesManagement.uploadFile.category')"
                                />
                            </app-registration-col>

                            <app-registration-col field>
                                <button-wrapper justify="start">
                                    <button-primary full-width @click="createFile" :loading="uploadFileLoadingButton">
                                        {{ $t('admin.documents.storage.panels.filesManagement.uploadFile.button') }}
                                    </button-primary>
                                </button-wrapper>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>

                    <app-expansion-panel :title="$t('admin.documents.storage.panels.categoriesManagement.title')">
                        <app-row>
                            <app-registration-col>
                                <p class="popover-category text-center text-sm-start">
                                    {{ $t('admin.documents.storage.panels.categoriesManagement.createCategory.title') }}
                                </p>
                            </app-registration-col>
                        </app-row>

                        <app-text>
                            {{ $t('admin.documents.storage.panels.categoriesManagement.createCategory.description') }}
                        </app-text>

                        <app-flex>
                            <app-flex-item class="three-items">
                                <app-text-field
                                    :label="
                                        $t(
                                            'admin.documents.storage.panels.categoriesManagement.createCategory.categoryName'
                                        )
                                    "
                                    v-model="addCategoryData.name"
                                />
                            </app-flex-item>

                            <app-flex-item class="three-items">
                                <app-text-field
                                    :label="
                                        $t(
                                            'admin.documents.storage.panels.categoriesManagement.createCategory.categoryDescription'
                                        )
                                    "
                                    v-model="addCategoryData.description"
                                />
                            </app-flex-item>

                            <app-flex-item class="three-items">
                                <button-wrapper justify="start">
                                    <button-primary
                                        full-width
                                        @click="createCategory"
                                        :loading="addCategoryLoadingButton"
                                    >
                                        {{
                                            $t(
                                                'admin.documents.storage.panels.categoriesManagement.createCategory.button'
                                            )
                                        }}
                                    </button-primary>
                                </button-wrapper>
                            </app-flex-item>
                        </app-flex>

                        <app-divider />

                        <app-row>
                            <app-registration-col>
                                <p class="popover-category text-center text-sm-start">
                                    {{ $t('admin.documents.storage.panels.categoriesManagement.removeCategory.title') }}
                                </p>
                            </app-registration-col>
                        </app-row>

                        <app-text>
                            {{ $t('admin.documents.storage.panels.categoriesManagement.removeCategory.description') }}
                        </app-text>

                        <app-row class="mt-3">
                            <app-registration-col field>
                                <app-select-field
                                    v-model="removeCategoryId"
                                    :items="categories"
                                    item-text="title"
                                    item-value="id"
                                    :label="
                                        $t(
                                            'admin.documents.storage.panels.categoriesManagement.removeCategory.category'
                                        )
                                    "
                                />
                            </app-registration-col>

                            <app-registration-col field>
                                <button-primary
                                    full-width
                                    @click="removeCategory"
                                    :loading="removeCategoryLoadingButton"
                                >
                                    {{
                                        $t('admin.documents.storage.panels.categoriesManagement.removeCategory.button')
                                    }}
                                </button-primary>
                            </app-registration-col>
                        </app-row>
                    </app-expansion-panel>
                </v-expansion-panels>
            </app-registration-col>
        </app-row>

        <app-divider />

        <app-title>{{ $t('admin.documents.storage.categories.title') }}</app-title>

        <app-error v-if="storageError" fetching horizontal />

        <app-row v-else-if="!storageFetched">
            <app-registration-col whole-screen>
                <app-card>
                    <app-row>
                        <app-registration-col>
                            <app-loader>{{ $t('table.loadingData') }}</app-loader>
                        </app-registration-col>
                    </app-row>
                </app-card>
            </app-registration-col>
        </app-row>

        <div v-else>
            <app-text>
                {{ $t('admin.documents.storage.categories.description') }}
            </app-text>

            <app-row>
                <app-registration-col>
                    <button-primary
                        full-width
                        @click="saveOrder"
                        :disabled="!saveOrderButtonAvailable"
                        :loading="saveOrderLoadingButton"
                        >{{ $t('admin.documents.storage.categories.saveOrder') }}</button-primary
                    >
                </app-registration-col>
            </app-row>

            <app-row v-if="!storageCategories || storageCategories.length <= 0" class="mt-3">
                <app-registration-col whole-screen>
                    <app-card>
                        <p class="text-start ml-6">{{ $t('common.noData') }}</p>
                    </app-card>
                </app-registration-col>
            </app-row>

            <app-row v-else class="mt-3">
                <app-registration-col whole-screen>
                    <v-list outlined>
                        <draggable
                            v-model="storageCategories"
                            class="list-group"
                            ghost-class="ghost"
                            @update="saveOrderButtonAvailable = true"
                        >
                            <v-list-item v-for="category in storageCategories" :key="category.id">
                                <v-list-group
                                    style="width: 100%"
                                    :disabled="getFilesCount(category) <= 0"
                                    :value="getFilesCount(category) <= 0 ? false : undefined"
                                >
                                    <template #activator>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <p class="popover-category">{{ category.name }}</p>
                                            </v-list-item-title>

                                            <v-list-item-subtitle v-if="category.description" class="mt-1">
                                                <p>{{ category.description }}</p>
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle>
                                                <div class="d-flex align-center mt-1">
                                                    <font-awesome-icon :icon="['fad', 'folder-open']" v-color:primary />
                                                    <p class="mb-0 ml-2">
                                                        {{ getFilesCount(category) }}
                                                        {{
                                                            $tc(
                                                                'admin.documents.storage.categories.files',
                                                                getFilesCount(category)
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                    <v-list-item>
                                        <v-list-item-content class="inner">
                                            <div class="ml-9">
                                                <app-divider :width-margin="0" :bottom-margin="0" />

                                                <draggable
                                                    v-model="category.files"
                                                    class="list-group"
                                                    ghost-class="ghost"
                                                    @update="saveOrderButtonAvailable = true"
                                                >
                                                    <div
                                                        v-for="file in category.files"
                                                        :key="file.id"
                                                        class="subitem__inner"
                                                    >
                                                        <div class="d-flex ml-3">
                                                            <font-awesome-icon
                                                                class="mt-1"
                                                                :icon="['fad', 'folder-open']"
                                                                v-color:primary
                                                            />

                                                            <div style="width: 100%">
                                                                <v-list-item-title>
                                                                    <p class="popover-category">
                                                                        {{ file.name }}
                                                                    </p>
                                                                </v-list-item-title>

                                                                <v-list-item-subtitle class="mt-1">
                                                                    <p>{{ file.description }}</p>
                                                                </v-list-item-subtitle>

                                                                <v-list-item-subtitle class="mt-1">
                                                                    <button-secondary
                                                                        icon="download"
                                                                        inline
                                                                        @click="downloadFile(file.id)"
                                                                        :loading="downloadFileButtonLoading[file.id]"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'admin.documents.storage.categories.download'
                                                                            )
                                                                        }}
                                                                    </button-secondary>

                                                                    <button-secondary
                                                                        v-color:danger
                                                                        class="ml-3"
                                                                        icon="trash-alt"
                                                                        inline
                                                                        @click="removeFile(file.id)"
                                                                        :loading="removeFileButtonLoading[file.id]"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'admin.documents.storage.categories.remove'
                                                                            )
                                                                        }}
                                                                    </button-secondary>
                                                                </v-list-item-subtitle>
                                                            </div>
                                                        </div>

                                                        <app-divider :width-margin="0" :bottom-margin="0" />
                                                    </div>
                                                </draggable>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list-item>
                        </draggable>
                    </v-list>
                </app-registration-col>
            </app-row>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { DocumentSet } from '@/store/admin/storage';

    import draggable from 'vuedraggable';
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppSelectField from '@/components/ui/form/AppSelectField';
    import AppTextField from '@/components/ui/form/AppTextField';
    import AppFlex from '@/components/ui/layout/AppFlex';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import DocumentUploadDialog from '@/components/ui/dialogs/DocumentUploadDialog';
    import AppExpansionPanel from '@lb-world/core/public/components/expansionPanels/AppExpansionPanel';

    export default {
        name: 'DocumentsStorage',

        components: {
            AppExpansionPanel,
            DocumentUploadDialog,
            AppFlexItem,
            AppFlex,
            AppTextField,
            AppSelectField,
            draggable
        },

        computed: {
            ...mapGetters('admin_storage', ['storage', 'storageError', 'storageFetched', 'categories']),

            storageCategories: {
                set(v) {
                    this.$set(this.$data, '_storageCategories', v);
                },
                get() {
                    return this.$data._storageCategories ?? this.categories;
                }
            },

            token() {
                return this.$store.getters['auth/token'];
            }
        },

        data: () => ({
            uploadFileDialog: {
                open: false
            },

            openedPanels: [],

            saveOrderButtonAvailable: false,
            saveOrderLoadingButton: false,

            uploadFileLoadingButton: false,
            uploadFileData: {
                name: '',
                description: '',
                categoryId: ''
            },

            removeCategoryLoadingButton: false,
            removeCategoryId: '',

            removeFileButtonLoading: {},
            downloadFileButtonLoading: {},

            addCategoryLoadingButton: false,
            addCategoryData: {
                name: '',
                description: ''
            },

            _storageCategories: null
        }),

        methods: {
            getFilesCount(category) {
                return category?.files?.length ?? '-';
            },
            createCategory() {
                this.addCategoryLoadingButton = true;

                this.$store
                    .dispatch('admin_storage/category:create', this.addCategoryData)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.storageChangesExecuted'));

                        this.addCategoryData.name = '';
                        this.addCategoryData.description = '';
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.addCategoryLoadingButton = false;
                    });
            },
            removeCategory() {
                this.removeCategoryLoadingButton = true;

                this.$store
                    .dispatch('admin_storage/category:remove', this.removeCategoryId)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.storageChangesExecuted'));

                        this.removeCategoryId = '';
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.removeCategoryLoadingButton = false;
                    });
            },
            createFile() {
                this.uploadFileDialog.documentSet = DocumentSet.STORAGE_FILE;
                this.uploadFileDialog.confirmBody = this.uploadFileData;
                this.uploadFileDialog.token = this.token;
                this.uploadFileDialog.open = true;
            },
            onFileAdded() {
                this.uploadFileData.categoryId = '';
                this.uploadFileData.description = '';
                this.uploadFileData.name = '';
            },
            downloadFile(fileId) {
                this.downloadFileButtonLoading[fileId] = true;

                const windowRef = window.open();

                this.$store
                    .dispatch('admin_storage/file:download', fileId)
                    .then(url => {
                        windowRef.location.href = url;
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.downloadFileButtonLoading[fileId] = false;
                    });
            },
            removeFile(fileId) {
                this.removeFileButtonLoading[fileId] = true;

                this.$store
                    .dispatch('admin_storage/file:remove', fileId)
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.storageChangesExecuted'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.removeFileButtonLoading[fileId] = false;
                    });
            },
            saveOrder() {
                this.saveOrderButtonAvailable = false;
                this.saveOrderLoadingButton = true;

                const categoryOrder = [];
                const fileOrder = {};

                for (const index in this.storageCategories) {
                    const category = this.storageCategories[index];

                    categoryOrder.push(category.id);

                    fileOrder[category.id] = category.files?.reduce((acc, category) => {
                        acc.push(category.id);

                        return acc;
                    }, []);
                }

                this.$store
                    .dispatch('admin_storage/storage:update', { categoryOrder, fileOrder })
                    .then(() => {
                        EventBus.$emit('snackbar:show:success', this.$t('success.storageChangesExecuted'));
                    })
                    .catch(error => {
                        EventBus.$emit('snackbar:show:danger', error);
                    })
                    .finally(() => {
                        this.saveOrderLoadingButton = false;
                    });
            }
        },

        mounted() {
            this.$store.dispatch('admin_storage/storage:fetch').then(() => {
                for (const cIndex in this.storageCategories) {
                    if (this.storageCategories[cIndex]?.files.length) {
                        for (const fIndex in this.storageCategories[cIndex].files) {
                            const file = this.storageCategories[cIndex].files[fIndex];

                            if (file) {
                                this.$set(this.removeFileButtonLoading, file.id, false);
                                this.$set(this.downloadFileButtonLoading, file.id, false);
                            }
                        }
                    }
                }
            });
        }
    };
</script>

<style scoped lang="scss">
    .subitem__inner {
        padding: 16px 0px 0;
    }

    .v-list-item__content.inner {
        padding: 0 16px 16px;
    }

    .v-list-item__content {
        padding: 16px 0 16px;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        padding: 0 16px;
    }
    .v-sheet.v-list {
        padding: 16px 0;
    }
    .v-list-item {
        padding: 0;
    }
    .v-list-item__title {
        text-align: start;
    }

    .v-list-item__subtitle p {
        text-align: start !important;
    }

    .ghost {
        opacity: 0.35;
        background: colors.$color-primary-50;
    }
</style>
