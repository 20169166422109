<template>
    <v-app class="app">
        <app-snackbar />

        <mfa-dialog :dialog="mfaDialog" :mode="mfaDialog.mode" :callback="mfaDialog.callback" />

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import AppSnackbar from './components/ui/AppSnackbar';
    import MfaDialog from '@/components/module-user/account/MfaDialog';

    export default {
        name: 'App',

        components: { MfaDialog, AppSnackbar },

        data: () => ({
            mfaDialog: {
                open: false,
                mode: null,
                callback: null
            }
        }),

        created() {
            EventBus.$on('jwt:refresh', () => {
                this.$log.debug('[JWT] Signal to refresh token received, dispatching action');

                this.$store
                    .dispatch('auth/token:store')
                    .then(({ expiration }) => {
                        this.$store.dispatch('auth/token:refresh', expiration);
                    })
                    .catch(({ error }) => {
                        this.$log.debug('[JWT] Dispatch action to refresh token failed');

                        EventBus.$emit('snackbar:show:danger', error);

                        this.$router.push({ name: 'account:services' });
                    });
            });

            EventBus.$on('mfa:activate', ({ mode, callback }) => {
                this.$set(this.mfaDialog, 'open', true);
                this.$set(this.mfaDialog, 'mode', mode);
                this.$set(this.mfaDialog, 'callback', callback);
            });
        }
    };
</script>

<style lang="scss">
    @import 'scss/main';
</style>
