<template>
    <div>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    rules="required"
                    :label="
                        $t('address.streetLine', {
                            number: 1
                        })
                    "
                    v-model="company.address.line1"
                    readonly
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    :label="
                        $t('address.streetLine', {
                            number: 2
                        })
                    "
                    v-model="company.address.line2"
                    readonly
                    :disabled="company.address.line2 === ''"
                ></app-text-field>
            </app-registration-col>
        </app-row>
        <app-row>
            <app-registration-col field>
                <app-text-field
                    rules="required"
                    :label="$t('address.city')"
                    v-model="company.address.city"
                    readonly
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field>
                <app-text-field
                    rules="required"
                    :label="$t('address.postalCode')"
                    v-model="company.address.postalCode"
                    readonly
                ></app-text-field>
            </app-registration-col>
        </app-row>

        <app-row>
            <app-registration-col field>
                <app-text-field
                    :label="$t('address.country')"
                    :value="parseCountry(company.address.country)"
                    rules="required"
                    readonly
                    :disabled="parseCountry(company.address.country) === null"
                ></app-text-field>
            </app-registration-col>
            <app-registration-col field />
        </app-row>
    </div>
</template>

<script>
    import AppTextField from '@/components/ui/form/AppTextField';

    import countries, { convertCodeToName } from '@/countries';

    export default {
        name: 'CompanyAddress',

        components: { AppTextField },

        props: ['company'],

        methods: {
            parseCountry(country) {
                return convertCodeToName(country, countries);
            }
        }
    };
</script>

<style scoped></style>
