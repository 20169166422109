<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-row class="mb-2">
            <v-col
                :class="{ 'color-red': errors.length }"
                cols="12"
                class="font-weight-thin text-caption ml-2 text-thin mb-n6 text-left font-size-12"
            >
                <div>{{ $attrs.label }}</div>
                <div class="mr-4" v-if="false && optional">{{ $t('forms.optionalField') }}</div>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    single-line
                    :type="fieldType"
                    :step="step"
                    outlined
                    v-model="innerValue"
                    v-bind="$attrs"
                    v-on="$listeners"
                    :hide-details="hideHint && !errors"
                    :persistent-hint="optional"
                    :hint="optional ? $t('forms.optionalField') : ''"
                    :error-messages="errors"
                    :readonly="readonly"
                    :label="placeholder || $attrs.label"
                    class="border-radius-8"
                >
                    <template #prepend-inner v-if="$slots['prepend']">
                        <span class="v-input__prepend-append-text">
                            <slot name="prepend"> </slot>
                        </span>
                        <div class="v-input__prepend-append-divider v-input__prepend-divider"></div>
                    </template>

                    <template #append v-if="$slots['append']">
                        <div class="v-input__prepend-append-divider v-input__append-divider"></div>

                        <span class="v-input__prepend-append-text v-input__append-text">
                            <slot name="append"> </slot>
                        </span>
                    </template>

                    <template #append v-else-if="!$slots['append'] && type === 'password'">
                        <div class="v-input__prepend-append-divider v-input__append-divider"></div>

                        <span class="v-input__prepend-append-text v-input__append-text cursor-pointer">
                            <font-awesome-icon @click="displayPassword" :icon="['fad', 'eye']" />
                        </span>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppTextField',

        components: {
            ValidationProvider
        },

        data: () => ({
            innerValue: '',
            fieldType: ''
        }),

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },
            value: { type: null },
            readonly: {
                type: Boolean
            },
            optional: {
                type: Boolean,
                default: false
            },
            hideHint: {
                type: Boolean
            },
            type: {
                type: String,
                default: 'text'
            },
            placeholder: {
                type: String,
                default: ''
            },
            step: {
                type: Number
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        methods: {
            displayPassword() {
                this.fieldType = this.fieldType === 'password' ? 'type' : 'password';
            }
        },

        created() {
            if (this.value) {
                this.innerValue = this.value;
            }

            this.fieldType = this.type;
        }
    };
</script>

<style lang="scss" scoped>
    .color-red {
        color: red !important;
    }
    .font-size-12 {
        font-size: 12px;
    }
    .border-radius-8 {
        border-radius: 8px !important;
    }
</style>
