import axios from 'axios';

import Vue from 'vue';

import {
    API_SERVER_ERROR,
    API_INTERNAL_ERROR,
    parseErrorMessageFromAPI,
    JWTRefreshOnExpiration,
    JWTDecode,
    getWorldAppUrl
} from '@/utils';

import { LOGIN_NAMESPACE_ADMIN } from '@lb-world/core/public/utils/auth';

import AuthRepository from '@lb-world/core/public/api/repositories/AuthRepository';
import UserRepository from '@lb-world/core/public/api/repositories/UserRepository';

import { UserRoles } from '@lb-world/core/public/static/roles';

const state = {
    token: '',
    tokenFetched: false,
    decodedToken: {}
};

const getters = {
    token: state => state.token,
    'token:fetched': state => state.tokenFetched,
    'token:id': state => state.decodedToken.id,
    'token:namespace': state => state.decodedToken.namespace,
    'token:username': state => state.decodedToken.username,
    'token:isAdmin': state => state.decodedToken.namespace === LOGIN_NAMESPACE_ADMIN,
    'token:role': state => role => UserRoles.hasRole(state.decodedToken, role)
};

const isOTPRequired = error => {
    return (
        error?.status === 403 &&
        error.data?.data?.errorKey === 'invalid_request' &&
        error?.data?.data?.attributes[0] === 'otp'
    );
};

const actions = {
    'user:login': (_, { email, password, namespace, continueUrl = getWorldAppUrl(), rememberMe = false, otp }) => {
        Vue.$log.debug('[ACTION] Running action with API call', email, password, namespace, rememberMe, continueUrl);

        let request = {
            email,
            password,
            namespace,
            rememberMe,
            continue: continueUrl
        };

        if (namespace === LOGIN_NAMESPACE_ADMIN) {
            request.continue += '/admin';
        }

        if (otp) {
            request.otp = otp;
        }

        return new Promise((resolve, reject) => {
            AuthRepository.login(request)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const redirect = response.data.redirect;

                    if (redirect) {
                        resolve(redirect);
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    if (isOTPRequired(error)) {
                        reject({ otpRequired: true });
                    } else {
                        reject({ error: parseErrorMessageFromAPI(error) });
                    }
                });
        });
    },
    'user:fetch': ({ commit, getters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            UserRepository.get(getters['token:id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const user = response.data;

                    if (user) {
                        commit('user/store', user, { root: true });

                        resolve(user);
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:logout': ({ commit, getters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AuthRepository.logout()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (getters['token:namespace'] === LOGIN_NAMESPACE_ADMIN) {
                        commit('admin/account:clear', null, { root: true });
                    } else {
                        commit('user/clear', null, { root: true });
                    }

                    commit('user:logout');

                    resolve();
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('user/clear', null, { root: true });
            commit('user:logout');

            resolve();
        });
    },
    'user:logout:force': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('user:logout');

            resolve();
        });
    },
    'token:refresh': (_, expiration) => {
        Vue.$log.debug('[ACTION] Running action with API call', expiration);

        return new Promise((resolve, reject) => {
            if (expiration) {
                JWTRefreshOnExpiration(expiration);

                resolve();
            } else {
                reject(API_INTERNAL_ERROR);
            }
        });
    },
    'token:store': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AuthRepository.checkToken()
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    const token = response.data.accessToken;
                    const expiration = response.data.expiration;

                    const decodedToken = JWTDecode(token);

                    if (token && expiration && decodedToken) {
                        commit('token:store', { token, decodedToken });

                        resolve({ token, expiration, decodedToken });
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject({
                        error: parseErrorMessageFromAPI(error),
                        statusCode: error?.status
                    });
                })
                .finally(() => {
                    commit('token:fetched');
                });
        });
    }
};

const mutations = {
    'token:fetched': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.tokenFetched = true;
    },
    'token:store': (state, { token, decodedToken }) => {
        Vue.$log.debug('[MUTATION] Running mutation', token, decodedToken);

        state.token = token;
        state.decodedToken = decodedToken;

        axios.defaults.headers.common['Authorization'] = token;
    },
    'user:logout': state => {
        Vue.$log.debug('[MUTATION] Running mutation');

        state.user = {};

        state.decodedToken = {};
        state.token = '';

        delete axios.defaults.headers.common['Authorization'];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
