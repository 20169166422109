import { API_INTERNAL_ERROR, parseErrorMessageFromAPI } from '@/utils';

import AMLType, { AMLLimits } from '@lb-world/core/public/models/AMLLimit';

import Vue from 'vue';

import TableDataset from '@lb-world/core/public/models/store/TableDataset';
import BaseDataset from '@lb-world/core/public/models/store/BaseDataset';
import AMLRepository from '@lb-world/core/public/api/repositories/AMLRepository';

export const baseLimits = {
    limit1: new AMLType(AMLLimits.LIMIT1, 'Limit 1', undefined, 40000),
    limit2: new AMLType(AMLLimits.LIMIT2, 'Limit 2', 40000, undefined)
};

const state = {
    users: new TableDataset(),
    userLimit: new BaseDataset(baseLimits)
};

const getters = {
    users: state => state.users.data?.items ?? [],
    usersFetched: state => state.users.fetched,
    usersError: state => state.users.fetchError,
    usersFilter: state => state.users.filter,
    usersPages: state => state.users.getPages(),

    userLimit: state => state.userLimit.data,
    userLimitFetched: state => state.userLimit.fetched,
    userLimitError: state => state.userLimit.fetchError
};

const actions = {
    'users:fetch': ({ commit }, { page = 1, filters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUsers({ columnOptions: { filters }, apiOptions: { page } })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', {
                            key: 'users',
                            data: response.data,
                            filter: filters
                        });

                        resolve();
                    } else {
                        commit('error', 'users');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'users');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'users:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'users');

            resolve();
        });
    },
    'user:fetch': ({ commit, rootGetters }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.getUserState(rootGetters['admin_userDetail/id'])
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store:limits', {
                            key: 'userLimit',
                            data: response.data
                        });

                        resolve();
                    } else {
                        commit('error', 'userLimit');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'userLimit');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            Vue.$log.debug('[ACTION] Received response');

            commit('clear', 'userLimit');

            resolve();
        });
    },
    'user:approve': ({ commit, rootGetters }, type) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.approveLimit(rootGetters['admin_userDetail/id'], type)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store:limits', {
                            key: 'userLimit',
                            data: response.data
                        });

                        commit('replace', { key: 'users', user: response.data });

                        resolve();
                    } else {
                        commit('error', 'userLimit');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'userLimit');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:reset': ({ commit, rootGetters }, type) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.resetLimit(rootGetters['admin_userDetail/id'], type)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store:limits', {
                            key: 'userLimit',
                            data: response.data
                        });

                        commit('replace', { key: 'users', user: response.data });

                        resolve();
                    } else {
                        commit('error', 'userLimit');

                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'userLimit');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'user:upload': ({ rootGetters }, data) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            AMLRepository.updateUserState(rootGetters['admin_userDetail/id'], data)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_INTERNAL_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    replace: (state, { key, user }) => {
        state[key].replaceItem(u => u.userId === user.userId, user);
    },
    store: (state, { key, data, filter }) => {
        state[key].storeData(data, filter);
    },
    'store:limits': (state, { key, data }) => {
        const fn = function(response) {
            for (const index in this) {
                const aml = this[index];

                this[aml.id].setServerData(response[aml.id]);
            }
        };

        state[key].storeData(data, fn);
    },
    clear: (state, key) => {
        state[key].clearData();
    },
    error: (state, key) => {
        state[key].storeError();
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
