<template>
    <app-container page-padding shadow class="base-overlay-vt-container">
        <app-row justify="center">
            <app-registration-col whole-screen>
                <div class="not-found-container">
                    <font-awesome-icon size="2x" :icon="['fad', icon]" v-color:primary />

                    <p class="not-found-container__title">
                        {{ title }}
                    </p>

                    <p class="not-found-container__description">
                        {{ description }}
                    </p>

                    <p
                        v-if="additionalDescription"
                        class="not-found-container__description not-found-container--additional"
                    >
                        {{ additionalDescription }}
                    </p>

                    <button-primary
                        class="not-found-container__button"
                        :loading="buttonLoading"
                        @click="$emit('button:click')"
                    >
                        {{ buttonTitle }}
                    </button-primary>
                </div>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    export default {
        name: 'BaseOverlay',

        props: ['icon', 'title', 'description', 'additionalDescription', 'buttonTitle', 'buttonLoading']
    };
</script>

<style scoped lang="scss"></style>
