<template>
    <v-dialog transition="slide-y-transition" v-model="dialog.open" max-width="1000" :key="$i18n.locale">
        <app-card dialog>
            <app-dialog-title :title="$t('admin.withdrawalConfirmation.dialog.title')" :dialog="dialog" />

            <app-row justify="center" class="mt-6">
                <app-col>
                    <p
                        v-html="
                            $t('admin.withdrawalConfirmation.dialog.label', {
                                id: transactionId
                            })
                        "
                    />
                </app-col>
            </app-row>

            <app-divider />

            <app-row justify="center" v-if="dialog.transaction">
                <app-registration-col whole-screen class="pt-0" style="max-width: 750px">
                    <app-flex>
                        <app-flex-item class="tree-items">
                            <app-text-stack justify="center">
                                <template #key> {{ $t('admin.withdrawalConfirmation.dialog.user') }} </template>

                                {{ dialog.transaction.userId }}
                            </app-text-stack>
                        </app-flex-item>
                        <app-flex-item class="tree-items">
                            <app-text-stack justify="center">
                                <template #key> {{ $t('admin.withdrawalConfirmation.dialog.amount') }} </template>

                                {{
                                    formatAmount(dialog.transaction.withdrawalInfo.amount, dialog.transaction.currency)
                                }}
                                {{ dialog.transaction.currency }}
                            </app-text-stack>
                        </app-flex-item>
                        <app-flex-item class="tree-items">
                            <app-text-stack justify="center">
                                <template #key>
                                    {{ $t('admin.withdrawalConfirmation.dialog.paymentIdentifier') }}
                                </template>

                                {{ transactionPaymentIdentifier }}
                            </app-text-stack>
                        </app-flex-item>
                    </app-flex>
                </app-registration-col>
            </app-row>

            <component ref="withdrawalComponent" :is="currency" :bankAccount="transactionBankAccount" />

            <app-divider />

            <template #button-primary>
                <button-primary @click="processWithdrawal" :loading="withdrawButtonLoading" full-width>
                    {{ $t('admin.withdrawalConfirmation.dialog.confirm') }}
                </button-primary>
            </template>
        </app-card>
    </v-dialog>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    import BankAccount from '@lb-world/core/public/models/user/BankAccount';

    import AppCol from '@/components/ui/layout/AppCol';
    import AppTextField from '@/components/ui/form/AppTextField';
    import WithdrawalCZK from '@/components/ui/dialogs/withdrawalDialog/WithdrawalCZK';
    import WithdrawalEFK from '@/components/ui/dialogs/withdrawalDialog/WithdrawalEFK';
    import AppFlexItem from '@/components/ui/layout/AppFlexItem';
    import AppFlex from '@/components/ui/layout/AppFlex';

    export default {
        name: 'WithdrawalConfirmationDialog',

        props: ['dialog'],

        components: {
            AppFlex,
            AppFlexItem,
            AppTextField,
            AppCol,

            CZK: WithdrawalCZK,
            EFK: WithdrawalEFK
        },

        data: () => ({
            withdrawButtonLoading: false,

            currency: '',
            transaction: '',

            withdrawalInfo: {
                transactionHash: ''
            }
        }),

        computed: {
            transactionId() {
                return this.transaction?.id ?? '';
            },
            transactionBankAccount() {
                const bankAccountInfo = this.transaction?.withdrawalInfo?.bankAccount;

                if (bankAccountInfo) {
                    const bankAccount = new BankAccount();

                    bankAccount.storeData(bankAccountInfo);

                    return bankAccount;
                }

                return null;
            },
            transactionPaymentIdentifier() {
                return this.transaction?.withdrawalInfo?.paymentIdentifier ?? '-';
            }
        },

        methods: {
            processWithdrawal() {
                this.withdrawButtonLoading = true;

                this.$refs['withdrawalComponent'].$refs['formCurrencyWithdrawalDialog' + this.currency]
                    .validate()
                    .then(result => {
                        if (result) {
                            const transactionId = this.dialog.transaction.id;

                            this.$store
                                .dispatch('admin/withdrawalTransactions:accept', {
                                    transactionId,
                                    data: this.withdrawalInfo
                                })
                                .then(() => {
                                    EventBus.$emit('snackbar:show:success', this.$t('success.transactionExecuted'));

                                    this.dialog.open = false;

                                    this.$emit('transaction:processed');
                                })
                                .catch(error => {
                                    EventBus.$emit('snackbar:show:danger', error);
                                })
                                .finally(() => {
                                    this.withdrawButtonLoading = false;
                                });
                        } else {
                            this.withdrawButtonLoading = false;
                        }
                    })
                    .catch(() => {
                        this.withdrawButtonLoading = false;
                    });
            }
        },

        watch: {
            'dialog.open': {
                immediate: true,
                handler(opened) {
                    if (opened) {
                        this.withdrawal = null;

                        this.currency = this.dialog.transaction.currency;
                        this.transaction = this.dialog.transaction;

                        if (this.$refs['withdrawalComponent']) {
                            this.$refs['withdrawalComponent'].resetValues();
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped></style>
