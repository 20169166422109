import axios from 'axios';

import Vue from 'vue';

import { API_INTERNAL_ERROR, API_SERVER_ERROR, parseErrorMessageFromAPI } from '@/utils';

const state = {
    tradeItems: {
        fetched: false,
        fetchError: false,
        data: []
    },
    tradeParameters: {
        fetched: false,
        fetchError: false,
        data: {}
    }
};

const getters = {
    tradeItemsFetched: state => state.tradeItems.fetched,
    tradeItemsError: state => state.tradeItems.fetchError,

    demandItems: state => state.tradeItems.data.demand ?? [],
    supplyItems: state => state.tradeItems.data.supply ?? [],

    tradingParameters: state => state.tradeParameters.data,
    tradingParametersFetched: state => state.tradeParameters.fetched,
    tradingParametersError: state => state.tradeParameters.fetchError,

    tradingRate: state => state.tradeItems.data.exchangeRateUSDCZK
};

const actions = {
    'items:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            axios
                .get('lbb/efk-trading/supply-and-demand')
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'tradeItems', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'tradeItems');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'tradeItems');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'items:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'tradeItems');

            resolve();
        });
    },
    'items:error': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('error', 'tradeItems');

            resolve();
        });
    },
    'parameters:fetch': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise((resolve, reject) => {
            axios
                .get('lbb/efk-trading/parameters')
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'tradeParameters', data: response.data });

                        resolve();
                    } else {
                        commit('error', 'tradeParameters');

                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    commit('error', 'tradeParameters');

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    'parameters:clear': ({ commit }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        return new Promise(resolve => {
            commit('clear', 'tradeParameters');

            resolve();
        });
    },
    'parameters:update': ({ commit }, diffPercentage) => {
        Vue.$log.debug('[ACTION] Running action with API call', diffPercentage);

        return new Promise((resolve, reject) => {
            axios
                .post('lbb/efk-trading/parameters', diffPercentage)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        commit('store', { key: 'tradeParameters', data: response.data });

                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    newTrade: (_, { demandPriceLevel, supplyItems }) => {
        Vue.$log.debug('[ACTION] Running action with API call', demandPriceLevel, supplyItems);

        return new Promise((resolve, reject) => {
            if (supplyItems.length <= 0) {
                reject(API_INTERNAL_ERROR);
            }

            axios
                .post('lbb/efk-trading/trade', { demandPriceLevel, supplyItems })
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    if (response.data) {
                        resolve();
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    },
    export: (_, { name, options, binary }) => {
        Vue.$log.debug('[ACTION] Running action with API call');

        const optionsSuffix = options ? '?' + options : '';
        const binaryOptions = binary ? { responseType: 'blob' } : null;

        return new Promise((resolve, reject) => {
            axios
                .get(`lbb/${name}${optionsSuffix}`, binaryOptions)
                .then(response => {
                    Vue.$log.debug('[ACTION] Received response', response.data);

                    // Export has no data available
                    if (response.status === 204) {
                        reject('export_no_data');
                    } else if (response.data) {
                        const searchKeyword = 'filename="';

                        const filenameHeader = response.headers['content-disposition'];
                        const filenameIndex = filenameHeader?.indexOf(searchKeyword);

                        let filename = 'lb-world_export.csv';

                        if (filenameIndex && filenameIndex !== -1) {
                            filename = filenameHeader.slice(filenameIndex + searchKeyword.length, -1);
                        }

                        resolve({ data: response.data, filename });
                    } else {
                        reject(API_SERVER_ERROR);
                    }
                })
                .catch(error => {
                    Vue.$log.error('[ACTION] Received error', error);

                    reject(parseErrorMessageFromAPI(error));
                });
        });
    }
};

const mutations = {
    store: (state, { key, data }) => {
        Vue.$log.debug('[MUTATION] Running mutation', key, data);

        state[key].fetched = true;
        state[key].fetchError = false;
        state[key].data = data;
    },
    clear: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = false;
        state[key].fetchError = false;
        state[key].data = [];
    },
    error: (state, key) => {
        Vue.$log.debug('[MUTATION] Running mutation', key);

        state[key].fetched = true;
        state[key].fetchError = true;
        state[key].data = [];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
