<template>
    <app-container page-padding unlimited-width>
        <app-row>
            <app-registration-col whole-screen>
                <app-card left>
                    <app-title>{{ $t('admin.dashboard.notifications.title') }}</app-title>

                    <app-text>
                        {{ $t('admin.dashboard.notifications.noNotifications') }}
                    </app-text>
                </app-card>
            </app-registration-col>
        </app-row>
    </app-container>
</template>

<script>
    export default {
        name: 'Dashboard'
    };
</script>

<style scoped></style>
