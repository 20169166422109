<template>
    <div class="mfa-container">
        <v-row>
            <v-col cols="12" class="vt-tab-title">
                <font-awesome-icon :icon="['fad', 'lock-alt']" class="title-icon" />
                {{ $t('bo.mfaWidget.title') }}
                (2FA)
            </v-col>
        </v-row>

        <div class="my-4">
            {{ $t('bo.mfaWidget.description1') }}
        </div>

        <app-row>
            <app-registration-col whole-screen>
                <p>
                    <span class="otp-container">
                        <span v-html="$t('bo.mfaWidget.description2')" class="grayfel" />
                    </span>

                    <span class="otp-container">
                        <span v-html="$t('bo.mfaWidget.description3')" class="grayfel" />
                    </span>
                </p>
            </app-registration-col>
        </app-row>

        <app-text>
            <div class="mfa__status" :class="{ danger: !mfaActive }">
                <div v-color:[iconColor] class="mfa__status__background" />

                <font-awesome-icon :icon="['fas', icon]" v-color:[iconColor] class="mfa__status-icon" />
                <span
                    v-color:[textColor]
                    class="mfa__status-text grayfel"
                    :class="{ 'mfa__status-text--danger': !mfaActive }"
                >
                    {{ $t('bo.mfaWidget.state.' + (mfaActive ? 'enabled' : 'disabled')) }}
                </span>
            </div>
        </app-text>

        <app-row>
            <app-registration-col whole-screen>
                <button-wrapper :justify="$vuetify.breakpoint.smAndUp ? 'start' : 'center'">
                    <vt-button-primary @click="changeMfaSettings" v-if="!mfaActive">
                        <span class="mx-8 grayfel">
                            {{ $t('bo.mfaWidget.button.enable') }}
                        </span>
                    </vt-button-primary>

                    <vt-button-secondary inline icon="power-off" @click="changeMfaSettings" v-else>
                        <span class="ml-2 mr-8 grayfel">
                            {{ $t('bo.mfaWidget.button.disable') }}
                        </span>
                    </vt-button-secondary>
                </button-wrapper>
            </app-registration-col>
        </app-row>

        <app-divider />
    </div>
</template>

<script>
    import EventBus from '@lb-world/core/public/plugins/eventBus';

    export default {
        name: 'VtMfaWidget',

        computed: {
            mfaActive() {
                return this.$store.getters['user/user:mfaActive'];
            },

            icon() {
                return this.mfaActive ? 'check' : 'exclamation-triangle';
            },

            iconColor() {
                return this.mfaActive ? 'success' : 'danger';
            },

            textColor() {
                return this.mfaActive ? 'black' : 'danger';
            }
        },

        methods: {
            toggleSettings({ otp, mode }) {
                return new Promise((resolve, reject) => {
                    this.$store
                        .dispatch('mfa/toggle', { mode, otp })
                        .then(() => {
                            EventBus.$emit('snackbar:show:success', this.$t('success.accountInfoUpdated'));

                            resolve();
                        })
                        .catch(error => {
                            EventBus.$emit('snackbar:show:danger', error);

                            reject();
                        });
                });
            },

            changeMfaSettings() {
                EventBus.$emit('mfa:activate', {
                    mode: this.mfaActive ? 'disable' : 'enable',
                    callback: this.toggleSettings
                });
            }
        }
    };
</script>

<style scoped lang="scss"></style>
