<template>
    <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
        <v-checkbox
            :readonly="readonly"
            :key="p.name"
            v-for="p in items"
            hide-details
            v-model="innerValue"
            :label="$t('' + p.name)"
            :value="p.value"
            style="margin-top: 0; margin-bottom: 8px"
            :error-messages="errors"
        />
    </validation-provider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'AppCheckboxGroup',

        components: {
            ValidationProvider
        },

        props: {
            rules: {
                type: [Object, String],
                default: ''
            },

            items: Array,

            value: {
                required: false
            },

            readonly: Boolean
        },

        data: () => ({
            innerValue: []
        }),

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },

        mounted() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>

<style scoped></style>
